import { InsuranceSubType } from '@main/types/insurance/InsuranceType';

import { InsuranceParameter } from '@main/types/insurance/Parameter';

import {
  InsuranceInformation,
  InsuranceHolderInformation,
  PriceDetails,
  CarInsuranceName,
} from './shared';

export enum CarAddOns {
  'addOnRental' = 'addOnRental',
  'addOnAssistance' = 'addOnAssistance',
  'addOnReducedDeductible' = 'addOnReducedDeductible',
}

export interface CarPrerequisites {
  yearlyMaxMileage: string[];
  deductibles: string[];
  addOns: CarAddOns[];
}

export interface CarInsuranceInformationWithPrereq
  extends Partial<InsuranceInformation<CarInsuranceName>> {
  prerequisites: CarPrerequisites;
  isInitialLoading: boolean;
}

// TODO: Remove CarInsuranceDetails when CarQuoteContext is refactured
export interface CarInsuranceDetails {
  registrationNo: string;
  deductibleVehicleDamage: string;
  yearlyMaxMileage: string;
  underAgedDriver: boolean;
  addOns: CarAddOns[];
  insuranceName: CarInsuranceName;
}

export interface CarInsuredObjectDetails {
  registrationNumber: string;
}

export interface CarInsuranceQuoteDetails {
  insuranceName: CarInsuranceName;
  deductibleVehicleDamage?: string;
  yearlyMaxMileage: string;
  underAgedDriver: boolean;
  addOns?: CarAddOns[];
}

export interface CarInsuranceAcceptedQuoteDetails extends CarInsuranceQuoteDetails {
  insuranceNumber: string;
  startDate: string;
}

export interface CarInsuranceCoverageParameters {
  allCarCoverageParameters: {
    insuranceName: CarInsuranceName;
    parameters?: InsuranceParameter[];
  }[];
  isInitialLoading: boolean;
}

// Payloads

export type CarQuotePayload = {
  insuranceCompany: AvailableInsuranceCompaniesCheckout;
  insuranceSubType: InsuranceSubType.carInsurance;
  insuranceHolderInformation: Pick<InsuranceHolderInformation, 'socialSecurityNumber'> &
    Partial<InsuranceHolderInformation>;
  insuredObjectDetails: CarInsuredObjectDetails;
  insuranceQuoteDetails: CarInsuranceQuoteDetails;
};

export type CarAcceptQuotePayload = {
  insuranceCompany: AvailableInsuranceCompaniesCheckout;
  insuranceSubType: InsuranceSubType.carInsurance;
  insurelyId: string;
  startDate: string;
  contactInformation: {
    email: string;
    phoneNumber?: string;
  };
};

// Responses

export type CarInsuranceInformationResponse = Array<InsuranceInformation<CarInsuranceName>>;

export interface CarPriceEstimateResponse {
  insuranceName: CarInsuranceName;
  priceDetails: PriceDetails;
}

export interface CarQuoteResponse {
  insurelyId: string;
  insuranceCompany: AvailableInsuranceCompaniesCheckout;
  insuranceSubType: InsuranceSubType.carInsurance;
  priceDetails: PriceDetails;
  insuranceHolderInformation: InsuranceHolderInformation;
  insuranceQuoteDetails: CarInsuranceQuoteDetails;
  insuredObjectDetails: CarInsuredObjectDetails;
}

export interface CarAcceptQuoteResponse extends Omit<CarQuoteResponse, 'insuranceQuoteDetails'> {
  insuranceQuoteDetails: CarInsuranceAcceptedQuoteDetails;
  paymentLink: {
    url: string;
  };
}

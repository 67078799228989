import { DescriptionList } from '@insurely/ui';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { PensionAccount } from '@main/types';

import { PlateContainer } from '../../../WealthOverview/components/PlateContainer';

import styles from './pensionDetails.module.css';

interface PensionDetailsProp {
  account: PensionAccount;
}

// TODO: Change PensionDetails when the design is ready
// Linear ticket: https://linear.app/insurely/issue/EXPD-180/implement-pension-details-section

export const PensionDetails = ({ account }: PensionDetailsProp) => {
  const intl = useIntl();

  const { productNumber } = account;

  const descriptionListItems = [
    {
      label: intl.formatMessage({
        id: 'overview.wealth-account.pension-info-term-1',
        defaultMessage: 'Account number',
      }),
      value: productNumber,
    },
    {
      label: intl.formatMessage({
        id: 'overview.wealth-account.pension-info-term-2',
        defaultMessage: 'Start date',
      }),
      value: account.startDate,
    },
    {
      label: intl.formatMessage({
        id: 'overview.wealth-account.pension-info-term-3',
        defaultMessage: 'Current payments',
      }),
      value: '?', // TODO: Change
    },
    {
      label: intl.formatMessage({
        id: 'overview.wealth-account.pension-info-term-4',
        defaultMessage: 'Pay-out plan',
      }),
      value: '?', // TODO: Change
    },
  ];

  return (
    <PlateContainer fullWidth className={classNames(styles.plate)}>
      <DescriptionList
        rows={descriptionListItems}
        labelTextVariant="ParagraphCaption"
        valueTextVariant="Label-2"
        className={styles.descriptionList}
      />
    </PlateContainer>
  );
};

import { formatDate } from '@insurely/ui';

import { da, enGB, es, et, fr, nb, sv } from 'date-fns/locale';

import { Country } from '@main/types';

import { sentryCaptureException } from '../services/sentry';

// TODO: temporary util for accepting date strings in non-ISO formats
export const toIso = (dateString: string): string => dateString.split('/').reverse().join('-');

/*
 * TODO: Temporary fix until we implement ISO-dates
 *  Parses a date in either ISO format or country-specific based on the input
 */
export const parseFrenchOrBritishDateString = (dateString: string): Date | undefined => {
  // Parse date with French/British format
  try {
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
      return new Date(toIso(dateString));
    }
    // Parse date with ISO format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return new Date(dateString);
    }
  } catch {
    return undefined;
  }
};

export const getLocaleByCountry = (country: Country) => {
  switch (country) {
    case Country.no:
      return nb;
    case Country.dk:
      return da;
    case Country.uk:
      return enGB;
    case Country.fr:
      return fr;
    case Country.es:
      return es;
    case Country.ee:
      return et;
    case Country.se:
    default:
      return sv;
  }
};

const safeFormatDate = (dateString: string, country: Country): undefined | string => {
  try {
    const date = new Date(dateString);
    if (!date || Number.isNaN(date.getTime())) {
      sentryCaptureException(`Invalid-date, date: ${date}`);
      return undefined;
    }

    return formatDate(date, getLocaleByCountry(country));
  } catch (error) {
    sentryCaptureException(error);
    return undefined;
  }
};

export const formatLocalDate = (date: string, country: Country): undefined | string => {
  if (country === Country.fr || country === Country.uk) {
    const parsedDate = parseFrenchOrBritishDateString(date);
    return parsedDate && formatDate(parsedDate, getLocaleByCountry(country));
  }

  return safeFormatDate(date, country);
};

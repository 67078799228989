import { WealthGetDataModel } from '@insurely/common-api-client';

import { PayloadInput } from '@main/contexts';
import {
  CollectionStatus,
  DataAggregationFilter,
  InsuranceSubType,
  LoginMethod,
} from '@main/types';

export type InputType = 'EMAIL' | 'MOBILE_PHONE_NUMBER';

export type TwoFactorAuthenticationInstruction = {
  authenticationInstruction: string;
  responseType: TwoFactorResponseTypeEnum;
};

export enum TwoFactorResponseTypeEnum {
  NUMBER_CODE = 'NUMBER_CODE',
  NO_RESPONSE = 'NO_RESPONSE',
}

export type ExtraInformation = {
  ACCESS_TOKEN?: string;
  AUTOSTART_TOKEN?: string;
  QR_CODE_DATA?: string;
  DANISH_NEM_ID_CARD_CODE_LOOKUP?: string;
  NORWEGIAN_BANKID_WORDS?: string;
  SWEDISH_BANKID_QRCODE?: string;
  SWEDISH_MOBILE_BANKID_ANIMATED_QR_DATA?: string;
  SWEDISH_MOBILE_BANKID_AUTOSTART_TOKEN?: string;
  REFERENCE_NUMBER?: string;
  requiredInputTypes?: InputType[];
  TWO_FACTOR_AUTHENTICATION_INSTRUCTION?: TwoFactorAuthenticationInstruction;
};
export interface CollectingItem {
  id: string;
  status: 'not-started' | 'loading' | 'done' | 'not-applicable';
  insuranceSubType?: InsuranceSubType;
  displayName?: string;
}
export interface CollectionStatusSuccessfulResponse {
  id: string;
  insuranceCompany?: string;
  status: CollectionStatus;
  extraInformation: ExtraInformation;
  collectingItems?: CollectingItem[];
}
export type CollectionStatusResponse = CollectionStatusSuccessfulResponse | ResponseError;

export const userActionRequiredStatuses = [
  CollectionStatus.LOGIN,
  CollectionStatus['TWO FACTOR PENDING'],
  CollectionStatus['CONTACT FORM PENDING'],
];

export const completedStatuses = [
  CollectionStatus.COMPLETED,
  CollectionStatus['COMPLETED PARTIAL'],
  CollectionStatus['COMPLETED EMPTY'],
];

export const verificationFailedStatuses = [
  CollectionStatus['INCORRECT CREDENTIALS'],
  CollectionStatus['WAITING FOR AUTHENTICATION'],
  CollectionStatus['AUTHENTICATION CANCELLED'],
  CollectionStatus['AUTHENTICATION CONFLICT'],
  CollectionStatus['AUTHENTICATION MISMATCH'],
];

export const userInputFailedStatuses = [
  CollectionStatus['USER INPUT'],
  CollectionStatus['CONTACT FORM'],
  CollectionStatus['KYC FORM'],
  CollectionStatus['CUSTOMER ENROLLMENT REQUIRED'],
  CollectionStatus['FAILED PDF PARSE'],
  CollectionStatus['FAILED PDF USER INPUT'],
];

export const failedStatuses = [
  CollectionStatus.FAILED,

  ...verificationFailedStatuses,
  ...userInputFailedStatuses,
];

export const finalStatuses = [...completedStatuses, ...failedStatuses];

export enum FailedCollectionStatus {
  START_COLLECTION_FAILED_INVALID_SESSION_ID = 'START_COLLECTION_FAILED_INVALID_SESSION_ID',
  START_COLLECTION_FAILED_CONNECTION_ERROR = 'START_COLLECTION_FAILED_CONNECTION_ERROR',
  STATUS_POLLING_FAILED = 'STATUS_POLLING_FAILED',
  LOADING_RESULTS_FAILED_WRONG_COLLECTION_ID = 'LOADING_RESULTS_FAILED_WRONG_COLLECTION_ID',
  COLLECTION_FAILED_NO_VERIFICATION = 'COLLECTION_FAILED_NO_VERIFICATION',
  COLLECTION_FAILED_USER_INPUT_REQUIRED = 'COLLECTION_FAILED_USER_INPUT_REQUIRED',
  COLLECTION_FAILED_GENERAL_ERROR = 'COLLECTION_FAILED_GENERAL_ERROR',
  COLLECTION_FAILED_INCORRECT_CREDENTIALS = 'COLLECTION_FAILED_INCORRECT_CREDENTIALS',
  COLLECTION_FAILED_PDF_PARSE_FAILED = 'COLLECTION_FAILED_PDF_PARSE_FAILED',
  COLLECTION_FAILED_PDF_USER_INPUT = 'COLLECTION_FAILED_PDF_USER_INPUT',
}

type ResponseError = { message?: string } | null;

export type GetDataResponse = Policy[] | WealthGetDataModel[] | ResponseError;

export type StartCollectionResponse = StartCollectionSuccessfulResponse | ResponseError;

export interface CollectDataError {
  cause: FailedCollectionStatus;
  message?: string;
}

export type MaybeCollectDataError<T> = T | CollectDataError;

export const isGetDataResponse = (data: GetDataResponse): data is Policy[] | WealthGetDataModel[] =>
  !!data && Array.isArray(data);

export const isStartCollectionResponse = (
  data: StartCollectionResponse,
): data is StartCollectionSuccessfulResponse => !!data && ('id' in data || 'collectionId' in data);

export interface StartCollectionPayload {
  input?: PayloadInput;
  insuranceCompany: string;
  loginMethod: LoginMethod | null;
  externalReference?: string;
  filter?: DataAggregationFilter;
}

export type StartCollectionSuccessfulResponse =
  | { id: string; collectionId: never }
  | { id: never; collectionId: string };

export type ApiClient = typeof fetch;

export type ElapsedTimeSeconds = Record<
  keyof Pick<typeof CollectionStatus, 'RUNNING' | 'LOGIN' | 'TWO FACTOR PENDING' | 'COLLECTING'>,
  number
>;

export type PollingStatus =
  | 'stopped'
  | 'notStarted'
  | 'initiated'
  | 'failed'
  | 'pollStarted'
  | 'fetchingResults'
  | 'collecting'
  | 'userActionRequired'
  | 'completed'
  | 'skip'
  | 'skipped'
  | undefined;

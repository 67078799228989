import { CollectionStatus } from '@main/types';

import { defaultElapsedTimeSeconds } from '../constants';
import {
  CollectionStatusResponse,
  CollectionStatusSuccessfulResponse,
  ElapsedTimeSeconds,
} from '../types';

export function isDurationsStatus(
  status?: keyof typeof CollectionStatus,
): status is keyof ElapsedTimeSeconds {
  return !!status && status in defaultElapsedTimeSeconds;
}

export const isCollectionStatusResponse = (
  data: CollectionStatusResponse,
): data is CollectionStatusSuccessfulResponse => !!data && 'id' in data;

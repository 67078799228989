import { useSharedData } from '../contexts';
import { AvailableInsuranceCompanyCheckout } from '../types';

interface useDirect {
  isDirect: boolean;
}

// This hook is meant to be used to have direct specific logic or filter out logic for Direct (fr-direct-assurance)
// The hook will be removed once there is no need for Direct specific logic

export const useDirect = (): useDirect => {
  const [
    {
      clientConfig: { productConfigs },
    },
  ] = useSharedData();

  const clientCompanyName = productConfigs?.compare?.offerCompany?.name;

  return {
    isDirect: clientCompanyName === AvailableInsuranceCompanyCheckout['fr-direct-assurance'],
  };
};

import { useEffect } from 'react';

import { IntlConfig, IntlProvider } from 'react-intl';

import { CustomizedCustomer } from '@main/__customer-implementations__/customization';

import { sentryCaptureException } from '@main/services';
import { Language } from '@main/types';
import { UserConfig } from '@main/types/configurations/moduleInput';

import {
  LazyMessageProvider,
  MessageLoader,
  extendMessages,
  messageProvider,
} from './MessageProvider';

import messagesInfo from './messages/__info__.json';

const languageLoaders = {
  da: () => import('./messages/da.json'),
  en: () => import('./messages/en.json'),
  et: () => import('./messages/et.json'),
  fr: () => import('./messages/fr.json'),
  lv: () => import('./messages/lv.json'),
  no: () => import('./messages/no.json'),
  sv: () => import('./messages/sv.json'),
} satisfies Record<Language, MessageLoader>;

const messageProviders = {
  da: messageProvider({ messageLoader: languageLoaders.da, baseLanguage: Language.da }),
  en: messageProvider({ messageLoader: languageLoaders.en, baseLanguage: Language.en }),
  et: messageProvider({ messageLoader: languageLoaders.et, baseLanguage: Language.et }),
  fr: messageProvider({ messageLoader: languageLoaders.fr, baseLanguage: Language.fr }),
  lv: messageProvider({ messageLoader: languageLoaders.lv, baseLanguage: Language.lv }),
  no: messageProvider({ messageLoader: languageLoaders.no, baseLanguage: Language.no }),
  sv: messageProvider({ messageLoader: languageLoaders.sv, baseLanguage: Language.sv }),
} satisfies Record<Language, LazyMessageProvider>;

export const clientsMessageProviders: Partial<
  Record<CustomizedCustomer, Partial<Record<Language, LazyMessageProvider>> | undefined>
> = {
  [CustomizedCustomer.BNPP]: {
    fr: messageProvider({
      messageLoader: extendMessages(languageLoaders.fr, () => import('./messages/fr_fr-bnpp.json')),
      baseLanguage: Language.fr,
      extensionName: 'fr_fr-bnpp',
    }),
  },
  [CustomizedCustomer.DirectAssurance]: {
    fr: messageProvider({
      messageLoader: extendMessages(
        languageLoaders.fr,
        () => import('./messages/fr_fr-direct-assurance.json'),
      ),
      baseLanguage: Language.fr,
      extensionName: 'fr_fr-direct-assurance',
    }),
  },
  [CustomizedCustomer.Kozoo]: {
    fr: messageProvider({
      messageLoader: extendMessages(
        languageLoaders.fr,
        () => import('./messages/fr_fr-kozoo.json'),
      ),
      baseLanguage: Language.fr,
      extensionName: 'fr_fr-kozoo',
    }),
  },
  [CustomizedCustomer.SPP]: {
    sv: messageProvider({
      messageLoader: extendMessages(languageLoaders.sv, () => import('./messages/sv_se-spp.json')),
      baseLanguage: Language.sv,
      extensionName: 'sv_se-spp',
    }),
  },
};

// empty messages and defaultLocale = locale -> default (source) messages without errors
const DevMessageProvider = ({
  children,
  ...props
}: React.PropsWithChildren<Pick<IntlConfig, 'onError' | 'onWarn'>>) => (
  <IntlProvider {...props} messages={{}} locale="en" defaultLocale="en">
    {children}
  </IntlProvider>
);

interface Props {
  devTranslations?: UserConfig['devTranslations'];
  language: Language;
  customizedCustomer?: CustomizedCustomer | null;
  children: React.ReactNode;
}

export const LanguageProvider = ({
  devTranslations,
  language,
  customizedCustomer,
  children,
}: Props) => {
  const clientMessagesEnabled = !IS_DEVMODE || devTranslations === 'enabled';

  const clientProviders =
    clientMessagesEnabled && customizedCustomer && clientsMessageProviders[customizedCustomer];
  const clientMessageProvider = clientProviders && clientProviders[language];

  if (DEBUG_LOGGING) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const info = messagesInfo as { datetime?: string; commit?: string };
      // eslint-disable-next-line no-console
      console.debug(
        info.datetime
          ? `[i18n] bundle messages ${info.datetime} @ ${info.commit ?? 'development'}`
          : '[i18n] no bundle messages',
      );
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // eslint-disable-next-line no-console
      console.debug(
        IS_DEVMODE && !devTranslations
          ? '[i18n] translations disabled'
          : `[i18n] translations ${language}${
              clientMessageProvider ? `_${customizedCustomer}` : ''
            }`,
      );
    }, [devTranslations, language, customizedCustomer, clientMessageProvider]);
  }

  const onIntlWarn: IntlConfig['onWarn'] = DEBUG_LOGGING
    ? (warning) => console.warn([`[i18n] intl: ${warning}`])
    : undefined;

  const onIntlError: IntlConfig['onError'] = (error) => {
    if (DEBUG_LOGGING) {
      console.error(`[i18n] intl:`, error);
    }
    sentryCaptureException(error);
  };

  if (ENVIRONMENT === 'testrunner' || (IS_DEVMODE && !devTranslations)) {
    return (
      <DevMessageProvider onError={onIntlError} onWarn={onIntlWarn}>
        {children}
      </DevMessageProvider>
    );
  }

  const IntlProviderWithMessages = clientMessageProvider || messageProviders[language];

  return (
    <IntlProviderWithMessages
      onError={onIntlError}
      onWarn={onIntlWarn}
      locale={language}
      defaultLocale="en"
    >
      {children}
    </IntlProviderWithMessages>
  );
};

import { CoreGeneralInsurance } from '@main/types/insurance/core';

export type FrenchGeneralInsurance = CoreGeneralInsurance &
  InsuranceHolderDetails &
  FrenchSpecificInsuranceFields;

type FrenchSpecificInsuranceFields = {
  insurancePreviouslyRefused?: boolean;
  endDate?: string;
};

type InsuranceHolderDetails = {
  insuranceHolderOccupation?: string;
  insuranceHolderEmail?: string;
  insuranceHolderPhoneNumber?: string;
  insuranceHolderGender: Gender;
  insuranceHolderDateOfBirth?: string;
};

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

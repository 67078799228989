import { build } from '@jackfranklin/test-data-bot';

import { enGB } from 'date-fns/locale';

import { InsuranceSubType, InsuranceType } from '@main/types';
import {
  ClientConfig,
  Country,
  Currency,
  getLocale,
  Language,
  UseCaseName,
} from '@main/types/configurations';

export const mockBlocksConfig = build<ClientConfig>({
  fields: {
    clientId: 'client-id',
    configName: 'config-name',
    customerName: 'customer-name',
    customizationId: 'customer-customizations-id',
    useCase: UseCaseName['prefill-and-compare'],
    productConfigs: {},
    allowedOrigin: ['*'],
    productType: 'insurance',
    language: Language.en,
    locale: enGB,
    showCustomization: true,
    market: {
      country: Country.se,
      currency: Currency.SEK,
    },
    possiblePhoneCall: true,
    hasDpa: true,
    insuranceTypes: [
      InsuranceType.groupInsurance,
      InsuranceType.houseContentInsurance,
      InsuranceType.vehicleInsurance,
      InsuranceType.generalInsurance,
      InsuranceType.personInsurance,
      InsuranceType.animalInsurance,
    ],
    insuranceSubTypes: [
      InsuranceSubType.healthInsurance,
      InsuranceSubType.mopedInsurance,
      InsuranceSubType.cottageInsurance,
      InsuranceSubType.trailerInsurance,
      InsuranceSubType.motorcycleInsurance,
      InsuranceSubType.condoInsurance,
      InsuranceSubType.childInsurance,
      InsuranceSubType.villaInsurance,
      InsuranceSubType.pregnancyInsurance,
      InsuranceSubType.catInsurance,
      InsuranceSubType.motorhomeInsurance,
      InsuranceSubType.otherVehicleInsurance,
      InsuranceSubType.boatInsurance,
      InsuranceSubType.groupInsurance,
      InsuranceSubType.horseInsurance,
      InsuranceSubType.lifeInsurance,
      InsuranceSubType.genericInsurance,
      InsuranceSubType.dogInsurance,
      InsuranceSubType.carInsurance,
      InsuranceSubType.accidentInsurance,
      InsuranceSubType.caravanInsurance,
      InsuranceSubType.otherAnimalInsurance,
    ],
  },
  postBuild: (clientConfig) => ({
    ...clientConfig,
    locale: getLocale(clientConfig.market.country, clientConfig.language),
  }),
  traits: {
    dk: {
      overrides: {
        market: {
          country: Country.dk,
          currency: Currency.DKK,
        },
      },
    },
    ee: {
      overrides: {
        market: {
          country: Country.ee,
          currency: Currency.EUR,
        },
      },
    },
    fr: {
      overrides: {
        market: {
          country: Country.fr,
          currency: Currency.EUR,
        },
      },
    },
    se: {
      overrides: {
        market: {
          country: Country.se,
          currency: Currency.SEK,
        },
      },
    },
    pension: {
      overrides: {
        productType: 'pension',
        insuranceTypes: [],
        insuranceSubTypes: [],
      },
    },
    'pension-move': {
      overrides: {
        useCase: UseCaseName['pension-move'],
        productType: 'pension',
        insuranceTypes: [],
        insuranceSubTypes: [],
      },
    },
    'wealth-overview': {
      overrides: {
        useCase: UseCaseName['wealth-overview'],
        productType: 'pension', // TODO: Change when we have mocked wealth for DA // Linear ticket: https://linear.app/insurely/issue/EXPD-159/mock-data-for-da-flow-for-wealth-overview
        insuranceTypes: [],
        insuranceSubTypes: [],
      },
    },
  },
});

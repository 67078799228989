import {
  CompanyLogo,
  Tabs,
  ParagraphBodySmall,
  Loader,
  Notification,
  HeadlineSix,
  ParagraphCaption,
} from '@insurely/ui';

import classNames from 'classnames';

import { useMemo, useState } from 'react';

import { useFlatPolicies, useSharedData } from '@main/contexts';
import { useCompanies } from '@main/hooks';
import { Country, InsuranceType, ParameterGroupsWithParameters, ParameterRow } from '@main/types';
import { getIconFromParamValue, getTitleFromInsuranceType, isPension } from '@main/utils';

import { Information, InformationCopy } from '../Information';
import { ParameterGroupList } from '../ParameterGroupList';

import styles from './insuranceDetails.module.css';

export interface InsuranceDetailsCopy extends InformationCopy {
  information: string;
  coverage: string;
  emptyStateTitle: string;
  emptyStateText: string;
  emptyStateAdditionalText: string;
  insuranceType?: string;
  addOnExplanation: string;
  addOnPurchased: string;
  addOnAvailable: string;
  addOn: string;
  included: string;
  notIncluded: string;
  unknown: string;
}

interface InsuranceDetailsProps {
  externalId: string | undefined;
  isComparisonPossible: boolean;
  topContent?: React.ReactNode;
  bottomContent: React.ReactNode;
  copy: InsuranceDetailsCopy;
}

export const InsuranceDetails = ({
  externalId,
  isComparisonPossible,
  topContent,
  bottomContent,
  copy,
}: InsuranceDetailsProps) => {
  const [
    {
      clientConfig: { language, market },
      userConfig: {
        customization: { logosVariant },
      },
    },
  ] = useSharedData();
  const { data: companies } = useCompanies();
  const insurancePolicies = useFlatPolicies();

  const policy = insurancePolicies.find((res) => res.insurance?.externalId === externalId);

  const [activeTab, setActiveTab] = useState('information');

  const headline = useMemo(
    () =>
      getTitleFromInsuranceType({
        policy,
        unknownCopy: copy.unknown,
      }),
    [copy.unknown, policy],
  );

  const insuranceCompanyDisplayName = useMemo(
    () =>
      companies?.find((company) => company.insuranceCompany === policy?.insurance?.insuranceCompany)
        ?.insuranceCompanyDisplayName,
    [companies, policy?.insurance?.insuranceCompany],
  );

  if (!policy?.insurance) {
    return <Loader.Content />;
  }

  const showCoverageTab =
    market.country !== Country.fr ||
    (policy.insurance.insuranceType === InsuranceType.houseContentInsurance &&
      isComparisonPossible) ||
    (policy.insurance.insuranceType === InsuranceType.vehicleInsurance && isComparisonPossible);

  const paramGroups = policy.parameters?.reduce(
    (groups: ParameterGroupsWithParameters, parameter) => {
      const parameterObject: ParameterRow = {
        id: parameter.parameterName,
        title: parameter.parameterDisplayName,
        description: parameter.parameterDescription,
        values: {
          value: getIconFromParamValue({
            value: parameter.value,
            isAddOnPurchased: parameter.isAddonPurchased,
            addOnPurchasedCopy: copy.addOnPurchased,
            addOnAvailableCopy: copy.addOnAvailable,
            trueAriaLabel: copy.included,
            falseAriaLabel: copy.notIncluded,
            unknownAriaLabel: copy.unknown,
          }),
        },
      };

      const groupKey = String(parameter.parameterGroupOrder);
      if (groupKey in groups) {
        return {
          ...groups,
          [groupKey]: {
            ...groups[groupKey],
            parameters: [...groups[groupKey].parameters, parameterObject],
          },
        };
      }

      return {
        ...groups,
        [groupKey]: {
          order: parameter.parameterGroupOrder,
          groupName: parameter.parameterGroup,
          parameters: [parameterObject],
        },
      };
    },
    {},
  );

  return (
    <>
      <div className={styles.topContentContainer}>
        {policy.insurance?.insuranceCompany && logosVariant !== 'noLogos' && (
          <CompanyLogo
            className={styles.companyLogo}
            company={policy.insurance.insuranceCompany}
            width="40px"
            aria-label={insuranceCompanyDisplayName ?? copy.unknown}
          />
        )}
        <div>
          <HeadlineSix
            alternating
            center
            className={classNames(styles.headlineContainer, 'ph-no-capture')}
          >
            {headline}
          </HeadlineSix>
          <div className={styles.inline}>
            {copy.insuranceType && (
              <>
                <ParagraphBodySmall>{copy.insuranceType}</ParagraphBodySmall>
                <div className={styles.dot} />
              </>
            )}
            <ParagraphBodySmall>{insuranceCompanyDisplayName}</ParagraphBodySmall>
          </div>
        </div>
      </div>
      {topContent}
      {showCoverageTab ? (
        <Tabs
          items={[
            { label: copy.information, value: 'information' },
            { label: copy.coverage, value: 'coverage' },
          ]}
          activeTab={activeTab}
          onChange={(tab: string): void => {
            setActiveTab(tab);
          }}
        />
      ) : null}
      {activeTab === 'information' ? (
        <Information
          companyName={insuranceCompanyDisplayName ?? copy.unknown}
          policy={policy}
          language={language}
          market={market}
          copy={copy}
        />
      ) : policy &&
        !isPension(policy) &&
        paramGroups &&
        Object.keys(paramGroups).length > 0 &&
        Object.values(paramGroups).some((group) => group.groupName !== undefined) ? (
        <ParameterGroupList
          hasAddons={!!policy.parameters?.find((item) => item.value === 'add-on')}
          addOnExplanationCopy={copy.addOnExplanation}
          groups={Object.values(paramGroups)}
          isOpenOnLoad
          className={styles.asterixText}
        />
      ) : (
        <Notification
          status="info"
          headline={copy.emptyStateTitle}
          className={styles.coverageEmptyState}
        >
          <div>
            <ParagraphCaption>{copy.emptyStateText}</ParagraphCaption>
            <ParagraphCaption className={styles.coverageEmptyStateDescription}>
              {copy.emptyStateAdditionalText}
            </ParagraphCaption>
          </div>
        </Notification>
      )}
      {bottomContent}
    </>
  );
};

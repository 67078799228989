import { useEffect, useState, useCallback } from 'react';

import { useBNPP } from '@main/hooks/useBNPP';
import { trackUserEngagement } from '@main/services/api';

import {
  posthog,
  useSharedData,
  isPosthogEnabled,
  postHogGroup,
  extractIdFromToken,
  postHogCapture,
  postHogRegisterOnce,
  postHogRegisterForSession,
  useUseCase,
} from '@main/shared-exports';

const utmParameters = [
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_term',
] as const;
type UTMParameter = (typeof utmParameters)[number];

const usePostHog = (uuid: string) => {
  const [
    {
      clientConfig: { productConfigs, clientId, useCase, market, configName, customerName },
      userConfig: { authToken, sessionId: _1, language, customerId, parentUrl, ...config },
    },
  ] = useSharedData();
  const { isBNPP } = useBNPP();
  const { isFirstBlock } = useUseCase();

  const [initialized, setInitialized] = useState(false);
  const sessionId = extractIdFromToken(authToken) ?? uuid;

  const handleUTMParameters = (url: URL) =>
    utmParameters.reduce((params: Partial<Record<UTMParameter, string>>, name) => {
      const value = url.searchParams.get(name);
      return value !== null ? { ...params, [name]: value } : params;
    }, {});

  const setupPostHog = useCallback(() => {
    const postHogProperties = {
      useCase,
      sessionId,
      configName,
      customerId,
      market: market.country,
      clientId,
      language,
      customerName,
    };

    posthog.identify(sessionId, postHogProperties);

    const url = new URL(parentUrl);
    const utmParams = handleUTMParameters(url);

    if (Object.keys(utmParams).length !== 0) {
      postHogRegisterForSession(utmParams);
    }

    if (isBNPP) {
      // eslint-disable-next-line no-void
      void trackUserEngagement(sessionId).then((res) => res);
    }

    postHogCapture('blocks-config', config);
    postHogGroup({ groupCollectionName: 'country', groupName: market.country });

    const registerProperties: Record<string, unknown> = { ...postHogProperties };
    if (productConfigs?.checkout?.selectedCheckout?.insuranceSubType) {
      registerProperties.lineOfBusiness =
        productConfigs.checkout.selectedCheckout.insuranceSubType.replace(/insurance/i, '');
    }

    postHogRegisterOnce(registerProperties);
  }, [
    clientId,
    config,
    configName,
    customerId,
    customerName,
    isBNPP,
    language,
    market.country,
    parentUrl,
    productConfigs?.checkout?.selectedCheckout?.insuranceSubType,
    sessionId,
    useCase,
  ]);

  useEffect(() => {
    if (!isPosthogEnabled()) return;

    posthog.init(POSTHOG_PROJECT_ID, {
      api_host: `https://posthog${ENVIRONMENT !== 'prod' ? '.test' : ''}.insurely.com`,
      autocapture: false,
      capture_pageview: false,
      persistence: 'memory',
      ip: false,
      capture_performance: false,
      capture_pageleave: true,
      loaded: () => {
        /**
         * Only setup PostHog if this is being called in first block. This is to
         * avoid bad data when browsers have been killed on phones. This function is being
         * called in other blocks with a new sessionId, before being redirected (EXPC-1154)
         * to the first block. Could be improved (EXPC-1158)
         */
        if (isFirstBlock) {
          setInitialized((alreadyInitialized) => {
            if (!alreadyInitialized) {
              setupPostHog();
            }
            return true;
          });
        }
      },
    });
  }, [
    clientId,
    customerId,
    configName,
    market.country,
    sessionId,
    useCase,
    language,
    customerName,
    isBNPP,
    parentUrl,
    config,
    productConfigs?.checkout?.selectedCheckout?.insuranceSubType,
    initialized,
    setupPostHog,
    isFirstBlock,
  ]);
};

export default usePostHog;

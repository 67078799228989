import { CompanyCollector } from '../types';

export const findNextCompanyToStart = (companyCollectors: CompanyCollector[]) => {
  const { collectors: updatedCompanies, currentCompanyAuthenticating } = companyCollectors.reduce<{
    collectors: CompanyCollector[];
    hasFoundNextCompanyToStart: boolean;
    currentCompanyAuthenticating?: string;
  }>(
    (acc, currentCompany) => {
      const { hasFoundNextCompanyToStart, collectors } = acc;

      if (hasFoundNextCompanyToStart)
        return { ...acc, collectors: [...collectors, currentCompany] };
      if (currentCompany.status === 'skip') {
        return {
          ...acc,
          collectors: [...collectors, { ...currentCompany, status: 'skipped' as const }],
        };
      }

      if (currentCompany.status === undefined) {
        return {
          ...acc,
          hasFoundNextCompanyToStart: true,
          currentCompanyAuthenticating: currentCompany.companyDetails.insuranceCompany,
          collectors: [...collectors, { ...currentCompany, status: 'notStarted' as const }],
        };
      }

      return {
        ...acc,
        collectors: [...collectors, currentCompany],
      };
    },
    { collectors: [], hasFoundNextCompanyToStart: false },
  );

  return {
    updatedCompanies,
    currentCompanyAuthenticating,
  };
};

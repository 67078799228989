import { Country, Currency } from '@main/types/configurations/market';
import { AddOn } from '@main/types/insurance/Addon';
import { Deductible } from '@main/types/insurance/Deductible';
import { Label } from '@main/types/insurance/Label';

import { InsuranceType, InsuranceSubType } from '../InsuranceType';

export enum PaymentMethod {
  UNDEFINED = 'UNDEFINED',
  AUTOGIRO = 'AUTOGIRO',
  PAPER_INVOICE = 'PAPER_INVOICE',
  ELECTRONIC_INVOICE = 'ELECTRONIC_INVOICE',
  GENERIC_INVOICE = 'GENERIC_INVOICE',
  ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT',
  EMPLOYER_PAID = 'EMPLOYER_PAID',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum PremiumFrequencyDA {
  MONTHLY_PREMIUM_FREQUENCY = 12,
  QUARTERLY_PREMIUM_FREQUENCY = 4,
  BI_YEARLY_PREMIUM_FREQUENCY = 2,
  YEARLY_PREMIUM_FREQUENCY = 1,
  ONE_TIME_PAYMENT = 0,
  UNKNOWN_PREMIUM_FREQUENCY = -1,
}

export type MonetaryAmount = {
  currencyCode: Currency;
  minorUnitAmount: number;
};

export enum TaxationType {
  INCLUSIVE_TAXATION = 'INCLUSIVE_TAXATION',
  EXCLUSIVE_TAXATION = 'EXCLUSIVE_TAXATION',
  UNKNOWN_TAXATION = 'UNKNOWN_TAXATION',
}

export enum PremiumFrequencyLiteral {
  YEARLY_PREMIUM_FREQUENCY = 'YEARLY_PREMIUM_FREQUENCY',
  UNKNOWN_PREMIUM_FREQUENCY = 'UNKNOWN_PREMIUM_FREQUENCY',
}

export type Premium = {
  amount: MonetaryAmount;
  frequency: PremiumFrequencyLiteral;
  taxation: TaxationType;
};

export type Name = {
  firstName?: string;
  lastName?: string;
  fullName: string;
};

export type PolicyHolder = {
  name: Name;
};

export type CoreGeneralInsurance = {
  addOns?: AddOn[];
  coming?: boolean;
  deductibles?: Deductible[];
  discountAmount?: number;
  discountPercentage?: string;
  employerPaid?: boolean;
  externalId: string;
  insuranceCompany: string;
  insuranceHolderCity?: string;
  insuranceHolderName?: string;
  insuranceHolderEmail?: string;
  insuranceHolderPhoneNumber?: string;
  insuranceHolderPostalCode?: string;
  insuranceHolderStreetAddress?: string;
  insuranceName: string;
  insuranceNumber?: string;
  insuranceSubType: InsuranceSubType;
  insuranceType: InsuranceType.generalInsurance;
  otherInsuranceHolder?: boolean;
  paymentMethod: keyof typeof PaymentMethod;
  premiumAmountYearRounded?: number;
  premiumFrequency: PremiumFrequencyDA;
  premium?: Premium;
  policyHolder?: PolicyHolder;
  renewalDate?: string;
  startDate?: string;
  region?: string;
  version?: '2';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unmapped?: any;
  // Label is used to distinguish special cases for insurance validation
  label?: Label;
  '@market': keyof typeof Country;
};

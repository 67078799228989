import {
  ExpandableSection,
  ExpandableSectionChevron,
  ExpandableSectionContent,
  ExpandableSectionTitle,
  ParagraphBodySmall,
  ParagraphCaption,
} from '@insurely/ui';

import classNames from 'classnames';

import { Fragment, ReactNode } from 'react';

import { useSharedData } from '@main/contexts';
import { ParameterRow } from '@main/types';

import styles from './parameterList.module.css';

export interface ParameterListTitle {
  id: string;
  content: ReactNode;
}
export function ParameterList({
  titles,
  parameters,
}: {
  titles?: ParameterListTitle[];
  parameters: ParameterRow[];
}) {
  const [
    {
      userConfig: {
        customization: { logosVariant },
      },
    },
  ] = useSharedData();

  return (
    <div>
      {titles ? (
        <div
          className={classNames(styles.coverageHeader, {
            [styles.noCompanyLogo]: logosVariant === 'noLogos',
          })}
        >
          {titles.map((item) => (
            <Fragment key={item.id}> {item.content}</Fragment>
          ))}
        </div>
      ) : null}
      {parameters.map(({ id, title, description, values }) => (
        <ExpandableSection className={styles.section} key={id}>
          <ExpandableSectionTitle className={styles.sectionTitle}>
            <ExpandableSectionChevron />
            <div className={styles.titleAndValues}>
              <ParagraphBodySmall className={styles.parameterTitle}>{title}</ParagraphBodySmall>
              <div
                className={classNames(styles.values, {
                  [styles.noCompanyLogo]: logosVariant === 'noLogos',
                })}
              >
                {titles
                  ? titles.map((t) => <Fragment key={t.id}>{values[t.id]}</Fragment>)
                  : Object.entries(values).map(([key, value]) => (
                      <Fragment key={key}>{value}</Fragment>
                    ))}
              </div>
            </div>
          </ExpandableSectionTitle>
          <ExpandableSectionContent>
            <ParagraphCaption className={styles.description}>{description}</ParagraphCaption>
          </ExpandableSectionContent>
        </ExpandableSection>
      ))}
    </div>
  );
}

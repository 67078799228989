/* eslint-disable max-lines */
import { ConsentRequirementsMethod, ConsentRequirementsType, LoginMethod } from '@main/types';

export const danishInsuranceCompanies: Company[] = [
  {
    insuranceCompany: 'dk-alka',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Alka',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-almbrand',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Alm. Brand',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-bauta',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Bauta',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-codan',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Codan',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-demo',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Demo Company',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-fdm',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'FDM',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-gfforsikring',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'GF Forsikring',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-gjensidige',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Gjensidige',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-ida',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'IDA Forsikring',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-if',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'If',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-lb',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'LB Forsikring',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-lbforsikringpfa',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'LB Forsikring PFA',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-runa',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Runa',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-tjm',
    functional: false,
    status: 'OK',
    reason: 'THIRD_PARTY_ERROR',
    insuranceCompanyDisplayName: 'TJM Forsikring',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-topdanmark',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Topdanmark',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
  {
    insuranceCompany: 'dk-tryg',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Tryg',
    loginMethods: [LoginMethod.DANISH_MITID_SAME_DEVICE, LoginMethod.DANISH_MITID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    consentRequirements: [
      {
        type: ConsentRequirementsType.TRADE_UNION_MEMBERSHIP,
        method: ConsentRequirementsMethod.NONE,
      },
    ],
  },
];

import { BlocksModule, UseCaseName } from '@main/types';

import { BlocksConfigType } from './types';

export const blocksConfig: BlocksConfigType = {
  [UseCaseName['pension-move']]: {
    [BlocksModule.Contact]: {
      summary: { show: false, showLogo: false },
      description: { show: true },
      phoneNumber: { show: true, optional: false },
      email: { show: true, optional: false },
      marketingConsent: { show: false },
      skip: { show: false },
      submit: { showIcon: true },
      requireSuccessfulSave: false,
      useCollectionContactDetails: false,
    },
  },
  [UseCaseName['collect-and-question']]: {
    [BlocksModule.Contact]: {
      summary: { show: false, showLogo: false },
      description: { show: true },
      phoneNumber: { show: true, optional: false },
      email: { show: true, optional: false },
      marketingConsent: { show: false },
      skip: { show: false },
      submit: { showIcon: false },
      requireSuccessfulSave: true,
      useCollectionContactDetails: true,
    },
  },
  [UseCaseName['collect-and-contact']]: {
    [BlocksModule.Contact]: {
      summary: { show: true, showLogo: true },
      description: { show: true },
      phoneNumber: { show: true, optional: false },
      email: { show: true, optional: true },
      marketingConsent: { show: false },
      skip: { show: false },
      submit: { showIcon: true },
      requireSuccessfulSave: true,
      useCollectionContactDetails: true,
    },
  },
};

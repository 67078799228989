import { Button } from '@insurely/ui';

import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { BaseView } from '@main/components/BaseView';
import { useUseCase, useFlatPolicies, postHogCapture, useSharedData } from '@main/shared-exports';

import { useCollectionData, useCollectionMetaData } from '@overview/hooks';

import { InsuranceList, InsuranceSummary, SkeletonLoaderOverview } from './components';

import styles from './insuranceOverview.module.css';

export const InsuranceOverview = () => {
  const intl = useIntl();
  const [
    {
      userConfig: { authToken },
      frontendOptions: { skipInsuranceOverviewPage },
    },
  ] = useSharedData();
  const { isInitialLoading } = useCollectionMetaData();
  const { hasFetchedAllCollections } = useCollectionData();
  const { nextBlock } = useUseCase();
  const policies = useFlatPolicies();

  // filter(Boolean) removes any falsy values since externalId is possibly undefined
  const externalIds = policies.map((p) => p.insurance?.externalId).filter(Boolean);

  useEffect(() => {
    if (!hasFetchedAllCollections) return;
    const numberOfPolicies = policies.length;
    postHogCapture('overview-number-of-policies', { numberOfPolicies, external_ids: externalIds });
    if (skipInsuranceOverviewPage && policies.length === 0) {
      setTimeout(() => nextBlock(), 900);
    }
  }, [
    hasFetchedAllCollections,
    skipInsuranceOverviewPage,
    nextBlock,
    policies.length,
    externalIds,
  ]);

  if (
    skipInsuranceOverviewPage &&
    ((!!authToken && isInitialLoading) || policies.length === 0 || !hasFetchedAllCollections)
  ) {
    return <SkeletonLoaderOverview />;
  }

  return (
    <BaseView type="fullWidth">
      <InsuranceSummary />
      {policies.length !== 0 && <InsuranceList />}
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => nextBlock()}
          variant={policies.length === 0 ? 'primary' : 'secondary'}
          fullWidth
          className={styles.button}
          center
        >
          {policies.length === 0
            ? intl.formatMessage({ defaultMessage: 'Add an existing insurance' })
            : intl.formatMessage({ defaultMessage: 'Add another existing insurance' })}
        </Button>
      </div>
    </BaseView>
  );
};

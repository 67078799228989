import { CoreGeneralHouseInsurance, CoreHomeInsurance } from '@main/types/insurance/core';
import { FrenchGeneralInsurance } from '@main/types/insurance/france/FrenchGeneralInsurance';

import { InsuranceSubType } from '../InsuranceType';

export enum Amenity {
  FIREPLACE = 'FIREPLACE',
  POOL = 'POOL',
  GARDEN = 'GARDEN',
}

export enum Floor {
  GROUND_FLOOR = 'GROUND_FLOOR',
  COURTYARD = 'COURTYARD',
  INTERMEDIATE_FLOOR = 'INTERMEDIATE_FLOOR',
  TOP_FLOOR = 'TOP_FLOOR',
  UNKNOWN = 'UNKNOWN',
}

export enum OwnershipStatus {
  OWNER = 'OWNER',
  TENANT = 'TENANT',
  // Roommate
  SECONDARY_TENANT = 'SECONDARY_TENANT',
  UNKNOWN = 'UNKNOWN',
}

export enum ResidentialStatusFR {
  PRIMARY_RESIDENCE = 'PRIMARY_RESIDENCE',
  SECONDARY_RESIDENCE = 'SECONDARY_RESIDENCE',
  RENTAL_RESIDENCE = 'RENTAL_RESIDENCE',
  UNKNOWN = 'UNKNOWN',
}

type ApartmentDetails = {
  floor: Floor;
};

type HouseDetails = {
  constructionYear?: string;
};

export const isFrenchOutbuildingsAreaRange = (
  outbuilding: FrenchOutbuildingsArea | FrenchOutbuildingsAreaRange,
): outbuilding is FrenchOutbuildingsAreaRange =>
  'lowerLimit' in outbuilding && 'upperLimit' in outbuilding;

export const isFrenchOutbuildingsArea = (
  outbuilding: FrenchOutbuildingsArea | FrenchOutbuildingsAreaRange,
): outbuilding is FrenchOutbuildingsArea => 'area' in outbuilding;

export interface FrenchOutbuildingsArea {
  area: number;
  lowerLimit?: never;
  upperLimit?: never;
}

export interface FrenchOutbuildingsAreaRange {
  area?: never;
  lowerLimit: number;
  upperLimit: number;
}

type FrenchSpecificHouseOrHomeFields = {
  residentialStatus: ResidentialStatusFR;
  numberOfAdults?: number;
  numberOfChildren?: number;
  insuredValuablesAmount?: number;
  professionalUsage?: boolean;
  ownershipStatus: OwnershipStatus;
  numberOfRooms?: number;
  additionalAmenities: Array<Amenity>;
  outbuildingsTotalArea?: FrenchOutbuildingsAreaRange | FrenchOutbuildingsArea;
};

export type FrenchHomeInsurance = Omit<FrenchGeneralInsurance, 'insuranceType'> &
  CoreHomeInsurance &
  FrenchSpecificHouseOrHomeFields &
  ApartmentDetails;

export type FrenchVillaInsurance = Omit<FrenchGeneralInsurance, 'insuranceType'> &
  CoreGeneralHouseInsurance &
  FrenchSpecificHouseOrHomeFields &
  HouseDetails & {
    InsuranceSubType: InsuranceSubType.villaInsurance;
  };

import {
  AnimalInsurance,
  CarInsurance,
  Deductible,
  HomeInsurance,
  LifeInsurance,
  InsuranceParameter,
  AccidentInsurance,
} from '@main/types';

import { fakeNewSwedishAnimalAddons } from './addons';
import { fakeNewSwedishAnimalDeductibles, fakeNewSwedishCarDeductibles } from './deductibles';
import {
  fakeNewSwedishAccidentInsurance,
  fakeNewSwedishAnimalInsurance,
  fakeNewSwedishCarInsurance,
  fakeNewSwedishHomeInsurance,
  fakeNewSwedishLifeInsurance,
} from './insurance';
import {
  fakeNewSwedishAnimalParameters,
  fakeNewSwedishCarParameters,
  fakeNewSwedishHomeParameters,
} from './parameters';

export const fakeNewSwedishHomeCollection = (
  overrides?:
    | Array<{
        insurance?: Partial<HomeInsurance>;
        deductibles?: Array<Deductible>;
        personalInformation?: Partial<PersonalInformation>;
        parameters?: Array<InsuranceParameter>;
      } | null>
    | number,
): Array<InsurancePolicy> => {
  const defaultData = () => ({
    insurance: fakeNewSwedishHomeInsurance.one(),
    parameters: fakeNewSwedishHomeParameters(),
    addons: [],
    deductibles: [],
    personalInformation: { PERSONAL_NUMBER: '199301010101' },
  });
  return typeof overrides === 'number'
    ? Array(overrides)
        .fill(0)
        .map((_) => defaultData())
    : (overrides?.map((override) => {
        const collectionData = defaultData();
        return {
          ...collectionData,
          insurance: {
            ...collectionData.insurance,
            ...override?.insurance,
          },
          deductibles: override?.deductibles ?? collectionData.deductibles,
          personalInformation: {
            ...collectionData.personalInformation,
            ...override?.personalInformation,
          },
          parameters: override?.parameters ?? collectionData.parameters,
        };
      }) ?? [defaultData()]);
};

export const fakeNewSwedishCarCollection = (
  overrides?:
    | Array<{
        insurance?: Partial<CarInsurance>;
        deductibles?: Array<Deductible>;
        parameters?: InsuranceParameter[];
      }>
    | number,
): Array<InsurancePolicy> => {
  const defaultData = () => ({
    insurance: fakeNewSwedishCarInsurance.one(),
    parameters: fakeNewSwedishCarParameters(),
    addons: [],
    deductibles: fakeNewSwedishCarDeductibles(),
    personalInformation: { PERSONAL_NUMBER: '199301010101' },
  });
  return typeof overrides === 'number'
    ? Array(overrides)
        .fill(0)
        .map((_) => defaultData())
    : (overrides?.map((o) => {
        const collectionData = defaultData();
        return {
          ...collectionData,
          insurance: {
            ...collectionData.insurance,
            ...o.insurance,
          },
          deductibles: o.deductibles ?? collectionData.deductibles,
          parameters: o.parameters ?? collectionData.parameters,
        };
      }) ?? [defaultData()]);
};

export const fakeNewSwedishLifeCollection = (
  overrides?:
    | Array<{ insurance?: Partial<LifeInsurance>; deductibles?: Array<Deductible> }>
    | number,
): Array<InsurancePolicy> => {
  const defaultData = () => ({
    insurance: fakeNewSwedishLifeInsurance.one(),
    parameters: [],
    addons: [],
    deductibles: [],
    personalInformation: { PERSONAL_NUMBER: '199301010101' },
  });
  return typeof overrides === 'number'
    ? Array(overrides)
        .fill(0)
        .map((_) => defaultData())
    : (overrides?.map((o) => {
        const collectionData = defaultData();
        return {
          ...collectionData,
          insurance: {
            ...collectionData.insurance,
            ...o.insurance,
          },
          deductibles: o.deductibles ?? collectionData.deductibles,
        };
      }) ?? [defaultData()]);
};

export const fakeNewSwedishAnimalCollection = (
  overrides?:
    | Array<{ insurance?: Partial<AnimalInsurance>; deductibles?: Array<Deductible> }>
    | number,
): Array<InsurancePolicy> => {
  const defaultData = () => ({
    insurance: fakeNewSwedishAnimalInsurance.one(),
    parameters: fakeNewSwedishAnimalParameters(),
    addons: fakeNewSwedishAnimalAddons(),
    deductibles: fakeNewSwedishAnimalDeductibles(),
    personalInformation: { PERSONAL_NUMBER: '199301010101' },
  });
  return typeof overrides === 'number'
    ? Array(overrides)
        .fill(0)
        .map((_) => defaultData())
    : (overrides?.map((o) => {
        const collectionData = defaultData();
        return {
          ...collectionData,
          insurance: {
            ...collectionData.insurance,
            ...o.insurance,
          },
          deductibles: o.deductibles ?? collectionData.deductibles,
        };
      }) ?? [defaultData()]);
};

export const fakeNewSwedishAccidentCollection = (
  overrides?:
    | Array<{ insurance?: Partial<AccidentInsurance>; deductibles?: Array<Deductible> }>
    | number,
): Array<InsurancePolicy> => {
  const defaultData = () => ({
    insurance: fakeNewSwedishAccidentInsurance.one(),
    parameters: [],
    addons: [],
    deductibles: [],
    personalInformation: { PERSONAL_NUMBER: '199301010101' },
  });
  return typeof overrides === 'number'
    ? Array(overrides)
        .fill(0)
        .map((_) => defaultData())
    : (overrides?.map((o) => {
        const collectionData = defaultData();
        return {
          ...collectionData,
          insurance: {
            ...collectionData.insurance,
            ...o.insurance,
          },
          deductibles: o.deductibles ?? collectionData.deductibles,
        };
      }) ?? [defaultData()]);
};

import { createContext, useMemo } from 'react';

import { useUseCase } from '@main/contexts';

import { blocksConfig } from './config';
import type { BlocksConfigContextInterface } from './types';

export const BlocksConfigContext = createContext<BlocksConfigContextInterface | undefined>(
  undefined,
);

export const BlocksConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    useCase: { name },
  } = useUseCase();

  const value: BlocksConfigContextInterface = useMemo(() => blocksConfig[name ?? ''], [name]);

  return <BlocksConfigContext.Provider value={value}>{children}</BlocksConfigContext.Provider>;
};

export enum BlocksModule {
  DataAggregation = 'dataAggregation',
  InitialNavigation = 'initialNavigation',
  Checkout = 'checkout',
  Compare = 'compare',
  Overview = 'overview',
  Questionnaire = 'questionnaire',
  Results = 'results',
  Contact = 'contact',
  EndState = 'endState',
  PensionMove = 'pensionMove',
}

/* eslint-disable max-lines */
import { InsuranceParameter } from '@main/types';

export const fakeNewFrenchHomeParameters = (): Array<InsuranceParameter> => [
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'burglary',
    parameterOrder: 0,
    parameterDisplayName: 'Vol et vandalisme',
    parameterDescription:
      'Indemnisation de biens et de votre habitation en cas de vol, tentative de vol et acte de vandalisme',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'breakdown',
    parameterOrder: 1,
    parameterDisplayName: 'Panne électroménager',
    parameterDescription:
      'Prise en charge de la réparation en cas de panne de vos appareils électroménagers ou de leur remplacement si non réparables.',
    parameterGroup: 'Vos biens',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'private liability',
    parameterOrder: 1,
    parameterDisplayName: 'Responsabilité Civile Vie Privée',
    parameterDescription:
      'En assurant votre résidence principale, prise en charge des dommages non intentionnels que vous pourriez causer aux autres.',
    parameterGroup: 'Vous et votre foyer',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'fire',
    parameterOrder: 2,
    parameterDisplayName: 'Incendie et événements assimilés',
    parameterDescription:
      'Indemnisation de votre logement et de vos biens en cas de dommages causés par le feu (inclus dommages induits par les secours).',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'building liability',
    parameterOrder: 2,
    parameterDisplayName: 'Responsabilité Civile Habitation',
    parameterDescription:
      'Prise en charge des dommages non intentionnels causés aux autres par votre responsabilité de propriétaire ou de locataire.',
    parameterGroup: 'Vous et votre foyer',
    parameterGroupOrder: 3,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'refurbishing',
    parameterOrder: 2,
    parameterDisplayName: 'Rééquipement à neuf',
    parameterDescription: "Indemnisation à hauteur de la valeur actuelle d'un bien similaire neuf.",
    parameterGroup: 'Vos biens',
    parameterGroupOrder: 2,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'electric equipment',
    parameterOrder: 3,
    parameterDisplayName: 'Dommages Électriques aux appareils',
    parameterDescription:
      'Indemnisation de vos appareils électriques en cas de surtension ou de foudre (téléviseur, four, congélateur, console de jeux...).',
    parameterGroup: 'Vos biens',
    parameterGroupOrder: 2,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'loanback',
    parameterOrder: 3,
    parameterDisplayName: 'Remboursement crédit immobilier',
    parameterDescription:
      'Suite à un sinistre rendant votre résidence principale inhabitable, remboursement des échéances de votre prêt immobilier.',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'emergency',
    parameterOrder: 3,
    parameterDisplayName: 'Urgence après sinistre',
    parameterDescription:
      "En cas de sinistre à votre domicile, assistance pour votre retour d'urgence, le gardiennage de votre habitation, l'avance de fonds...",
    parameterGroup: 'Vous et votre foyer',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'attorney',
    parameterOrder: 4,
    parameterDisplayName: 'Défense Pénale et Recours',
    parameterDescription:
      "Prise en charge de la défense de vos intérêts en cas d'action judiciaire ou administrative dans le cadre d'un sinistre.",
    parameterGroup: 'Vous et votre foyer',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'water',
    parameterOrder: 4,
    parameterDisplayName: 'Dégâts des eaux',
    parameterDescription:
      "Garantie des dommages consécutifs à une action de l'eau, hors inondation comme la fuite, le débordement, l'infiltration…",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'food',
    parameterOrder: 4,
    parameterDisplayName: 'Perte de denrées alimentaires',
    parameterDescription:
      'Indemnisation des denrées alimentaires contenues dans votre congélateur suite à une variation de température accidentelle.',
    parameterGroup: 'Vos biens',
    parameterGroupOrder: 2,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'glass equipment',
    parameterOrder: 5,
    parameterDisplayName: 'Bris de glace mobilier et électroménager',
    parameterDescription:
      'Indemnisation des parties vitrées de vos biens en cas de casse accidentelle (miroir, plaque de cuisson, porte de four, aquarium…).',
    parameterGroup: 'Vos biens',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'craftsman',
    parameterOrder: 5,
    parameterDisplayName: 'Mise en relation avec des artisans agréés',
    parameterDescription:
      "Même sans sinistre, assistance pour vous mettre en relation avec un artisan en cas d'événement nécessitant une intervention urgente.",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'legal',
    parameterOrder: 5,
    parameterDisplayName: 'Protection juridique',
    parameterDescription:
      'Pour tout litige ou différend relatif à votre logement, assistance juridique et prise en charge des frais de procédure.',
    parameterGroup: 'Vous et votre foyer',
    parameterGroupOrder: 3,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'quote',
    parameterOrder: 6,
    parameterDisplayName: 'Aide au devis travaux',
    parameterDescription:
      'En cas de travaux à votre domicile, assistance pour vous aider à choisir le devis le plus adapté (plomberie, électricité, peinture...).',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'travel',
    parameterOrder: 6,
    parameterDisplayName: 'Assistance voyage',
    parameterDescription:
      "Prestation d'assistance dans le monde entier en cas de blessure, de maladie ou de décès.",
    parameterGroup: 'Vous et votre foyer',
    parameterGroupOrder: 3,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'robbery',
    parameterOrder: 6,
    parameterDisplayName: 'Vol hors domicile des objets de loisirs',
    parameterDescription:
      "Indemnisation de vos objets de loisirs en cas de vol à l'arraché ou par agression en dehors de votre domicile (vélo, instrument de musique...).",
    parameterGroup: 'Vos biens',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'flood',
    parameterOrder: 7,
    parameterDisplayName: 'Inondation',
    parameterDescription:
      "Indemnisation des dommanges causés par une inondation (débordement d'un cours d'eau, eaux de ruissellement...).",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'outdoor',
    parameterOrder: 7,
    parameterDisplayName: 'Mobilier extérieur',
    parameterDescription:
      'Indemnisation de votre mobilier extérieur en cas de vol, incendie, événement climatique, etc. (salon de jardin, barbecue, tondeuse...).',
    parameterGroup: 'Vos biens',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'terrorism',
    parameterOrder: 8,
    parameterDisplayName: 'Attentat',
    parameterDescription:
      'Indemnisation des dommages directs causés par un attentat ou un acte de terrorisme.',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'professional',
    parameterOrder: 8,
    parameterDisplayName: 'Matériel professionnel',
    parameterDescription: 'Indemnisation de votre matériel professionnel entreposé au domicile. ',
    parameterGroup: 'Vos biens',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'devices',
    parameterOrder: 9,
    parameterDisplayName: 'Appareils nomades',
    parameterDescription:
      "Indemnisation de vos appareils nomades en cas de casse accidentelle chez vous comme à l'extérieur.",
    parameterGroup: 'Vos biens',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'storm',
    parameterOrder: 9,
    parameterDisplayName: 'Tempête et événement climatique',
    parameterDescription:
      "Indemnisation des dommages causés par des événements liés au climat dont l'intensité est exceptionnelle (tempête, grêle, neige...).",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'natural disaster',
    parameterOrder: 10,
    parameterDisplayName: 'Catastrophes naturelles',
    parameterDescription:
      "Indemnisation des dommages causés par des événements tels qu'un tremblement de terre, un tsunami, une période de sécheresse, etc.",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'technological disaster',
    parameterOrder: 11,
    parameterDisplayName: 'Catastrophe technologique',
    parameterDescription:
      'Indemnisation des dommages causés par un accident industriel majeur (sites industriels réglementés).',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'glass',
    parameterOrder: 12,
    parameterDisplayName: 'Bris de vitres',
    parameterDescription:
      "Indemnisation en cas de casse accidentelle d'une surface vitrée fixe dans votre domicile (fenêtre, véranda, vitre d'insert…).",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'building electric',
    parameterOrder: 13,
    parameterDisplayName: 'Dommages Electriques aux bâtiments',
    parameterDescription:
      'Indemnisation des frais de remise en état suite à des dommages électriques (circuits, appareils électriques...)',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'burried pipe',
    parameterOrder: 14,
    parameterDisplayName: 'Canalisations enterrées',
    parameterDescription:
      'Les canalisations d’adduction d’eau enterrées situées entre le compteur du service des eaux et les bâtiments',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'sustainable',
    parameterOrder: 15,
    parameterDisplayName: 'Équipements de développement durable',
    parameterDescription:
      "Indemnisation de vos équipements d'énergie renouvelable (panneaux photovoltaïques, éoliennes, pompe à chaleur géothermique...).",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'exterior',
    parameterOrder: 16,
    parameterDisplayName: 'Aménagements extérieurs',
    parameterDescription:
      "Indemnisation des éléments fixés de manière permanente dans votre jardin (arbres, systèmes d'arrosage, court de tennis...).",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'pool',
    parameterOrder: 17,
    parameterDisplayName: 'Piscine, spa et leurs équipements',
    parameterDescription:
      'Indemnisation des dommages causés à votre piscine ou spa, notamment en cas de risque climatique, de dommage électrique...',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
];

export const fakeNewFrenchCarParameters = (): Array<InsuranceParameter> => [
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'breakdown',
    parameterOrder: 0,
    parameterDisplayName: 'Assistance',
    parameterDescription:
      "Assistance dépannage en cas d'accident ou de panne prévoyant la plupart du temps, une franchise kilométrique au départ de à votre lieu d'habitation",
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'terrorism',
    parameterOrder: 0,
    parameterDisplayName: 'Attentats',
    parameterDescription:
      'Les dommages causés à votre véhicule suite à un acte de terrorisme ou un attentat sont couvert par cette garantie.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'glass',
    parameterOrder: 0,
    parameterDisplayName: 'Bris de glace',
    parameterDescription:
      'Prise en charge du remplacement de toutes les parties vitrées de vorte véhicule',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'glassWindshield',
    parameterOrder: 0,
    parameterDisplayName: 'Bris de pare-brise',
    parameterDescription: 'Prise en charge des dommages subit uniquement par le pare-brise',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'naturalDisaster',
    parameterOrder: 0,
    parameterDisplayName: 'Catastrophes naturelles',
    parameterDescription:
      'La garantie catastrophe naturelle couvre les dommages matériels subis par le véhicule assuré à la suite d’un événement naturel de forte ampleur.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'technologicalDisaster',
    parameterOrder: 0,
    parameterDisplayName: 'Catastrophes technologiques',
    parameterDescription:
      "Cette garantie couvre votre véhicule en cas d'accident industriel majeur.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'content',
    parameterOrder: 0,
    parameterDisplayName: 'Contenu du véhicule',
    parameterDescription: "Vos effets personnels sont couverts lors d'un évènement garanti",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'attorney',
    parameterOrder: 0,
    parameterDisplayName: 'Défense Pénale et Recours Suite à Accident',
    parameterDescription:
      "En cas de litige, cette garantie couvre vos frais de défense ou de recours suite à un accident, dans le but d'être dédommagé des frais juridiques engagés par l'assuré automobile.",
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'breakdownPremium',
    parameterOrder: 0,
    parameterDisplayName: 'Dépannage 0 km',
    parameterDescription:
      "Assistance dépannage en cas d'accident ou de pane sans franchise kilométrique",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'storm',
    parameterOrder: 0,
    parameterDisplayName: 'Evénements climatiques',
    parameterDescription:
      "La garantie évènements climatiques couvre les dommages matériels causés par des événements liés au climat dont l'intensité est exceptionnelle (tempête, grêle ou encore neige)",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'driver',
    parameterOrder: 0,
    parameterDisplayName: 'Garantie du Conducteur',
    parameterDescription:
      'La garantie personnelle du conducteur en assurance auto permet à un assuré d’être indemnisé pour les dommages corporels qu’il pourrait subir dans un accident de la route.',
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'fire',
    parameterOrder: 0,
    parameterDisplayName: 'Incendie',
    parameterDescription:
      'La garantie incendie couvre votre véhicule contre les dommages liés à un incendie ou à une explosion.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'professionals',
    parameterOrder: 0,
    parameterDisplayName: 'Marchandises et outillages professionnels',
    parameterDescription:
      "À l’occasion d’un événement garanti, cette garantie vous permet d'être indemnisé pour vos effets professionnels transportés dans ou sur le véhicule assuré ou bien arrimés à celui-ci.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'electricVehiclePowerOutage',
    parameterOrder: 0,
    parameterDisplayName: 'Panne d’énergie',
    parameterDescription:
      "En cas de batterie déchargée immobilisant le véhicule, couverture pour le dépannage ou le remorquage jusqu'à la borne de recharge la plus proche",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'mechanicalBreakdown',
    parameterOrder: 0,
    parameterDisplayName: 'Panne mécanique',
    parameterDescription:
      'Prise en charge de la remise en état ou le changement des organes et pièces listés ci-après reconnus défectueux à la suite d’une panne garantie en cas de panne mécanique',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'purchaseValue',
    parameterOrder: 0,
    parameterDisplayName: "Prolongation de la valeur d'achat",
    parameterDescription:
      "Votre véhicule est indemnisé sellon sa valeur d'achat ou son indemnisation est majorée à la hausee par rapport à son prix de remplacement",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'liability',
    parameterOrder: 0,
    parameterDisplayName: 'Responsabilité Civile',
    parameterDescription:
      'La responsabilité civile est la protection minimum comprise dans tous les contrats d’assurance auto. Il s’agit d’une garantie obligatoire. Elle permet d’indemniser les dommages causés à une tierce personne en cas d’accident responsable.',
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'allAccidents',
    parameterOrder: 0,
    parameterDisplayName: 'Tous accidents',
    parameterDescription:
      "La garantie tous risques vous permet d'être indemnisé pour tous les dommages subis par votre véhicule, quel que soit le type d'accident et quelle que soit votre responsabilité en tant que conducteur.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'vandalism',
    parameterOrder: 0,
    parameterDisplayName: 'Vandalism',
    parameterDescription:
      'Couvre les dommages matériels infligés à votre véhicule suite à des actes de vandalisme, tels que les graffitis, les rayures, le bris de vitres, les pneus crevés, ou les dommages intentionnels de la carrosserie.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'theft',
    parameterOrder: 0,
    parameterDisplayName: 'Vol',
    parameterDescription:
      "Celle-ci permet d'être indemnisé en cas de vol ou de tentative de vol de votre véhicule. Elle peut, dans une certaine mesure, assurer également le contenu du véhicule selon les termes du contrat.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: true,
    parameterName: 'replacementVehicle',
    parameterOrder: 0,
    parameterDisplayName: 'Véhicule de remplacement',
    parameterDescription: "Mise à disposition d'un véhicule de remplacement temporaire",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
];

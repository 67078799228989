import { WealthGetDataModel } from '@insurely/common-api-client';
import isEqual from 'react-fast-compare';

import { Pension } from '@main/types';
import { isPension } from '@main/utils';

function isPensionPoliciesEqual(firstPension: Pension, secondPension: Pension) {
  const { externalId: _, ...firstPensionData } = firstPension;
  const { externalId: _2, ...secondPensionData } = secondPension;

  return isEqual(firstPensionData, secondPensionData);
}

function isInsurancePoliciesEqual(
  firstInsurance: InsurancePolicy,
  secondInsurance: InsurancePolicy,
) {
  const {
    insurance: { externalId: _, insuranceNumber: insuranceNumber1, ...firstInsuranceData },
    ...firstCollectionData
  } = firstInsurance;
  const {
    insurance: { externalId: _2, insuranceNumber: insuranceNumber2, ...secondInsuranceData },
    ...secondCollectionData
  } = secondInsurance;

  if (insuranceNumber1 && insuranceNumber2) {
    return insuranceNumber1 === insuranceNumber2;
  }

  if (!insuranceNumber1 && !insuranceNumber2) {
    return isEqual(
      {
        ...firstCollectionData,
        insurance: { ...firstInsuranceData },
      },
      {
        ...secondCollectionData,
        insurance: { ...secondInsuranceData },
      },
    );
  }

  return false;
}

const isPoliciesEqual = (firstPolicy: Policy, secondPolicy: Policy) => {
  const firstIsPension = isPension(firstPolicy);
  const secondIsPension = isPension(secondPolicy);
  if (firstIsPension && secondIsPension) {
    return isPensionPoliciesEqual(firstPolicy, secondPolicy);
  }
  if (!firstIsPension && !secondIsPension) {
    return isInsurancePoliciesEqual(firstPolicy, secondPolicy);
  }
  return false;
};

export function getDistinctPolicies(previousPolicies: Policy[], newPolicies: Policy[]) {
  return previousPolicies.reduce(
    (newPolicyList, currentOldPolicy) => {
      if (newPolicyList.find((newPolicy: Policy) => isPoliciesEqual(newPolicy, currentOldPolicy))) {
        return newPolicyList;
      }

      return [...newPolicyList, currentOldPolicy];
    },
    [...newPolicies],
  );
}

const isWealthDataEqual = (
  firstWealthData: WealthGetDataModel,
  secondWealthData: WealthGetDataModel,
) => {
  const { id: _, ...first } = firstWealthData;
  const { id: _2, ...second } = secondWealthData;
  return isEqual(first, second);
};

export function getDistinctWealthData(
  previousWealthData: WealthGetDataModel[],
  newWealthData: WealthGetDataModel[],
) {
  return previousWealthData.reduce(
    (newWealthDataList, currentWealthData) => {
      if (
        newWealthDataList.find((wealthData: WealthGetDataModel) =>
          isWealthDataEqual(wealthData, currentWealthData),
        )
      ) {
        return newWealthDataList;
      }

      return [...newWealthDataList, currentWealthData];
    },
    [...newWealthData],
  );
}

import { EstonianCarInsurance } from '@main/types/insurance/estonia';

import { fakeNewEstonianCarDeductibles } from './deductibles';
import { fakeNewEstonianCarInsurance } from './insurance';
import { fakeNewEstonianCarParameters } from './parameters';

export const fakeNewEstonianCarCollection = (
  overrides?: Array<{ insurance?: Partial<EstonianCarInsurance> }> | number,
): Array<InsurancePolicy> => {
  const defaultData = () => ({
    insurance: fakeNewEstonianCarInsurance.one(),
    parameters: fakeNewEstonianCarParameters,
    addons: [],
    personalInformation: { PERSONAL_NUMBER: '199301010101' },
    deductibles: fakeNewEstonianCarDeductibles,
  });
  return typeof overrides === 'number'
    ? Array(overrides)
        .fill(0)
        .map((_) => defaultData())
    : (overrides?.map((overwrittenData) => {
        const collectionData = defaultData();
        return {
          ...collectionData,
          insurance: {
            ...collectionData.insurance,
            ...overwrittenData.insurance,
          },
        };
      }) ?? [defaultData()]);
};

import { IntlShape } from 'react-intl';

import { PaymentMethod } from '@main/types';

import { OwnershipStatus, ResidentialStatusFR } from '@main/types/insurance/france';

export const formatPaymentMethod = (intl: IntlShape, paymentMethod: PaymentMethod | undefined) => {
  if (!paymentMethod || paymentMethod === PaymentMethod.UNDEFINED) {
    return intl.formatMessage({ defaultMessage: 'Unknown' });
  }
  if (paymentMethod === PaymentMethod.AUTOGIRO) {
    return intl.formatMessage({ defaultMessage: 'Direct debit' });
  }

  // eslint-disable-next-line default-case
  switch (paymentMethod) {
    case PaymentMethod.PAPER_INVOICE:
      return intl.formatMessage({ defaultMessage: 'Paper invoice' });
    case PaymentMethod.GENERIC_INVOICE:
      return intl.formatMessage({ defaultMessage: 'Generic invoice' });
    case PaymentMethod.ELECTRONIC_INVOICE:
      return intl.formatMessage({ defaultMessage: 'Electronic invoice' });
    case PaymentMethod.ONE_TIME_PAYMENT:
      return intl.formatMessage({ defaultMessage: 'One time payment' });
    case PaymentMethod.EMPLOYER_PAID:
      return intl.formatMessage({ defaultMessage: 'Employer paid' });
    case PaymentMethod.CREDIT_CARD:
      return intl.formatMessage({ defaultMessage: 'Credit card' });
  }
};

export const formatOwnershipStatus = (intl: IntlShape, status: OwnershipStatus) => {
  // eslint-disable-next-line default-case
  switch (status) {
    case OwnershipStatus.OWNER:
      return intl.formatMessage({ defaultMessage: 'Owner' });
    case OwnershipStatus.SECONDARY_TENANT:
      return intl.formatMessage({ defaultMessage: 'Secondary tenant' });
    case OwnershipStatus.TENANT:
      return intl.formatMessage({ defaultMessage: 'Tenant' });
    case OwnershipStatus.UNKNOWN:
      return intl.formatMessage({ defaultMessage: 'Unknown' });
  }
};

export const formatResidentialStatusFR = (intl: IntlShape, status: ResidentialStatusFR) => {
  // eslint-disable-next-line default-case
  switch (status) {
    case ResidentialStatusFR.PRIMARY_RESIDENCE:
      return intl.formatMessage({ defaultMessage: 'Primary residence' });
    case ResidentialStatusFR.SECONDARY_RESIDENCE:
      return intl.formatMessage({ defaultMessage: 'Secondary residence' });
    case ResidentialStatusFR.RENTAL_RESIDENCE:
      return intl.formatMessage({ defaultMessage: 'Rental residence' });
    case ResidentialStatusFR.UNKNOWN:
      return intl.formatMessage({ defaultMessage: 'Unknown' });
  }
};

import { useSharedData } from '../contexts';
import { AvailableInsuranceCompanyCheckout } from '../types';

interface useBNPP {
  isBNPP: boolean;
}

// This hook is meant to be used as a temporary Release Flag for the client BNPP (fr-cardif)
// If the client is BNPP (fr-cardif) we don't want to release any new features
// The hook will be removed once we are allowed to release new feature

export const useBNPP = (): useBNPP => {
  const [
    {
      clientConfig: { productConfigs },
    },
  ] = useSharedData();

  const clientCompanyName = productConfigs?.compare?.offerCompany?.name;

  return {
    isBNPP: clientCompanyName === AvailableInsuranceCompanyCheckout['fr-cardif'],
  };
};

import { Icon } from '@insurely/ui';

import { useIntl } from 'react-intl';

import { ResultItemContent } from '@main/components/ResultItemContent';
import {
  useNavigate,
  OVERVIEW_PATH,
  OVERVIEW_WEALTH_ACCOUNT,
  Account,
  useSharedData,
  formatCurrency,
} from '@main/shared-exports';

import styles from './accountListItems.module.css';

import { formattedSubTitle, formattedWealthType } from './utils';

export interface AccountListItemsProps {
  accounts: Account[];
}

export const AccountListItems = ({ accounts }: AccountListItemsProps) => {
  const intl = useIntl();

  const [
    {
      clientConfig: { language, market },
    },
  ] = useSharedData();

  const navigate = useNavigate();

  return accounts.map((account) => {
    const { company, companyDisplayName, financialInformation, holder, id, type } = account;

    const title = formattedWealthType(type, intl);
    const subTitle = formattedSubTitle({ companyDisplayName, holder });
    const amount = formatCurrency({
      language,
      market: {
        country: market.country,
        currency: financialInformation.currentValue.currency,
      },
      amount: financialInformation.currentValue.amount,
    });

    const navigateToAccount = () => {
      /** Todo: Remove /${externalId} from the path
       * This is used for dual compatibility, analytics will consume query parameters soon
       * https://linear.app/insurely/issue/EXP-3261/insurance-details-remove-externalid-from-path */
      navigate(`${OVERVIEW_PATH}/${OVERVIEW_WEALTH_ACCOUNT}/${id}`, {
        searchParams: { id },
      });
    };

    // TODO: In the new UI component (not yet implemented), center the amount vertically and add "..." if content takes up too much space. (Use grid instead of flex for ResultItemContent/ListItem)
    // Linear ticket: https://linear.app/insurely/issue/EXPC-1173/implement-listitem-component-in-ui

    return (
      <button
        key={id}
        type="button"
        className={styles.buttonContainer}
        onClick={navigateToAccount}
        data-testid="result-item"
        aria-label={`${title} ${subTitle}`}
      >
        <ResultItemContent
          className={styles.itemContent}
          title={title}
          subTitle={subTitle}
          subTitleVariant="ParagraphCaption"
          amount={amount}
          insuranceCompany={company}
          insuranceCompanyDisplayName={companyDisplayName}
          icon={
            <div className={styles.chevronContainer}>
              <Icon size={24} name="chevron-right" color="var(--colorBody)" />
            </div>
          }
        />
      </button>
    );
  });
};

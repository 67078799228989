import { Fees, Language } from '@main/types';
import { formatNumber } from '@main/utils';

export const feeSectionExist = (fees: Fees): boolean => fees.feeFixed?.amount !== undefined;

export const formattedPerformanceSinceStart = ({
  initialValue,
  currentValue,
  performanceSinceStart,
  language,
}: {
  initialValue?: number;
  currentValue: number;
  performanceSinceStart?: number;
  language: Language;
}): string => {
  let perfomancePercentage = 0;

  if (performanceSinceStart) {
    perfomancePercentage = performanceSinceStart * 100;
  } else if (initialValue) {
    perfomancePercentage = (currentValue / initialValue) * 100 - 100;
  }

  if (perfomancePercentage > 0) {
    return `+ ${formatNumber(perfomancePercentage, language, 2, 0)} %`;
  }
  return `${formatNumber(perfomancePercentage, language, 2, 0)} %`;
};

const calculateInitialValue = ({
  initialValue,
  currentValue,
  performanceSinceStart,
}: {
  initialValue?: number;
  currentValue: number;
  performanceSinceStart?: number;
}): number | undefined => {
  if (initialValue) {
    return initialValue;
  }
  if (performanceSinceStart) {
    return currentValue / (1 + performanceSinceStart);
  }
};

export const formattedInitialValue = ({
  initialValue,
  currentValue,
  performanceSinceStart,
  language,
}: {
  initialValue?: number;
  currentValue: number;
  performanceSinceStart?: number;
  language: Language;
}): string | undefined => {
  const calculatedInitialValue = calculateInitialValue({
    initialValue,
    currentValue,
    performanceSinceStart,
  });

  if (calculatedInitialValue) {
    return formatNumber(calculatedInitialValue, language);
  }
};

export const formattedReturnValue = ({
  initialValue,
  currentValue,
  performanceSinceStart,
  language,
}: {
  initialValue?: number;
  currentValue: number;
  performanceSinceStart?: number;
  language: Language;
}): string => {
  const depositedValue = calculateInitialValue({
    initialValue,
    currentValue,
    performanceSinceStart,
  });

  let returnValue = 0;

  if (depositedValue) {
    returnValue = currentValue - depositedValue;
  }

  if (returnValue > 0) {
    return `+ ${formatNumber(returnValue, language)}`;
  }
  return formatNumber(returnValue, language);
};

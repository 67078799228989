import { Language } from '@main/types/configurations/language';
import { OwnershipStatus, ResidentialStatusFR } from '@main/types/insurance/france';
import { InsuranceSubType, InsuranceType } from '@main/types/insurance/InsuranceType';
import { InsuranceParameter } from '@main/types/insurance/Parameter';

export interface InsuranceHolderInformation {
  socialSecurityNumber: string;
  email?: string;
  phoneNumber?: string;
}

export interface Option {
  id: string;
  includedByDefault?: boolean;
}

export enum FrenchCarInsuranceName {
  'formule tous risques' = 'formule tous risques',
  'formule tiers +' = 'formule tiers +',
  'formule tiers' = 'formule tiers',
}

export enum CarInsuranceName {
  'Helförsäkring' = 'Helförsäkring',
  'Halvförsäkring' = 'Halvförsäkring',
  'Trafikförsäkring' = 'Trafikförsäkring',
}
export enum CondoInsuranceName {
  'Hemförsäkring' = 'Hemförsäkring',
  'MRH' = 'MRH',
  'MRH Formule Confort' = 'MRH Formule Confort',
  'Demo Habitation' = 'Demo Habitation',
  'mrh principale' = 'mrh principale',
  'mrh secondaire' = 'mrh secondaire',
}

export type InsurancePackageName = CarInsuranceName | CondoInsuranceName | FrenchCarInsuranceName;

interface InsuranceOption {
  name: string;
  values: Option[];
}

export type InsuanceLinkDocumentType =
  | 'insurance-company-additional-information'
  | 'intermediary-distance-sales-contract'
  | 'insurance-company-product-information'
  | 'insurance-company-distance-sales'
  | 'intermediary-pre-purchase-information'
  | 'insurance-company-full-terms-and-conditions'
  | 'insurance-company-pre-purchase';

export interface InsuranceDocument {
  documentType: InsuanceLinkDocumentType;
  language: Language;
  url: string;
}

export interface PriceDetails {
  annualPremium: string;
}

export interface InsuranceInformation<NameOfInsurance extends string> {
  insuranceName: NameOfInsurance;
  insuranceDocuments: InsuranceDocument[];
  insuranceQuoteOptions: InsuranceOption[];
}

export interface SelectedInsuranceParameters {
  insuranceName: InsurancePackageName;
  parameters?: InsuranceParameter[];
  isInitialLoading: boolean;
}

/** *************** Payloads **************** */

export type GetPersonInfoPayload = {
  personalNumber: string;
};

export interface CoveragePolicyData {
  residentialStatus?: ResidentialStatusFR;
  ownershipStatus?: OwnershipStatus;
  region?: string;
  otherInsuranceHolder?: boolean;
}

export interface InsuranceParametersPayload {
  insuranceCompany: AvailableInsuranceCompaniesCheckout;
  insuranceName: InsurancePackageName;
  insuranceSubType: InsuranceSubType;
  insuranceType: InsuranceType;
  coveragePolicyData?: CoveragePolicyData;
}

/** *************** Responses ************* *** */

export type InsuranceInformationResponse<NameOfInsurance extends string> =
  InsuranceInformation<NameOfInsurance>[];

export interface PersonInfoResponse {
  firstName: string;
  surName: string;
  city: string;
  postalCode: string;
  streetAddress: string;
  validPersonalNumber: boolean;
}

export type InsuranceParametersResponse = InsuranceParameter[];

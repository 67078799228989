export enum CompareVariant {
  full = 'full',
  'price-only' = 'price-only',
  'parameters-only' = 'parameters-only',
  'without-deductible' = 'without-deductible',
}

export enum FailedToCompareReason {
  NoPolicySelected = 'NoPolicySelected',
  MissingParameters = 'MissingParameters',
  MissingRequiredFields = 'MissingRequiredFields',
  SubTypeNotSupported = 'SubTypeNotSupported',
  MarketNotSupported = 'MarketNotSupported',
  ClientChoice = 'ClientChoice',
}

export type PossibleToCompare = {
  isComparisonPossible: boolean;
  failedReason?: FailedToCompareReason;
  failedNavigatePath?: string;
  missingFields?: Array<string>;
};

import { Button, IconError, IconInfoCircle, IconWarning, Typography } from '@insurely/ui';

import { useEffect } from 'react';

import { sentryCaptureException } from '@main/services';
import { postHogCapture } from '@main/shared-exports';
import { ErrorButtonWithOnClick, ErrorButtonWithHref, StructuredError } from '@main/utils';

import { BaseHeader } from '../Header';

import styles from './errorFallback.module.css';

interface HandledFallbackProps {
  error: StructuredError;
  resetError: () => void;
  pageTitle: string;
  resetButtonText: string;
}

const getButtonRender = (
  resetError: () => void,
  buttonObject?: ErrorButtonWithOnClick | ErrorButtonWithHref,
  isMainButton?: boolean,
  resetButtonText?: string,
) => {
  if (!buttonObject) return null;
  if (buttonObject.href)
    return (
      <Button
        variant={isMainButton ? 'primary' : 'tertiary'}
        Component="a"
        href={buttonObject.href}
        target="_blank"
        center
        style={
          isMainButton
            ? {
                minWidth: '186px',
              }
            : {
                minWidth: '186px',
                marginTop: '16.5px',
              }
        }
        fullWidth
      >
        {buttonObject.text ?? resetButtonText}
      </Button>
    );

  return (
    <Button
      variant={isMainButton ? 'primary' : 'tertiary'}
      onClick={() => {
        if (buttonObject?.onClick) {
          buttonObject.onClick();
        }
        resetError();
      }}
      center
      style={
        isMainButton
          ? {
              minWidth: '186px',
            }
          : {
              minWidth: '186px',
              marginTop: '16.5px',
            }
      }
      fullWidth
    >
      {buttonObject.text ?? resetButtonText}
    </Button>
  );
};

export function HandledFallback({
  error,
  resetError,
  pageTitle,
  resetButtonText,
}: HandledFallbackProps) {
  const { title, type = 'error', message, link, button } = error.details;

  useEffect(() => {
    postHogCapture('view:handledError', {
      action: 'view',
      category: 'outsideOfBlocks',
      object: 'HandledFallback',
      metadata: {
        title,
        description: message,
        type,
      },
    });
    sentryCaptureException(error);
  }, [error, message, title, type]);

  return (
    <div>
      <BaseHeader
        title={pageTitle}
        backButtonAriaLabel="Go back to previous page"
        closeButtonAriaLabel="Close application"
      />
      {type === 'error' ? (
        <IconError width="48px" className={styles.icon} />
      ) : type === 'warning' ? (
        <IconWarning width="48px" className={styles.icon} />
      ) : (
        <IconInfoCircle width="48px" className={styles.icon} /> // Change to clock icon when design is set
      )}
      <Typography component="h2" variant="Headline-6" className={styles.headline}>
        {title}
      </Typography>

      <div className={styles.content}>
        {message ? (
          Array.isArray(message) ? (
            message.map((paragraph) => (
              <Typography
                component="p"
                variant="ParagraphBody"
                className={styles.paragraph}
                key={paragraph}
              >
                {paragraph}
              </Typography>
            ))
          ) : (
            <Typography component="p" variant="ParagraphBody" className={styles.paragraph}>
              {message}
            </Typography>
          )
        ) : null}
        <div className={styles.buttons}>
          {getButtonRender(resetError, button, true, resetButtonText)}
          {getButtonRender(resetError, link)}
        </div>
      </div>
    </div>
  );
}

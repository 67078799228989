import { sharedApiClient } from './sharedApiClient';

interface TrackUserEngagementsBody {
  externalId: string;
  hasAcceptedOffer?: boolean;
}

export const trackUserEngagement = (externalId: string, hasAcceptedOffer?: boolean) =>
  sharedApiClient('blocks/track-user-engagement', {
    method: 'PUT',
    data: {
      externalId,
      hasAcceptedOffer,
    } as TrackUserEngagementsBody,
  })
    .then((response) => response.data)
    .catch(() => null);

import { DescriptionList } from '@insurely/ui';

import { useIntl } from 'react-intl';

import { useSharedData } from '@main/contexts';
import { PensionAccount } from '@main/types';

import { convertSnakeCaseToSentence, formatCurrency } from '@main/utils';

import { formattedWealthType } from '../../../WealthOverview/components/AccountList/utils';

import { feeSectionExist } from '../../utils';

import styles from './index.module.css';
import { convertBooleanToFormattedString } from './utils';

interface PensionDescriptionListProp {
  account: PensionAccount;
}

export const PensionDescriptionList = ({ account }: PensionDescriptionListProp) => {
  const intl = useIntl();

  const [
    {
      clientConfig: { language, market },
    },
  ] = useSharedData();

  const { holder, type, movability, insurance, fees } = account;

  const wealthType = formattedWealthType(type, intl);

  const previousYearFee = formatCurrency({
    language,
    market: {
      country: market.country,
      currency: fees.feesPreviousYear.currency,
    },
    amount: fees.feesPreviousYear.amount,
  });

  const descriptionListItems = [
    ...(holder.organization?.name
      ? [
          {
            label: intl.formatMessage({
              id: 'overview.wealth-account.general-info-pension.term-1',
              defaultMessage: 'Employer',
            }),
            value: holder.organization?.name,
          },
        ]
      : []),
    {
      label: intl.formatMessage({
        id: 'overview.wealth-account.general-info-pension.term-2',
        defaultMessage: 'Pension type',
      }),
      value: wealthType,
    },
    {
      label: intl.formatMessage({
        id: 'overview.wealth-account.general-info-pension.term-3',
        defaultMessage: 'Status',
      }),
      value: convertSnakeCaseToSentence(movability.result),
    },
    {
      label: intl.formatMessage({
        id: 'overview.wealth-account.general-info-pension.term-4',
        defaultMessage: 'Repayment-cover',
      }),
      value: convertBooleanToFormattedString(insurance.survivorsProtection, intl),
    },
    ...(!feeSectionExist(fees)
      ? [
          {
            label: intl.formatMessage({
              id: 'overview.wealth-account.general-info-pension.term-5',
              defaultMessage: 'Fees last year',
            }),
            value: previousYearFee,
          },
        ]
      : []),
  ];

  return (
    <DescriptionList
      rows={descriptionListItems}
      labelTextVariant="ParagraphCaption"
      valueTextVariant="Label-2"
      className={styles.descriptionList}
    />
  );
};

import {
  fakeNewSwedishCarParameters,
  fakeNewSwedishHomeParameters,
} from '@main/mocks/fake-utils/collections';
import { InsuranceSubType } from '@main/types';

export function getParametersSE({ insuranceSubType }: { insuranceSubType: InsuranceSubType }) {
  switch (insuranceSubType) {
    case InsuranceSubType.condoInsurance:
      return fakeNewSwedishHomeParameters();
    case InsuranceSubType.carInsurance:
    default:
      return fakeNewSwedishCarParameters();
  }
}

import {
  ExpandableSection,
  ExpandableSectionChevron,
  ExpandableSectionContent,
  ExpandableSectionTitle,
  Typography,
} from '@insurely/ui';

import classNames from 'classnames';

import { FormattedParameterGroup } from '@main/types';

import { ParameterList, ParameterListTitle } from '../ParameterList';

import styles from './parameterGroupList.module.css';

interface ParameterGroupListProps {
  addOnExplanationCopy: string;
  hasAddons?: boolean;
  groups: FormattedParameterGroup[];
  titles?: ParameterListTitle[];
  isOpenOnLoad?: boolean;
  className?: string;
}

export const ParameterGroupList = ({
  addOnExplanationCopy,
  hasAddons,
  groups,
  titles,
  isOpenOnLoad,
  className,
}: ParameterGroupListProps) => (
  <div>
    {hasAddons && (
      <Typography
        component="p"
        variant="ParagraphCaption"
        className={classNames(styles.addOnExplanation, className)}
      >
        {addOnExplanationCopy}
      </Typography>
    )}
    {groups
      .sort((a, b) => a.order - b.order)
      .map(({ groupName, order, parameters }) => {
        if (!groupName) return null;
        return (
          <ExpandableSection key={order} isOpenOnLoad={isOpenOnLoad}>
            <ExpandableSectionTitle className={styles.title}>
              <ExpandableSectionChevron />
              <Typography component="p" variant="Headline-7">
                {groupName}
              </Typography>
            </ExpandableSectionTitle>
            <ExpandableSectionContent className={styles.content} bottomPadding={false}>
              <ParameterList titles={titles} parameters={parameters} />
            </ExpandableSectionContent>
          </ExpandableSection>
        );
      })}
  </div>
);

import { CompareVariant } from '@main/types/compare';

import {
  AvailableInsuranceCompanyCheckout,
  ClientConfig,
  Language,
  UseCaseName,
} from '@main/types/configurations';

import { InsuranceSubType } from '@main/types/insurance';
import { DeepPartial } from '@main/types/utils';

import { mockBlocksConfig } from './fake-utils/blocksConfig';

/* eslint-disable max-lines */

const dkTopDanmark = mockBlocksConfig.one({
  traits: ['dk'],
  overrides: {
    clientId: 'dk-topdanmark',
    configName: 'dk-topdanmark',
    customizationId: 'f21b371c-523c-41cc-9819-1de5c212717a',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName['collect-and-question'],
    productConfigs: {
      compare: {
        variant: CompareVariant.full,
        offerCompany: {
          name: 'dk-topdanmark' as AvailableInsuranceCompaniesCheckout,
          displayName: 'TopDanmark',
        },
      },
    },
  },
});

const etCitadele = mockBlocksConfig.one({
  traits: ['ee'],
  overrides: {
    clientId: 'ee-citadele',
    configName: 'ee-citadele',
    customizationId: '7d64447a-d227-41a4-b21d-82abf1a5002c',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName['data-aggregation'],
  },
});

const frPrefillAndCompare = mockBlocksConfig.one({
  traits: ['fr'],
  overrides: {
    clientId: 'fr-prefill-and-compare-condo',
    configName: 'fr-prefill-and-compare-condo',
    language: Language.en,
    useCase: UseCaseName['prefill-and-compare'],
    productConfigs: {
      checkout: {
        availableCheckouts: [
          {
            insuranceCompany: AvailableInsuranceCompanyCheckout['fr-direct-assurance'],
            insuranceCompanyDisplayName: 'Direct Assurance',
            insuranceSubType: InsuranceSubType.condoInsurance,
            insuranceSubTypeDisplayName: 'Home insurance',
          },
        ],
        insuranceDocuments: [
          {
            documentType: 'intermediary-distance-sales-contract',
            url: 'https://assets.insurely.com/checkout/S%C3%A4rskild+information+enligt+lagen+om+distansavtal+och+avtal+utanf%C3%B6r+aff%C3%A4rslokaler.pdf',
            language: 'sv',
          },
          {
            documentType: 'intermediary-pre-purchase-information',
            url: 'https://assets.insurely.com/checkout/F%C3%B6rs%C3%A4kringsdistribution.pdf',
            language: 'sv',
          },
        ],
      },
      compare: {
        offerCompany: {
          name: AvailableInsuranceCompanyCheckout['fr-demo'],
          displayName: 'Demo Company',
        },
        variant: CompareVariant['without-deductible'],
        redirectUrl: 'https://www.insurely.com/',
      },
    },
  },
});

const frDirectAssurance = mockBlocksConfig.one({
  traits: ['fr'],
  overrides: {
    clientId: 'direct-assurance-cs',
    configName: 'direct-assurance-cs',
    customizationId: 'b837c9e6-e56e-476f-a102-d718c801c73f',
    language: Language.fr,
    allowedOrigin: [
      'http://localhost:4000',
      'https://devis-habitation.direct-assurance.fr',
      'https://www.direct-assurance.fr',
      'https://www.direct-assurance.fr/assurance-habitation/comparer-mon-assurance',
      'https://docs.insurely.com/',
      'https://pp.direct-assurance.fr/assurance-habitation/comparer-mon-assurance',
      '*direct-assurance.fr*',
    ],
    useCase: UseCaseName['collect-question-and-compare'],
    productConfigs: {
      checkout: {
        availableCheckouts: [
          {
            insuranceCompany: AvailableInsuranceCompanyCheckout['fr-direct-assurance'],
            insuranceCompanyDisplayName: 'Direct Assurance',
            insuranceSubTypeDisplayName: 'Assurance Habitation',
            insuranceSubType: InsuranceSubType.condoInsurance,
          },
          {
            insuranceCompany: 'fr-demo' as AvailableInsuranceCompanyCheckout,
            insuranceCompanyDisplayName: 'Demo Company',
            insuranceSubTypeDisplayName: 'Assurance Habitation',
            insuranceSubType: InsuranceSubType.condoInsurance,
          },
        ],
        insuranceDocuments: [],
      },
      compare: {
        redirectUrl: 'https://www.direct-assurance.fr/assurance-habitation/comparer-mon-assurance',
        offerCompany: {
          name: AvailableInsuranceCompanyCheckout['fr-direct-assurance'],
          displayName: 'Direct Assurance',
        },
        variant: CompareVariant['without-deductible'],
        showPreviousCoverage: true,
        skipCompareUrl:
          'https://devis-habitation.direct-assurance.fr/assurance-habitation/devis/mon-logement',
      },
    },
  },
});

const frDirectAssuranceWithoutCustomization = mockBlocksConfig.one({
  traits: ['fr'],
  overrides: {
    clientId: 'direct-assurance-portal',
    configName: 'direct-assurance-portal',
    customizationId: 'b837c9e6-e56e-476f-a102-d718c801c73f',
    language: Language.fr,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName['data-aggregation'],
    showCustomization: false,
  },
});

const frOverviewAndCompare = mockBlocksConfig.one({
  traits: ['fr'],
  overrides: {
    clientId: 'fr-overview-and-compare',
    configName: 'fr-overview-and-compare',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName['overview-and-compare'],
    productConfigs: {
      compare: {
        offerCompany: {
          name: AvailableInsuranceCompanyCheckout['fr-demo'],
          displayName: 'Demo Company',
        },
        variant: CompareVariant['parameters-only'],
        redirectUrl: 'https://www.insurely.com/',
      },
    },
  },
});

const frBnpp = mockBlocksConfig.one({
  traits: ['fr'],
  overrides: {
    clientId: 'fr-bnpp',
    configName: 'fr-bnpp',
    customizationId: '9923f23e-3db4-42bc-b99e-3aa68b8f0350',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName['overview-and-compare'],
    productConfigs: {
      compare: {
        offerCompany: {
          name: AvailableInsuranceCompanyCheckout['fr-cardif'],
          displayName: 'BNP Paribas',
        },
        variant: CompareVariant['parameters-only'],
        redirectUrl: 'https://www.insurely.com/',
      },
    },
  },
});

const frKozoo = mockBlocksConfig.one({
  traits: ['fr'],
  overrides: {
    clientId: 'fr-kozoo',
    configName: 'fr-kozoo',
    customizationId: '2f7c2d3a-005e-4b10-9ddf-e81d9b5ae5d9',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName['prefill-and-compare'],
    productConfigs: {
      checkout: {
        availableCheckouts: [
          {
            insuranceCompany: AvailableInsuranceCompanyCheckout['fr-demo'],
            insuranceCompanyDisplayName: 'Demo Company',
            insuranceSubTypeDisplayName: 'Assurance animaux',
            insuranceSubType: InsuranceSubType.dogInsurance,
          },
          {
            insuranceCompany: AvailableInsuranceCompanyCheckout['fr-demo'],
            insuranceCompanyDisplayName: 'Demo Company',
            insuranceSubTypeDisplayName: 'Assurance animaux',
            insuranceSubType: InsuranceSubType.catInsurance,
          },
          {
            insuranceCompany: AvailableInsuranceCompanyCheckout['fr-kozoo'],
            insuranceCompanyDisplayName: 'Kozoo',
            insuranceSubTypeDisplayName: 'Assurance animaux',
            insuranceSubType: InsuranceSubType.dogInsurance,
          },
          {
            insuranceCompany: AvailableInsuranceCompanyCheckout['fr-kozoo'],
            insuranceCompanyDisplayName: 'Kozoo',
            insuranceSubTypeDisplayName: 'Assurance animaux',
            insuranceSubType: InsuranceSubType.catInsurance,
          },
        ],
        insuranceDocuments: [],
      },
    },
  },
});

const seCheckoutVilla = mockBlocksConfig.one({
  traits: ['se'],
  overrides: {
    clientId: 'se-checkout-villa',
    configName: 'se-checkout-villa',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName.checkout,
    productConfigs: {
      checkout: {
        availableCheckouts: [
          {
            insuranceCompany: AvailableInsuranceCompanyCheckout['se-ica'],
            insuranceCompanyDisplayName: 'ICA Försäkring',
            insuranceSubType: InsuranceSubType.villaInsurance,
            insuranceSubTypeDisplayName: 'Villaförsäkring',
          },
        ],
        insuranceDocuments: [
          {
            documentType: 'intermediary-distance-sales-contract',
            url: 'https://assets.insurely.com/checkout/S%C3%A4rskild+information+enligt+lagen+om+distansavtal+och+avtal+utanf%C3%B6r+aff%C3%A4rslokaler.pdf',
            language: 'sv',
          },
          {
            documentType: 'intermediary-pre-purchase-information',
            url: 'https://assets.insurely.com/checkout/F%C3%B6rs%C3%A4kringsdistribution.pdf',
            language: 'sv',
          },
        ],
      },
    },
  },
});

const seCheckoutCondo = mockBlocksConfig.one({
  traits: ['se'],
  overrides: {
    clientId: 'se-checkout-condo',
    configName: 'se-checkout-condo',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName.checkout,
    productConfigs: {
      checkout: {
        availableCheckouts: [
          {
            insuranceCompany: AvailableInsuranceCompanyCheckout['se-ica'],
            insuranceCompanyDisplayName: 'ICA Försäkring',
            insuranceSubType: InsuranceSubType[InsuranceSubType.condoInsurance],
            insuranceSubTypeDisplayName: 'Hemförsäkring',
          },
        ],
        insuranceDocuments: [
          {
            documentType: 'intermediary-distance-sales-contract',
            url: 'https://assets.insurely.com/checkout/S%C3%A4rskild+information+enligt+lagen+om+distansavtal+och+avtal+utanf%C3%B6r+aff%C3%A4rslokaler.pdf',
            language: 'sv',
          },
          {
            documentType: 'intermediary-pre-purchase-information',
            url: 'https://assets.insurely.com/checkout/F%C3%B6rs%C3%A4kringsdistribution.pdf',
            language: 'sv',
          },
        ],
      },
    },
  },
});

const seCheckoutCar = mockBlocksConfig.one({
  traits: ['se'],
  overrides: {
    clientId: 'se-checkout-car',
    configName: 'se-checkout-car',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName.checkout,
    productConfigs: {
      checkout: {
        availableCheckouts: [
          {
            insuranceCompany: AvailableInsuranceCompanyCheckout['se-ica'],
            insuranceCompanyDisplayName: 'ICA Försäkring',
            insuranceSubType: InsuranceSubType.carInsurance,
            insuranceSubTypeDisplayName: 'Bilförsäkring',
          },
        ],
        insuranceDocuments: [
          {
            documentType: 'intermediary-distance-sales-contract',
            url: 'https://assets.insurely.com/checkout/S%C3%A4rskild+information+enligt+lagen+om+distansavtal+och+avtal+utanf%C3%B6r+aff%C3%A4rslokaler.pdf',
            language: 'sv',
          },
          {
            documentType: 'intermediary-pre-purchase-information',
            url: 'https://assets.insurely.com/checkout/F%C3%B6rs%C3%A4kringsdistribution.pdf',
            language: 'sv',
          },
        ],
      },
    },
  },
});

const seCompareAndSwitchCar = mockBlocksConfig.one({
  traits: ['se'],
  overrides: {
    clientId: 'se-compare-and-switch-car',
    configName: 'se-compare-and-switch-car',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName['compare-and-switch'],
    productConfigs: {
      checkout: {
        availableCheckouts: [
          {
            insuranceCompany: AvailableInsuranceCompanyCheckout['se-ica'],
            insuranceCompanyDisplayName: 'ICA Försäkring',
            insuranceSubType: InsuranceSubType.carInsurance,
            insuranceSubTypeDisplayName: 'Bilförsäkring',
          },
        ],
        insuranceDocuments: [
          {
            documentType: 'intermediary-distance-sales-contract',
            url: 'https://assets.insurely.com/checkout/S%C3%A4rskild+information+enligt+lagen+om+distansavtal+och+avtal+utanf%C3%B6r+aff%C3%A4rslokaler.pdf',
            language: 'sv',
          },
          {
            documentType: 'intermediary-pre-purchase-information',
            url: 'https://assets.insurely.com/checkout/F%C3%B6rs%C3%A4kringsdistribution.pdf',
            language: 'sv',
          },
        ],
      },
      compare: {
        offerCompany: {
          name: AvailableInsuranceCompanyCheckout['se-ica'],
          displayName: 'ICA Försäkring',
        },
        variant: CompareVariant.full,
        redirectUrl: 'https://www.insurely.com/',
      },
    },
  },
});

const seCompareAndSwitchCondo = mockBlocksConfig.one({
  traits: ['se'],
  overrides: {
    clientId: 'se-compare-and-switch-condo',
    configName: 'se-compare-and-switch-condo',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName['compare-and-switch'],
    productConfigs: {
      checkout: {
        availableCheckouts: [
          {
            insuranceCompany: AvailableInsuranceCompanyCheckout['se-ica'],
            insuranceCompanyDisplayName: 'ICA Försäkring',
            insuranceSubType: InsuranceSubType[InsuranceSubType.condoInsurance],
            insuranceSubTypeDisplayName: 'Hemförsäkring',
          },
        ],
        insuranceDocuments: [
          {
            documentType: 'intermediary-distance-sales-contract',
            url: 'https://assets.insurely.com/checkout/S%C3%A4rskild+information+enligt+lagen+om+distansavtal+och+avtal+utanf%C3%B6r+aff%C3%A4rslokaler.pdf',
            language: 'sv',
          },
          {
            documentType: 'intermediary-pre-purchase-information',
            url: 'https://assets.insurely.com/checkout/F%C3%B6rs%C3%A4kringsdistribution.pdf',
            language: 'sv',
          },
        ],
      },
      compare: {
        offerCompany: {
          name: AvailableInsuranceCompanyCheckout['se-ica'],
          displayName: 'ICA Försäkring',
        },
        variant: CompareVariant.full,
        redirectUrl: 'https://www.insurely.com/',
      },
    },
  },
});

const seDataAggregationPension = mockBlocksConfig.one({
  traits: ['se', 'pension'],
  overrides: {
    clientId: 'se-data-aggregation-pension',
    configName: 'se-data-aggregation-pension',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName['data-aggregation'],
  },
});

const seDataAggregationInsurance = mockBlocksConfig.one({
  traits: ['se'],
  overrides: {
    clientId: 'se-data-aggregation-insurance',
    configName: 'se-data-aggregation-insurance',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName['data-aggregation'],
  },
});

const seSpp = mockBlocksConfig.one({
  traits: ['se', 'pension-move'],
  overrides: {
    clientId: 'se-spp-pension',
    configName: 'se-spp',
    customizationId: '88fc43b0-2f65-4ba5-89e0-2daf5c195dff',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
  },
});

const seWealthOverviewSPP = mockBlocksConfig.one({
  traits: ['se', 'wealth-overview'],
  overrides: {
    clientId: 'se-spp-wealth-overview-investment-and-pension',
    configName: 'se-spp-wealth-overview-investment-and-pension',
    customizationId: '88fc43b0-2f65-4ba5-89e0-2daf5c195dff',
    language: Language.en,
    allowedOrigin: ['http://localhost:4000'],
    useCase: UseCaseName['wealth-overview'],
  },
});

export const configs: Record<string, DeepPartial<ClientConfig>> = {
  'dk-topdanmark': dkTopDanmark,
  'ee-citadele': etCitadele,
  'fr-direct-assurance': frDirectAssurance,
  'fr-direct-assurance-portal': frDirectAssuranceWithoutCustomization,
  'fr-prefill-and-compare-condo': frPrefillAndCompare,
  'fr-overview-and-compare': frOverviewAndCompare,
  'fr-bnpp': frBnpp,
  'fr-kozoo': frKozoo,
  'se-checkout-villa': seCheckoutVilla,
  'se-checkout-condo': seCheckoutCondo,
  'se-checkout-car': seCheckoutCar,
  'se-compare-and-switch-car': seCompareAndSwitchCar,
  'se-compare-and-switch-condo': seCompareAndSwitchCondo,
  'se-data-aggregation-pension': seDataAggregationPension,
  'se-data-aggregation-insurance': seDataAggregationInsurance,
  'se-spp': seSpp,
  'se-wealth-overview-spp': seWealthOverviewSPP,
};

export const configOptions = Object.keys(configs);

/* eslint-disable max-lines */
import {
  CompanyAndLineOfBusiness,
  LoginConfigPassword,
  LoginConfigUsernameFieldPlaceholder,
  LoginConfigUsernameLabel,
  LoginMethod,
} from '@main/types';

export const frenchInsuranceCompanies: Company[] = [
  {
    insuranceCompany: 'fr-allianz',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Allianz',
    loginMethods: [LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [LoginMethod.USERNAME_AND_PASSWORD],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_ALLIANZ_HOME,
      CompanyAndLineOfBusiness.FR_ALLIANZ_VEHICLE,
    ],
    resetCredentials: {
      resetPasswordUrl: 'https://espace-client.allianz.fr/mot-de-passe-oublie',
      resetUsernameUrl: null,
    },
    loginConfig: {
      usernameLabel: LoginConfigUsernameLabel.EMAIL,
      usernameFieldPlaceholder: LoginConfigUsernameFieldPlaceholder.EMAIL,
      passwordLabel: LoginConfigPassword.PASSWORD,
      passwordFieldPlaceholder: LoginConfigPassword.PASSWORD,
    },
  },
  {
    insuranceCompany: 'fr-assur-opoil',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: "Assur O'Poil",
    loginMethods: [LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
    resetCredentials: {
      resetPasswordUrl: 'https://www.assuropoil.com/MdpPerdu.aspx',
      resetUsernameUrl: null,
    },
  },
  {
    insuranceCompany: 'fr-axa',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'AXA',
    loginMethods: [LoginMethod.PDF_UPLOAD, LoginMethod.USERNAME_AND_PASSWORD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_AXA_VEHICLE,
      CompanyAndLineOfBusiness.FR_AXA_HOME,
    ],
    resetCredentials: {
      resetPasswordUrl: 'https://connect.axa.fr/newPassword',
      resetUsernameUrl: null,
    },
  },
  {
    insuranceCompany: 'fr-bulle-bleue',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Bulle Bleue',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [CompanyAndLineOfBusiness.FR_BULLEBLEUE_HOME],
    resetCredentials: {
      resetUsernameUrl: 'https://espaceclient.bullebleue.fr/authless-forgot-login',
      resetPasswordUrl: 'https://espaceclient.bullebleue.fr/authless-forgot-password',
    },
  },
  {
    insuranceCompany: 'fr-cardif',
    functional: true,
    status: 'BETA',
    insuranceCompanyDisplayName: 'BNP Paribas Cardif',
    loginMethods: [LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [CompanyAndLineOfBusiness.FR_CARDIF_HOME],
    resetCredentials: {
      resetUsernameUrl: 'https://mabanque.bnpparibas/fr/banque-contacts/rit/',
      resetPasswordUrl: 'https://mabanque.bnpparibas/fr/banque-contacts/recuperer-code-secret/',
    },
  },
  {
    insuranceCompany: 'fr-credit-agricole',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Crédit Agricole',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD, LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [
      {
        type: 'selectOne',
        key: 'region',
        options: [
          {
            value: 'ca-normandie-seine',
            label: 'Normandie Seine',
          },
          {
            value: 'ca-guadeloupe',
            label: 'Guadeloupe',
          },
          {
            value: 'ca-des-savoie',
            label: 'Des Savoie',
          },
          {
            value: 'ca-lorraine',
            label: 'Lorraine',
          },
          {
            value: 'ca-norddefrance',
            label: 'Nord de France',
          },
          {
            value: 'ca-centreloire',
            label: 'Centre Loire',
          },
          {
            value: 'ca-centrest',
            label: 'Centre Est',
          },
          {
            value: 'ca-paris',
            label: 'Paris Ile de France',
          },
          {
            value: 'ca-sudmed',
            label: 'Sud Méditerranée',
          },
          {
            value: 'ca-reunion',
            label: 'Réunion',
          },
          {
            value: 'ca-cmds',
            label: 'Charente Maritime Deux Sèvres',
          },
          {
            value: 'ca-cotesdarmor',
            label: "Côtes d'Armor",
          },
          {
            value: 'ca-morbihan',
            label: 'Morbihan',
          },
          {
            value: 'ca-franchecomte',
            label: 'Franche Comté',
          },
          {
            value: 'ca-charente-perigord',
            label: 'Charente Périgord',
          },
          {
            value: 'ca-illeetvilaine',
            label: 'Ille et Vilaine',
          },
          {
            value: 'ca-languedoc',
            label: 'Languedoc',
          },
          {
            value: 'ca-nmp',
            label: 'Nord Midi Pyrénées',
          },
          {
            value: 'ca-toulouse31',
            label: 'Toulouse 31',
          },
          {
            value: 'ca-aquitaine',
            label: 'Aquitaine',
          },
          {
            value: 'ca-cb',
            label: 'Champagne Bourgogne',
          },
          {
            value: 'ca-pyrenees-gascogne',
            label: 'Pyrénées Gascogne',
          },
          {
            value: 'ca-nord-est',
            label: 'Nord Est',
          },
          {
            value: 'ca-corse',
            label: 'Corse',
          },
          {
            value: 'ca-valdefrance',
            label: 'Val de France',
          },
          {
            value: 'ca-pca',
            label: "Provence Côte d'Azur",
          },
          {
            value: 'ca-alsace-vosges',
            label: 'Alsace Vosges',
          },
          {
            value: 'ca-atlantique-vendee',
            label: 'Atlantique Vendée',
          },
          {
            value: 'ca-tourainepoitou',
            label: 'Touraine Poitou',
          },
          {
            value: 'ca-normandie',
            label: 'Normandie',
          },
          {
            value: 'ca-loirehauteloire',
            label: 'Loire Haute Loire',
          },
          {
            value: 'ca-martinique',
            label: 'Martinique-Guyane',
          },
          {
            value: 'ca-anjou-maine',
            label: 'Anjou Maine',
          },
          {
            value: 'ca-briepicardie',
            label: 'Brie Picardie',
          },
          {
            value: 'ca-centrefrance',
            label: 'Centre France',
          },
          {
            value: 'ca-finistere',
            label: 'Finistère',
          },
          {
            value: 'ca-sudrhonealpes',
            label: 'Sud Rhône Alpes',
          },
          {
            value: 'ca-centreouest',
            label: 'Centre Ouest',
          },
          {
            value: 'ca-alpesprovence',
            label: 'Alpes Provence',
          },
        ],
      },
    ],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_CREDIT_AGRICOLE_HOME,
      CompanyAndLineOfBusiness.FR_CREDIT_AGRICOLE_VEHICLE,
    ],
    resetCredentials: {
      resetPasswordUrl:
        'https://www.credit-agricole.fr/ca-atlantique-vendee/particulier/reinitialisation-code-bam.html',
      resetUsernameUrl: null,
    },
    loginConfig: {
      usernameLabel: LoginConfigUsernameLabel.USERNAME,
      usernameFieldPlaceholder: LoginConfigUsernameFieldPlaceholder.IDENTIFICATION_NUMBER_11,
      passwordLabel: LoginConfigPassword.PERSONAL_CODE,
      passwordFieldPlaceholder: LoginConfigPassword.PERSONAL_CODE,
    },
  },
  {
    insuranceCompany: 'fr-credit-mutuel',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Crédit Mutuel',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD, LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_CREDIT_MUTUEL_VEHICLE,
      CompanyAndLineOfBusiness.FR_CREDIT_MUTUEL_HOME,
    ],
    resetCredentials: {
      resetPasswordUrl:
        'https://www.creditmutuel.fr/fr/identification/assistance-technique/demander_votre_mot_de_passe.aspx',
      resetUsernameUrl:
        'https://www.creditmutuel.fr/fr/identification/assistance-technique/demander_votre_identifiant.aspx',
    },
  },
  {
    insuranceCompany: 'fr-demo',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Demo Company',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD, LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_DEMO_HOME,
      CompanyAndLineOfBusiness.FR_DEMO_VEHICLE,
    ],
  },
  {
    insuranceCompany: 'fr-eca',
    functional: true,
    status: 'BETA',
    insuranceCompanyDisplayName: 'ECA',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [CompanyAndLineOfBusiness.FR_ECA_HOME],
  },
  {
    insuranceCompany: 'fr-generali',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Generali',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD, LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_GENERALI_HOME,
      CompanyAndLineOfBusiness.FR_GENERALI_VEHICLE,
    ],
    resetCredentials: {
      resetPasswordUrl:
        'https://www.monespace.generali.fr/compte/mot-de-passe-oublie/identification',
      resetUsernameUrl:
        'https://www.monespace.generali.fr/compte/identifiant-oublie/identification',
    },
    loginConfig: {
      usernameLabel: LoginConfigUsernameLabel.USERNAME,
      usernameFieldPlaceholder: LoginConfigUsernameFieldPlaceholder.IDENTIFICATION_CHARACTERS_8,
      passwordLabel: LoginConfigPassword.PASSWORD,
      passwordFieldPlaceholder: LoginConfigPassword.PASSWORD,
    },
  },
  {
    insuranceCompany: 'fr-gmf',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'GMF',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD, LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_GMF_HOME,
      CompanyAndLineOfBusiness.FR_GMF_VEHICLE,
    ],
    resetCredentials: {
      resetPasswordUrl: 'https://mon-espace-societaire.gmf.fr/connexion/saisie-identifiant',
      resetUsernameUrl: null,
    },
    loginConfig: {
      usernameLabel: LoginConfigUsernameLabel.USERNAME,
      usernameFieldPlaceholder: LoginConfigUsernameFieldPlaceholder.EMAIL_MOBILE_MUTUAL_MEMBER,
      passwordLabel: LoginConfigPassword.PASSWORD,
      passwordFieldPlaceholder: LoginConfigPassword.PASSWORD,
    },
  },
  {
    insuranceCompany: 'fr-groupama',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Groupama',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD, LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_GROUPAMA_HOME,
      CompanyAndLineOfBusiness.FR_GROUPAMA_VEHICLE,
    ],
    resetCredentials: {
      resetPasswordUrl: 'https://authentification.groupama.fr/auth/gconnect/front/id-recovery',
      resetUsernameUrl: null,
    },
    loginConfig: {
      usernameLabel: LoginConfigUsernameLabel.USERNAME,
      usernameFieldPlaceholder: LoginConfigUsernameFieldPlaceholder.EMAIL_MOBILE_CLIENT,
      passwordLabel: LoginConfigPassword.PASSWORD,
      passwordFieldPlaceholder: LoginConfigPassword.PASSWORD,
    },
  },
  {
    insuranceCompany: 'fr-luko',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Luko',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [CompanyAndLineOfBusiness.FR_LUKO_HOME],
    resetCredentials: {
      resetPasswordUrl: 'https://www.luko.eu/my-account/forgot-password',
      resetUsernameUrl: null,
    },
    loginConfig: {
      usernameLabel: LoginConfigUsernameLabel.EMAIL,
      usernameFieldPlaceholder: LoginConfigUsernameFieldPlaceholder.EMAIL,
      passwordLabel: LoginConfigPassword.PASSWORD,
      passwordFieldPlaceholder: LoginConfigPassword.PASSWORD,
    },
  },
  {
    insuranceCompany: 'fr-maaf',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'MAAF',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD, LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_MAAF_VEHICLE,
      CompanyAndLineOfBusiness.FR_MAAF_HOME,
    ],
    resetCredentials: {
      resetPasswordUrl: 'https://www.maaf.fr/fr/espace-client',
      resetUsernameUrl: null,
    },
    loginConfig: {
      usernameLabel: LoginConfigUsernameLabel.USERNAME,
      usernameFieldPlaceholder: LoginConfigUsernameFieldPlaceholder.EMAIL_MOBILE_CLIENT,
      passwordLabel: LoginConfigPassword.PASSWORD,
      passwordFieldPlaceholder: LoginConfigPassword.PASSWORD,
    },
  },
  {
    insuranceCompany: 'fr-macif',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'MACIF',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD, LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_MACIF_HOME,
      CompanyAndLineOfBusiness.FR_MACIF_VEHICLE,
    ],
    resetCredentials: {
      resetPasswordUrl: 'https://www.macif.fr/',
      resetUsernameUrl: null,
    },
    loginConfig: {
      usernameLabel: LoginConfigUsernameLabel.USERNAME,
      usernameFieldPlaceholder: LoginConfigUsernameFieldPlaceholder.EMAIL_OR_MEMBER,
      passwordLabel: LoginConfigPassword.PASSWORD,
      passwordFieldPlaceholder: LoginConfigPassword.PASSWORD,
    },
  },
  {
    insuranceCompany: 'fr-maif',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'MAIF',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD, LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_MAIF_HOME,
      CompanyAndLineOfBusiness.FR_MAIF_VEHICLE,
    ],
    resetCredentials: {
      resetPasswordUrl:
        'https://connect.maif.fr/connect/oic/service/mot-de-passe-oublie/identification',
      resetUsernameUrl: null,
    },
    loginConfig: {
      usernameLabel: LoginConfigUsernameLabel.USERNAME,
      usernameFieldPlaceholder: LoginConfigUsernameFieldPlaceholder.EMAIL_MUTUAL_OR_REGULAR_MEMBER,
      passwordLabel: LoginConfigPassword.PASSWORD,
      passwordFieldPlaceholder: LoginConfigPassword.PASSWORD,
    },
  },
  {
    insuranceCompany: 'fr-mma',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'MMA',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD, LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [
      CompanyAndLineOfBusiness.FR_MMA_HOME,
      CompanyAndLineOfBusiness.FR_MMA_VEHICLE,
    ],
    resetCredentials: {
      resetPasswordUrl: 'https://espace-client.mma.fr/connaissance-client/synthese',
      resetUsernameUrl: null,
    },
    loginConfig: {
      usernameLabel: LoginConfigUsernameLabel.USERNAME,
      usernameFieldPlaceholder: LoginConfigUsernameFieldPlaceholder.EMAIL_MOBILE_CONTRACT,
      passwordLabel: LoginConfigPassword.PASSWORD,
      passwordFieldPlaceholder: LoginConfigPassword.PASSWORD,
    },
  },
  {
    insuranceCompany: 'fr-santevet',
    functional: true,
    status: 'BETA',
    insuranceCompanyDisplayName: 'SantéVet',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [CompanyAndLineOfBusiness.FR_SANTEVET_HOME],
    resetCredentials: {
      resetPasswordUrl: 'https://espaceclient.santevet.com/authless-forgot-password',
      resetUsernameUrl: 'https://espaceclient.santevet.com/authless-forgot-login',
    },
  },
];

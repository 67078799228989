import classNames from 'classnames';

import styles from './skeleton.module.css';

export const ListSkeleton = ({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) => (
  <div aria-hidden className={classNames(styles.background, styles.listMask)} style={style}>
    <svg
      width="233"
      height="40"
      viewBox="0 0 233 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <clipPath id="clipPathList">
        <rect y="0.5" width="32" height="32" rx="16" fill="white" />
        <rect width="111" height="16" fill="white" rx="4" ry="4" transform="translate(41 3.5)" />
        <rect width="192" height="16" fill="white" rx="4" ry="4" transform="translate(41 23.5)" />
      </clipPath>
    </svg>
  </div>
);

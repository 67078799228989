import {
  ExpandableSection,
  ExpandableSectionTitle,
  ExpandableSectionContent,
  ExpandableSectionCollapse,
  Icon,
  Typography,
} from '@insurely/ui';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { AccountListItems, AccountListItemsProps } from '../AccountList';

import styles from './hiddenAccounts.module.css';

export const HiddenAccounts = ({ accounts }: AccountListItemsProps) => {
  const intl = useIntl();

  return (
    <ExpandableSection
      withBackground
      className={classNames(styles.expandableSection, styles.overrideUiStyling)}
    >
      <ExpandableSectionTitle className={classNames(styles.titleSection, styles.overrideUiStyling)}>
        <Icon name="hidden" size={24} />
        <Typography component="span" variant="Label-2">
          {intl.formatMessage({
            id: 'overview.wealth.expandable-section.buttonText',
            defaultMessage: 'Hidden accounts',
          })}
        </Typography>
        <ExpandableSectionCollapse color="var(--colorInteractive)" />
      </ExpandableSectionTitle>
      <ExpandableSectionContent className={styles.content}>
        <AccountListItems accounts={accounts} />
      </ExpandableSectionContent>
    </ExpandableSection>
  );
};

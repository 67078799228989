export const checkInvalidValueAndReturnString = ({
  value,
  unknownCopy,
  displayValue,
}: {
  value: string | null;
  unknownCopy: string;
  displayValue?: string;
}) => {
  if (!value || value === 'UNDEFINED') {
    return unknownCopy;
  }
  return displayValue ?? value;
};

import {
  IconHouse,
  IconUmbrella,
  IconUser,
  IconVehicle,
  ParagraphCaption,
  ParagraphTiny,
} from '@insurely/ui';

import classNames from 'classnames';

import React from 'react';
import { useIntl } from 'react-intl';

import { useSharedData } from '@main/contexts';

import { formatCurrency, isPension } from '@main/shared-exports';
import { InsuranceType } from '@main/types';
import { getInsuranceYearlyPrice } from '@main/utils/getInsurancePrice';

import styles from './insuranceChart.module.css';

interface InsuranceChartProps {
  policies: Policy[];
}

export const InsuranceChart = ({ policies }: InsuranceChartProps) => {
  const intl = useIntl();
  const [
    {
      clientConfig: { language, market },
    },
  ] = useSharedData();

  const items = policies.reduce(
    (itemsObject, policy) => {
      const itemsCopy = { ...itemsObject };
      if (isPension(policy)) return itemsCopy;
      if (policy.insurance.insuranceType in itemsCopy) {
        itemsCopy[policy.insurance.insuranceType as keyof typeof items].cost +=
          getInsuranceYearlyPrice(policy) ?? 0;
      } else {
        itemsCopy.OTHER.cost += getInsuranceYearlyPrice(policy) ?? 0;
      }
      return itemsCopy;
    },
    {
      [InsuranceType.houseContentInsurance]: {
        icon: <IconHouse width="32px" aria-hidden />,
        cost: 0,
        section: intl.formatMessage({ defaultMessage: 'Home' }),
      },
      [InsuranceType.vehicleInsurance]: {
        icon: <IconVehicle width="32px" aria-hidden />,
        cost: 0,
        section: intl.formatMessage({ defaultMessage: 'Vehicle' }),
      },
      [InsuranceType.personInsurance]: {
        icon: <IconUser width="32px" aria-hidden />,
        cost: 0,
        section: intl.formatMessage({ defaultMessage: 'Personal' }),
      },
      OTHER: {
        icon: <IconUmbrella width="32px" aria-hidden />,
        cost: 0,
        section: intl.formatMessage({ defaultMessage: 'Other' }),
      },
    },
  );
  const biggestPrice = Math.max(...Object.values(items).map((i) => i.cost));

  return (
    <div className={styles.chart}>
      {Object.values(items).map((item) => (
        <div key={item.section} className={classNames(styles.column, styles.costAndBar)}>
          <ParagraphCaption>
            {formatCurrency({
              language,
              market,
              amount: item.cost,
              maximumFractionDigits: 0,
            })}
          </ParagraphCaption>
          <div
            className={classNames(styles.bar, {
              [styles.barEmpty]: item.cost === 0,
            })}
            style={
              {
                '--percentageValue': item.cost !== 0 ? item.cost / biggestPrice : 0,
              } as React.CSSProperties
            }
          />
        </div>
      ))}
      {Object.values(items).map((item) => (
        <div
          key={`category-${item.section}`}
          className={classNames(styles.column, styles.category)}
        >
          {item.icon}
          <ParagraphTiny
            className={styles.categoryText}
            aria-label={intl.formatMessage(
              {
                defaultMessage: 'The total cost for {category} insurance policies is {cost}',
              },
              {
                category: item.section.toLowerCase(),
                cost: formatCurrency({
                  language,
                  market,
                  amount: item.cost,
                  maximumFractionDigits: 0,
                }),
              },
            )}
          >
            {item.section}
          </ParagraphTiny>
        </div>
      ))}
    </div>
  );
};

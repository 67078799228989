import { CoreCarInsurance, CoreVehicleInsurance } from '@main/types/insurance/core';

import { EstonianGeneralInsurance } from './EstonianGeneralInsurance';

export type EstonianVehicleInsurance = CoreVehicleInsurance;

export type EstonianCarDeductibleId = 'deductibleGeneral' | 'deductibleGlass' | 'deductibleTheft';

export type EstonianCarInsurance = Omit<
  Omit<EstonianGeneralInsurance, 'insuranceType'> & CoreCarInsurance & EstonianVehicleInsurance,
  'deductibles'
> &
  EstonianCarInsuranceFields;

type EstonianCarInsuranceFields = {
  coverage: EstonianVehicleCoverage;
  deductibles?: EstonianCarDeductible[];
};

export enum EstonianVehicleCoverage {
  CASCO = 'CASCO',
  MTPL = 'MTPL',
}

/**
 * Partially selected deductibleId's from scraper types
 *
 * @see https://github.com/insurely/insurely-scraper/blob/master/src/models/insurance/estonia/Deductibles.ts
 */

export type EstonianCarDeductible = {
  deductibleId: EstonianCarDeductibleId;
  deductibleName: string;
  deductibleAmount?: number;
  deductibleFreeTextFormula?: string;
};

export enum EstonianCollectionResult {
  CASCO = EstonianVehicleCoverage.CASCO,
  MTPL = EstonianVehicleCoverage.MTPL,
  COMBO = `${EstonianVehicleCoverage.CASCO}-${EstonianVehicleCoverage.MTPL}`,
}

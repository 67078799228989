import classNames from 'classnames';

import styles from './skeleton.module.css';

export const ChartSkeleton = ({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) => (
  <div aria-hidden className={classNames(styles.background, styles.chartMask)} style={style}>
    <svg
      width="213"
      height="120"
      viewBox="0 0 213 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <clipPath id="clipPathChart">
        <path
          d="M0.5 4.39844C0.5 2.1893 2.29086 0.398438 4.5 0.398438H28.5C30.7091 0.398438 32.5 2.1893 32.5 4.39844V95.3984H0.5V4.39844Z"
          fill="white"
        />
        <rect width="32" height="16" fill="white" rx="4" transform="translate(0.5 103.398)" />
        <path
          d="M60.5 24.3984C60.5 22.1893 62.2909 20.3984 64.5 20.3984H88.5C90.7091 20.3984 92.5 22.1893 92.5 24.3984V95.3984H60.5V24.3984Z"
          fill="white"
        />
        <rect width="32" height="16" fill="white" rx="4" transform="translate(60.5 103.398)" />
        <path
          d="M120.5 48.3984C120.5 46.1893 122.291 44.3984 124.5 44.3984H148.5C150.709 44.3984 152.5 46.1893 152.5 48.3984V95.3984H120.5V48.3984Z"
          fill="white"
        />
        <rect width="32" height="16" fill="white" rx="4" transform="translate(120.5 103.398)" />
        <path
          d="M180.5 70.3984C180.5 68.1893 182.291 66.3984 184.5 66.3984H208.5C210.709 66.3984 212.5 68.1893 212.5 70.3984V95.3984H180.5V70.3984Z"
          fill="white"
        />
        <rect width="32" height="16" fill="white" rx="4" transform="translate(180.5 103.398)" />
      </clipPath>
    </svg>
  </div>
);

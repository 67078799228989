import { COMPARE_NOT_POSSIBLE } from '@main/constants';
import { CompareVariant, FailedToCompareReason, PossibleToCompare } from '@main/types';
import { isHomeInsuranceFR, isCarInsuranceFR } from '@main/utils/typeAssertions';

export const isPossibleToCompareFR = ({
  policy,
  compareVariant,
}: {
  policy: InsurancePolicy;
  compareVariant?: CompareVariant;
}): PossibleToCompare => {
  const { insurance } = policy;

  if (
    (!policy.parameters || policy.parameters.length === 0) &&
    compareVariant === CompareVariant['parameters-only']
  ) {
    return {
      isComparisonPossible: false,
      failedReason: FailedToCompareReason.MissingParameters,
      failedNavigatePath: COMPARE_NOT_POSSIBLE,
    };
  }

  if (isHomeInsuranceFR(insurance) || isCarInsuranceFR(insurance)) {
    return { isComparisonPossible: true };
  }

  return {
    isComparisonPossible: false,
    failedReason: FailedToCompareReason.SubTypeNotSupported,
    failedNavigatePath: COMPARE_NOT_POSSIBLE,
  };
};

import * as Sentry from '@sentry/react';

import { getDevToolsLocalItem } from '@main/utils/devTools';

export * as Sentry from '@sentry/react';
export { ExtraErrorData } from '@sentry/integrations';

export const isSentryEnabled = () => {
  if (ENVIRONMENT === 'testrunner') return false;
  if (/prod|test/.test(ENVIRONMENT)) return true;

  return (
    getDevToolsLocalItem({
      key: 'devtoolsRequiresReloadUpdate',
    })?.general.advanced.sentryEnabled ?? false
  );
};

export const sentrySetUser = (userId: string) => {
  if (!isSentryEnabled()) return;

  Sentry.setUser({ id: userId });
};

export const sentrySetContext = (name: string, context: Record<string, unknown>) => {
  if (!isSentryEnabled()) return;

  Sentry.setContext(name, context);
};

export const sentrySetExtra = (name: string, extra: unknown) => {
  if (!isSentryEnabled()) return;

  Sentry.setExtra(name, extra);
};

export const sentryCaptureException = (...args: Parameters<typeof Sentry.captureException>) => {
  if (!isSentryEnabled()) return;

  return Sentry.captureException(...args);
};

export const sentryCaptureMessage = (...args: Parameters<typeof Sentry.captureMessage>) => {
  if (!isSentryEnabled()) return;

  return Sentry.captureMessage(...args);
};

import { COMPARE_NOT_POSSIBLE } from '@main/constants';
import { PossibleToCompare, FailedToCompareReason } from '@main/types';
import { SwedishCarInsurance } from '@main/types/insurance/sweden';
import { icaCarCoverageMap } from '@main/utils/compareMappers';

const dataNeededToCompareCarIcaSE = ({
  insurance,
  personalInformation,
}: {
  insurance: SwedishCarInsurance;
  personalInformation?: PersonalInformation;
}): Record<string, string | undefined | number> => ({
  personalNumber: personalInformation?.PERSONAL_NUMBER,
  registrationNo: insurance.registrationNo,
  carCoverage: icaCarCoverageMap(insurance.coverage),
});

export const isPossibleToCompareCarIcaSE = ({
  insurance,
  personalInformation,
}: {
  insurance: SwedishCarInsurance;
  personalInformation?: PersonalInformation;
}): PossibleToCompare => {
  const compareData = dataNeededToCompareCarIcaSE({
    insurance,
    personalInformation,
  });

  const isDataMissing = Object.values(compareData).includes(undefined);

  if (!isDataMissing) {
    return { isComparisonPossible: true };
  }

  const missingFields = Object.keys(compareData).filter((key) => !compareData[key]);

  return {
    isComparisonPossible: false,
    missingFields,
    failedReason: FailedToCompareReason.MissingRequiredFields,
    failedNavigatePath: COMPARE_NOT_POSSIBLE,
  };
};

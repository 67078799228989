import { setup, assign } from 'xstate';

import {
  MultiCollectionMachineContext,
  MultiCollectionMachineEvents,
  MultiCollectionMachineInput,
} from './types';
import { isActiveCollectorStatus } from './utils/collectorStatuses';
import { findNextCompanyToStart } from './utils/findNextCompanyToStart';
import { startCompanyCollection } from './utils/startCompanyCollection';

export const multiCollectionMachine = setup({
  types: {
    context: {} as MultiCollectionMachineContext,
    input: {} as MultiCollectionMachineInput,
    events: {} as MultiCollectionMachineEvents,
  },
  actions: {
    updateCollectorStatus: assign({
      collectors: ({ context, event }) =>
        context.collectors.map((c) => {
          if (!event.data || !event.data.insuranceCompany || !event.data.status) return c;
          if (event.data.insuranceCompany !== c.companyDetails.insuranceCompany) return c;

          return {
            ...c,
            status: event.data.status,
          };
        }),
    }),
  },
  guards: {
    shouldStartNextCollector: ({ context }) =>
      !context.collectors.some((c) => isActiveCollectorStatus(c.status)) &&
      context.collectors.some((c) => !c.status),
    isAllCollectorsFinal: ({ context }) =>
      context.collectors.every(
        (c) => c.status && ['failed', 'completed', 'skipped', 'skip'].includes(c.status),
      ),
    hasUserActionRequired: ({ context }) =>
      context.collectors.some((c) => c.status === 'userActionRequired'),
  },
  delays: {
    pollDelay: 1000,
  },
}).createMachine({
  context: ({ input: { apiClient, insuranceApiClient, wealthApiClient, productType } }) => ({
    collectors: [],
    apiClient,
    insuranceApiClient,
    wealthApiClient,
    productType,
  }),
  id: 'multi-collections',
  initial: 'notStarted',
  on: {
    UPDATE_COLLECTOR_STATUS: {
      actions: 'updateCollectorStatus',
    },
    SKIP_COLLECTOR: {
      actions: assign({
        collectors: ({ context, event }) =>
          context.collectors.map((c) => {
            if (event.data.insuranceCompany !== c.companyDetails.insuranceCompany) return c;

            return {
              ...c,
              status: c.status === 'skip' ? undefined : 'skip', // TODO: should we have a status instead of undefined?
            };
          }),
      }),
    },
    START: {
      target: '.waitingForNextCollectorToStart',
      actions: [
        ({ context }) => {
          context.collectors.forEach((collector) => collector.actor?.send({ type: 'STOP' }));
        },
        assign(({ event }) => ({
          collectors: event.data.companies.map((company) => ({
            companyDetails: company,
          })),
          resumeCode: event.data.resumeCode,
        })),
      ],
    },
  },
  states: {
    notStarted: {},
    waitingForNextCollectorToStart: {
      entry: assign(({ context }) => {
        const { updatedCompanies, currentCompanyAuthenticating } = findNextCompanyToStart(
          context.collectors,
        );
        return {
          collectors: updatedCompanies,
          currentCompanyAuthenticating,
        };
      }),
      on: {
        START_COLLECTOR: {
          target: 'checkCollectorStatuses',
          actions: assign(({ context, spawn, self, event: { data } }) => ({
            collectors: startCompanyCollection({ context, spawn, self, data }),
          })),
        },
      },
    },
    checkCollectorStatuses: {
      always: [
        {
          target: 'completed',
          guard: 'isAllCollectorsFinal',
        },
        {
          target: 'userActionRequired',
          guard: 'hasUserActionRequired',
        },
        {
          target: 'waitingForNextCollectorToStart',
          guard: 'shouldStartNextCollector',
        },
        {
          target: 'scheduleNextPoll',
        },
      ],
    },
    scheduleNextPoll: {
      after: {
        pollDelay: { target: 'checkCollectorStatuses' },
      },
    },
    userActionRequired: {
      on: {
        UPDATE_COLLECTOR_STATUS: {
          target: 'scheduleNextPoll',
          actions: 'updateCollectorStatus',
          guard: ({ event, context }) =>
            event.data.insuranceCompany === context.currentCompanyAuthenticating &&
            event.data.status !== 'userActionRequired',
        },
      },
    },
    completed: {},
  },
});

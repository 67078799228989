import { ResultItem } from '@main/components/ResultItem';

import { ResultCardItem, ResultCardItemProps } from './ResultCardItem';
import { ResultDetailsItem } from './ResultDetailsItem';

interface InsuranceListItemProps extends ResultCardItemProps {
  isComparisonPossible: boolean;
  resultItemWithButtons?: boolean;
}

export const InsuranceListItem = ({
  title,
  subTitle,
  amount,
  statusText,
  statusColor,
  insuranceCompany,
  insuranceCompanyDisplayName,
  isComparisonPossible,
  resultItemWithButtons,
  navigateToDetails,
  navigateToCompare,
}: InsuranceListItemProps) => {
  if (resultItemWithButtons) {
    return isComparisonPossible ? (
      <ResultCardItem
        title={title}
        subTitle={subTitle}
        amount={amount}
        statusText={statusText}
        statusColor={statusColor}
        insuranceCompany={insuranceCompany}
        insuranceCompanyDisplayName={insuranceCompanyDisplayName}
        navigateToDetails={navigateToDetails}
        navigateToCompare={navigateToCompare}
      />
    ) : (
      <ResultDetailsItem
        title={title}
        subTitle={subTitle}
        amount={amount}
        statusText={statusText}
        statusColor={statusColor}
        insuranceCompany={insuranceCompany}
        insuranceCompanyDisplayName={insuranceCompanyDisplayName}
        navigateToDetails={navigateToDetails}
      />
    );
  }

  return (
    <ResultItem
      title={title}
      subTitle={subTitle}
      amount={amount}
      statusText={statusText}
      statusColor={statusColor}
      insuranceCompany={insuranceCompany}
      insuranceCompanyDisplayName={insuranceCompanyDisplayName}
      onClick={navigateToDetails}
    />
  );
};

import { Country } from '@main/types';
import { formatLocalDate } from '@main/utils/locale';

export function expireStatus(paidUntil: string): 'expired' | 'expiresSoon' | 'active' {
  const today = new Date();
  const itemExpires = new Date(paidUntil);
  if (itemExpires < today) {
    return 'expired';
  }

  if (itemExpires < new Date(today.setMonth(today.getMonth() + 1))) {
    return 'expiresSoon';
  }
  return 'active';
}

export const getStatusText = ({
  paidUntil,
  country,
  expiredCopy,
  validUntilCopy,
}: {
  paidUntil: string | undefined;
  country: Country;
  expiredCopy: string;
  validUntilCopy: string;
}): string | undefined => {
  const parsedDate = paidUntil && formatLocalDate(paidUntil, country);
  return parsedDate
    ? `${expireStatus(paidUntil) === 'expired' ? expiredCopy : validUntilCopy} ${parsedDate}`
    : undefined;
};

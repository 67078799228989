import { InsuranceSubType } from '../insurance/InsuranceType';

export enum AvailableInsuranceCompanyCheckout {
  'se-ica' = 'se-ica',
  'se-demo' = 'se-demo',
  'fr-direct' = 'fr-direct',
  'fr-cardif' = 'fr-cardif',
  'fr-direct-assurance' = 'fr-direct-assurance',
  'fr-kozoo' = 'fr-kozoo',
  'fr-demo' = 'fr-demo',
}

export enum AvailableCheckoutLanguage {
  'sv' = 'sv',
  'en' = 'en',
  'fr' = 'fr',
}

declare global {
  type AvailableInsuranceCompaniesCheckout = AvailableInsuranceCompanyCheckout;
  type AvailableCheckoutLanguages = AvailableCheckoutLanguage;

  interface CheckoutConfigResponse {
    availableCheckouts: Array<AvailableCheckout>;
    insuranceDocuments: Array<InsuranceDocument>;
  }

  interface AvailableCheckout {
    insuranceCompany: AvailableInsuranceCompaniesCheckout;
    insuranceCompanyDisplayName: string;
    insuranceSubType: InsuranceSubType;
    insuranceSubTypeDisplayName: string;
  }

  interface InsuranceDocument {
    documentType: string;
    url: string;
    language: string;
  }
}

import {
  DevToolsContinuousUpdateContextState,
  FrHomeQuoteResponse,
  Insurance,
  Language,
  LinkedFrenchHomeForm,
  CollectInfo,
  FrontendOptions,
  ClientConfig,
} from '@main/types';
import { UserConfig } from '@main/types/configurations/moduleInput';
import { Prefill } from '@main/types/configurations/prefill';
import { ResumableCollectionResponse } from '@main/types/resumable-collections';

export interface SharedDataState {
  apiVersion?: string;
  collectInfo?: Partial<CollectInfo>;
  sessionId: string;
  clientConfig: ClientConfig;
  frontendOptions: FrontendOptions;
  userConfig: UserConfig;
  prefill?: Prefill;
  selectedPolicy?: InsurancePolicy;
  complementaryUserInput?: Partial<Insurance>;
  acceptedOfferInCompare: boolean;
  offerData?: FrHomeQuoteResponse;
  formData?: LinkedFrenchHomeForm;
  resumableCollections?: ResumableCollectionResponse['resumableCollections'];
}

export const SET_CONFIG = 'SET_CONFIG';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_SELECTED_POLICY = 'SET_SELECTED_POLICY';
export const SET_USER_INPUT_DATA = 'SET_USER_INPUT_DATA';
export const SET_OFFER_DATA = 'SET_OFFER_DATA';
export const SET_ACCEPTED_OFFER_IN_COMPARE = 'SET_ACCEPTED_OFFER_IN_COMPARE';
export const CLEAR_SELECTED_POLICY = 'CLEAR_SELECTED_POLICY';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_DEVTOOLS_CONTINUOUS_UPDATE_DATA = 'SET_DEVTOOLS_CONTINUOUS_UPDATE_DATA';
export const CLEAR_FORM_DATA = 'CLEAR_FORM_DATA';
export const SET_COLLECTION_INFO = 'SET_COLLECTION_INFO';

type SharedDataAction<Type, Payload> = Payload extends undefined
  ? { type: Type }
  : {
      type: Type;
      payload: Payload;
    };

export type ReducerData =
  | SharedDataAction<typeof SET_COLLECTION_INFO, Partial<CollectInfo>>
  | SharedDataAction<typeof SET_SELECTED_POLICY, InsurancePolicy>
  | SharedDataAction<typeof SET_USER_INPUT_DATA, Partial<Insurance>>
  | SharedDataAction<typeof SET_OFFER_DATA, FrHomeQuoteResponse>
  | SharedDataAction<typeof SET_LANGUAGE, Language>
  | SharedDataAction<typeof SET_AUTH_TOKEN, string>
  | SharedDataAction<typeof SET_CONFIG, UserConfig>
  | SharedDataAction<typeof SET_ACCEPTED_OFFER_IN_COMPARE, boolean>
  | SharedDataAction<typeof CLEAR_SELECTED_POLICY, undefined>
  | SharedDataAction<typeof SET_FORM_DATA, LinkedFrenchHomeForm>
  | SharedDataAction<
      typeof SET_DEVTOOLS_CONTINUOUS_UPDATE_DATA,
      DevToolsContinuousUpdateContextState
    >
  | SharedDataAction<typeof CLEAR_FORM_DATA, undefined>;

import { SingleCollectorMachineState } from '../../singleCollectorMachine';
import { PollingStatus } from '../../types';

export const getPollingStatus = (pollingStatus: SingleCollectorMachineState) => {
  if (typeof pollingStatus === 'object') {
    return pollingStatus.polling;
  }
  return pollingStatus;
};

export function isActiveCollectorStatus(status?: PollingStatus) {
  return ['notStarted', 'initiated', 'pollStarted', 'userActionRequired'].includes(status ?? '');
}

import { useSharedData } from '@main/contexts';

import { AvailableInsuranceCompanyCheckout } from '@main/types/configurations/checkoutConfig';

interface useKozoo {
  isKozoo: boolean;
}

// This hook is meant to be used as a temporary Release Flag for the client Kozoo (fr-kozoo)

export const useKozoo = (): useKozoo => {
  const [
    {
      clientConfig: { productConfigs },
    },
  ] = useSharedData();

  const clientCompanyName = productConfigs?.checkout?.selectedCheckout?.insuranceCompany;

  return {
    isKozoo: clientCompanyName === AvailableInsuranceCompanyCheckout['fr-kozoo'],
  };
};

import { Button, Color, IconArrowRight, Loader } from '@insurely/ui';

import { useIntl } from 'react-intl';

import { BaseView } from '@main/components/BaseView';

import { InsuranceDetails, InsuranceDetailsCopy } from '@main/components/InsuranceDetails';
import {
  InsuranceType,
  PaymentMethod,
  useUseCase,
  useFlatPolicies,
  useSharedData,
  isHomeInsuranceFR,
  useIsPossibleToCompare,
  Country,
  InsuranceSubType,
  useNavigate,
  useSearchParams,
  formatInsuranceSubType,
  isFormattedInsuranceType,
  BlocksModule,
} from '@main/shared-exports';

import { formatPaymentMethod, formatOwnershipStatus, formatResidentialStatusFR } from './helpers';
import styles from './insuranceDetailsView.module.css';

export const InsuranceDetailsView = () => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const externalId = searchParams.get('externalId') ?? undefined;

  const { skipBlock, currentModule } = useUseCase();
  const skipIsCompare = currentModule.skip === BlocksModule.Compare;
  const [
    {
      clientConfig: { productConfigs, market },
    },
    { selectPolicy },
  ] = useSharedData();
  const navigate = useNavigate();

  const insurancePolicies = useFlatPolicies();
  const policy = insurancePolicies.find((res) => res.insurance?.externalId === externalId);

  const isPossibleToCompare = useIsPossibleToCompare();
  const { isComparisonPossible } = isPossibleToCompare(policy as InsurancePolicy);

  const {
    insuranceType = InsuranceType.generalInsurance,
    insuranceSubType = InsuranceSubType.genericInsurance,
  } = policy?.insurance || {};

  const insuranceTypeDisplayText = isFormattedInsuranceType(insuranceType)
    ? formatInsuranceSubType({ intl, insuranceType, insuranceSubType })
    : undefined;

  const paymentMethod = policy?.insurance?.paymentMethod;
  const formattedPaymentMethod = formatPaymentMethod(
    intl,
    paymentMethod ? PaymentMethod[paymentMethod] : undefined,
  );

  const offerCompanyDisplayName =
    productConfigs?.compare?.offerCompany?.displayName ??
    productConfigs?.checkout?.selectedCheckout?.insuranceCompanyDisplayName ??
    '';

  const insuranceDetailsCopy: InsuranceDetailsCopy = {
    insuranceType: insuranceTypeDisplayText,
    information: intl.formatMessage({ defaultMessage: 'Information' }),
    coverage: intl.formatMessage({ defaultMessage: 'Coverage' }),
    monthlyCost: intl.formatMessage({ defaultMessage: 'Monthly cost' }),
    annualCost: intl.formatMessage({ defaultMessage: 'Annual cost' }),
    validUntil: intl.formatMessage({ defaultMessage: 'Due date' }),
    insuranceName: intl.formatMessage({ defaultMessage: 'Insurance name' }),
    insuranceHolder: intl.formatMessage({ defaultMessage: 'Policy holder' }),
    insuranceCompany: intl.formatMessage({ defaultMessage: 'Insurance company' }),
    policyNumber: intl.formatMessage({ defaultMessage: 'Policy number' }),
    paymentType: intl.formatMessage({ defaultMessage: 'Payment methods' }),
    paymentMethod: formattedPaymentMethod,
    addons: intl.formatMessage({ defaultMessage: 'Add-ons' }),
    noAddons: intl.formatMessage({ defaultMessage: 'No add-ons' }),
    addOnExplanation: intl.formatMessage({
      defaultMessage: 'Add-ons are optional additions to the insurance policy.',
    }),
    addOn: intl.formatMessage({ defaultMessage: 'Add-on' }),
    addOnPurchased: intl.formatMessage({ defaultMessage: 'Purchased add-on' }),
    addOnAvailable: intl.formatMessage({ defaultMessage: 'Add-on available' }),
    included: intl.formatMessage({ defaultMessage: 'Included' }),
    notIncluded: intl.formatMessage({ defaultMessage: 'Not included' }),
    emptyStateTitle: intl.formatMessage(
      { defaultMessage: 'Your {insuranceType} is not available right now' },
      { insuranceType: insuranceTypeDisplayText?.toLowerCase() ?? 'insurance' },
    ),
    emptyStateText: intl.formatMessage({
      defaultMessage:
        'At this point we are unable to find the coverage included in this insurance.',
    }),
    emptyStateAdditionalText: intl.formatMessage({
      defaultMessage:
        'We are always working on improving our services, we hope to have a solution for it as soon as possible.',
    }),
    unknown: intl.formatMessage({ defaultMessage: 'Unknown' }),
    notAvailable: intl.formatMessage({
      id: 'results.insurance-details.not-available',
      defaultMessage: 'Not available',
    }),
    ...(market.country === Country.fr && {
      homeOwnership: intl.formatMessage({ defaultMessage: 'Home ownership' }),
      homeOwnershipDetails:
        isHomeInsuranceFR(policy?.insurance) && policy?.insurance?.ownershipStatus
          ? formatOwnershipStatus(intl, policy.insurance.ownershipStatus)
          : intl.formatMessage({ defaultMessage: 'Unknown' }),
      residentialStatus: intl.formatMessage({ defaultMessage: 'Residential status' }),
      residentialStatusDetails:
        isHomeInsuranceFR(policy?.insurance) && policy?.insurance?.residentialStatus
          ? formatResidentialStatusFR(intl, policy.insurance.residentialStatus)
          : intl.formatMessage({ defaultMessage: 'Unknown' }),
    }),
  };

  if (!policy?.insurance) {
    return <Loader.Content />;
  }

  const onClick = () => {
    selectPolicy(policy);
    skipBlock({ urlQueryParams: { externalId: policy.insurance?.externalId } });
  };

  return (
    <BaseView>
      <InsuranceDetails
        externalId={externalId}
        isComparisonPossible={isComparisonPossible}
        topContent={
          isComparisonPossible && skipIsCompare ? (
            <Button fullWidth style={{ margin: '8px auto 40px' }} onClick={onClick}>
              {intl.formatMessage(
                { defaultMessage: 'Compare with {company}' },
                { company: offerCompanyDisplayName ?? '' },
              )}
            </Button>
          ) : null
        }
        copy={insuranceDetailsCopy}
        bottomContent={
          <div className={styles.buttonsContainer}>
            {isComparisonPossible && skipIsCompare && (
              <Button
                style={{ marginTop: '32px' }}
                variant="tertiary"
                size="large"
                onClick={onClick}
                icon={<IconArrowRight width="32px" color={Color.green} aria-hidden />}
                iconPosition="right"
                center
                fullWidth
              >
                {intl.formatMessage(
                  { defaultMessage: 'Compare with {company}' },
                  { company: offerCompanyDisplayName },
                )}
              </Button>
            )}
            <Button
              style={{ marginTop: '32px' }}
              variant="secondary"
              size="small"
              onClick={() => navigate(-1)}
              center
              fullWidth
            >
              {intl.formatMessage({ defaultMessage: 'Back to result' })}
            </Button>
          </div>
        }
      />
    </BaseView>
  );
};

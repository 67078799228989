import {
  fakeNewSwedishAnimalCollection,
  fakeNewSwedishCarCollection,
  fakeNewSwedishHomeCollection,
  fakeNewSwedishLifeCollection,
  fakeNewSwedishPension,
} from '@main/mocks/fake-utils/collections/se';
import { fakeNewSwedishCarDeductibles } from '@main/mocks/fake-utils/collections/se/deductibles';
import { MovabilityResult, Pension, PensionType } from '@main/types';
import { SwedishVehicleCoverage } from '@main/types/insurance/sweden';

export const carInsurances = fakeNewSwedishCarCollection([
  {
    insurance: {
      coverage: SwedishVehicleCoverage.FULL_WITH_CHASSI,
      insuranceName: 'Helförsäkring',
    },
    deductibles: [
      ...fakeNewSwedishCarDeductibles(),
      {
        deductibleName: 'Självrisk vagnskada',
        deductibleId: 'deductibleVehicleDamage',
        deductibleAmount: 1000,
      },
    ],
  },
  {
    insurance: {
      coverage: SwedishVehicleCoverage.FULL_WITHOUT_CHASSI,
      insuranceName: 'Halvförsäkring',
    },
    deductibles: [
      ...fakeNewSwedishCarDeductibles(),
      {
        deductibleName: 'Självrisk vagnskada',
        deductibleId: 'deductibleVehicleDamage',
        deductibleAmount: 1000,
      },
    ],
  },
  {
    insurance: { coverage: SwedishVehicleCoverage.TRAFFIC, insuranceName: 'Trafikförsäkring' },
  },
  {
    insurance: {
      coverage: SwedishVehicleCoverage.FULL_WITH_CHASSI,
      insuranceName: 'Helförsäkring',
    },
    deductibles: [
      ...fakeNewSwedishCarDeductibles(),
      {
        deductibleName: 'Självrisk vagnskada',
        deductibleId: 'deductibleVehicleDamage',
        deductibleAmount: 1000,
      },
    ],
    parameters: [],
  },
]);

export const houseInsurances = fakeNewSwedishHomeCollection([
  null,
  {
    insurance: { insuranceObjectStreetAddress: 'No living area street 34', livingArea: undefined },
  },
  {
    insurance: { insuranceObjectStreetAddress: 'No personal number 22' },
    personalInformation: { PERSONAL_NUMBER: undefined },
  },
]);

export const lifeInsurances: InsurancePolicy[] = fakeNewSwedishLifeCollection();

export const animalInsurances: InsurancePolicy[] = fakeNewSwedishAnimalCollection();

export const pension: Pension[] = [
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.OCCUPATIONAL_PENSION,
      insuranceName: 'Individuell Tjänstepension',
      startDate: '2018-01-01',
      currentValue: 20867.79,
      totalPaid: 8000.0,
      activePayment: true,
      activePayout: false,
      feeFixed: 240.0,
      feeVariable: 0.0065,
      feesCollected: true,
      feesPreviousYear: 324.45,
      hasSurvivorsProtection: true,
      movability: {
        result: MovabilityResult.NOT_MOVABLE,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYMENT',
            result: MovabilityResult.NOT_MOVABLE,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.MOVABLE,
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234568',
          name: 'Framtidsfond',
          shareCount: 998.23,
          value: 10000.76,
          valueInPercent: 0.479,
          feeVariable: 0.01,
          currencyCode: 'SEK',
        },
        {
          isin: 'SE0001234561',
          name: 'Globalfond',
          shareCount: 14.22,
          value: 6544.16,
          valueInPercent: 0.314,
          feeVariable: 0.005,
          currencyCode: 'SEK',
        },
        {
          isin: 'SE0001234563',
          name: 'Sverigefond',
          shareCount: 99.42,
          value: 4322.87,
          valueInPercent: 0.207,
          feeVariable: 0.012,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.CAPITAL_INSURANCE,
      insuranceName: 'Kapitalförsäkring',
      currentValue: 15000.45,
      totalPaid: 12000.0,
      startDate: '2019-01-01',
      activePayment: true,
      activePayout: false,
      hasSurvivorsProtection: true,
      movability: {
        result: MovabilityResult.MOVABLE,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.MOVABLE,
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234512',
          name: 'Nordenfond',
          shareCount: 99.23,
          value: 7400.23,
          valueInPercent: 49.3,
          currencyCode: 'SEK',
        },
        {
          isin: 'SE0001234561',
          name: 'Asienfond',
          shareCount: 34.45,
          value: 7600.22,
          valueInPercent: 50.7,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.COLLECTIVE_OCCUPATIONAL_PENSION,
      insuranceName: 'Tjänstepension ITP 1',
      currentValue: 12777.79,
      totalPaid: 8000.0,
      startDate: '2017-01-01',
      activePayment: false,
      activePayout: false,
      feeFixed: 65.0,
      feeVariable: 0.0,
      feesCollected: false,
      feesPreviousYear: 124.75,
      hasSurvivorsProtection: true,
      movability: {
        result: MovabilityResult.MOVABLE,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.MOVABLE,
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234111',
          name: 'Asienfond',
          shareCount: 99.23,
          value: 10000.76,
          valueInPercent: 0.783,
          feeVariable: 0.014,
          currencyCode: 'SEK',
        },
        {
          isin: 'SE0001234561',
          name: 'Globalfond',
          shareCount: 14.22,
          value: 2777.03,
          valueInPercent: 0.217,
          feeVariable: 0.005,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.PRIVATE_PENSION,
      insuranceName: 'Pensionssparande',
      currentValue: 10000.76,
      totalPaid: 8000.0,
      startDate: '2017-01-01',
      activePayment: false,
      activePayout: false,
      feeFixed: 0.0,
      feeVariable: 0.0,
      feesCollected: false,
      feesPreviousYear: 0.0,
      hasSurvivorsProtection: false,
      movability: {
        result: MovabilityResult.UNKNOWN,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.UNKNOWN,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.MOVABLE,
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234111',
          name: 'Asienfond',
          shareCount: 99.23,
          value: 10000.76,
          valueInPercent: 1.0,
          feeVariable: 0.014,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.IPS_PENSION,
      insuranceName: 'IPS Pension',
      currentValue: 10000.76,
      totalPaid: 8000.0,
      startDate: '2012-01-01',
      activePayment: false,
      activePayout: false,
      feeFixed: 0.0,
      feeVariable: 0.0,
      feesCollected: false,
      feesPreviousYear: 0.0,
      hasSurvivorsProtection: false,
      movability: {
        result: MovabilityResult.NOT_MOVABLE,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.NOT_MOVABLE,
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234111',
          name: 'Asienfond',
          shareCount: 99.23,
          value: 10000.76,
          valueInPercent: 1.0,
          feeVariable: 0.014,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
];

/* eslint-disable no-param-reassign */
import axios from 'axios';
import { v4 as uuid } from 'uuid';

export const DEFAULT_API_VERSION = '2021-01-01';

const sharedApiClient = axios.create({
  baseURL: `${API_URL}/`,
  headers: {
    'insurely-version': DEFAULT_API_VERSION,
  },
});

const errorHandler = (error: Error) => Promise.reject(error);

sharedApiClient.interceptors.request.use(
  (config) => {
    config.headers['request-id'] = uuid();
    return config;
  },
  (error) => errorHandler(error),
);

sharedApiClient.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error),
);

export { sharedApiClient };

import { Address, CoreCarInsurance, CoreVehicleInsurance } from '@main/types/insurance/core';
import { FrenchGeneralInsurance } from '@main/types/insurance/france/FrenchGeneralInsurance';

export type FrenchVehicleInsurance = Omit<FrenchGeneralInsurance, 'insuranceType'> &
  CoreVehicleInsurance &
  DriverDetails &
  FranceSpecificVehicleInsuranceFields;

export type FranceSpecificVehicleInsuranceFields = {
  coverage: FrenchVehicleCoverage;
  vehicleOwnership: {
    status: VehicleOwnershipStatus;
  };
  parking?: Parking;
  usagePurposes?: Array<VehicleUsage>;
};

export type FrenchCarInsurance = CoreCarInsurance & FrenchVehicleInsurance;

export enum FrenchVehicleCoverage {
  UNDEFINED = 'UNDEFINED',
}

export type Driver = {
  name: string;
  birthDate?: string;
  licenseIssuingDate?: string;
  noClaimsDiscount?: NoClaimsDiscount;
  // TODO [DA-2272]: claimsHistory: ClaimsHistory;
};

export type NoClaimsDiscount = CoefficientNoClaimsDiscount;

export type CoefficientNoClaimsDiscount = {
  type: 'coefficient';
  coefficient: number;
};

export type DriverDetails = {
  primaryDriver: Driver;
  secondaryDrivers: Array<Driver>;
};

export enum VehicleUsage {
  PRIVATE = 'PRIVATE',
  COMMUTING = 'COMMUTING',
  WORK = 'WORK',
}

export type Parking = {
  parkingAddress: ParkingAddress;
  parkingLocationType: ParkingLocationType;
};

export enum ParkingLocationType {
  STREET = 'STREET',
  COMMON_GARAGE = 'COMMON_GARAGE',
  INDIVIDUAL_GARAGE = 'INDIVIDUAL_GARAGE',
}

export enum VehicleOwnershipStatus {
  OWNED = 'OWNED',
  LEASING = 'LEASING',
  UNKNOWN = 'UNKNOWN',
}

export type ParkingAddress = Address;

export enum CollectionStatus {
  // Passive
  'COLLECTING' = 'COLLECTING',
  'LOGIN' = 'LOGIN',
  'RUNNING' = 'RUNNING',

  // Interactive
  'CONTACT FORM PENDING' = 'CONTACT FORM PENDING',
  'TWO FACTOR PENDING' = 'TWO FACTOR PENDING',

  /** Exit statuses */
  // Successfully
  'COMPLETED EMPTY' = 'COMPLETED EMPTY',
  'COMPLETED PARTIAL' = 'COMPLETED PARTIAL',
  'COMPLETED' = 'COMPLETED',

  // Failures
  'AUTHENTICATION CANCELLED' = 'AUTHENTICATION CANCELLED',
  'AUTHENTICATION CONFLICT' = 'AUTHENTICATION CONFLICT',
  'AUTHENTICATION MISMATCH' = 'AUTHENTICATION MISMATCH',
  'CONTACT FORM' = 'CONTACT FORM',
  'CUSTOMER ENROLLMENT REQUIRED' = 'CUSTOMER ENROLLMENT REQUIRED',
  'FAILED PDF PARSE' = 'FAILED PDF PARSE',
  'FAILED PDF USER INPUT' = 'FAILED PDF USER INPUT',
  'FAILED' = 'FAILED',
  'INCORRECT CREDENTIALS' = 'INCORRECT CREDENTIALS',
  'KYC FORM' = 'KYC FORM',
  'WAITING FOR AUTHENTICATION' = 'WAITING FOR AUTHENTICATION',

  // Inactive
  'USER INPUT' = 'USER INPUT',
}

export enum CollectionEndedStatuses {
  START_COLLECTION_FAILED_INVALID_SESSION_ID = 'START_COLLECTION_FAILED_INVALID_SESSION_ID',
  START_COLLECTION_FAILED_CONNECTION_ERROR = 'START_COLLECTION_FAILED_CONNECTION_ERROR',
  STATUS_POLLING_FAILED = 'STATUS_POLLING_FAILED',
  FINISHED_WITH_EMPTY_RESULT = 'FINISHED_WITH_EMPTY_RESULT',
  FINISHED_WITH_RESULT = 'FINISHED_WITH_RESULT',
  FINISHED_SHOULD_CHANGE_BLOCK = 'FINISHED_SHOULD_CHANGE_BLOCK',
  LOADING_RESULTS_FAILED_WRONG_COLLECTION_ID = 'LOADING_RESULTS_FAILED_WRONG_COLLECTION_ID',
  COLLECTION_FAILED_NO_VERIFICATION = 'COLLECTION_FAILED_NO_VERIFICATION',
  COLLECTION_FAILED_USER_INPUT_REQUIRED = 'COLLECTION_FAILED_USER_INPUT_REQUIRED',
  COLLECTION_FAILED_GENERAL_ERROR = 'COLLECTION_FAILED_GENERAL_ERROR',
  COLLECTION_FAILED_INCORRECT_CREDENTIALS = 'COLLECTION_FAILED_INCORRECT_CREDENTIALS',
  COLLECTION_FAILED_PDF_PARSE_FAILED = 'COLLECTION_FAILED_PDF_PARSE_FAILED',
  COLLECTION_FAILED_PDF_USER_INPUT = 'COLLECTION_FAILED_PDF_USER_INPUT',
}

export const FAILED_COLLECTION_END_STATUSES = [
  CollectionEndedStatuses.START_COLLECTION_FAILED_INVALID_SESSION_ID,
  CollectionEndedStatuses.START_COLLECTION_FAILED_CONNECTION_ERROR,
  CollectionEndedStatuses.STATUS_POLLING_FAILED,
  CollectionEndedStatuses.FINISHED_WITH_EMPTY_RESULT,
  CollectionEndedStatuses.COLLECTION_FAILED_INCORRECT_CREDENTIALS,
  CollectionEndedStatuses.LOADING_RESULTS_FAILED_WRONG_COLLECTION_ID,
  CollectionEndedStatuses.COLLECTION_FAILED_NO_VERIFICATION,
  CollectionEndedStatuses.COLLECTION_FAILED_GENERAL_ERROR,
  CollectionEndedStatuses.COLLECTION_FAILED_INCORRECT_CREDENTIALS,
  CollectionEndedStatuses.COLLECTION_FAILED_PDF_PARSE_FAILED,
  CollectionEndedStatuses.COLLECTION_FAILED_PDF_USER_INPUT,
  CollectionEndedStatuses.COLLECTION_FAILED_USER_INPUT_REQUIRED,
];

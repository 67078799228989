import { JwtToken } from '@main/utils';

import { DeepPartial } from '../utils';

import { Language } from './language';
import { Prefill } from './prefill';
import {
  DataAggregationProductConfig,
  CheckoutProductConfig,
  CompareProductConfig,
} from './productConfig';
import { Customization } from './theme';

export type UserConfig = {
  customerId: string;
  configName: string;
  language: Language;
  customization: Customization;
  sessionId?: string;
  externalSchemaUrl?: string;
  resumeCode?: string;
  showResumeInput?: boolean;
  showCloseButton: boolean;
  showBackButton: boolean;
  authToken?: JwtToken;
  dataAggregation?: DataAggregationProductConfig;
  checkout?: CheckoutProductConfig;
  compare?: CompareProductConfig;
  parentOrigin: string;
  parentUrl: string;
  devToolsEnabled: boolean;
  devTranslations?: 'enabled' | 'no-client-messages';
  isWebView?: boolean;
  showExperimentalFeatures?: boolean;
};

export type ModuleInputConfig = Pick<UserConfig, 'parentOrigin' | 'parentUrl'> &
  DeepPartial<Omit<UserConfig, 'parentOrigin' | 'parentUrl'>>;

export interface ModuleInput {
  config: ModuleInputConfig;
  prefill?: Prefill;
}

interface ValidResumeCodeModuleInput {
  config: Required<Pick<UserConfig, 'resumeCode' | 'parentOrigin' | 'parentUrl'>> &
    DeepPartial<Omit<UserConfig, 'resumeCode' | 'parentOrigin' | 'parentUrl'>>;
  prefill?: Prefill;
}

interface ValidShowResumeInputModuleInput {
  config: Required<Pick<UserConfig, 'showResumeInput' | 'parentOrigin' | 'parentUrl'>> &
    DeepPartial<Omit<UserConfig, 'showResumeInput' | 'parentOrigin' | 'parentUrl'>>;
  prefill?: Prefill;
}

export interface ValidRegularModuleInput {
  config: Pick<UserConfig, 'customerId' | 'configName' | 'parentOrigin' | 'parentUrl'> &
    DeepPartial<Omit<UserConfig, 'customerId' | 'configName' | 'parentOrigin' | 'parentUrl'>>;
  prefill?: Prefill;
}

export type ValidModuleInput =
  | ValidRegularModuleInput
  | ValidResumeCodeModuleInput
  | ValidShowResumeInputModuleInput;

export function isValidResumeCodeModuleInput(
  moduleInput: ModuleInput,
): moduleInput is ValidResumeCodeModuleInput {
  return !!moduleInput.config.resumeCode;
}

export function isValidShowResumeInputModuleInput(
  moduleInput: ModuleInput,
): moduleInput is ValidResumeCodeModuleInput {
  return !!moduleInput.config.showResumeInput;
}

export function isValidRegularModuleInput(
  moduleInput: ModuleInput,
): moduleInput is ValidRegularModuleInput {
  return !!moduleInput.config.customerId && !!moduleInput.config.configName;
}

export function isValidModuleInput(moduleInput: ModuleInput): moduleInput is ValidModuleInput {
  // TODO: Use zod schema.parse to validate
  return (
    isValidRegularModuleInput(moduleInput) ||
    isValidResumeCodeModuleInput(moduleInput) ||
    isValidShowResumeInputModuleInput(moduleInput)
  );
}

import { createBrowserInspector } from '@statelyai/inspect';
import { createActorContext } from '@xstate/react';

import { ReactNode } from 'react';

import { useSharedDataConfig } from '@main/contexts';
import {
  useApiClient,
  useInsuranceApiClient,
  useWealthApiClient,
} from '@main/contexts/CollectDataMachine/apiClient';

import { multiCollectionMachine } from './multiCollectionMachine';
import { isActiveCollectorStatus } from './multiCollectionMachine/utils/collectorStatuses';

export const inspector = createBrowserInspector({
  // iframe,
  autoStart: false,
});

export const CollectDataMachineContext = createActorContext(multiCollectionMachine, {
  inspect: inspector.inspect,
});

export const CollectDataMachineProvider = ({ children }: { children: ReactNode }) => {
  const {
    apiVersion,
    clientConfig: { productType },
  } = useSharedDataConfig();
  const apiClient = useApiClient({ apiVersion });
  const insuranceApiClient = useInsuranceApiClient();
  const wealthApiClient = useWealthApiClient();

  return (
    <CollectDataMachineContext.Provider
      options={{ input: { apiClient, insuranceApiClient, wealthApiClient, productType } }}
    >
      {children}
    </CollectDataMachineContext.Provider>
  );
};

export const useCollectDataActor = CollectDataMachineContext.useActorRef;
export const useCollectDataSelector = CollectDataMachineContext.useSelector;
export const useCollectors = () =>
  useCollectDataSelector((snapshot) => snapshot.context.collectors);
export const useCurrentCollector = () =>
  useCollectDataSelector((snapshot) =>
    snapshot.context.collectors.length === 1
      ? snapshot.context.collectors[0]
      : snapshot.context.collectors.find((collector) => isActiveCollectorStatus(collector.status)),
  );

export const useCurrentCompany = () => useCurrentCollector()?.companyDetails;

import { build, perBuild } from '@jackfranklin/test-data-bot';

import { MovabilityResult, Pension, PensionClassification, PensionType } from '@main/types';

import { swedishFaker } from './swedishFaker';

export const fakeNewSwedishPension = build<Pension>({
  fields: {
    traditionallyManaged: false,
    insuranceCompany: 'se-demo',
    pensionType: swedishFaker.helpers.enumValue(PensionType),
    insuranceName: 'Individuell Tjänstepension',
    insuranceNumber: perBuild(() => swedishFaker.string.uuid()),
    currentValue: 20867.79,
    totalPaid: 8000.0,
    startDate: '2018-01-01',
    activePayment: true,
    activePayout: false,
    insuranceHolderName: perBuild(() => swedishFaker.person.fullName()),
    insuranceHolderOrgNumber: '555555-5111',
    insuranceBeneficiaryName: 'Testpersson Testsson',
    feeFixed: 240.0,
    feeVariable: 0.0065,
    feesCollected: true,
    feesPreviousYear: 324.45,
    hasSurvivorsProtection: true,
    externalId: perBuild(() => swedishFaker.string.uuid()),
    pensionClassification: PensionClassification.UNKNOWN_PENSION_CLASSIFICATION,
    movability: {
      result: MovabilityResult.NOT_MOVABLE,
      evaluatedRules: [
        {
          rule: 'ACTIVE_PAYOUT',
          result: MovabilityResult.MOVABLE,
        },
        {
          rule: 'ACTIVE_PAYMENT',
          result: MovabilityResult.NOT_MOVABLE,
        },
        {
          rule: 'OLD_TRAD_PENSION',
          result: MovabilityResult.MOVABLE,
        },
      ],
    },
    holding: [
      {
        isin: 'SE0001234568',
        name: 'Framtidsfond',
        shareCount: 998.23,
        value: 10000.76,
        valueInPercent: 0.479,
        feeVariable: 0.01,
        currencyCode: 'SEK',
      },
      {
        isin: 'SE0001234561',
        name: 'Globalfond',
        shareCount: 14.22,
        value: 6544.16,
        valueInPercent: 0.314,
        feeVariable: 0.005,
        currencyCode: 'SEK',
      },
      {
        isin: 'SE0001234563',
        name: 'Sverigefond',
        shareCount: 99.42,
        value: 4322.87,
        valueInPercent: 0.207,
        feeVariable: 0.012,
        currencyCode: 'SEK',
      },
    ],
  },
});

import { useCallback } from 'react';

import { useSharedData, useUseCase } from '@main/contexts';
import { isPossibleToCompare } from '@main/hooks/useIsPossibleToCompare/isPossibleToCompare';
import { CompareVariant, PossibleToCompare } from '@main/types';

import { isPetInsurance } from '@main/utils';

import { useKozoo } from '../useKozoo';

export const useIsPossibleToCompare = (): ((policy?: InsurancePolicy) => PossibleToCompare) => {
  const [
    {
      clientConfig: {
        market: { country },
        productConfigs,
      },
    },
  ] = useSharedData();
  const { useCase } = useUseCase();
  const { isKozoo } = useKozoo(); // TODO!: Will be tailor made soon™

  return useCallback(
    (policy?: InsurancePolicy) => {
      if (isKozoo) return { isComparisonPossible: isPetInsurance(policy?.insurance) };

      return isPossibleToCompare({
        policy,
        compareVariant: productConfigs?.compare?.variant ?? CompareVariant.full,
        country,
        useCaseName: useCase.name,
      });
    },
    [isKozoo, productConfigs, country, useCase],
  );
};

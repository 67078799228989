export enum UseCaseName {
  'data-aggregation' = 'data-aggregation',
  'data-aggregation-without-results' = 'data-aggregation-without-results',
  'checkout' = 'checkout',
  'collect-and-contact' = 'collect-and-contact',
  'compare-and-switch' = 'compare-and-switch',
  'prefill-and-compare' = 'prefill-and-compare',
  'overview' = 'overview',
  'overview-and-compare' = 'overview-and-compare',
  'wealth-overview' = 'wealth-overview',
  'collect-and-question' = 'collect-and-question',
  'collect-question-and-compare' = 'collect-question-and-compare',
  'pension-move' = 'pension-move',
  'prefill-overview-and-compare' = 'prefill-overview-and-compare',
}

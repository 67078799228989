import { COMPARE_INFO, COMPARE_NOT_POSSIBLE } from '@main/constants';
import {
  CompareVariant,
  Country,
  FailedToCompareReason,
  PossibleToCompare,
  UseCaseName,
} from '@main/types';
import { isPossibleToCompareFR, isPossibleToCompareSE } from '@main/utils';
import { isPossibleToCompareUK } from '@main/utils/compareAssertions/uk/compareAssertionsUK';

interface isPossibleToCompareProps {
  policy?: InsurancePolicy;
  compareVariant: CompareVariant;
  country: Country;
  useCaseName: UseCaseName;
}

export const isPossibleToCompare = ({
  policy,
  compareVariant,
  country,
  useCaseName,
}: isPossibleToCompareProps) => {
  if (!policy) {
    return {
      isComparisonPossible: false,
      failedReason: FailedToCompareReason.NoPolicySelected,
      failedNavigatePath:
        compareVariant === CompareVariant['parameters-only'] ? COMPARE_INFO : COMPARE_NOT_POSSIBLE,
    };
  }

  const marketAssertions: Partial<
    Record<
      Country,
      ({
        policy,
        useCaseName,
        compareVariant,
      }: {
        policy: InsurancePolicy;
        useCaseName: UseCaseName;
        compareVariant: CompareVariant;
      }) => PossibleToCompare
    >
  > = {
    [Country.se]: isPossibleToCompareSE,
    [Country.fr]: isPossibleToCompareFR,
    [Country.uk]: isPossibleToCompareUK,
  };

  if (
    !policy.parameters?.length &&
    marketAssertions[country]?.({ policy, useCaseName, compareVariant })?.isComparisonPossible !==
      false
  ) {
    return {
      isComparisonPossible: true,
      failedReason: FailedToCompareReason.MissingParameters,
      failedNavigatePath: COMPARE_INFO,
    };
  }

  return (
    marketAssertions[country]?.({ policy, useCaseName, compareVariant }) ?? {
      isComparisonPossible: false,
      failedReason: FailedToCompareReason.MarketNotSupported,
      failedNavigatePath: COMPARE_NOT_POSSIBLE,
    }
  );
};

import React, { createContext, useContext, useMemo, useState } from 'react';

import { Prefill } from '@main/types/configurations/prefill';

type ContactDetailsContextType = {
  contactDetails: {
    contactEmail: string;
    contactPhone: string;
  };
  setContactDetails: React.Dispatch<
    React.SetStateAction<{ contactEmail: string; contactPhone: string }>
  >;
};

const ContactDetailsContext = createContext<ContactDetailsContextType | undefined>(undefined);

type ContactDetailsContextProviderProps = {
  children: React.ReactNode;
  prefill?: Prefill;
};

export function useContactDetails(): ContactDetailsContextType {
  const context = useContext(ContactDetailsContext);
  if (context === undefined) {
    throw new Error('useContactDetails must be used within a ContactDetailsContextProvider');
  }

  return context;
}

export default function ContactDetailsContextProvider({
  prefill,
  children,
}: ContactDetailsContextProviderProps) {
  const [contactDetails, setContactDetails] = useState({
    contactEmail: prefill?.customer?.email ?? '',
    contactPhone: prefill?.customer?.phoneNumber ?? '',
  });

  const value = useMemo(
    () => ({
      contactDetails,
      setContactDetails,
    }),
    [contactDetails, setContactDetails],
  );

  return <ContactDetailsContext.Provider value={value}>{children}</ContactDetailsContext.Provider>;
}

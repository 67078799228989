import { QueryObserverOptions } from '@tanstack/react-query';

import { useSharedData } from '@main/contexts';
import { useQuery, sharedApiClient } from '@main/services';

export const useCompanies = (options?: QueryObserverOptions<Company[]>) => {
  const [{ sessionId }] = useSharedData();

  return useQuery<Company[]>({
    queryKey: ['companies', sessionId],
    queryFn: () =>
      sharedApiClient('/insurance-company/status', {
        headers: {
          'insurely-session-id': sessionId,
        },
      }).then((response) => response.data),
    refetchOnWindowFocus: false,
    staleTime: 60 * 5 * 1000,
    enabled: !!sessionId,
    ...options,
  });
};

export const useFetchedCompanies = (options?: QueryObserverOptions<Company[]>) => {
  const { data: companies, isError } = useCompanies(options);

  if (isError) {
    throw new Error(
      'Failed to fetch companies. Ensure the hook is used in a component or context where company data can be fetched and is not dependent on unresolved async operations.',
    );
  }

  if (!companies) {
    throw new Error(
      'No companies data available. This might happen if the hook is used in a place where companies data fetching cannot be guaranteed.',
    );
  }

  return { companies };
};

import { useIntl } from 'react-intl';

import {
  isPension,
  useSharedData,
  useUseCase,
  getTitleFromInsuranceType,
  getStatusText,
  expireStatus,
  OVERVIEW_INSURANCE_DETAILS,
  formatInsurancePrice,
  useFlatPolicies,
  useCompanies,
  isInsurancePolicy,
  useNavigate,
  OVERVIEW_PATH,
  formatInsuranceSubType,
  useIsPossibleToCompare,
} from '@main/shared-exports';
import { getEndDate } from '@main/utils/parsers/policyParsers';

import styles from './insuranceList.module.css';
import { InsuranceListItem } from './InsuranceListItem';

export const InsuranceList = () => {
  const intl = useIntl();
  const [
    {
      clientConfig: { market, language },
      frontendOptions: { resultItemWithButtons },
    },
    { selectPolicy },
  ] = useSharedData();
  const isPossibleToCompare = useIsPossibleToCompare();

  const policies = useFlatPolicies();
  const { data: companies } = useCompanies();
  const { skipBlock } = useUseCase();
  const navigate = useNavigate();

  const insurancePolicies = policies.filter(isInsurancePolicy);

  const sortByComparable = (policyA: InsurancePolicy, policyB: InsurancePolicy) => {
    if (isPension(policyA) || isPension(policyB)) {
      return 1;
    }

    const { isComparisonPossible: isAPossibleToCompare } = isPossibleToCompare(policyA);
    const { isComparisonPossible: isBPossibleToCompare } = isPossibleToCompare(policyB);

    return isAPossibleToCompare && !isBPossibleToCompare
      ? -1
      : isBPossibleToCompare && !isAPossibleToCompare
        ? 1
        : 0;
  };

  return (
    <div className={styles.container}>
      {insurancePolicies.sort(sortByComparable).map((policy) => {
        const { insuranceType, insuranceSubType, externalId, insuranceCompany } = policy.insurance;

        const { isComparisonPossible } = isPossibleToCompare(policy);

        const endDate = getEndDate(policy.insurance);

        const insuranceCompanyDisplayName = companies?.find(
          (item: Company) => item.insuranceCompany === insuranceCompany,
        )?.insuranceCompanyDisplayName;

        const navigateToDetails = () => {
          /** Todo: Remove /${externalId} from the path
           * This is used for dual compatibility, analytics will consume query parameters soon
           * https://linear.app/insurely/issue/EXP-3261/insurance-details-remove-externalid-from-path */
          navigate(`${OVERVIEW_PATH}/${OVERVIEW_INSURANCE_DETAILS}/${externalId}`, {
            searchParams: { externalId },
          });
        };

        const navigateToCompare = () => {
          selectPolicy(policy);
          skipBlock({ urlQueryParams: { externalId: policy.insurance.externalId } });
        };

        return (
          <InsuranceListItem
            key={policy.insurance.externalId}
            title={getTitleFromInsuranceType({
              policy,
              unknownCopy: intl.formatMessage({ defaultMessage: 'Unknown' }),
            })}
            subTitle={formatInsuranceSubType({ intl, insuranceType, insuranceSubType })}
            amount={formatInsurancePrice({ policy, market, language }) ?? undefined}
            statusText={getStatusText({
              paidUntil: endDate,
              country: market.country,
              expiredCopy: intl.formatMessage({ defaultMessage: 'Expired' }),
              validUntilCopy: intl.formatMessage({ defaultMessage: 'Valid until' }),
            })}
            statusColor={
              endDate
                ? ['expired', 'expiresSoon'].includes(expireStatus(endDate))
                  ? 'red'
                  : 'green'
                : ''
            }
            insuranceCompany={insuranceCompany}
            insuranceCompanyDisplayName={insuranceCompanyDisplayName ?? ''}
            isComparisonPossible={isComparisonPossible}
            resultItemWithButtons={resultItemWithButtons}
            navigateToDetails={navigateToDetails}
            navigateToCompare={navigateToCompare}
          />
        );
      })}
    </div>
  );
};

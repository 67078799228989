import { build, perBuild } from '@jackfranklin/test-data-bot';

import { Country, InsuranceSubType, InsuranceType, PaymentMethod } from '@main/types';
import { EstonianCarInsurance } from '@main/types/insurance/estonia';
import { EstonianVehicleCoverage } from '@main/types/insurance/estonia/EstonianCarInsurance';

import { getEndDate, getRenewalDate, getStartDate } from '../common/utils';

import { latvianFaker } from './latvianFaker'; // Estonian locale currently doesn't exist in faker-js which is why we are using latvian locale instead

export const fakeNewEstonianCarInsurance = build<EstonianCarInsurance>({
  fields: {
    externalId: perBuild(() => latvianFaker.string.uuid()),
    insuranceName: 'Liikluskindlustus',
    insuranceCompany: 'ee-demo',
    insuranceType: InsuranceType.vehicleInsurance,
    insuranceSubType: InsuranceSubType.carInsurance,
    insuranceNumber: perBuild(() => latvianFaker.string.uuid()),
    premiumAmountYearRounded: perBuild(() => latvianFaker.number.int({ min: 200, max: 500 })),
    renewalDate: perBuild(getRenewalDate),
    endDate: perBuild(() => getEndDate(getRenewalDate())),
    startDate: perBuild(() => getStartDate(getRenewalDate())),
    coming: false,
    premiumFrequency: 12,
    paymentMethod: latvianFaker.helpers.enumValue(PaymentMethod),
    insuranceHolderName: 'Olga Tamm',
    insuranceHolderStreetAddress: perBuild(() => latvianFaker.location.streetAddress()),
    insuranceHolderPostalCode: perBuild(() => latvianFaker.location.zipCode()),
    insuranceHolderCity: perBuild(() => latvianFaker.location.city()),
    registrationNo: perBuild(() =>
      `${latvianFaker.string.numeric(2)}${latvianFaker.string.alphanumeric(1)}${latvianFaker.string.alpha(3)}`.toUpperCase(),
    ),
    brand: perBuild(() => latvianFaker.vehicle.manufacturer()),
    model: perBuild(() => latvianFaker.vehicle.model()),
    coverage: EstonianVehicleCoverage.CASCO,
    '@market': Country.ee,
  },
});

import { IconChevronRight } from '@insurely/ui';

import { ResultItemContent, ResultItemContentProps } from '../ResultItemContent';

import styles from './resultItem.module.css';

export interface ResultItemProps extends Omit<ResultItemContentProps, 'showChevron'> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ResultItem = ({
  title,
  amount,
  statusText,
  statusColor,
  subTitle,
  insuranceCompany,
  insuranceCompanyDisplayName,
  onClick,
}: ResultItemProps) =>
  onClick ? (
    <button
      type="button"
      className={styles.buttonContainer}
      onClick={onClick}
      data-testid="result-item"
      aria-label={`${title} ${subTitle}`}
    >
      <ResultItemContent
        title={title}
        amount={amount}
        statusText={statusText}
        statusColor={statusColor}
        subTitle={subTitle}
        insuranceCompany={insuranceCompany}
        insuranceCompanyDisplayName={insuranceCompanyDisplayName}
        icon={
          <div className={styles.chevronContainer}>
            <IconChevronRight
              width="24px"
              color="var(--colorLink)"
              strokeWidth={3}
              className={styles.chevron}
            />
          </div>
        }
      />
    </button>
  ) : (
    <div className={styles.divContainer}>
      <ResultItemContent
        title={title}
        amount={amount}
        statusText={statusText}
        statusColor={statusColor}
        subTitle={subTitle}
        insuranceCompany={insuranceCompany}
        insuranceCompanyDisplayName={insuranceCompanyDisplayName}
      />
    </div>
  );

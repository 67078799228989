export enum InsuranceType {
  houseContentInsurance = 'houseContentInsurance',
  vehicleInsurance = 'vehicleInsurance',
  personInsurance = 'personInsurance',
  animalInsurance = 'animalInsurance',
  generalInsurance = 'generalInsurance',
  incomeInsurance = 'incomeInsurance',
  groupInsurance = 'groupInsurance',
}

export enum InsuranceSubType {
  healthInsurance = 'healthInsurance',
  mopedInsurance = 'mopedInsurance',
  cottageInsurance = 'cottageInsurance',
  trailerInsurance = 'trailerInsurance',
  motorcycleInsurance = 'motorcycleInsurance',
  condoInsurance = 'condoInsurance',
  childInsurance = 'childInsurance',
  villaInsurance = 'villaInsurance',
  contentsInsurance = 'contentsInsurance',
  pregnancyInsurance = 'pregnancyInsurance',
  catInsurance = 'catInsurance',
  motorhomeInsurance = 'motorhomeInsurance',
  otherVehicleInsurance = 'otherVehicleInsurance',
  boatInsurance = 'boatInsurance',
  groupInsurance = 'groupInsurance',
  horseInsurance = 'horseInsurance',
  lifeInsurance = 'lifeInsurance',
  genericInsurance = 'genericInsurance',
  dogInsurance = 'dogInsurance',
  carInsurance = 'carInsurance',
  accidentInsurance = 'accidentInsurance',
  caravanInsurance = 'caravanInsurance',
  otherAnimalInsurance = 'otherAnimalInsurance',
  farmInsurance = 'farmInsurance',
  incomeInsurance = 'incomeInsurance',
  sicknessInsurance = 'sicknessInsurance',
  loanInsurance = 'loanInsurance',
  travelInsurance = 'travelInsurance',
}

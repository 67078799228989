export enum Country {
  se = 'se',
  dk = 'dk',
  no = 'no',
  ee = 'ee',
  es = 'es',
  uk = 'uk',
  fr = 'fr',
  lv = 'lv',
  lt = 'lt',
}

export enum Currency {
  SEK = 'SEK',
  EUR = 'EUR',
  GBP = 'GBP',
  NOK = 'NOK',
  DKK = 'DKK',
}

import { WealthApiClient, INSURELY_API_V5 } from '@insurely/common-api-client';

import { ALLOWED_API_VERSIONS } from '@main/constants/appVersions';

import { useSharedData, useSharedDataConfig } from '@main/contexts';

import { DEFAULT_API_VERSION } from '@main/services';

import { ApiClient } from './types';

export const useWealthApiClient = () => {
  const {
    clientConfig: { clientId },
    userConfig: { language },
  } = useSharedDataConfig();
  const [{ sessionId }] = useSharedData();

  const config = {
    version: INSURELY_API_V5,
    environment: ENVIRONMENT,
    init: {
      headers: {
        'client-id': clientId,
        'insurely-session-id': sessionId,
        ...(language !== undefined && { 'insurely-language': language }),
        'content-type': 'application/json',
      },
    },
  };
  return new WealthApiClient(config);
};

export const useApiClient = ({ apiVersion }: { apiVersion?: string }): ApiClient => {
  const {
    clientConfig: { clientId, productType },
    userConfig: { language },
  } = useSharedDataConfig();

  return async (input, init) => {
    const { headers, ...restInit } = init ?? {};
    return fetch(`${API_URL}/${productType}-collection/${input}`, {
      ...restInit,
      headers: {
        'client-id': clientId,
        'insurely-version':
          apiVersion && ALLOWED_API_VERSIONS.includes(apiVersion)
            ? apiVersion
            : DEFAULT_API_VERSION,
        ...(language !== undefined && { 'insurely-language': language }),
        ...headers,
      },
    });
  };
};

export const useInsuranceApiClient = (): ApiClient => {
  const {
    clientConfig: { clientId },
    userConfig: { language },
  } = useSharedDataConfig();
  const [{ sessionId }] = useSharedData();

  return async (input, init) => {
    const { headers, ...restInit } = init ?? {};
    return fetch(`${API_URL}/insurance/${input}`, {
      ...restInit,
      headers: {
        'client-id': clientId,
        'insurely-session-id': sessionId,
        'insurely-version': INSURELY_API_V5,
        ...(language !== undefined && { 'insurely-language': language }),
        'content-type': 'application/json',
        ...headers,
      },
    });
  };
};

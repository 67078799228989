import { useSharedDataConfig } from '@main/contexts';
import { ClientConfig } from '@main/types';

/**
 * Customers with customization support. `const enum` so that the enum members
 * (customer names that we don't want to expose) get removed during compilation.
 *
 * @see https://www.typescriptlang.org/docs/handbook/enums.html#const-enums
 */
export const enum CustomizedCustomer {
  BNPP = 1, // not falsy
  Citadele = 2,
  DirectAssurance = 3,
  SPP = 4,
  Topdanmark = 5,
  Kozoo = 6,
}

/**
 * Map of customizationId -> CustomizedCustomer
 *
 * customizationId is set in the database per customer (blocks_customer_customization table)
 *
 * Adding a new CustomizedCustomer:
 * 1. Add a new entry in `CustomizedCustomer` enum, e.g. `FooCorp`
 * 2. Create a uuid (https://www.uuidgenerator.net/)
 * 3. Add mapping below from created uuid to the new CustomizedCustomer
 * 4. Add mapping in notion table (https://www.notion.so/insurely/7737b15b09dc404bb0787360c38ef391?v=f4f9ecd4bc544ff086e874468f2634b6&pvs=4)
 */
const customerCustomizations: Record<string, CustomizedCustomer | undefined> = {
  '9923f23e-3db4-42bc-b99e-3aa68b8f0350': CustomizedCustomer.BNPP,
  'b837c9e6-e56e-476f-a102-d718c801c73f': CustomizedCustomer.DirectAssurance,
  '7d64447a-d227-41a4-b21d-82abf1a5002c': CustomizedCustomer.Citadele,
  '88fc43b0-2f65-4ba5-89e0-2daf5c195dff': CustomizedCustomer.SPP,
  'f21b371c-523c-41cc-9819-1de5c212717a': CustomizedCustomer.Topdanmark,
  '2f7c2d3a-005e-4b10-9ddf-e81d9b5ae5d9': CustomizedCustomer.Kozoo,
};

const getCustomerCustomizationId = (clientConfig: ClientConfig) =>
  clientConfig.customizationId && clientConfig.customizationId in customerCustomizations
    ? customerCustomizations[clientConfig.customizationId]
    : null;

export const useCustomizedCustomer = () => {
  const { clientConfig } = useSharedDataConfig();
  if (!clientConfig.showCustomization) {
    return null;
  }

  return getCustomerCustomizationId(clientConfig);
};

import { Deductible } from '@main/types';

export const fakeNewSwedishHomeDeductibles = () => [
  {
    deductibleName: 'Självrisk lösöre',
    deductibleId: 'deductibleMovables',
    deductibleAmount: 1500,
  },
  {
    deductibleName: 'Självrisk resa',
    deductibleId: 'deductibleTravel',
    deductibleAmount: 1500,
  },
  {
    deductibleName: 'Självrisk allrisk',
    deductibleId: 'deductibleAccident',
    deductibleAmount: 1500,
  },
];

export const fakeNewSwedishCarDeductibles = (): Array<Deductible> => [
  {
    deductibleName: 'Självrisk trafik',
    deductibleId: 'deductibleTraffic',
    deductibleAmount: 1000,
  },
  {
    deductibleName: 'Självrisk brand',
    deductibleId: 'deductibleFire',
    deductibleAmount: 1000,
  },
  {
    deductibleName: 'Självrisk bärgning',
    deductibleId: 'deductibleTowing',
    deductibleAmount: 1000,
  },
  {
    deductibleName: 'Självrisk glas',
    deductibleId: 'deductibleGlass',
    deductibleAmount: 1000,
  },
  {
    deductibleName: 'Självrisk allrisk',
    deductibleId: 'deductibleAccident',
    deductibleAmount: 1000,
  },
];

export const fakeNewSwedishAnimalDeductibles = (): Array<Deductible> => [
  {
    deductibleName: 'Rörlig självrisk',
    deductibleId: 'deductibleVariable',
    deductibleAmount: 15,
  },
  {
    deductibleName: 'Fast självrisk',
    deductibleId: 'deductibleFixed',
    deductibleAmount: 3000,
  },
];

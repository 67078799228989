export const DATA_AGGREGATION_SELECT_COMPANIES = '/select-companies';
export const DATA_AGGREGATION_LOGIN = '/authenticate';
export const DATA_AGGREGATION_SELECT_AUTHENTICATION = '/select-authentication';
export const DATA_AGGREGATION_RESUME_CODE = '/resume-code';
export const DATA_AGGREGATION_COLLECT_DATA = '/collect-data';
export const DATA_AGGREGATION_FAILED_COLLECTION = '/failed-collection';
export const DATA_AGGREGATION_EMPTY_RESULT = '/empty-result';

// subroutes
export const DATA_AGGREGATION_AUTHENTICATION_CREDENTIALS = '/authentication-credentials';

import { Typography } from '@insurely/ui';

import React from 'react';
import { useIntl } from 'react-intl';

import {
  formatCurrency,
  useFlatPolicies,
  useSharedData,
  getInsuranceYearlyPrice,
} from '@main/shared-exports';

import styles from '../insuranceSummary.module.css';

import { InsuranceChart } from './InsuranceChart';

export const InsuranceSummary: React.FC = () => {
  const intl = useIntl();
  const [
    {
      clientConfig: { language, market },
    },
  ] = useSharedData();
  const policies = useFlatPolicies();

  const totalPrice = formatCurrency({
    language,
    market,
    amount: policies.reduce((total, policy) => total + (getInsuranceYearlyPrice(policy) ?? 0), 0),
  });

  return (
    <div className={styles.outerDiv}>
      <div className={styles.container}>
        <Typography component="h2" variant="ParagraphCaption">
          {intl.formatMessage({ defaultMessage: 'Total cost' })}
        </Typography>
        <Typography component="p" variant="Headline-4" className={styles.totalPrice}>
          {intl.formatMessage({ defaultMessage: '{number}/year' }, { number: totalPrice })}
        </Typography>
        <InsuranceChart policies={policies} />
      </div>
    </div>
  );
};

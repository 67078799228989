import { COMPARE_MISSING_INFO, COMPARE_NOT_POSSIBLE } from '@main/constants';
import { HomeInsurance, PossibleToCompare, FailedToCompareReason } from '@main/types';

const dataNeededToCompareCondoIcaSE = ({
  insurance,
  personalInformation,
}: {
  insurance: HomeInsurance;
  personalInformation?: PersonalInformation;
}): Record<string, string | undefined | number> => ({
  personalNumber: personalInformation?.PERSONAL_NUMBER,
  insuranceObjectStreetAddress: insurance.insuranceObjectStreetAddress,
  insuranceObjectPostalCode: insurance.insuranceObjectPostalCode,
  livingArea: insurance.livingArea,
  numberOfResidents: insurance.numberOfResidents,
});

export const isPossibleToCompareCondoIcaSE = ({
  insurance,
  personalInformation,
}: {
  insurance: HomeInsurance;
  personalInformation?: PersonalInformation;
}): PossibleToCompare => {
  const compareData = dataNeededToCompareCondoIcaSE({
    insurance,
    personalInformation,
  });

  const missingFields = Object.keys(compareData).filter((key) => !compareData[key]);
  const isDataMissing = missingFields.length > 0;

  if (!isDataMissing) {
    return { isComparisonPossible: true };
  }

  const hasMissingPersonalNumber = missingFields.includes('personalNumber');

  return {
    isComparisonPossible: !hasMissingPersonalNumber,
    missingFields,
    failedReason: FailedToCompareReason.MissingRequiredFields,
    failedNavigatePath: hasMissingPersonalNumber ? COMPARE_NOT_POSSIBLE : COMPARE_MISSING_INFO,
  };
};

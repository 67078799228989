import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { CompanyAndLineOfBusiness, LoginMethod } from '@main/types';
import { Prefill, UserData } from '@main/types/configurations/prefill';

type PdfDataType = {
  lineOfBusiness: CompanyAndLineOfBusiness;
  pdfBase64: string;
};

export type AdditionalPayloadInput = Record<string, string>;

export interface PayloadInput {
  ADDITIONAL_INPUTS?: AdditionalPayloadInput;
  EMAIL?: string;
  ESTONIAN_PERSONAL_NUMBER?: string;
  ESTONIAN_PHONE_NUMBER?: string;
  LATVIAN_PERSONAL_NUMBER?: string;
  LITHUANIAN_PERSONAL_NUMBER?: string;
  LITHUANIAN_PHONE_NUMBER?: string;
  NORWEGIAN_FULL_PERSONAL_NUMBER?: string;
  NORWEGIAN_PERSONAL_NUMBER?: string;
  NORWEGIAN_PHONE_NUMBER?: string;
  PASSWORD?: string;
  PDF_DATA?: PdfDataType[];
  SWEDISH_PERSONAL_NUMBER?: string;
  USERNAME?: string;
  REGION?: string;
  SESSION_ID?: string;
  EXTERNAL_REFERENCE?: string;
}

type UserInputContextType = {
  selectedCompanies: [Company] | Company[];
  setSelectedCompanies: (
    selectedCompanies: Company[] | ((prevSelectedCompanies: Company[]) => Company[]),
  ) => void;
  loginMethod: LoginMethod | null;
  selectedAutomatically: boolean;
  setLoginMethod: (loginMethod: LoginMethod | null, selectedAutomatically?: boolean) => void;
  setPayloadInput: React.Dispatch<React.SetStateAction<PayloadInput>>;
  payloadInput: PayloadInput;
};

const UserInputContext = createContext<UserInputContextType | undefined>(undefined);

type UserInputContextProviderProps = {
  prefill?: Prefill;
  children: React.ReactNode;
};

type SelectedCompanyUserInputContextType = Omit<UserInputContextType, 'company'> & {
  company: Company;
};

export function useUserInput(): UserInputContextType | SelectedCompanyUserInputContextType {
  const context = useContext(UserInputContext);
  if (context === undefined) {
    throw new Error('useUserInput must be used within a UserInputContextProvider');
  }

  return context;
}

export const noCompanySelected: Company = {
  insuranceCompany: 'no-company-selected',
  functional: false,
  status: 'OK',
  insuranceCompanyDisplayName: 'No company selected',
  loginMethods: [],
  nonFunctionalLoginMethods: [],
};

type UserDataToPayloadInput<T extends keyof UserData> = (value: UserData[T]) => {
  key: keyof PayloadInput;
  value?: string;
};

const userDataToPayloadInput: { [T in keyof UserData]: UserDataToPayloadInput<T> } = {
  estonianPersonalNumber: (value) => ({
    key: 'ESTONIAN_PERSONAL_NUMBER',
    value: typeof value === 'string' ? value : value?.value,
  }),
  estonianPhoneNumber: (value) => ({
    key: 'ESTONIAN_PHONE_NUMBER',
    value: typeof value === 'string' ? value : value?.value,
  }),
  norwegianPersonalNumber: (value) => ({ key: 'NORWEGIAN_PERSONAL_NUMBER', value }),
  norwegianPhoneNumber: (value) => ({ key: 'NORWEGIAN_PHONE_NUMBER', value }),
  swedishPersonalNumber: (value) => ({ key: 'SWEDISH_PERSONAL_NUMBER', value }),
  username: (value) => ({
    key: 'USERNAME',
    value: typeof value === 'string' ? value : value?.value,
  }),
  email: (value) => ({ key: 'EMAIL', value }),
};

const preparePayloadInput = (userData: UserData): PayloadInput =>
  Object.keys(userData).reduce(
    <T extends keyof UserData>(payloadInput: PayloadInput, userDataKey: T) => {
      const userDataValue = userData[userDataKey];
      if (userDataValue === undefined || userDataToPayloadInput[userDataKey] === undefined) {
        return payloadInput;
      }
      const { key, value } = userDataToPayloadInput[userDataKey](userDataValue);
      return { ...payloadInput, [key]: value };
    },
    {},
  );

export function UserInputContextProvider({ prefill, children }: UserInputContextProviderProps) {
  const [selectedCompanies, _setSelectedCompanies] = useState<Company[]>([]);
  const [{ loginMethod, selectedAutomatically }, _setLoginMethod] = useState<{
    loginMethod: LoginMethod | null;
    selectedAutomatically: boolean;
  }>({ loginMethod: null, selectedAutomatically: false });
  const [payloadInput, setPayloadInput] = useState<PayloadInput>(
    prefill?.user ? preparePayloadInput(prefill.user) : {},
  );

  const setLoginMethod = useCallback(
    (newLoginMethod: LoginMethod | null, newSelectedAutomatically?: boolean) => {
      _setLoginMethod({
        loginMethod: newLoginMethod,
        selectedAutomatically: newSelectedAutomatically ?? false,
      });
    },
    [],
  );

  useEffect(() => {
    if (!prefill?.user) return;
    setPayloadInput(prefill?.user ? preparePayloadInput(prefill.user) : {});
  }, [prefill]);

  const setSelectedCompanies = useCallback(
    (_selectedCompanies: Company[] | ((prevCompanies: Company[]) => Company[])) => {
      setLoginMethod(null);
      if (typeof _selectedCompanies === 'function') {
        _setSelectedCompanies((prevCompanies) =>
          _selectedCompanies(
            prevCompanies.filter((c) => c.insuranceCompany !== noCompanySelected.insuranceCompany),
          ),
        );
      } else {
        _setSelectedCompanies(_selectedCompanies);
      }
    },
    [setLoginMethod],
  );

  const value = useMemo(
    () => ({
      selectedCompanies: selectedCompanies.length ? selectedCompanies : [noCompanySelected],
      loginMethod,
      selectedAutomatically,
      payloadInput,
      setSelectedCompanies,
      setLoginMethod,
      setPayloadInput,
    }),
    [
      selectedCompanies,
      loginMethod,
      selectedAutomatically,
      payloadInput,
      setSelectedCompanies,
      setLoginMethod,
    ],
  );

  return <UserInputContext.Provider value={value}>{children}</UserInputContext.Provider>;
}

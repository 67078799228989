import { Color, CompanyLogo, StatusText, Typography } from '@insurely/ui';

import classNames from 'classnames';

import { useSharedData } from '@main/contexts';

import styles from './resultItemContent.module.css';

type SubTitleVariant = 'ParagraphTiny' | 'Label-3' | 'ParagraphCaption';

export type ResultItemContentProps = {
  insuranceCompany: string;
  insuranceCompanyDisplayName: string;
  title: string;
  amount?: string;
  subTitle: string;
  subTitleVariant?: SubTitleVariant;
  icon?: JSX.Element;
  statusText?: string;
  statusColor?: string;
  className?: string;
};

export const ResultItemContent = ({
  title,
  amount,
  statusText,
  statusColor,
  subTitle,
  subTitleVariant = 'ParagraphTiny',
  insuranceCompany,
  insuranceCompanyDisplayName,
  className,
  icon,
}: ResultItemContentProps) => {
  const [
    {
      userConfig: {
        customization: { logosVariant },
      },
    },
  ] = useSharedData();
  return (
    <div className={classNames(styles.contentContainer, className)}>
      <div
        style={{
          flexDirection: logosVariant === 'noLogos' ? 'column' : 'row',
        }}
        className={styles.textContainer}
      >
        {logosVariant === 'noLogos' ? (
          <Typography
            component="h3"
            variant="ParagraphTiny"
            className={styles.companyName}
            style={{ color: 'var(--colorLink)' }}
          >
            {insuranceCompanyDisplayName}
          </Typography>
        ) : (
          <div>
            <CompanyLogo
              className={styles.logo}
              company={insuranceCompany ?? ''}
              width="32px"
              aria-label={insuranceCompanyDisplayName}
            />
          </div>
        )}
        <div className={styles.card}>
          <div className={styles.cardtop}>
            <Typography
              component="p"
              variant="Label-2"
              className={classNames(styles.title, 'ph-no-capture')}
            >
              {title}
            </Typography>
            {amount && (
              <Typography component="p" variant="Label-2" className={styles.amount}>
                {amount}
              </Typography>
            )}
          </div>

          <div className={styles.cardbottom}>
            {subTitle && (
              <Typography
                component="p"
                variant={subTitleVariant}
                className={styles.insuranceSubType}
              >
                {subTitle}
              </Typography>
            )}
            {statusText && statusColor && (
              <StatusText
                color={statusColor}
                text={statusText}
                className={styles.statusText}
                textColor={Color['grey-1']}
              />
            )}
          </div>
        </div>
      </div>
      {icon}
    </div>
  );
};

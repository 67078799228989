import { build, perBuild } from '@jackfranklin/test-data-bot';

import { fakeNewAnimalInsurance } from './insurance';

export const fakeNewAnimalCollection = build<InsurancePolicy>({
  fields: {
    insurance: perBuild(() => fakeNewAnimalInsurance.one()),
    parameters: [],
    addons: [],
    personalInformation: { PERSONAL_NUMBER: '199301010101' },
  },
});

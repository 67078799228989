import { BlocksBaseView } from '@main/components/BlocksBaseView/BlocksBaseView';
import { ErrorFallback } from '@main/components/ErrorFallback';
import { useRouteError } from '@main/services';

import { Language } from '@main/types';
import { StructuredError } from '@main/utils';

export function RouteError() {
  const error = useRouteError() as Error | StructuredError;
  return (
    <BlocksBaseView>
      <ErrorFallback
        error={error}
        resetError={() => {
          window.location.reload();
        }}
        language={Language.en}
      />
    </BlocksBaseView>
  );
}

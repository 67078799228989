import { useIntl } from 'react-intl';

import { useSharedData } from '@main/shared-exports';
import { Currency, KfAccount, PensionAccount } from '@main/types';

import { formatCurrency as defaultFormatCurrency } from '@main/utils';

import { DescriptionSection } from '../DescriptionSection';

import styles from './index.module.css';

interface GeneralInfoProp {
  account: PensionAccount | KfAccount;
}

export const FeeSection = ({ account }: GeneralInfoProp) => {
  const intl = useIntl();

  const [
    {
      clientConfig: { language, market },
    },
  ] = useSharedData();

  const { fees } = account;

  const formatCurrency = ({ currency, amount }: { currency: Currency; amount: number }) =>
    defaultFormatCurrency({
      language,
      market: {
        country: market.country,
        currency,
      },
      amount,
    });

  const previousYearFee = formatCurrency({
    currency: fees.feesPreviousYear.currency,
    amount: fees.feesPreviousYear.amount,
  });

  const fixedFee = formatCurrency({
    currency: fees.feeFixed?.currency ?? market.currency,
    amount: fees.feeFixed?.amount ?? 0,
  });

  const descriptionSectionItems = [
    {
      term: intl.formatMessage({
        id: 'overview.wealth-account.general-info.fee-section-term-1',
        defaultMessage: 'Last year',
      }),
      description: previousYearFee,
    },
    {
      term: intl.formatMessage({
        id: 'overview.wealth-account.general-info.fee-section-term-2',
        defaultMessage: 'Fixed fee',
      }),
      description: fixedFee,
    },
  ];

  return (
    <DescriptionSection
      columns="two"
      headline={intl.formatMessage({
        id: 'overview.wealth-account.general-info.fee-section-headline',
        defaultMessage: 'Fees',
      })}
      items={descriptionSectionItems}
      className={styles.feeSection}
    />
  );
};

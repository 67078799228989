import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';

import {
  CHECKOUT_PATH,
  COMPARE_PATH,
  CONTACT_PATH,
  DATA_AGGREGATION_PATH,
  END_STATE_PATH,
  INITIAL_NAVIGATION_PATH,
  OVERVIEW_PATH,
  PENSION_MOVE_PATH,
  QUESTIONNAIRE_PATH,
  RESULTS_PATH,
} from '@main/constants';
import { useNavigate, useLocation } from '@main/services';
import { BlocksModule } from '@main/types/blocksModule';
import {
  getBlocksModule,
  invariant,
  PostMessageName,
  safeSendInfoToParentWindow,
} from '@main/utils';

import { useSharedData } from '../SharedDataContext';

import { getUseCase } from './useCases';

type NavigationOptions = {
  replace?: boolean;
  urlQueryParams?: Record<string, string>;
};

type UseCaseContextType = {
  nextBlock: (options?: NavigationOptions) => void;
  skipBlock: (options?: NavigationOptions) => void;
  currentModuleName: BlocksModule;
  currentModule: UseCaseModule;
  previousBlock: () => void;
  useCase: UseCase;
  isFirstBlock: boolean;
  isLastBlock: boolean;
};

const UseCaseContext = createContext<UseCaseContextType | undefined>(undefined);

export const blocksPathMapping: Record<BlocksModule, string> = {
  initialNavigation: INITIAL_NAVIGATION_PATH,
  dataAggregation: DATA_AGGREGATION_PATH,
  checkout: CHECKOUT_PATH,
  compare: COMPARE_PATH,
  overview: OVERVIEW_PATH,
  questionnaire: QUESTIONNAIRE_PATH,
  results: RESULTS_PATH,
  contact: CONTACT_PATH,
  endState: END_STATE_PATH,
  pensionMove: PENSION_MOVE_PATH,
};

type UseCaseContextProviderProps = {
  children: React.ReactNode;
};

export function useUseCase() {
  const context = useContext(UseCaseContext);
  if (context === undefined) {
    throw new Error('useUseCase must be used within a UseCaseContextProvider');
  }
  return context;
}

function getModuleNameFromPathname(pathname: string) {
  const newBlocksPathMatch = pathname.match(/^\/(.[^/]*)/);

  if (!newBlocksPathMatch) return;
  const [, productPath] = newBlocksPathMatch;
  return getBlocksModule(productPath);
}

export function UseCaseContextProvider({ children }: UseCaseContextProviderProps) {
  const [
    {
      clientConfig: { useCase: useCaseName, allowedOrigin },
      userConfig: { parentOrigin, dataAggregation },
    },
  ] = useSharedData();
  const useCase = getUseCase(useCaseName, dataAggregation?.hideResultsView);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentModuleName = getModuleNameFromPathname(pathname) ?? useCase.initialModule;

  const currentModule = useCase.modules[currentModuleName];

  invariant(!!currentModule, `Could not find current module from path: ${currentModuleName}`);

  if (DEBUG_LOGGING) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // eslint-disable-next-line no-console
      console.debug(`[UseCaseContext] currentModule ${currentModuleName}`, currentModule);
    }, [currentModule, currentModuleName]);
  }

  const isFirstBlock = useCase.initialModule === currentModuleName;
  const isLastBlock = !currentModule?.next;

  const navigateBlock = useCallback(
    (action: 'next' | 'skip', options?: NavigationOptions) => {
      const modulePath = currentModule?.[action];
      if (!modulePath) return;

      const { urlQueryParams = {}, replace = false } = options || {};

      navigate(blocksPathMapping[modulePath], { replace, searchParams: urlQueryParams });
    },
    [currentModule, navigate],
  );

  const nextBlock = useCallback(
    (options?: NavigationOptions) => navigateBlock('next', options),
    [navigateBlock],
  );

  const skipBlock = useCallback(
    (options?: NavigationOptions) => navigateBlock('skip', options),
    [navigateBlock],
  );

  const previousBlock = useCallback(() => {
    if (isFirstBlock) {
      safeSendInfoToParentWindow({
        name: PostMessageName.APP_CLOSE,
        targetOrigin: parentOrigin,
        allowedOrigins: allowedOrigin,
      });
      return;
    }
    navigate(-1);
  }, [allowedOrigin, isFirstBlock, navigate, parentOrigin]);

  const value = useMemo<UseCaseContextType>(
    () => ({
      nextBlock,
      skipBlock,
      previousBlock,
      currentModuleName,
      currentModule,
      useCase,
      isFirstBlock,
      isLastBlock,
    }),
    [
      nextBlock,
      skipBlock,
      previousBlock,
      currentModuleName,
      currentModule,
      useCase,
      isFirstBlock,
      isLastBlock,
    ],
  );

  return <UseCaseContext.Provider value={value}>{children}</UseCaseContext.Provider>;
}

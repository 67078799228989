import { Divider, Typography } from '@insurely/ui';
import { useIntl } from 'react-intl';

import { PlateContainer } from '../PlateContainer';

import styles from './assetsDetails.module.css';

interface AssetsDetailsProps {
  sumOfTotalAssets: string;
  sumOfSavings: string;
  sumOfPensions: string;
  latestUpdatedDate: string;
}

export const AssetsDetails = ({
  sumOfTotalAssets,
  sumOfSavings,
  sumOfPensions,
  latestUpdatedDate,
}: AssetsDetailsProps) => {
  const { formatMessage } = useIntl();
  return (
    <PlateContainer>
      <dl className={styles.descriptionList}>
        <div className={styles.topSection}>
          <Typography component="dt" variant="ParagraphCaption">
            {formatMessage({
              id: 'overview.wealth.assets-details.headline-1',
              defaultMessage: 'Total assets',
            })}
          </Typography>
          <Typography component="dd" variant="Headline-4">
            {sumOfTotalAssets}
          </Typography>
          <Divider className={styles.divider} />
        </div>
        <div>
          <Typography component="dt" variant="ParagraphCaption">
            {formatMessage({
              id: 'overview.wealth.assets-details.headline-2',
              defaultMessage: 'Savings',
            })}
          </Typography>
          <Typography component="dd" variant="Headline-6">
            {sumOfSavings}
          </Typography>
        </div>
        <div>
          <Typography component="dt" variant="ParagraphCaption">
            {formatMessage({
              id: 'overview.wealth.assets-details.headline-3',
              defaultMessage: 'Pension',
            })}
          </Typography>
          <Typography component="dd" variant="Headline-6">
            {sumOfPensions}
          </Typography>
        </div>
      </dl>
      <Typography component="p" variant="ParagraphTiny" className={styles.timeframe}>
        {latestUpdatedDate}
      </Typography>
    </PlateContainer>
  );
};

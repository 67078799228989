import { Typography } from '@insurely/ui';

import classNames from 'classnames';

import { DescriptionListItem, DescriptionListItemProps } from '../DescriptionListItem';

import styles from './descriptionSection.module.css';

interface DescriptionSectionProps {
  headline: string;
  items: DescriptionListItemProps[];
  columns?: 'one' | 'two';
  className?: string;
}

export const DescriptionSection = ({
  headline,
  items,
  columns = 'one',
  className,
}: DescriptionSectionProps) => (
  <div className={classNames(styles.section, className)}>
    <Typography component="h3" variant="Label-2" className={styles.headline}>
      {headline}
    </Typography>
    {items.map((item) => (
      <DescriptionListItem
        key={`${item.description}-${item.term}`}
        term={item.term}
        description={item.description}
        className={classNames(columns === 'one' && styles.oneColumn)}
      />
    ))}
  </div>
);

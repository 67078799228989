import { Buffer } from 'buffer';

const header = {
  alg: 'HS256',
  typ: 'JWT',
};
const encodedHeaders = Buffer.from(JSON.stringify(header)).toString('base64');

export function mockJwtToken(claims: unknown) {
  // correct type for claims is an object, but this is to be able to mock faulty claims
  const encodedPlayload = Buffer.from(JSON.stringify(claims)).toString('base64');

  return `Bearer ${encodedHeaders}.${encodedPlayload}.korvknappen2`;
}

import { assign, createActor, setup } from 'xstate';

import { PayloadInput } from '@main/contexts';

import { defaultElapsedTimeSeconds } from '../constants';
import { pollingMachine } from '../pollingMachine';
import { mapFailedStatus } from '../statusMapping';
import {
  failedStatuses,
  CollectDataError,
  StartCollectionSuccessfulResponse,
  CollectionStatusSuccessfulResponse,
} from '../types';

import { getDataActor, startCollectionActor } from './actors';
import {
  SingleCollectorMachineContext,
  SingleCollectorMachineEvents,
  SingleCollectorMachineInput,
} from './types';
import { mapCollectionStatusToState } from './utils/mapCollectionStatusToState';

export const singleCollectionMachine = setup({
  types: {
    context: {} as SingleCollectorMachineContext,
    input: {} as SingleCollectorMachineInput,
    events: {} as SingleCollectorMachineEvents,
  },
  actors: {
    startCollection: startCollectionActor,
    getData: getDataActor,
    poll: pollingMachine,
  },
  guards: {
    hasCollectionId: ({ context }) => !!context.collectionId,
    hasAccessToken: ({ context }) => !!context.collectionInfo?.extraInformation?.ACCESS_TOKEN,
  },
}).createMachine({
  id: 'single-collection',
  context: ({ input }) => ({
    apiClient: input.apiClient,
    insuranceApiClient: input.insuranceApiClient,
    wealthApiClient: input.wealthApiClient,
    productType: input.productType,
    collectionId: input.collectionId,
    payload: input.payload,
    elapsedTimeSeconds: defaultElapsedTimeSeconds,
    resumeCode: input.resumeCode,
    resumeCodeIndex: input.index,
    loginMethod: input.payload?.loginMethod ?? input.loginMethod,
  }),
  initial: 'notStarted',
  on: {
    STOP: {
      target: '.stopped',
    },
  },
  states: {
    notStarted: {
      always: [
        {
          target: 'polling',
          guard: {
            type: 'hasCollectionId',
          },
        },
        {
          target: 'initiated',
        },
      ],
    },
    initiated: {
      invoke: {
        src: 'startCollection',
        input: ({ context }) => {
          if (!context.payload && !context.resumeCode) {
            throw new Error('Payload or resume code is required');
          }

          // Remove all unused prefilled inputs
          const input: PayloadInput | undefined = context.payload?.input
            ? Object.fromEntries(Object.entries(context.payload.input).filter(([_, v]) => v !== ''))
            : undefined;
          const payload = context.payload
            ? {
                ...context.payload,
                input,
              }
            : undefined;

          return {
            resumeCode: context.resumeCode,
            resumeCodeIndex: context.resumeCodeIndex,
            productType: context.productType,
            apiClient: context.apiClient,
            insuranceApiClient: context.insuranceApiClient,
            wealthApiClient: context.wealthApiClient,
            payload,
          };
        },
        onDone: [
          {
            guard: ({ event }) => 'cause' in event.output && !!event.output.cause,
            actions: assign({
              error: ({ event }) => event.output as CollectDataError,
            }),
            target: 'failed',
          },
          {
            target: 'polling',
            actions: assign({
              collectionId: ({ event }) => {
                const output = event.output as StartCollectionSuccessfulResponse;
                return output.id ?? output.collectionId;
              },
            }),
          },
        ],
        onError: {
          target: 'failed',
        },
      },
    },
    polling: {
      initial: 'pollStarted',
      invoke: {
        src: 'poll',
        input: ({ context }) => {
          if (!context.collectionId) throw new Error('Tried to poll without collectionId');
          return {
            collectionId: context.collectionId,
            apiClient: context.apiClient,
            wealthApiClient: context.wealthApiClient,
            productType: context.productType,
          };
        },
        onDone: [
          {
            guard: ({ event }) => !!event.output && 'cause' in event.output,
            target: 'failed',
            actions: assign({
              error: ({ event }) => event.output as CollectDataError,
            }),
          },
          {
            guard: ({ event }) =>
              !!event.output &&
              failedStatuses.includes((event.output as CollectionStatusSuccessfulResponse).status),
            target: 'failed',
            actions: [
              assign(({ event }) => ({
                error: {
                  cause: mapFailedStatus(
                    (event.output as CollectionStatusSuccessfulResponse).status,
                  ),
                },
              })),
            ],
          },
          {
            target: 'fetchingResults',
            actions: [
              assign(({ event }) => ({
                collectionInfo: event.output as CollectionStatusSuccessfulResponse,
              })),
            ],
          },
        ],
        onSnapshot: {
          actions: [
            assign(({ event }) => ({
              collectionInfo: event.snapshot.context.collectionInfo,
              elapsedTimeSeconds: event.snapshot.context.elapsedTimeSeconds,
            })),
            ({ event, self, context }) => {
              const { loginMethod } = context;
              if (!event.snapshot.context.collectionInfo || !loginMethod) return;

              const type = mapCollectionStatusToState(
                event.snapshot.context.collectionInfo,
                loginMethod,
              );

              if (type) {
                self.send({ type, data: event.snapshot.context.collectionInfo });
              }
            },
          ],
        },
      },
      on: {
        COLLECT: {
          target: '.collecting',
        },
        REQUEST_USER_ACTION: {
          target: '.userActionRequired',
        },
        FAIL: {
          target: 'failed',
        },
        FETCH_RESULTS: [
          {
            target: 'fetchingResults',
            guard: 'hasAccessToken',
            actions: [
              assign(({ event }) => ({
                status: event.data.status,
                collectionInfo: event.data,
              })),
            ],
          },
          {
            target: 'failed',
          },
        ],
      },
      states: {
        pollStarted: {},
        collecting: {},
        userActionRequired: {},
      },
    },
    fetchingResults: {
      invoke: {
        src: 'getData',
        input: ({ context }) => ({
          authorizationToken: context.collectionInfo?.extraInformation?.ACCESS_TOKEN ?? '',
          collectionId: context.collectionId ?? '',
          apiClient: context.apiClient,
          wealthApiClient: context.wealthApiClient,
          productType: context.productType,
        }),
        onDone: [
          {
            guard: ({ event }) => !!event.output && 'cause' in event.output,
            actions: assign({
              error: ({ event }) => event.output as CollectDataError,
            }),
            target: 'failed',
          },
          {
            target: 'completed',
            actions: [assign({ collectionData: ({ event }) => event.output as Policy[] })],
          },
        ],
        onError: {
          target: 'failed',
        },
      },
    },
    failed: {
      type: 'final',
    },
    completed: {
      type: 'final',
    },
    stopped: {
      type: 'final',
    },
  },
});

export type SingleCollectorMachineActor = ReturnType<
  typeof createActor<typeof singleCollectionMachine>
>;

export type SingleCollectorMachineState = ReturnType<
  SingleCollectorMachineActor['getSnapshot']
>['value'];

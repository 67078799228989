import { ResponseError } from '@insurely/common-api-client';
import { assign, fromPromise, setup } from 'xstate';

import { defaultElapsedTimeSeconds } from '../constants';
import {
  finalStatuses,
  CollectDataError,
  FailedCollectionStatus,
  MaybeCollectDataError,
  CollectionStatusSuccessfulResponse,
  CollectionStatusResponse,
} from '../types';

import {
  PollStatusInput,
  PollingMachineContext,
  PollingMachineInput,
  PollingMachineOutput,
} from './types';
import { isCollectionStatusResponse, isDurationsStatus } from './utils';

const pollDelaySeconds = 1;

export const pollingMachine = setup({
  types: {
    context: {} as PollingMachineContext,
    input: {} as PollingMachineInput,
    output: {} as PollingMachineOutput,
  },
  actors: {
    pollStatus: fromPromise<
      MaybeCollectDataError<CollectionStatusSuccessfulResponse>,
      PollStatusInput
    >(async ({ input: { apiClient, wealthApiClient, productType, collectionId } }) => {
      let body: CollectionStatusResponse;

      if (productType === 'wealth') {
        try {
          const response = await wealthApiClient.getStatus(collectionId);
          body = response as CollectionStatusResponse;
        } catch (error) {
          return {
            cause: FailedCollectionStatus.STATUS_POLLING_FAILED,
            message: (error as ResponseError).message,
          } as CollectDataError;
        }
      } else {
        const response = await apiClient(`status/${collectionId}`);
        body = (await response.json()) as CollectionStatusResponse;
      }

      if (!isCollectionStatusResponse(body)) {
        return {
          cause: FailedCollectionStatus.STATUS_POLLING_FAILED,
          message: body?.message,
        } as CollectDataError;
      }

      return body;
    }),
  },
  guards: {
    isFinalStatus: ({ context }) =>
      !!context.collectionInfo && finalStatuses.includes(context.collectionInfo.status),
  },
  delays: {
    pollDelay: pollDelaySeconds * 1000,
  },
}).createMachine({
  id: 'poller',
  initial: 'polling',
  context: ({ input }) => ({
    collectionId: input.collectionId,
    pollingCount: 0,
    elapsedTimeSeconds: defaultElapsedTimeSeconds,
    apiClient: input.apiClient,
    wealthApiClient: input.wealthApiClient,
    productType: input.productType,
  }),
  states: {
    polling: {
      entry: [
        assign(({ context }) => {
          const elapsedTimeSeconds = { ...context.elapsedTimeSeconds };
          const status = context.collectionInfo?.status;
          if (isDurationsStatus(status)) {
            elapsedTimeSeconds[status] += pollDelaySeconds;
          }

          return {
            pollingCount: context.pollingCount + 1,
            elapsedTimeSeconds,
          };
        }),
      ],
      invoke: {
        src: 'pollStatus',
        input: ({ context }) => ({
          collectionId: context.collectionId,
          apiClient: context.apiClient,
          wealthApiClient: context.wealthApiClient,
          productType: context.productType,
        }),
        onDone: [
          {
            guard: ({ event }) => 'cause' in event.output && !!event.output.cause,
            target: 'finished',
            actions: [
              assign({
                error: ({ event }) => event.output as CollectDataError,
              }),
            ],
          },
          {
            target: 'checkStatus',
            actions: [
              assign({
                collectionInfo: ({ event }) => event.output as CollectionStatusSuccessfulResponse,
              }),
            ],
          },
        ],
        onError: { target: 'finished' },
      },
    },
    checkStatus: {
      always: [{ target: 'finished', guard: 'isFinalStatus' }, { target: 'scheduleNextPoll' }],
    },
    scheduleNextPoll: {
      after: {
        pollDelay: { target: 'polling' },
      },
    },
    finished: {
      type: 'final',
    },
    timeout: {
      type: 'final',
    },
  },
  output: ({ context }) => context.error ?? context.collectionInfo ?? null,
});

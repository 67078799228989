import { IntlShape } from 'react-intl';

import { Holder, FinancialProductType } from '@main/types/wealth';

export const formattedSubTitle = ({
  companyDisplayName,
  holder,
}: {
  companyDisplayName: string;
  holder: Holder;
}) => {
  switch (true) {
    case !!holder.name:
      return `${companyDisplayName} · ${holder.name}`;
    case !!holder.organization?.name:
      return `${companyDisplayName} · ${holder.organization.name}`;
    default:
      return `${companyDisplayName}`;
  }
};

export const formattedWealthType = (type: FinancialProductType, intl: IntlShape) => {
  switch (type) {
    case FinancialProductType.KF:
      return intl.formatMessage({
        id: 'overview.wealth.financial-product-type.kf',
        defaultMessage: 'KF',
      });
    case FinancialProductType.ISK:
      return intl.formatMessage({
        id: 'overview.wealth.financial-product-type.isk',
        defaultMessage: 'ISK',
      });
    case FinancialProductType.CUSTODY_ACCOUNT:
      return intl.formatMessage({
        id: 'overview.wealth.financial-product-type.custody-account',
        defaultMessage: 'Custody Account',
      });
    case FinancialProductType.UNKNOWN_INVESTMENT_TYPE:
      return intl.formatMessage({
        id: 'overview.wealth.financial-product-type.unknown-investment-type',
        defaultMessage: 'Investment',
      });
    case FinancialProductType.IPS_PENSION:
      return intl.formatMessage({
        id: 'overview.wealth.financial-product-type.ips-pension',
        defaultMessage: 'IPS Pension',
      });
    case FinancialProductType.PRIVATE_PENSION:
      return intl.formatMessage({
        id: 'overview.wealth.financial-product-type.private-pension',
        defaultMessage: 'Private Pension',
      });
    case FinancialProductType.OCCUPATIONAL_PENSION:
      return intl.formatMessage({
        id: 'overview.wealth.financial-product-type.occupational-pension',
        defaultMessage: 'Occupational Pension',
      });
    case FinancialProductType.COLLECTIVE_OCCUPATIONAL_PENSION:
      return intl.formatMessage({
        id: 'overview.wealth.financial-product-type.collective-occupational-pension',
        defaultMessage: 'Collective Occupational Pension',
      });
    case FinancialProductType.UNKNOWN_PENSION_TYPE:
      return intl.formatMessage({
        id: 'overview.wealth.financial-product-type.unknown-pension-type',
        defaultMessage: 'Pension',
      });
    default:
      return intl.formatMessage({
        id: 'overview.wealth.financial-product-type.unknown',
        defaultMessage: 'Unknown',
      });
  }
};

import { AddOn } from '@main/types';

export const fakeNewSwedishHomeAddons = (): Array<AddOn> => [
  { addOnName: 'Bostadsrättsförsäkring', addOnId: 'addOnCondo', addOnPrice: 100 },
  { addOnName: 'Utökat reseskydd', addOnId: 'addOnTravel', addOnPrice: 75 },
  { addOnName: 'Allrisk', addOnId: 'addOnAccident', addOnPrice: 50 },
];

export const fakeNewSwedishAnimalAddons = (): Array<AddOn> => [
  {
    addOnName: 'Livförsäkring',
    addOnId: 'addOnLife',
    addOnPrice: 600,
  },
  {
    addOnName: 'Avel',
    addOnId: 'addOnBreeding',
    addOnPrice: 1000,
  },
];

/* eslint-disable max-lines */
import {
  FrenchCarInsuranceName,
  InsurancePackageName,
  InsuranceParameter,
  InsuranceSubType,
} from '@main/types';

const condoParameters: Array<InsuranceParameter> = [
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'breakdown',
    parameterDisplayName: 'Panne électroménager',
    parameterDescription:
      "Option garantissant vos biens électroménagers contre la panne résultant d’une cause interne de l’appareil et nuisant à son bon fonctionnement (garantie 7 ans de l'appareil)",
    parameterGroup: 'Vos biens personnels',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'natural disaster',
    parameterDisplayName: 'Catastrophes Naturelles',
    parameterDescription:
      "La garantie catastrophe naturelle de l’assurance habitation a pour but de vous couvrir et de vous indemniser en cas de dommages matériels causés directement à vos biens par les événements tels qu'un tremblement de terre ; Une inondation ; Une coulée de boue ; Une période de sécheresse ou la réhydratation des sols ; L’action mécanique des vagues, un raz-de-marée, un tsunami ; Une avalanche, une éruption volcanique.",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'terrorism',
    parameterDisplayName: 'Attentats',
    parameterDescription:
      'Cette garantie s’applique dans les mêmes conditions et limites que la garantie incendie. Elle vient couvrir les dommages directs causés par un attentat ou un acte de terrorisme.',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'private liability',
    parameterDisplayName: 'Responsabilité Civile Vie Privée',
    parameterDescription:
      'Cette garantie couvre non seulement les dommages matériels et immatériels mais aussi corporels causés à un tiers, par le souscripteur ou les assurés à partir du moment où ces dommages ne sont pas intentionnelles dans la limite des plafonds fixés dans votre contrat.',
    parameterGroup: 'Vous et votre famille',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'electric equipment',
    parameterDisplayName: 'Dommages Électriques aux appareils',
    parameterDescription:
      "L'assurance dommages électriques couvre vos appareils électriques utilisés au quotidien (machine à laver, four, lave-vaisselle…) et qui auraient été détériorés par une surtension. Cette surtension doit être causée par une défaillance sur le réseau électrique ou par la foudre.",
    parameterGroup: 'Vos biens personnels',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'storm',
    parameterDisplayName: 'Tempête, Grêle, Neige',
    parameterDescription:
      "La garantie évènements climatiques couvre les dommages matériels causés par des événements liés au climat dont l'intensité est exceptionnelle (tempête, grêle ou encore neige)",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'outdoor',
    parameterDisplayName: 'Mobiliers extérieurs',
    parameterDescription:
      "Couvre certains mobiliers situés à l'extérieur de votre habitation lors d'évènements garantis au contrat",
    parameterGroup: 'Vos biens personnels',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'quote',
    parameterDisplayName: 'Aide au devis travaux',
    parameterDescription:
      'Analyse des devis de réparation et préconisation de la solution la mieux adaptée lorsque vous faites appel à un des prestataires tels que : Plombier Menuisier Vitrier Chauffagiste Maçon Électricien Peintre',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'fire',
    parameterDisplayName: 'Incendie et Événements assimilés',
    parameterDescription:
      "La garantie incendie vous couvre en cas de dommages causés par le feu à votre logement ou à vos biens. Sont également couverts, notamment : l'explosion ou une implosion liée à cet incendie ; la chute de la foudre ainsi que la surtension engendrée sur les canalisations électriques, les installations de chauffage, de climatisation ou d’alarme ; La combustion et la fumée ; Le choc d’un véhicule terrestre ou d’un appareil de navigation aérienne n’appartenant pas à l’assuré ; L’intervention des secours et premiers sauvetages : porte fracturée ou mobilier endommagé par une lance à incendie par exemple.",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'building liability',
    parameterDisplayName: 'Responsabilité Civile Habitation',
    parameterDescription:
      'Cette garantie couvre votre responsabilité civile à l’égard des tiers lorsque les dommages corporels, matériels et immatériels consécutifs qu’ils ont subis ont été occasionnés, rendus possibles ou aggravés en raison de l’existence des biens immobiliers assurés, leurs terrains, y compris terrain nu, inférieur à 5 hectares et dont vous êtes propriétaire.',
    parameterGroup: 'Vous et votre famille',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'exterior',
    parameterDisplayName: 'Aménagements extérieurs',
    parameterDescription:
      'Cette garantie vient couvrir les éléments extérieurs fixés de manière permanente tels que, notamment : clôtures végétales, les arbres et arbustes plantés dans le sol, les murs de soutènement, les terrasses non accolées au bâtiment à usage d’habitation ou ne formant pas toiture, chemins, voies d’accès, escaliers extérieurs non accolés aux bâtiments, terrains de sport (court de tennis...), portiques de jeux, bassins, puits, fontaines, leurs accessoires,',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'craftsman',
    parameterDisplayName: 'Mise en relation avec des artisans agréés',
    parameterDescription:
      'En dehors de tout sinistre, votre assureur vous met en relation avec des prestataires sur appel tel que : Plombier Menuisier Vitrier Chauffagiste Maçon Électricien, Peintre',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'cash',
    parameterDisplayName: 'Fonds et Valeurs',
    parameterDescription:
      "Cette garantie permet l'indemnisation des billets de banque ou pièces de monnaie ayant cours légal lors d'évènements garantis",
    parameterGroup: 'Vos biens personnels',
    parameterGroupOrder: 3,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'tennis',
    parameterDisplayName: 'Cours de Tennis',
    parameterDescription:
      "Cette garantie couvre votre court de tennis contre les dommages pouvant survenir lors d'évènements garantis.",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'travel',
    parameterDisplayName: 'Assistance Voyage',
    parameterDescription:
      "Garantie vous permettant de bénéficier d'une assistance en cas de blessures ou du décès d'un des assurés lors d'un voyage",
    parameterGroup: 'Vous et votre famille',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'flood',
    parameterDisplayName: 'Inondation',
    parameterDescription:
      'Garantie les dommages matériels causés aux biens assurés par une inondation dans la mesure où elle n’a pas fait l’objet d’un arrêté de catastrophes naturelles',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'pool',
    parameterDisplayName: 'Piscine',
    parameterDescription:
      'Cette garantie couvre votre piscine et ses équipements notamment contre les risques climatiques, les dommages électriques, les inondations, les actes de vandalisme…',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'professional',
    parameterDisplayName: 'Matériel Professionnel',
    parameterDescription:
      "Garantie couvrant le matériel professionnel entreposé au domicile assuré lors d'un évènement garanti",
    parameterGroup: 'Vos biens personnels',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'emergency',
    parameterDisplayName: 'Urgence après sinistre',
    parameterDescription:
      "Ensemble de prestations d'intervention d'urgence suite à un sinistre garanti tels que notamment : Retour d’urgence de l’assuré sur le lieu de l’habitation sinistrée, Hébergement d’urgence des assurés, Gardiennage de l’habitation sinistrée, Remplacement des effets personnels de première nécessité, Avance des fonds...",
    parameterGroup: 'Vous et votre famille',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'burglary',
    parameterDisplayName: 'Vol et Vandalisme',
    parameterDescription:
      'Cette garantie vous indemnise si vos biens sont volés ou endommagés après un cambriolage : meubles, vêtements, matériel informatique, multimédia et objets de valeur.',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'attorney',
    parameterDisplayName: 'Défense Pénale et Recours',
    parameterDescription:
      "Cette garantie intervient si vous et/ou votre famille êtes victimes d'un dommage physique ou matériel occasionné par un tiers, ou êtes mis en cause. Elle vous permet également de lancer un recours contre un tiers identifié lors d'un litige.",
    parameterGroup: 'Vous et votre famille',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'technological disaster',
    parameterDisplayName: 'Catastrophes Technologiques',
    parameterDescription:
      'Incluse dans tous les contrats d’assurance multirisques habitation, elle est indispensable pour être couvert en cas d’accident industriel majeur.',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'glass equipment',
    parameterDisplayName: 'Bris de glace électroménager',
    parameterDescription:
      'Couverture du bris accidentel des parties vitrées des appareils électroménagers (ex: portes de four, plaques de cuisson, hottes, caves à vin) ou de vos meubles meublants (ex: tables, bureaux, bibliothèques, vitrines, miroirs, aquariums).',
    parameterGroup: 'Vos biens personnels',
    parameterGroupOrder: 3,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'food',
    parameterDisplayName: 'Perte de denrées alimentaires',
    parameterDescription:
      'Couvre la perte des denrées alimentaires destinées à la consommation familiale, contenues dans le congélateur et/ou le réfrigérateur situé au lieu d’assurance, consécutives à une variation de température résultant d’un arrêt accidentel de fonctionnement du congélateur et/ou réfrigérateur',
    parameterGroup: 'Vos biens personnels',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'water',
    parameterDisplayName: 'Dégât des eaux',
    parameterDescription:
      "La garantie dégâts des eaux couvre les dommages consécutifs à une action de l'eau, hors inondation : fuite, débordement, infiltration.",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'sustainable',
    parameterDisplayName: 'Equipements de developpement durable',
    parameterDescription:
      "Cette garantie vient couvrir les équipements d'énergies renouvelables tels que, notamment : les pompes à chaleur géo- thermiques et les équipements de fourniture d’eau ou d’électricité fonctionnant à l’énergie électrique, solaire, éolienne, géothermique, aérothermique ou hydraulique.",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'refurbishing',
    parameterDisplayName: 'Rééquipement à Neuf',
    parameterDescription:
      'La garantie rééquipement à neuf, permet de bénéficier d’une indemnisation plus complète : l’assurance vous indemnisera, selon les prix du marché, à hauteur de la valeur actuelle d’un bien similaire neuf sans appliquer de taux de vétusté.',
    parameterGroup: 'Vos biens personnels',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'loanback',
    parameterDisplayName: 'Remboursement crédit immobilier',
    parameterDescription:
      'Cette garantie intervient dans le remboursement des échéances de votre prêt immobilier en cours, en cas de sinistre garanti, rendant votre résidence principale inhabitable.',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'legal',
    parameterDisplayName: 'Protection juridique',
    parameterDescription:
      "La garantie protection juridique est une garantie qui vous permet d'obtenir des informations juridiques sur un sujet ou de trouver une solution amiable dans un litige qui vous oppose à un tiers. Elle peut être partielle ou totale.",
    parameterGroup: 'Vous et votre famille',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'building electric',
    parameterDisplayName: 'Dommages Electriques aux bâtiments',
    parameterDescription:
      'La garantie inclut les frais de remise en état des détériorations immobilières nécessaires à la réparation des conducteurs électriques.',
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'glass',
    parameterDisplayName: 'Bris de glace',
    parameterDescription:
      "La garantie bris de glace vous permet d'etre remboursé en cas de casse accidentelle d'une surface vitrée (fenêtre, baie vitrée, balcon, etc.)",
    parameterGroup: 'Votre habitation',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'robbery',
    parameterDisplayName: 'Vol Hors domicile',
    parameterDescription:
      'Garantie couvrant le vol à l’arraché ou en cas de vol avec agression en tout lieu, vos objets de loisirs.',
    parameterGroup: 'Vos biens personnels',
    parameterGroupOrder: 3,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'devices',
    parameterDisplayName: 'Appareils Nomades',
    parameterDescription:
      "Garantie contre la casse accidentelle tous risque des appareils nomades au domicile comme à l'extérieur",
    parameterGroup: 'Vos biens personnels',
    parameterGroupOrder: 3,
  },
];

const carParametersAllRisksFr: Array<InsuranceParameter> = [
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'breakdown',
    parameterOrder: 0,
    parameterDisplayName: 'Assistance',
    parameterDescription:
      "Assistance dépannage en cas d'accident ou de panne prévoyant la plupart du temps, une franchise kilométrique au départ de à votre lieu d'habitation",
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'terrorism',
    parameterOrder: 0,
    parameterDisplayName: 'Attentats',
    parameterDescription:
      'Les dommages causés à votre véhicule suite à un acte de terrorisme ou un attentat sont couvert par cette garantie.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'glass',
    parameterOrder: 0,
    parameterDisplayName: 'Bris de glace',
    parameterDescription:
      'Prise en charge du remplacement de toutes les parties vitrées de vorte véhicule',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'glassWindshield',
    parameterOrder: 0,
    parameterDisplayName: 'Bris de pare-brise',
    parameterDescription: 'Prise en charge des dommages subit uniquement par le pare-brise',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'naturalDisaster',
    parameterOrder: 0,
    parameterDisplayName: 'Catastrophes naturelles',
    parameterDescription:
      'La garantie catastrophe naturelle couvre les dommages matériels subis par le véhicule assuré à la suite d’un événement naturel de forte ampleur.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'technologicalDisaster',
    parameterOrder: 0,
    parameterDisplayName: 'Catastrophes technologiques',
    parameterDescription:
      "Cette garantie couvre votre véhicule en cas d'accident industriel majeur.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'content',
    parameterOrder: 0,
    parameterDisplayName: 'Contenu du véhicule',
    parameterDescription: "Vos effets personnels sont couverts lors d'un évènement garanti",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'attorney',
    parameterOrder: 0,
    parameterDisplayName: 'Défense Pénale et Recours Suite à Accident',
    parameterDescription:
      "En cas de litige, cette garantie couvre vos frais de défense ou de recours suite à un accident, dans le but d'être dédommagé des frais juridiques engagés par l'assuré automobile.",
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'breakdownPremium',
    parameterOrder: 0,
    parameterDisplayName: 'Dépannage 0 km',
    parameterDescription:
      "Assistance dépannage en cas d'accident ou de pane sans franchise kilométrique",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'storm',
    parameterOrder: 0,
    parameterDisplayName: 'Evénements climatiques',
    parameterDescription:
      "La garantie évènements climatiques couvre les dommages matériels causés par des événements liés au climat dont l'intensité est exceptionnelle (tempête, grêle ou encore neige)",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'driver',
    parameterOrder: 0,
    parameterDisplayName: 'Garantie du Conducteur',
    parameterDescription:
      'La garantie personnelle du conducteur en assurance auto permet à un assuré d’être indemnisé pour les dommages corporels qu’il pourrait subir dans un accident de la route.',
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'fire',
    parameterOrder: 0,
    parameterDisplayName: 'Incendie',
    parameterDescription:
      'La garantie incendie couvre votre véhicule contre les dommages liés à un incendie ou à une explosion.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'professionals',
    parameterOrder: 0,
    parameterDisplayName: 'Marchandises et outillages professionnels',
    parameterDescription:
      "À l’occasion d’un événement garanti, cette garantie vous permet d'être indemnisé pour vos effets professionnels transportés dans ou sur le véhicule assuré ou bien arrimés à celui-ci.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'electricVehiclePowerOutage',
    parameterOrder: 0,
    parameterDisplayName: 'Panne d’énergie',
    parameterDescription:
      "En cas de batterie déchargée immobilisant le véhicule, couverture pour le dépannage ou le remorquage jusqu'à la borne de recharge la plus proche",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'mechanicalBreakdown',
    parameterOrder: 0,
    parameterDisplayName: 'Panne mécanique',
    parameterDescription:
      'Prise en charge de la remise en état ou le changement des organes et pièces listés ci-après reconnus défectueux à la suite d’une panne garantie en cas de panne mécanique',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'purchaseValue',
    parameterOrder: 0,
    parameterDisplayName: "Prolongation de la valeur d'achat",
    parameterDescription:
      "Votre véhicule est indemnisé sellon sa valeur d'achat ou son indemnisation est majorée à la hausee par rapport à son prix de remplacement",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'liability',
    parameterOrder: 0,
    parameterDisplayName: 'Responsabilité Civile',
    parameterDescription:
      'La responsabilité civile est la protection minimum comprise dans tous les contrats d’assurance auto. Il s’agit d’une garantie obligatoire. Elle permet d’indemniser les dommages causés à une tierce personne en cas d’accident responsable.',
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'allAccidents',
    parameterOrder: 0,
    parameterDisplayName: 'Tous accidents',
    parameterDescription:
      "La garantie tous risques vous permet d'être indemnisé pour tous les dommages subis par votre véhicule, quel que soit le type d'accident et quelle que soit votre responsabilité en tant que conducteur.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'vandalism',
    parameterOrder: 0,
    parameterDisplayName: 'Vandalism',
    parameterDescription:
      'Couvre les dommages matériels infligés à votre véhicule suite à des actes de vandalisme, tels que les graffitis, les rayures, le bris de vitres, les pneus crevés, ou les dommages intentionnels de la carrosserie.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'theft',
    parameterOrder: 0,
    parameterDisplayName: 'Vol',
    parameterDescription:
      "Celle-ci permet d'être indemnisé en cas de vol ou de tentative de vol de votre véhicule. Elle peut, dans une certaine mesure, assurer également le contenu du véhicule selon les termes du contrat.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'replacementVehicle',
    parameterOrder: 0,
    parameterDisplayName: 'Véhicule de remplacement',
    parameterDescription: "Mise à disposition d'un véhicule de remplacement temporaire",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
];

const carParametersThirdPartyPlus: Array<InsuranceParameter> = [
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'breakdown',
    parameterOrder: 0,
    parameterDisplayName: 'Assistance',
    parameterDescription:
      "Assistance dépannage en cas d'accident ou de panne prévoyant la plupart du temps, une franchise kilométrique au départ de à votre lieu d'habitation",
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'terrorism',
    parameterOrder: 0,
    parameterDisplayName: 'Attentats',
    parameterDescription:
      'Les dommages causés à votre véhicule suite à un acte de terrorisme ou un attentat sont couvert par cette garantie.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'glass',
    parameterOrder: 0,
    parameterDisplayName: 'Bris de glace',
    parameterDescription:
      'Prise en charge du remplacement de toutes les parties vitrées de vorte véhicule',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'glassWindshield',
    parameterOrder: 0,
    parameterDisplayName: 'Bris de pare-brise',
    parameterDescription: 'Prise en charge des dommages subit uniquement par le pare-brise',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'naturalDisaster',
    parameterOrder: 0,
    parameterDisplayName: 'Catastrophes naturelles',
    parameterDescription:
      'La garantie catastrophe naturelle couvre les dommages matériels subis par le véhicule assuré à la suite d’un événement naturel de forte ampleur.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'technologicalDisaster',
    parameterOrder: 0,
    parameterDisplayName: 'Catastrophes technologiques',
    parameterDescription:
      "Cette garantie couvre votre véhicule en cas d'accident industriel majeur.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'content',
    parameterOrder: 0,
    parameterDisplayName: 'Contenu du véhicule',
    parameterDescription: "Vos effets personnels sont couverts lors d'un évènement garanti",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'attorney',
    parameterOrder: 0,
    parameterDisplayName: 'Défense Pénale et Recours Suite à Accident',
    parameterDescription:
      "En cas de litige, cette garantie couvre vos frais de défense ou de recours suite à un accident, dans le but d'être dédommagé des frais juridiques engagés par l'assuré automobile.",
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'breakdownPremium',
    parameterOrder: 0,
    parameterDisplayName: 'Dépannage 0 km',
    parameterDescription:
      "Assistance dépannage en cas d'accident ou de pane sans franchise kilométrique",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'storm',
    parameterOrder: 0,
    parameterDisplayName: 'Evénements climatiques',
    parameterDescription:
      "La garantie évènements climatiques couvre les dommages matériels causés par des événements liés au climat dont l'intensité est exceptionnelle (tempête, grêle ou encore neige)",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'driver',
    parameterOrder: 0,
    parameterDisplayName: 'Garantie du Conducteur',
    parameterDescription:
      'La garantie personnelle du conducteur en assurance auto permet à un assuré d’être indemnisé pour les dommages corporels qu’il pourrait subir dans un accident de la route.',
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'fire',
    parameterOrder: 0,
    parameterDisplayName: 'Incendie',
    parameterDescription:
      'La garantie incendie couvre votre véhicule contre les dommages liés à un incendie ou à une explosion.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'professionals',
    parameterOrder: 0,
    parameterDisplayName: 'Marchandises et outillages professionnels',
    parameterDescription:
      "À l’occasion d’un événement garanti, cette garantie vous permet d'être indemnisé pour vos effets professionnels transportés dans ou sur le véhicule assuré ou bien arrimés à celui-ci.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'electricVehiclePowerOutage',
    parameterOrder: 0,
    parameterDisplayName: 'Panne d’énergie',
    parameterDescription:
      "En cas de batterie déchargée immobilisant le véhicule, couverture pour le dépannage ou le remorquage jusqu'à la borne de recharge la plus proche",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'mechanicalBreakdown',
    parameterOrder: 0,
    parameterDisplayName: 'Panne mécanique',
    parameterDescription:
      'Prise en charge de la remise en état ou le changement des organes et pièces listés ci-après reconnus défectueux à la suite d’une panne garantie en cas de panne mécanique',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'purchaseValue',
    parameterOrder: 0,
    parameterDisplayName: "Prolongation de la valeur d'achat",
    parameterDescription:
      "Votre véhicule est indemnisé sellon sa valeur d'achat ou son indemnisation est majorée à la hausee par rapport à son prix de remplacement",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'liability',
    parameterOrder: 0,
    parameterDisplayName: 'Responsabilité Civile',
    parameterDescription:
      'La responsabilité civile est la protection minimum comprise dans tous les contrats d’assurance auto. Il s’agit d’une garantie obligatoire. Elle permet d’indemniser les dommages causés à une tierce personne en cas d’accident responsable.',
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'allAccidents',
    parameterOrder: 0,
    parameterDisplayName: 'Tous accidents',
    parameterDescription:
      "La garantie tous risques vous permet d'être indemnisé pour tous les dommages subis par votre véhicule, quel que soit le type d'accident et quelle que soit votre responsabilité en tant que conducteur.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'vandalism',
    parameterOrder: 0,
    parameterDisplayName: 'Vandalism',
    parameterDescription:
      'Couvre les dommages matériels infligés à votre véhicule suite à des actes de vandalisme, tels que les graffitis, les rayures, le bris de vitres, les pneus crevés, ou les dommages intentionnels de la carrosserie.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'theft',
    parameterOrder: 0,
    parameterDisplayName: 'Vol',
    parameterDescription:
      "Celle-ci permet d'être indemnisé en cas de vol ou de tentative de vol de votre véhicule. Elle peut, dans une certaine mesure, assurer également le contenu du véhicule selon les termes du contrat.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'replacementVehicle',
    parameterOrder: 0,
    parameterDisplayName: 'Véhicule de remplacement',
    parameterDescription: "Mise à disposition d'un véhicule de remplacement temporaire",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
];

const carParametersThirdParty: Array<InsuranceParameter> = [
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'breakdown',
    parameterOrder: 0,
    parameterDisplayName: 'Assistance',
    parameterDescription:
      "Assistance dépannage en cas d'accident ou de panne prévoyant la plupart du temps, une franchise kilométrique au départ de à votre lieu d'habitation",
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'terrorism',
    parameterOrder: 0,
    parameterDisplayName: 'Attentats',
    parameterDescription:
      'Les dommages causés à votre véhicule suite à un acte de terrorisme ou un attentat sont couvert par cette garantie.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'glass',
    parameterOrder: 0,
    parameterDisplayName: 'Bris de glace',
    parameterDescription:
      'Prise en charge du remplacement de toutes les parties vitrées de vorte véhicule',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'glassWindshield',
    parameterOrder: 0,
    parameterDisplayName: 'Bris de pare-brise',
    parameterDescription: 'Prise en charge des dommages subit uniquement par le pare-brise',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'naturalDisaster',
    parameterOrder: 0,
    parameterDisplayName: 'Catastrophes naturelles',
    parameterDescription:
      'La garantie catastrophe naturelle couvre les dommages matériels subis par le véhicule assuré à la suite d’un événement naturel de forte ampleur.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'technologicalDisaster',
    parameterOrder: 0,
    parameterDisplayName: 'Catastrophes technologiques',
    parameterDescription:
      "Cette garantie couvre votre véhicule en cas d'accident industriel majeur.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'content',
    parameterOrder: 0,
    parameterDisplayName: 'Contenu du véhicule',
    parameterDescription: "Vos effets personnels sont couverts lors d'un évènement garanti",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'attorney',
    parameterOrder: 0,
    parameterDisplayName: 'Défense Pénale et Recours Suite à Accident',
    parameterDescription:
      "En cas de litige, cette garantie couvre vos frais de défense ou de recours suite à un accident, dans le but d'être dédommagé des frais juridiques engagés par l'assuré automobile.",
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'breakdownPremium',
    parameterOrder: 0,
    parameterDisplayName: 'Dépannage 0 km',
    parameterDescription:
      "Assistance dépannage en cas d'accident ou de pane sans franchise kilométrique",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'storm',
    parameterOrder: 0,
    parameterDisplayName: 'Evénements climatiques',
    parameterDescription:
      "La garantie évènements climatiques couvre les dommages matériels causés par des événements liés au climat dont l'intensité est exceptionnelle (tempête, grêle ou encore neige)",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'driver',
    parameterOrder: 0,
    parameterDisplayName: 'Garantie du Conducteur',
    parameterDescription:
      'La garantie personnelle du conducteur en assurance auto permet à un assuré d’être indemnisé pour les dommages corporels qu’il pourrait subir dans un accident de la route.',
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'fire',
    parameterOrder: 0,
    parameterDisplayName: 'Incendie',
    parameterDescription:
      'La garantie incendie couvre votre véhicule contre les dommages liés à un incendie ou à une explosion.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'professionals',
    parameterOrder: 0,
    parameterDisplayName: 'Marchandises et outillages professionnels',
    parameterDescription:
      "À l’occasion d’un événement garanti, cette garantie vous permet d'être indemnisé pour vos effets professionnels transportés dans ou sur le véhicule assuré ou bien arrimés à celui-ci.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'electricVehiclePowerOutage',
    parameterOrder: 0,
    parameterDisplayName: 'Panne d’énergie',
    parameterDescription:
      "En cas de batterie déchargée immobilisant le véhicule, couverture pour le dépannage ou le remorquage jusqu'à la borne de recharge la plus proche",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'mechanicalBreakdown',
    parameterOrder: 0,
    parameterDisplayName: 'Panne mécanique',
    parameterDescription:
      'Prise en charge de la remise en état ou le changement des organes et pièces listés ci-après reconnus défectueux à la suite d’une panne garantie en cas de panne mécanique',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'purchaseValue',
    parameterOrder: 0,
    parameterDisplayName: "Prolongation de la valeur d'achat",
    parameterDescription:
      "Votre véhicule est indemnisé sellon sa valeur d'achat ou son indemnisation est majorée à la hausee par rapport à son prix de remplacement",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'liability',
    parameterOrder: 0,
    parameterDisplayName: 'Responsabilité Civile',
    parameterDescription:
      'La responsabilité civile est la protection minimum comprise dans tous les contrats d’assurance auto. Il s’agit d’une garantie obligatoire. Elle permet d’indemniser les dommages causés à une tierce personne en cas d’accident responsable.',
    parameterGroup: 'Vous et vos passengers',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'allAccidents',
    parameterOrder: 0,
    parameterDisplayName: 'Tous accidents',
    parameterDescription:
      "La garantie tous risques vous permet d'être indemnisé pour tous les dommages subis par votre véhicule, quel que soit le type d'accident et quelle que soit votre responsabilité en tant que conducteur.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'vandalism',
    parameterOrder: 0,
    parameterDisplayName: 'Vandalism',
    parameterDescription:
      'Couvre les dommages matériels infligés à votre véhicule suite à des actes de vandalisme, tels que les graffitis, les rayures, le bris de vitres, les pneus crevés, ou les dommages intentionnels de la carrosserie.',
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'theft',
    parameterOrder: 0,
    parameterDisplayName: 'Vol',
    parameterDescription:
      "Celle-ci permet d'être indemnisé en cas de vol ou de tentative de vol de votre véhicule. Elle peut, dans une certaine mesure, assurer également le contenu du véhicule selon les termes du contrat.",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
  {
    value: 'add-on',
    isAddonPurchased: false,
    parameterName: 'replacementVehicle',
    parameterOrder: 0,
    parameterDisplayName: 'Véhicule de remplacement',
    parameterDescription: "Mise à disposition d'un véhicule de remplacement temporaire",
    parameterGroup: 'Votre véhicule',
    parameterGroupOrder: 2,
  },
];

export function getParametersFR({
  insuranceSubType,
  insuranceName,
}: {
  insuranceSubType: InsuranceSubType;
  insuranceName: InsurancePackageName;
}) {
  switch (insuranceSubType) {
    case InsuranceSubType.condoInsurance:
      return condoParameters;
    case InsuranceSubType.carInsurance:
      if (insuranceName === FrenchCarInsuranceName['formule tiers +']) {
        return carParametersThirdPartyPlus;
      }
      if (insuranceName === FrenchCarInsuranceName['formule tiers']) {
        return carParametersThirdParty;
      }
      return carParametersAllRisksFr;
    default:
      return condoParameters;
  }
}

import { InsuranceParameter } from '@main/types';

export const fakeNewEstonianCarParameters: Array<InsuranceParameter> = [
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'twentyFourSevenAssistance',
    parameterOrder: 0,
    parameterDisplayName: 'Autoabi 24/7',
    parameterDescription: 'Autoabi on 24/7 kättesaadav',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'replacementCarRepair',
    parameterOrder: 0,
    parameterDisplayName: 'asendusauto',
    parameterDescription:
      'See kaitse hüvitab asendusauto kulu, kui sinu enda oma on kindlustusjuhtumi tõttu remondis. ',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'trailer',
    parameterOrder: 0,
    parameterDisplayName: 'haagis',
    parameterDescription:
      'Hüvitatakse haagisele tekitatud kahju juhul, kui haagis oli juhtumi toimumise hetkel haagitud kindlustatud sõiduki külge',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'thirdPartyLiabilityPerson',
    parameterOrder: 0,
    parameterDisplayName: 'isik',
    parameterDescription:
      'Kohustusliku liikluskindlustuse lepingu alusel kahju põhjustanud sõidukijuhi ravikulu raviasutuses',
    parameterGroup: 'Põhikaitse',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'deductibleDiscountGlass',
    parameterOrder: 0,
    parameterDisplayName: 'klaasikahjud(0)',
    parameterDescription: 'Klaasikahjudele kehtib 0 omavastutus',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'fullRisk',
    parameterOrder: 0,
    parameterDisplayName: 'kogurisk',
    parameterDescription:
      'Õnnetusjuhtum - hüvitatakse sõiduki kahjustumise või hävimine ettenägematu sündmuse tagajärjel – liiklusõnnetus, loodusõnnetus, sõiduki osade kahjustumine.\nVargus - hüvitatakse sõiduki või selle osade varguse või röövimise kahjud.\nVandalism - hüvitatakse kolmandate isikute poolt sõidukile tahtlikult tekitatud kahjud.\nTulekahju - hüvitatakse sõiduki kahjustumise või hävimise tule tõttu, sh kolmandate isikute poolt süütamise, suitsu, tahma või kustutustööde tõttu.',
    parameterGroup: 'Põhikaitse',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'chargingStation',
    parameterOrder: 0,
    parameterDisplayName: 'laadimisjaam',
    parameterDescription:
      'Hüvitatakse laadimisjaama kahjustumise, kadumise või hävimisega seotud kulud',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'pet',
    parameterOrder: 0,
    parameterDisplayName: 'lemmikloom',
    parameterDescription:
      'Hüvitame kahju, mis on tekkinud kuni kahe sõidukis viibinud lemmiklooma vigastuse või surma tõttu, mille tingis liiklusõnnetus',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'leasingPayment',
    parameterOrder: 0,
    parameterDisplayName: 'liisingmaksed',
    parameterDescription:
      'Liisingmaksete kindlustusmaksete hüvitamine teatud ajad jooksul, juhul kui kindlustatud isik on töövõimetu.',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'leasingValue',
    parameterOrder: 0,
    parameterDisplayName: 'liisingväärtus',
    parameterDescription:
      'Liisinguväärtuse kindlustus - hüvitatakse sõiduki liisingu jääkväärtus, kui sõiduki jääkväärtus ületab turuväärtust.',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'vehicleRemovableExtras',
    parameterOrder: 0,
    parameterDisplayName: 'lisaosad',
    parameterDescription:
      'Sõidukiga koos on kindlustatud lasteiste, katuseboks, katuseraam ja jalgrattahoidja',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'extendedDealerRepair',
    parameterOrder: 0,
    parameterDisplayName: 'margiesindus',
    parameterDescription:
      'Sõiduki remondi kulude hüvitamine margiesinduses ka siis, kui garantiiaeg on lõppenud.',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'deductibleDiscountAnimalCollision',
    parameterOrder: 0,
    parameterDisplayName: 'metsloom',
    parameterDescription: 'Kokkupõrkel metsloomaga omavastutus 0',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'claimHandlingPossibilityThroughOwnInsuranceCompany',
    parameterOrder: 0,
    parameterDisplayName: 'oma selts',
    parameterDescription:
      'Sa ei pea liiklusõnnetuse korral süüdlase seltsiga asju ajama, vaid saad minna otse oma seltsi poolt soovitatud remonditöökotta;',
    parameterGroup: 'Põhikaitse',
    parameterGroupOrder: 1,
  },
  {
    value: 'possible',
    isAddonPurchased: false,
    parameterName: 'personalLuggage',
    parameterOrder: 0,
    parameterDisplayName: 'pagas',
    parameterDescription:
      'Kui sinu asjad lukustatud sõidukist varastatakse murdvarguse teel, hüvitatakse varastatud asjade taassoetamise maksumus.',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'deductibleDiscountExternalMirrors',
    parameterOrder: 0,
    parameterDisplayName: 'peeglid',
    parameterDescription:
      'Hüvitatakse sõiduki välispeeglite ja nende korpuste remondi või asendamise kulu ilma omavastutuseta.',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'towingServiceAccident',
    parameterOrder: 0,
    parameterDisplayName: 'puksiir',
    parameterDescription: 'Tasuta puksiirabi liikluskindlustuse juhtumi korral',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'travelInteruption',
    parameterOrder: 0,
    parameterDisplayName: 'reisikatkestus',
    parameterDescription:
      'Kui ootamatu juhtumi (näiteks sõiduki tehnilise rikke, varguse või võtmete kadumise) tõttu ei ole võimalik reisi jätkata, hüvitatakse mõistlikud koju naasmise kulud. Kui samal päeval ei ole võimalik tagasi sõita, siis ka majutuskulud. ',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'deductibleDiscountRentalCar',
    parameterOrder: 0,
    parameterDisplayName: 'rendiauto',
    parameterDescription: 'Sõiduki rendilepingusse märgitud omavastutuse hüvitamine',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'thirdPartyLiabilityCar',
    parameterOrder: 0,
    parameterDisplayName: 'sõiduk',
    parameterDescription:
      'Kohustusliku liikluskindlustuse lepingu alusel vastutus poliisil märgitud sõidukiga kindlustusjuhtumi läbi tekitatud kahju eest',
    parameterGroup: 'Põhikaitse',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'technicalIssue',
    parameterOrder: 0,
    parameterDisplayName: 'tehniline rike',
    parameterDescription:
      'Teatud tingimustel mootori ja elektroonika vigade kaitse ootamatu ja ettenägematu sündmuse tagajärjel.',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'animalCollision',
    parameterOrder: 0,
    parameterDisplayName: 'valikulised lisakaitsed',
    parameterDescription:
      'Võimalus liikluskindlustuse juurde valida mitmeid lisakaitseid: sõiduki õigusabikulude kindlustus, metsloomale otsasõit, autoabi, sõidukijuhi õnnetusjuhtumi kindlustus, asendusauto vms ',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'wheelsAndTires',
    parameterOrder: 0,
    parameterDisplayName: 'veljed&rehvid',
    parameterDescription:
      'Üks lisa komplekt sõiduki velgesid ja rehve, mida hoitakse lukustatud ruumis ',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'lossOfKeys',
    parameterOrder: 0,
    parameterDisplayName: 'votmed',
    parameterDescription: 'Sõiduki võtmete või pultide kaotamine või hävimine',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'legal',
    parameterOrder: 0,
    parameterDisplayName: 'õigusabikulud',
    parameterDescription: 'Kindlustusjuhtumi tõttu tekkinud õigusabikulude hüvitamine',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'driverPassengerAccident',
    parameterOrder: 0,
    parameterDisplayName: 'õnnetusjuhtum',
    parameterDescription:
      'Kui liiklusõnnetuse tagajärjel saab juht või kaasreisija püsiva tervisekahjustuse või sureb, makstakse ühekordne hüvitis.',
    parameterGroup: 'Lisakaitse',
    parameterGroupOrder: 2,
  },
];

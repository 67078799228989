import { CarAddOns, CarInsuranceName, CarQuotePayload } from '@main/types/api-client';

import { AddOn, Deductible, InsuranceSubType } from '@main/types/insurance';
import { SwedishVehicleCoverage } from '@main/types/insurance/sweden';

import { isCarInsuranceSE } from '@main/utils/typeAssertions';

import { MappedInsuranceValues } from '../types';

export const icaCarCoverageMap = (
  coverage?: SwedishVehicleCoverage,
): CarInsuranceName | undefined => {
  switch (coverage) {
    case SwedishVehicleCoverage.FULL_WITH_CHASSI:
      return CarInsuranceName.Helförsäkring;
    case SwedishVehicleCoverage.FULL_WITHOUT_CHASSI:
      return CarInsuranceName.Halvförsäkring;
    case SwedishVehicleCoverage.TRAFFIC:
      return CarInsuranceName.Trafikförsäkring;
    case SwedishVehicleCoverage.NOT_IN_TRAFFIC:
    case SwedishVehicleCoverage.UNDEFINED:
    default:
      return undefined;
  }
};

export const getIcaCarMaxMilageFromValue = (maxMilage?: number) => {
  if (!maxMilage) return undefined;
  switch (true) {
    case maxMilage <= 10000:
      return '-10000';
    case maxMilage <= 15000:
      return '10000-15000';
    case maxMilage <= 20000:
      return '15000-20000';
    case maxMilage <= 25000:
      return '20000-25000';
    default:
      return '25000-';
  }
};

export const getIcaCarDeductibleHullFromValue = (deductibles?: Deductible[]) => {
  const deductibleHullValue = deductibles?.find(
    (deductible) => deductible.deductibleId === 'deductibleVehicleDamage',
  );
  if (deductibleHullValue?.deductibleAmount) {
    switch (true) {
      case deductibleHullValue.deductibleAmount > 5000:
        return '6000';
      case deductibleHullValue.deductibleAmount > 4000:
        return '4500';
      default:
        return '3500';
    }
  }
  return undefined;
};

const getIcaCarAddonFromValue = (addon?: AddOn): CarAddOns | undefined => {
  if (!addon) return undefined;
  switch (addon.addOnId) {
    case CarAddOns.addOnAssistance:
      return CarAddOns.addOnAssistance;
    case CarAddOns.addOnReducedDeductible:
      return CarAddOns.addOnReducedDeductible;
    case CarAddOns.addOnRental:
      return CarAddOns.addOnRental;
    default:
      return undefined;
  }
};

export const getIcaCarAddonsFromValue = (addons?: AddOn[]) => {
  if (!addons) return [];
  return addons?.reduce((parsedAddons: CarAddOns[], addon) => {
    const parsedAddon = getIcaCarAddonFromValue(addon);
    if (!parsedAddon) return parsedAddons;
    return [...parsedAddons, parsedAddon];
  }, []);
};

export const carInsuranceToIcaValues = (
  insuranceCompany: AvailableInsuranceCompaniesCheckout,
  policy?: InsurancePolicy,
): MappedInsuranceValues<CarQuotePayload> | undefined => {
  if (!policy) return undefined;
  if (isCarInsuranceSE(policy.insurance) && policy.insurance.registrationNo) {
    const coverage = icaCarCoverageMap(policy.insurance.coverage);
    if (!coverage) return undefined;
    return {
      mappedInsuranceValues: {
        insuranceCompany,
        insuranceSubType: InsuranceSubType.carInsurance,
        insuranceHolderInformation: {
          socialSecurityNumber: policy.personalInformation.PERSONAL_NUMBER,
        },
        insuredObjectDetails: { registrationNumber: policy.insurance.registrationNo },
        insuranceQuoteDetails: {
          insuranceName: coverage,
          ...(coverage === 'Helförsäkring' && {
            deductibleVehicleDamage: getIcaCarDeductibleHullFromValue(policy.deductibles) ?? '3500',
          }),
          yearlyMaxMileage: getIcaCarMaxMilageFromValue(policy.insurance.maxMileage) ?? '25000-',
          underAgedDriver: false,
          ...(coverage !== 'Trafikförsäkring' && {
            addOns: getIcaCarAddonsFromValue(policy.addons),
          }),
        },
      },
      containsAssumedValue:
        !getIcaCarDeductibleHullFromValue(policy.deductibles) ||
        !getIcaCarMaxMilageFromValue(policy.insurance.maxMileage),
    };
  }
  return undefined;
};

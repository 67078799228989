export const getQueryString = (urlQueryParams: Record<string, string> = {}): string => {
  const previousSearchParams = window.location.search;
  const searchParams = new URLSearchParams(previousSearchParams);
  Object.entries(urlQueryParams).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  const hasParams: boolean = Array.from(searchParams.entries()).length > 0;
  return hasParams ? `?${searchParams.toString()}` : '';
};

/* eslint-disable max-lines */
import { IntlShape } from 'react-intl';

import { sentryCaptureException } from '@main/services/sentry';
import {
  Language,
  Currency,
  HomeInsurance,
  PersonInsurance,
  AnimalInsurance,
  VehicleInsurance,
  InsuranceType,
  Country,
  Market,
  InsuranceSubType,
} from '@main/types';

const CORRECT_UK_LOCALE = 'gb';
const CORRECT_DK_LOCALE = 'da';
const getLocale = ({ language, country }: { language: Language; country: Country }) => {
  if (country === Country.se) return language;
  if (country === Country.uk) return CORRECT_UK_LOCALE;
  if (country === Country.dk) return CORRECT_DK_LOCALE;
  return country;
};

export const formatCurrency = ({
  language,
  market: { currency, country },
  amount,
  maximumFractionDigits,
  minimumFractionDigits,
}: {
  language: Language;
  market: Market;
  amount: number;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}) => {
  const maximumDecimals =
    (maximumFractionDigits ?? [Currency.EUR, Currency.GBP].includes(currency)) ? 2 : 0;

  const minimumDecimals =
    (minimumFractionDigits ?? [Currency.EUR, Currency.GBP].includes(currency)) ? 2 : 0;

  const formattedCurrency = Intl.NumberFormat(getLocale({ language, country }), {
    maximumFractionDigits: maximumDecimals,
    minimumFractionDigits: maximumDecimals < minimumDecimals ? maximumDecimals : minimumDecimals,
    style: 'currency',
    currency,
  }).format(amount);

  return country === Country.se && language === Language.en
    ? formattedCurrency.replace(/,/g, '\u00a0').replace(/\./g, ',')
    : formattedCurrency;
};

export const formatNumber = (
  amount: number,
  language: Language,
  maximumFractionDigits: number = 0,
  minimumFractionDigits: number = 0,
) =>
  Intl.NumberFormat(language, {
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(amount);

export const upperCaseFirstLetter = (string?: string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const convertSnakeCaseToSentence = (string: string) =>
  upperCaseFirstLetter(string).replace(/_/g, ' ');

export const washInsuranceFromString = (string: string) =>
  string == null
    ? ''
    : string
        .replace('försäkring', '')
        .replace(/insurance/i, '')
        .replace(/coverage/i, '')
        .trim();

// When we want to display "ICA" and not "ICA Försäkring". This might cause problem in the future if other insurance companies also have " Försäkring" in their name
export const washInsuranceCompany = (company: string) =>
  company == null ? '' : company.replace(' Försäkring', '');

export const getTitleFromInsuranceType = ({
  policy,
  unknownCopy,
}: {
  policy?: Policy;
  unknownCopy: string;
}) => {
  if (!policy?.insurance) {
    return unknownCopy;
  }

  switch (policy.insurance.insuranceType) {
    case InsuranceType.houseContentInsurance:
      return (
        (policy.insurance as HomeInsurance).insuranceObjectStreetAddress ??
        policy.insurance.insuranceName
      );
    case InsuranceType.personInsurance:
      return (
        (policy.insurance as PersonInsurance).insuredPersonName ?? policy.insurance.insuranceName
      );
    case InsuranceType.animalInsurance:
      return (policy.insurance as AnimalInsurance).animalName ?? policy.insurance.insuranceName;
    case InsuranceType.vehicleInsurance:
      return (policy.insurance as VehicleInsurance)?.brand &&
        (policy.insurance as VehicleInsurance)?.registrationNo
        ? `${(policy.insurance as VehicleInsurance).brand} ${
            (policy.insurance as VehicleInsurance).registrationNo
          }`
        : policy.insurance.insuranceName;
    case InsuranceType.incomeInsurance: {
      return policy.insurance.insuranceHolderName ?? policy.insurance.insuranceName;
    }
    case InsuranceType.generalInsurance:
      return policy.insurance.insuranceName;
    default:
      return unknownCopy;
  }
};

export const cleanNumber = (value: string) => value.replace(/[^0-9]/g, ''); // Remove everything that is not number

const insuranceTypeMapper = {
  [InsuranceType.houseContentInsurance]: (intl) =>
    intl.formatMessage({
      id: 'shared.insurance-type.house-content-insurance',
      defaultMessage: 'Home insurance',
    }),
  [InsuranceType.vehicleInsurance]: (intl: IntlShape) =>
    intl.formatMessage({
      id: 'shared.insurance-type.vehicle-insurance',
      defaultMessage: 'Vehicle insurance',
    }),
  [InsuranceType.animalInsurance]: (intl: IntlShape) =>
    intl.formatMessage({
      id: 'shared.insurance-type.animal-insurance',
      defaultMessage: 'Animal insurance',
    }),
  [InsuranceType.groupInsurance]: (intl: IntlShape) =>
    intl.formatMessage({
      id: 'shared.insurance-type.group-insurance',
      defaultMessage: 'Group insurance',
    }),
  [InsuranceType.personInsurance]: (intl: IntlShape) =>
    intl.formatMessage({
      id: 'shared.insurance-type.person-insurance',
      defaultMessage: 'Person insurance',
    }),
  [InsuranceType.incomeInsurance]: (intl: IntlShape) =>
    intl.formatMessage({
      id: 'shared.insurance-type.income-insurance',
      defaultMessage: 'Income insurance',
    }),
} satisfies Partial<Record<InsuranceType, (intl: IntlShape) => string>>;

export const insuranceSubTypeMapper = ({
  insuranceName,
  insuranceSubType,
}: {
  insuranceName?: string;
  insuranceSubType: InsuranceSubType;
}) => {
  const insuranceSubTypeMap = {
    [InsuranceSubType.carInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.car-insurance',
        defaultMessage: 'Car Insurance',
      }),
    [InsuranceSubType.condoInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.home-insurance',
        defaultMessage: 'Home Insurance',
      }),
    [InsuranceSubType.contentsInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.contents-insurance',
        defaultMessage: 'Contents insurance',
      }),
    [InsuranceSubType.healthInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.health-care-insurance',
        defaultMessage: 'Health Care Insurance',
      }),
    [InsuranceSubType.mopedInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.moped-insurance',
        defaultMessage: 'Moped Insurance',
      }),
    [InsuranceSubType.cottageInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.cottage-insurance',
        defaultMessage: 'Cottage Insurance',
      }),
    [InsuranceSubType.trailerInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.trailer-insurance',
        defaultMessage: 'Trailer Insurance',
      }),
    [InsuranceSubType.motorcycleInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.motorcycle-insurance',
        defaultMessage: 'Motorcycle Insurance',
      }),
    [InsuranceSubType.pregnancyInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.pregnancy-insurance',
        defaultMessage: 'Pregnancy Insurance',
      }),
    [InsuranceSubType.catInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.cat-insurance',
        defaultMessage: 'Cat Insurance',
      }),
    [InsuranceSubType.motorhomeInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.motorhome-insurance',
        defaultMessage: 'Motorhome Insurance',
      }),
    [InsuranceSubType.otherVehicleInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.other-vehicle-insurance',
        defaultMessage: 'Other Vehicle Insurance',
      }),
    [InsuranceSubType.boatInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.boat-insurance',
        defaultMessage: 'Boat Insurance',
      }),
    [InsuranceSubType.groupInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.group-insurance',
        defaultMessage: 'Group Insurance',
      }),
    [InsuranceSubType.horseInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.horse-insurance',
        defaultMessage: 'Horse Insurance',
      }),
    [InsuranceSubType.lifeInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.life-insurance',
        defaultMessage: 'Life Insurance',
      }),
    [InsuranceSubType.genericInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.generic-insurance',
        defaultMessage: 'Generic Insurance',
      }),
    [InsuranceSubType.dogInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.dog-insurance',
        defaultMessage: 'Dog Insurance',
      }),
    [InsuranceSubType.caravanInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.caravan-insurance',
        defaultMessage: 'Caravan Insurance',
      }),
    [InsuranceSubType.otherAnimalInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.other-animal-insurance',
        defaultMessage: 'Other Animal Insurance',
      }),
    [InsuranceSubType.farmInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.farm-insurance',
        defaultMessage: 'Farm Insurance',
      }),
    [InsuranceSubType.incomeInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.income-insurance',
        defaultMessage: 'Income Insurance',
      }),
    [InsuranceSubType.sicknessInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.sickness-insurance',
        defaultMessage: 'Health Insurance',
      }),
    [InsuranceSubType.loanInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.loan-insurance',
        defaultMessage: 'Loan Payment Protection',
      }),
    [InsuranceSubType.villaInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.villa-insurance',
        defaultMessage: 'Villa Insurance',
      }),
    [InsuranceSubType.childInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.child-insurance',
        defaultMessage: 'Child Health Insurance',
      }),
    [InsuranceSubType.travelInsurance]: (intl: IntlShape) =>
      intl.formatMessage({
        id: 'shared.insurance-subtype.travel-insurance',
        defaultMessage: 'Travel Insurance',
      }),
    [InsuranceSubType.accidentInsurance]: (intl: IntlShape) => {
      if (insuranceName?.toLowerCase().includes('sjuk')) {
        return intl.formatMessage({
          id: 'shared.insurance-subtype.accident-display-name-2',
          defaultMessage: 'Personal accident insurance',
        });
      }
      return intl.formatMessage({
        id: 'shared.insurance-subtype.accident-display-name-1',
        defaultMessage: 'Personal accident insurance',
      });
    },
  } satisfies Partial<Record<InsuranceSubType, unknown>>;
  return insuranceSubTypeMap[insuranceSubType];
};

type FormattedInsuranceType = keyof typeof insuranceTypeMapper;

export const isFormattedInsuranceType = (
  insuranceType: InsuranceType,
): insuranceType is FormattedInsuranceType => insuranceType in insuranceTypeMapper;

type FormatInsuranceSubTypeProps = {
  intl: IntlShape;
  insuranceType?: InsuranceType;
  insuranceSubType?: InsuranceSubType;
  insuranceName?: string;
};
export const formatInsuranceSubType: {
  (
    props: FormatInsuranceSubTypeProps & {
      useFallbackMessage: boolean;
    },
  ): string | undefined;
  (props: FormatInsuranceSubTypeProps): string;
} = ({
  intl,
  insuranceType,
  insuranceSubType,
  insuranceName,
  useFallbackMessage = true,
}: FormatInsuranceSubTypeProps & {
  useFallbackMessage?: boolean;
  // To allow overload, "any" is needed. Function return type won't be "any"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any => {
  const shouldUseSubType =
    insuranceSubType &&
    (!insuranceType ||
      [
        InsuranceType.personInsurance,
        InsuranceType.incomeInsurance,
        InsuranceType.houseContentInsurance,
      ].includes(insuranceType));

  if (shouldUseSubType) {
    const insuranceSubTypeTranslation = insuranceSubTypeMapper({
      insuranceSubType,
      insuranceName,
    })?.(intl);
    if (insuranceSubTypeTranslation) return insuranceSubTypeTranslation;
    sentryCaptureException(
      new Error(
        `Not able to map InsuranceSubType: "${insuranceSubType}" in insuranceSubTypeMapper`,
      ),
    );
  } else if (insuranceType) {
    if (isFormattedInsuranceType(insuranceType)) {
      return insuranceTypeMapper[insuranceType](intl);
    }
    if (InsuranceType.generalInsurance !== insuranceType) {
      /**
       * Special check due to generalInsurance should use fallbackMessage if  useFallbackMessage === true
       * But should not send sentry exception
       */
      sentryCaptureException(
        new Error(`Not able to map InsuranceType: "${insuranceType}" in insuranceTypeMapper`),
      );
    }
  }
  if (!useFallbackMessage) return;
  return intl.formatMessage({
    id: 'shared.insurance-type.insurance-policy',
    defaultMessage: 'Insurance policy',
  });
};

export const formatInsuranceSubTypes = ({
  intl,
  insuranceSubTypes,
  insuranceName,
}: {
  intl: IntlShape;
  insuranceSubTypes: InsuranceSubType[];
  insuranceName?: string;
}) => {
  if (insuranceSubTypes.length !== 1)
    return intl.formatMessage({
      id: 'shared.insurance-type.insurance',
      defaultMessage: 'insurance',
    });
  return formatInsuranceSubType({ intl, insuranceSubType: insuranceSubTypes[0], insuranceName });
};

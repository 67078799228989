import { DescriptionList } from '@insurely/ui';

import { useIntl } from 'react-intl';

import { useSharedData } from '@main/contexts';
import { InvestmentAccount } from '@main/types';

import { formatCurrency } from '@main/utils';

import styles from './index.module.css';

interface InvestmentDescriptionListProp {
  account: InvestmentAccount;
}

export const InvestmentDescriptionList = ({ account }: InvestmentDescriptionListProp) => {
  const intl = useIntl();

  const [
    {
      clientConfig: { language, market },
    },
  ] = useSharedData();

  const { monthlySavingsAmount, accountInformation } = account;

  const formattedMonthlySavings = formatCurrency({
    language,
    market,
    amount: monthlySavingsAmount.amount,
  });

  const descriptionListItems = [
    {
      label: intl.formatMessage({
        id: 'overview.wealth-account.general-info-investment.term-1',
        defaultMessage: 'Monthly savings',
      }),
      value: intl.formatMessage(
        {
          id: 'overview.wealth-account.general-info-investment.value-1',
          defaultMessage: '{amount}/month',
        },
        { amount: formattedMonthlySavings },
      ),
    },
    {
      label: intl.formatMessage({
        id: 'overview.wealth-account.general-info-investment.term-2',
        defaultMessage: 'Account number',
      }),
      value: accountInformation.cashAccountNumber,
    },
  ];

  return (
    <DescriptionList
      rows={descriptionListItems}
      labelTextVariant="ParagraphCaption"
      valueTextVariant="Label-2"
      className={styles.descriptionList}
    />
  );
};

import { CoreCarInsurance, CoreVehicleInsurance } from '@main/types/insurance/core';

export type SwedishVehicleInsurance = CoreVehicleInsurance;

export type SwedishCarInsurance = CoreCarInsurance &
  SwedishVehicleInsurance &
  SwedishCarInsuranceFields;

type SwedishCarInsuranceFields = {
  // Standardized way of saying hel, halv, trafik, enbart vagnskada, avställning
  coverage: SwedishVehicleCoverage;
};

export enum SwedishVehicleCoverage {
  UNDEFINED = 'UNDEFINED',
  FULL_WITH_CHASSI = 'FULL_WITH_CHASSI',
  FULL_WITHOUT_CHASSI = 'FULL_WITHOUT_CHASSI',
  TRAFFIC = 'TRAFFIC',
  NOT_IN_TRAFFIC = 'NOT_IN_TRAFFIC',
}

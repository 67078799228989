import { IntlShape } from 'react-intl';

export const convertBooleanToFormattedString = (boolean: boolean, intl: IntlShape) => {
  if (boolean) {
    return intl.formatMessage({
      id: 'overview.wealth-account.general-info.repayment-cover-value-1',
      defaultMessage: 'Yes',
    });
  }
  return intl.formatMessage({
    id: 'overview.wealth-account.general-info.repayment-cover-value-2',
    defaultMessage: 'No',
  });
};

import { PlateContainer } from '../PlateContainer';

import styles from './accountList.module.css';
import { AccountListItems, AccountListItemsProps } from './AccountListItems';

export const AccountList = ({ accounts }: AccountListItemsProps) => (
  <PlateContainer className={styles.accountList}>
    <AccountListItems accounts={accounts} />
  </PlateContainer>
);

import { useEffect, useState } from 'react';

import { sentryCaptureException, sharedApiClient, useQuery, useQueryClient } from '@main/services';

import { CollectionMetaData, useCollectionItems, useSharedData } from '@main/shared-exports';
import { CollectionStatus } from '@main/types';

import { tokenIsValid } from '@main/utils/jwtToken';

const FIFTEEN_MINUTES_IN_MILLI_SEC = 900000;

type CollectionPromiseFulfilledResult = PromiseFulfilledResult<{
  id: string;
  policyList: Policy[];
  companyKey: string;
  status: CollectionStatus;
}>;

const metaDataQueryKey = 'collectionIds';

export const useCollectionMetaData = () => {
  const [
    {
      userConfig: { authToken },
    },
  ] = useSharedData();

  return useQuery({
    queryKey: [metaDataQueryKey, authToken],
    queryFn: async () =>
      sharedApiClient<CollectionMetaData[]>('/insurance-collection/meta-data', {
        headers: {
          Authorization: authToken,
          'insurely-version': '2023-03-15',
        },
      }).then((response) => response.data),
    refetchOnWindowFocus: false,
    staleTime: FIFTEEN_MINUTES_IN_MILLI_SEC,
    enabled: !!authToken && tokenIsValid(authToken),
  });
};

export const useCollectionData = () => {
  const [
    {
      userConfig: { authToken },
      clientConfig: { language },
    },
  ] = useSharedData();

  const { data: collectionMetaData } = useCollectionMetaData();
  const { addPolicies } = useCollectionItems();
  const [hasFetchedAllCollections, setHasFetchedAllCollections] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    async function invalidate() {
      try {
        await queryClient.invalidateQueries({ queryKey: [metaDataQueryKey] });
      } catch (e) {
        sentryCaptureException(
          new Error("Unable to invalidate query for meta-data using key 'collectionIds'"),
        );
      }
    }

    // Refetch the metadata to ensure that all collections are included before showing the overview
    // eslint-disable-next-line no-void
    void invalidate();
  }, [queryClient]);

  useEffect(() => {
    async function getCollections() {
      const results = await Promise.allSettled(
        collectionMetaData
          ?.filter((metaData) =>
            [CollectionStatus.COMPLETED, CollectionStatus['COMPLETED PARTIAL']].includes(
              metaData.status,
            ),
          )
          .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
          .map((metaData) =>
            sharedApiClient<Policy[]>(`/insurance-collection/get-data/${metaData.id}`, {
              headers: {
                Authorization: authToken,
                'insurely-version': '2023-03-15',
                'insurely-language': language,
              },
            }).then((response) => ({
              id: metaData.id,
              policyList: response.data,
              companyKey: metaData.insuranceCompany,
              status: metaData.status,
            })),
          ) ?? [],
      );

      // Failed collections are just filtered out, so we are still missing a more user-friendly way to deal with it
      const collectionList = results
        .filter(
          (result): result is CollectionPromiseFulfilledResult => result.status === 'fulfilled',
        )
        .map((result) => result.value);

      collectionList.forEach((collection) =>
        addPolicies({
          collectionId: collection.id,
          companyKey: collection.companyKey,
          policies: collection.policyList,
          status: collection.status,
        }),
      );
      setHasFetchedAllCollections(true);
    }

    if (!authToken) {
      setHasFetchedAllCollections(true);
    }

    if (!collectionMetaData) return;

    // eslint-disable-next-line no-void
    void getCollections();
  }, [addPolicies, authToken, collectionMetaData, language]);

  return {
    hasFetchedAllCollections,
  };
};

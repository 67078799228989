import { CollectionStatus } from '@main/types';

import { ElapsedTimeSeconds } from './types';

export const defaultElapsedTimeSeconds: ElapsedTimeSeconds = {
  [CollectionStatus.RUNNING]: 0,
  [CollectionStatus.LOGIN]: 0,
  [CollectionStatus['TWO FACTOR PENDING']]: 0,
  [CollectionStatus.COLLECTING]: 0,
};

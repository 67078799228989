import { Plate } from '@insurely/ui';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './plateContainer.module.css';

interface PlateContainerProps extends PropsWithChildren {
  className?: string;
  fullWidth?: boolean;
  variant?: 'outlined' | 'filled';
}

export const PlateContainer = ({
  children,
  className,
  variant = 'outlined',
  fullWidth = false,
}: PlateContainerProps) => (
  <Plate
    variant={variant}
    responsiveBorderRadius={fullWidth}
    transitionWidth={fullWidth ? 640 : undefined}
    className={classNames(styles.plate, className)}
  >
    {children}
  </Plate>
);

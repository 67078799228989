import { WealthLoginMethod, ResponseError, WealthGetDataModel } from '@insurely/common-api-client';
import { fromPromise } from 'xstate';

import {
  MaybeCollectDataError,
  FailedCollectionStatus,
  CollectDataError,
  StartCollectionSuccessfulResponse,
  StartCollectionResponse,
  isStartCollectionResponse,
  GetDataResponse,
  isGetDataResponse,
} from '../types';

import { StartCollectionInput, GetDataInput } from './types';

const getErrorCause = (status?: number) =>
  status === 403
    ? FailedCollectionStatus.START_COLLECTION_FAILED_INVALID_SESSION_ID
    : FailedCollectionStatus.START_COLLECTION_FAILED_CONNECTION_ERROR;

export const startCollectionActor = fromPromise<
  MaybeCollectDataError<StartCollectionSuccessfulResponse>,
  StartCollectionInput
>(
  async ({
    input: {
      payload,
      resumeCode,
      resumeCodeIndex,
      productType,
      apiClient,
      insuranceApiClient,
      wealthApiClient,
    },
  }) => {
    let response = null;
    let body = null;

    if (!resumeCode && !payload) {
      throw new Error('Missing resumeCode or payload to start collection');
    }
    if (resumeCode) {
      response = await insuranceApiClient(`resumable-collections/${resumeCode}/resume`, {
        method: 'POST',
        body: JSON.stringify({
          collectionIndex: resumeCodeIndex,
        }),
      });
      body = (await response.json()) as StartCollectionResponse;
    } else if (productType === 'wealth') {
      try {
        if (!payload || !payload?.loginMethod) {
          throw new Error('Missing payload to start collection');
        }

        const requestBody = {
          company: payload.insuranceCompany,
          loginMethod: (payload.loginMethod as WealthLoginMethod) ?? undefined,
          input: payload.input as Record<string, string>,
        };

        const initiateCollectionResponse = await wealthApiClient.initiateCollection(requestBody);
        body = {
          id: initiateCollectionResponse.id,
        } as StartCollectionResponse;
      } catch (error) {
        return {
          cause: getErrorCause((error as ResponseError)?.response.status),
          message: (error as ResponseError)?.message,
        } as CollectDataError;
      }
    } else {
      // TODO: make sure payload is typed as non optional here
      response = await apiClient(
        `collect-info${payload?.loginMethod === 'PDF_UPLOAD' ? '/pdf' : ''}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        },
      );
      body = (await response.json()) as StartCollectionResponse;
    }

    if (!isStartCollectionResponse(body)) {
      return {
        cause: getErrorCause(response?.status),
        message: body?.message,
      } as CollectDataError;
    }

    return body;
  },
);

export const getDataActor = fromPromise<
  MaybeCollectDataError<Policy[] | WealthGetDataModel[]>,
  GetDataInput
>(
  async ({
    input: { collectionId, authorizationToken, productType, apiClient, wealthApiClient },
  }) => {
    let body = null;

    if (productType === 'wealth') {
      try {
        const response = await wealthApiClient.getData(collectionId, authorizationToken);
        body = response as GetDataResponse;
      } catch (error) {
        return {
          cause: FailedCollectionStatus.LOADING_RESULTS_FAILED_WRONG_COLLECTION_ID,
          message: (error as Error)?.message,
        } as CollectDataError;
      }
    } else {
      const response = await apiClient(`get-data/${collectionId}`, {
        headers: {
          'Authorization-token': authorizationToken,
        },
      });
      body = (await response.json()) as GetDataResponse;
    }

    if (!isGetDataResponse(body)) {
      return {
        cause: FailedCollectionStatus.LOADING_RESULTS_FAILED_WRONG_COLLECTION_ID,
        message: body?.message,
      } as CollectDataError;
    }

    return body;
  },
);

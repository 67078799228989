import { SharedDataState } from '@main/contexts';
import {
  fakeNewAnimalCollection,
  fakeNewAnimalInsurance,
  fakeNewEstonianCarCollection,
  fakeNewFrenchAccidentCollection,
  fakeNewFrenchCarCollection,
  fakeNewFrenchHomeCollection,
  fakeNewSwedishAccidentCollection,
  fakeNewSwedishHomeCollection,
} from '@main/mocks/fake-utils/collections';
import { mockDb } from '@main/mocks/mockDb';

import { AnimalGender, Country, InsuranceSubType } from '@main/types';

import { getDevToolsLocalItem, isPension } from '@main/utils';

import { frenchHomeInsurances, frenchPreviousCollections } from './collectDataFR';
import { lifeInsurances, carInsurances, houseInsurances } from './collectDataSE';
import { pension } from './pensionSE';

function mapInsuranceCompany(collections: Policy[], insuranceCompany?: string) {
  return insuranceCompany
    ? collections.map((collection) => {
        if (isPension(collection)) {
          return {
            ...collection,
            insuranceCompany,
          };
        }
        return {
          ...collection,
          insurance: {
            ...collection.insurance,
            insuranceCompany,
          },
        };
      })
    : collections;
}

function getSwedishData(sharedData?: SharedDataState | null, insuranceCompany?: string) {
  const clientId = sharedData?.clientConfig.clientId;
  const productType = sharedData?.clientConfig.productType;
  switch (true) {
    case productType === 'pension' || clientId?.includes('pension'):
      return mapInsuranceCompany(pension, insuranceCompany);
    case clientId?.includes('life'):
      return mapInsuranceCompany(lifeInsurances, insuranceCompany);
    case clientId?.includes('car'):
      return mapInsuranceCompany(carInsurances, insuranceCompany);
    case clientId?.includes('house'):
      return mapInsuranceCompany(houseInsurances, insuranceCompany);
    default:
      return mapInsuranceCompany(
        [...houseInsurances, ...carInsurances, ...lifeInsurances],
        insuranceCompany,
      );
  }
}

const getInsuranceFromCollectionId = (collectionId: string, company?: string) =>
  mapInsuranceCompany(
    collectionId in frenchPreviousCollections
      ? frenchPreviousCollections[collectionId]
      : frenchHomeInsurances,
    company,
  );

function getFrenchData(
  sharedData?: SharedDataState | null,
  insuranceCompany?: string,
  collectionId?: string,
) {
  const clientId = sharedData?.clientConfig.clientId;

  if (collectionId && frenchPreviousCollections[collectionId]) {
    return getInsuranceFromCollectionId(collectionId, insuranceCompany);
  }
  switch (true) {
    case clientId?.includes('car'):
      return mapInsuranceCompany(fakeNewFrenchCarCollection(), insuranceCompany);
    case clientId?.includes('house'):
      return mapInsuranceCompany(fakeNewFrenchHomeCollection(), insuranceCompany);
    case clientId?.includes('accident'):
      return mapInsuranceCompany(fakeNewFrenchAccidentCollection(), insuranceCompany);
    case clientId?.includes('animal'):
      return mapInsuranceCompany(
        fakeNewAnimalCollection.many(2, {
          overrides: {
            insurance: fakeNewAnimalInsurance.one({ overrides: { '@market': Country.fr } }),
          },
        }),
        insuranceCompany,
      );
    default:
      return mapInsuranceCompany(
        [
          ...fakeNewFrenchHomeCollection([null, { parameters: [] }]),
          ...fakeNewFrenchCarCollection(),
          ...fakeNewFrenchAccidentCollection(),
          fakeNewAnimalCollection.one({
            overrides: {
              insurance: fakeNewAnimalInsurance.one({
                overrides: { '@market': Country.fr },
              }),
            },
          }),
          fakeNewAnimalCollection.one({
            overrides: {
              insurance: fakeNewAnimalInsurance.one({
                map: (insurance) => ({
                  ...insurance,
                  insuranceSubType: InsuranceSubType.catInsurance,
                  animalGender: AnimalGender.FEMALE,
                  animalBreed: 'Chocolate Suffolk',
                  animalName: 'Cathy',
                  '@market': Country.fr,
                }),
              }),
            },
          }),
        ],
        insuranceCompany,
      );
  }
}

const getEstonianData = (insuranceCompany?: string) =>
  mapInsuranceCompany(fakeNewEstonianCarCollection(), insuranceCompany);

export function getData(collection: ReturnType<typeof mockDb.collection.findFirst>) {
  const sharedData = getDevToolsLocalItem({ key: 'localSharedData' });

  /**
   * Used for testing accident insurances with different displayname for the insurance subType
   */
  if (collection && ['199010106666'].includes(collection.userInput)) {
    return [
      ...fakeNewSwedishAccidentCollection([
        {
          insurance: {
            insuranceName: 'Sjuk- och Olycksfallsförsäkring',
            insuranceCompany: 'se-ica',
          },
        },
      ]),
      ...fakeNewSwedishAccidentCollection([{ insurance: { insuranceCompany: 'se-if' } }]),
    ];
  }

  if (collection && ['empty', '200001010000', '200000000000'].includes(collection.userInput)) {
    return [];
  }

  /**
   * Used for testing DA with skipping the overview
   */
  if (collection && ['skip-idv'].includes(collection.userInput)) {
    return fakeNewFrenchHomeCollection();
  }

  /**
   * Used for testing DA with two insurances from different companies
   */
  if (collection && ['two'].includes(collection.userInput)) {
    return [
      ...fakeNewSwedishHomeCollection([{ insurance: { insuranceCompany: 'se-ica' } }]),
      ...fakeNewSwedishHomeCollection([{ insurance: { insuranceCompany: 'se-if' } }]),
    ];
  }

  /**
   * Used for testing compare for swedish market with some missing data from DA:
   */
  if (collection && ['partial', '199010105555'].includes(collection.userInput)) {
    return fakeNewSwedishHomeCollection([
      { insurance: { livingArea: undefined, numberOfResidents: undefined } },
    ]);
  }

  if (collection && ['199010108888'].includes(collection.userInput)) {
    return fakeNewSwedishHomeCollection([{ parameters: [] }]);
  }

  if (collection && ['no-cost-no-param'].includes(collection.userInput)) {
    return fakeNewSwedishHomeCollection([
      { insurance: { premiumAmountYearRounded: undefined }, parameters: [] },
    ]);
  }

  if (collection && ['no-param'].includes(collection.userInput)) {
    return fakeNewSwedishHomeCollection([{ parameters: [] }]);
  }

  if (collection && ['no-cost'].includes(collection.userInput)) {
    return fakeNewSwedishHomeCollection([{ insurance: { premiumAmountYearRounded: undefined } }]);
  }

  /**
   * Used for testing compare for french market with some missing data from DA:
   */

  if (collection && ['no-parameters'].includes(collection.userInput)) {
    return fakeNewFrenchHomeCollection([{ parameters: [] }]);
  }

  switch (sharedData?.clientConfig.market.country) {
    case Country.fr:
      return getFrenchData(sharedData, collection?.insuranceCompany, collection?.id);
    case Country.ee:
      return getEstonianData(collection?.insuranceCompany);
    case Country.se:
    default:
      return getSwedishData(sharedData, collection?.insuranceCompany);
  }
}

import { ToastProvider } from '@insurely/ui';

import { PropsWithChildren, Suspense } from 'react';

import { useCustomizedCustomer } from '@main/__customer-implementations__/customization';
import { ErrorBoundary } from '@main/components/ErrorBoundary';

import Loading from '@main/components/Loading';
import { UserInputContextProvider } from '@main/contexts';
import { BlocksConfigProvider } from '@main/contexts/BlocksConfigContext';
import { CollectDataMachineProvider } from '@main/contexts/CollectDataMachine/context';
import ContactDetailsContextProvider from '@main/contexts/ContactDetailsContext';
import QuestionnaireProvider from '@main/contexts/QuestionnaireContext';

import { TransferPensionProvider } from '@main/contexts/TransferPensionContext';

import { useAuthTokenExpirationCheck } from '@main/hooks/useAuthToken';
import { useDevTools } from '@main/hooks/useDevTools';
import useHistoryListener from '@main/hooks/useHistoryListener';
import usePostHog from '@main/hooks/usePosthog';
import { LanguageProvider } from '@main/i18n';
import {
  BlocksClientConfig,
  CollectionItemsContextProvider,
  InsurelyQueryClientProvider,
  SharedDataContextProvider,
  UseCaseContextProvider,
  useIsDevToolsEnabled,
  useSharedDataConfig,
} from '@main/shared-exports';

import { AppLoader } from './loader';

export function GlobalContextProviders({ children }: PropsWithChildren) {
  const {
    userConfig: { devTranslations },
    clientConfig: { language },
    prefill,
    sessionId,
  } = useSharedDataConfig();
  const customizedCustomer = useCustomizedCustomer();

  usePostHog(sessionId);
  useHistoryListener();
  useAuthTokenExpirationCheck();

  return (
    <InsurelyQueryClientProvider>
      <ToastProvider>
        <CollectionItemsContextProvider>
          <CollectDataMachineProvider>
            <ContactDetailsContextProvider prefill={prefill}>
              <UserInputContextProvider prefill={prefill}>
                <QuestionnaireProvider>
                  <TransferPensionProvider>
                    <BlocksConfigProvider>
                      <ErrorBoundary language={language}>
                        <Suspense fallback={<Loading />}>
                          <LanguageProvider
                            devTranslations={devTranslations}
                            customizedCustomer={customizedCustomer}
                            language={language}
                          >
                            {children}
                          </LanguageProvider>
                        </Suspense>
                      </ErrorBoundary>
                    </BlocksConfigProvider>
                  </TransferPensionProvider>
                </QuestionnaireProvider>
              </UserInputContextProvider>
            </ContactDetailsContextProvider>
          </CollectDataMachineProvider>
        </CollectionItemsContextProvider>
      </ToastProvider>
    </InsurelyQueryClientProvider>
  );
}

function DevToolsListener({ children }: PropsWithChildren) {
  useDevTools();

  return children;
}

export const WithDevTools = ({ children }: PropsWithChildren) =>
  useIsDevToolsEnabled() ? <DevToolsListener>{children}</DevToolsListener> : children;

export function AppLayout({
  children,
  loaderData,
  blocksClientConfig,
  apiVersion,
}: PropsWithChildren<{
  loaderData: AppLoader;
  blocksClientConfig: BlocksClientConfig;
  apiVersion?: string;
}>) {
  const { moduleInput, sessionId, resumableCollections } = loaderData;
  return (
    <SharedDataContextProvider
      apiVersion={apiVersion}
      moduleInput={moduleInput}
      clientConfig={blocksClientConfig.config}
      sessionId={sessionId}
      blocksConfig={blocksClientConfig.blocksConfig}
      resumableCollections={resumableCollections}
    >
      <WithDevTools>
        <UseCaseContextProvider>
          <GlobalContextProviders>{children}</GlobalContextProviders>
        </UseCaseContextProvider>
      </WithDevTools>
    </SharedDataContextProvider>
  );
}

import { SharedDataState } from '@main/contexts';
import {
  DevToolsContinuousUpdateContextState,
  DevToolsRequiresReloadContextState,
} from '@main/types';

import {
  GetItemParams,
  RemoveItemParams,
  SetItemParams,
  getLocalItem,
  removeLocalItem,
  setLocalItem,
} from './localStorage';

const devtoolsKey = 'devtools';

type StorageKeyValue = {
  'has-opened': boolean;
  requests: Request[];
  open: boolean;
  authorizationKey: string;
  checkoutInsuranceCompany: string;
  localSharedData: SharedDataState;
  devtoolsContinuousUpdate: DevToolsContinuousUpdateContextState;
  devtoolsRequiresReloadUpdate: DevToolsRequiresReloadContextState;
};

type StorageKey = keyof StorageKeyValue;

export const removeDevToolsLocalItem = <K extends StorageKey>({
  key,
  useSessionStorage,
}: RemoveItemParams<K>) => removeLocalItem({ prefix: devtoolsKey, key, useSessionStorage });

export const getDevToolsLocalItem = <K extends StorageKey>({
  key,
  useSessionStorage,
}: GetItemParams<K>) =>
  getLocalItem<StorageKeyValue, K>({ prefix: devtoolsKey, key, useSessionStorage });

export const setDevToolsLocalItem = <K extends StorageKey>({
  key,
  value,
  useSessionStorage,
}: SetItemParams<StorageKeyValue, K>) =>
  setLocalItem<StorageKeyValue, K>({ prefix: devtoolsKey, key, value, useSessionStorage });

export type RequestMethod = 'ALL' | 'GET' | 'POST' | 'PUT' | 'DELETE';

export type Request = {
  requestMethod: RequestMethod;
  urlMatch: string;
  statusCode: number;
  failRate?: number;
  responseBody?: string;
};

export enum DevToolsEventName {
  UPDATE_REQUESTS = 'UPDATE_REQUESTS',
  NAVIGATE = 'NAVIGATE',
  SET_CONTINUOUS_UPDATE_DATA = 'SET_CONTINUOUS_UPDATE_DATA',
  SET_REQUIRES_RELOAD_DATA = 'SET_REQUIRES_RELOAD_DATA',
}

export enum DcDevToolsEventName {
  SET_LOGIN_METHOD = 'SET_LOGIN_METHOD',
  SET_COLLECTION_STATUS = 'SET_COLLECTION_STATUS',
  SET_COLLECTIONS_DISABLED = 'SET_COLLECTIONS_DISABLED',
}

export type SharedDevToolsEvent =
  | {
      name: DevToolsEventName.UPDATE_REQUESTS;
      value: Request[];
    }
  | {
      name: DevToolsEventName.NAVIGATE;
      value: string;
    }
  | {
      name: DevToolsEventName.SET_CONTINUOUS_UPDATE_DATA;
      value: DevToolsContinuousUpdateContextState;
    }
  | {
      name: DevToolsEventName.SET_REQUIRES_RELOAD_DATA;
      value: DevToolsRequiresReloadContextState;
    };

export type DcDevToolsEvent =
  | {
      name: DcDevToolsEventName.SET_LOGIN_METHOD;
      value: string;
    }
  | {
      name: DcDevToolsEventName.SET_COLLECTION_STATUS;
      value: string;
    }
  | {
      name: DcDevToolsEventName.SET_COLLECTIONS_DISABLED;
      value: boolean;
    };

export type DevToolsEvent = SharedDevToolsEvent | DcDevToolsEvent;

import { CompanyLogo, ParagraphCaption } from '@insurely/ui';

import styles from './footer.module.css';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <ParagraphCaption className={styles.footerText} lang="en">
        Connected by
      </ParagraphCaption>
      <CompanyLogo
        company="insurely"
        height="19px"
        color="var(--textColor, black)"
        width="64px"
        aria-label="Insurely logotyp"
        suspenseFallback={
          <ParagraphCaption className={styles.footerText} lang="en">
            Insurely
          </ParagraphCaption>
        }
      />
    </footer>
  );
}

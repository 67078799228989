import { mapEquivalentPackageScopeFr } from '@main/mocks/data/parameters/equivalentPackageScopeFr';
import { CoveragePolicyData, InsurancePackageName, InsuranceSubType } from '@main/types';

import { PackageScopeRequest } from '@main/types/insurance/EquivalentPackageScope';

import { getParametersFR } from './parametersFR';
import { getParametersSE } from './parametersSE';

export function getParameters({
  insuranceCompany,
  insuranceSubType,
  insuranceName,
}: {
  insuranceSubType: InsuranceSubType;
  insuranceCompany: string;
  insuranceName: InsurancePackageName;
}) {
  const market = insuranceCompany.split('-')[0];
  switch (market) {
    case 'fr':
      return getParametersFR({ insuranceSubType, insuranceName });
    case 'se':
    default:
      return getParametersSE({ insuranceSubType });
  }
}

export const getEquivalentPackageScope = ({
  coveragePolicyData,
  packageScope,
}: {
  coveragePolicyData: CoveragePolicyData;
  packageScope: PackageScopeRequest;
}) => {
  const { market, insuranceSubType } = packageScope;
  const { residentialStatus, ownershipStatus } = coveragePolicyData;
  switch (market) {
    case 'fr':
      return mapEquivalentPackageScopeFr({ insuranceSubType, residentialStatus, ownershipStatus });
    // case 'se': Todo: Add response for other markets
    default:
      return mapEquivalentPackageScopeFr({ insuranceSubType, residentialStatus, ownershipStatus });
  }
};

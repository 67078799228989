import { ComponentType, lazy, LazyExoticComponent } from 'react';

function retry<T>(
  factory: () => Promise<{ default: ComponentType<T> }>,
  retriesLeft = 4,
  interval = 1000,
): Promise<{ default: ComponentType<T> }> {
  return new Promise((resolve, reject) => {
    factory()
      .then((result) => resolve(result))
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 0) {
            reject(error);
            return;
          }
          retry(factory, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function lazyWithPreloadAndRetry<T>(factory: () => Promise<{ default: ComponentType<T> }>) {
  const Component: LazyExoticComponent<ComponentType<T>> & {
    preload?: () => Promise<{ default: ComponentType<T> }>;
  } = lazy(() => retry(factory));
  Component.preload = () => retry(factory);
  return Component;
}

import { InsuranceSubType, InsuranceType } from '../InsuranceType';

import { CoreGeneralInsurance } from './CoreGeneralInsurance';

export type CoreVehicleInsurance = Omit<CoreGeneralInsurance, 'insuranceType'> & {
  insuranceType: InsuranceType.vehicleInsurance;
  registrationNo?: string;
  brand?: string;
  model?: string;
};

export type CoreCarInsurance = CoreVehicleInsurance & {
  insuranceSubType: InsuranceSubType.carInsurance;
  maxMileage?: number;
  youngDriver?: boolean;
  youngDriverAge?: number;
  usedForWork?: boolean;
  // Standardized way of saying hel, halv, trafik, enbart vagnskada, avställning
  // coverage: VehicleCoverage | VehicleCoverageUK;
  insuranceLevel?: InsuranceLevel;
};

// Legacy
// Should be deprecated
export enum InsuranceLevel {
  UNDEFINED,
  BASE,
  PLUS,
}

export enum VehicleType {
  SNOW_MOBILE = 'SNOW_MOBILE',
  ATV = 'ATV',
  JET_SKI = 'JET_SKI',
  TRACTOR = 'TRACTOR',
  UNKNOWN = 'UNKNOWN',
}

export enum PensionType {
  IPS_PENSION = 'IPS_PENSION', // 'Individuellt pensionssparande'
  PRIVATE_PENSION = 'PRIVATE_PENSION', // 'Privatpension'
  OCCUPATIONAL_PENSION = 'OCCUPATIONAL_PENSION', // 'Tjänstepension'
  COLLECTIVE_OCCUPATIONAL_PENSION = 'COLLECTIVE_OCCUPATIONAL_PENSION', // 'Tjänstepension'
  CAPITAL_INSURANCE = 'CAPITAL_INSURANCE', // 'Kapitalförsäkring'
  UNKNOWN_PENSION_TYPE = 'UNKNOWN_PENSION_TYPE', // 'Okänt pensionssparande'
}

export enum PensionClassification {
  ITP = 'ITP',
  FTP = 'FTP',
  KAP_KL = 'KAP-KL',
  SAF_LO = 'SAF-LO',
  PA16 = 'PA16',
  PA_KFS = 'PA-KFS',
  BTP = 'BTP',
  PPA07 = 'PPA07',
  PPA13 = 'PPA13',
  UNKNOWN_PENSION_CLASSIFICATION = 'UNKNOWN_PENSION_CLASSIFICATION',
}

export type PensionFund = {
  type?: string;
  isin: string;
  name: string;
  shareCount: number;
  value: number;
  valueInPercent: number;
  feeFixed?: number;
  feeVariable?: number;
  currencyCode: string;
};

export type Pension = {
  insuranceCompany: string;
  pensionType: PensionType;
  pensionClassification?: PensionClassification;
  /**
   * @deprecated use insurance name instead
   */
  accountName?: string;
  insuranceName: string;
  insuranceNumber: string;
  currentValue: number;
  totalPaid: number;
  startDate: string;
  activePayment?: boolean;
  activePayout?: boolean;
  hasSurvivorsProtection?: boolean;
  insuranceHolderName?: string;
  insuranceHolderOrgNumber?: string;
  insuranceBeneficiaryName?: string; // Will eventually be replaced by insuredPersonName
  insuredPersonName?: string; // Will eventually replace insuranceBeneficiaryName
  feeFixed?: number;
  feeVariable?: number;
  feesCollected?: boolean;
  feesPreviousYear?: number;
  holding: PensionFund[];
  organizationAlternatives?: OrganizationAlternative[];
  otherInsuredPerson?: boolean;
  traditionallyManaged?: boolean;
  insurance?: never;
  movability?: Movability;
  externalId: string;
};

export type OrganizationAlternative = {
  orgNumber: string;
  orgName: string;
  orgCity: string;
};

export enum MovabilityResult {
  MOVABLE = 'MOVABLE',
  NOT_MOVABLE = 'NOT_MOVABLE',
  UNKNOWN = 'UNKNOWN',
}

export type MobilityRuleName =
  | 'ACTIVE_PAYMENT'
  | 'ACTIVE_PAYOUT'
  | 'COMPATIBLE_PENSION_TYPE'
  | 'DEFAULT_RULE_NAME'
  | 'OLD_PENSION'
  | 'OLD_TRAD_PENSION'
  | 'TRAD_PENSION';

export interface MovabilityRuleResult {
  rule: MobilityRuleName;
  result: MovabilityResult;
}

export interface ManagementInstitution {
  name: string;
  url: string;
}

export interface Movability {
  result: MovabilityResult;
  evaluatedRules: MovabilityRuleResult[];
  managementInstitutions?: ManagementInstitution[];
}

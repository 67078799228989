import { IntlConfig } from 'react-intl';

import { sentryCaptureException } from '@main/services';
import { Language } from '@main/types';

type LatestMessagesProps = {
  baseLanguage: Language;
  extensionName?: string;
};

type Messages = NonNullable<IntlConfig['messages']>;

const TRANSLATIONS_BASE_URL = 'https://translations.insurely.com/blocks/latest/';

export const fetchLatestMessages = async ({
  baseLanguage,
  extensionName,
}: LatestMessagesProps): Promise<Messages | undefined> => {
  try {
    let extendedMessages = {};
    const baseMessagesResponse = await fetch(`${TRANSLATIONS_BASE_URL}${baseLanguage}.json`);
    const baseMessages = await baseMessagesResponse.json();

    if (extensionName) {
      const extendedMessagesResponse = await fetch(`${TRANSLATIONS_BASE_URL}${extensionName}.json`);
      extendedMessages = await extendedMessagesResponse.json();
    }

    return {
      ...baseMessages,
      ...extendedMessages,
    };
  } catch (e) {
    sentryCaptureException(
      new Error(
        `Error when fetching latest translations for: ${extensionName || baseLanguage}. Exception: ${e}`,
      ),
    );
  }
};

import { CarAddOns, CarQuotePayload } from '@main/types';

export const getPriceEstimateSE = (payload: CarQuotePayload) => {
  let price = 0;
  switch (payload.insuranceQuoteDetails.insuranceName) {
    case 'Helförsäkring':
      price += 7000;
      break;
    case 'Halvförsäkring':
      price += 5500;
      break;
    default:
      price += 3000;
      break;
  }
  switch (payload.insuranceQuoteDetails.deductibleVehicleDamage) {
    case '6000':
      price += 500;
      break;
    case '4500':
      price += 1000;
      break;
    default:
      price += 1500;
      break;
  }
  switch (payload.insuranceQuoteDetails.yearlyMaxMileage) {
    case '25000-':
      price += 1000;
      break;
    case '20000-25000':
      price += 800;
      break;
    case '15000-20000':
      price += 500;
      break;
    case '10000-15000':
      price += 300;
      break;
    default:
      price += 100;
      break;
  }
  if (payload.insuranceQuoteDetails.underAgedDriver) {
    price += 500;
  }

  if (payload.insuranceQuoteDetails.addOns?.includes(CarAddOns.addOnAssistance)) {
    price += 150;
  }
  if (payload.insuranceQuoteDetails.addOns?.includes(CarAddOns.addOnReducedDeductible)) {
    price += 300;
  }
  if (payload.insuranceQuoteDetails.addOns?.includes(CarAddOns.addOnRental)) {
    price += 500;
  }

  return {
    insuranceName: payload.insuranceQuoteDetails.insuranceName,
    priceDetails: {
      annualPremium: price,
    },
  };
};

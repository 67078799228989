import { sharedApiClient, useQuery } from '@main/services';

import { WealthApiResponse } from '@main/shared-exports';

const FIFTEEN_MINUTES_IN_MILLI_SEC = 900000;

export const useWealthData = ({ userId }: { userId: string }) =>
  useQuery({
    queryKey: ['wealthOverview', userId],
    queryFn: async () =>
      sharedApiClient<WealthApiResponse>(`/user/${userId}/wealth/overview`, {
        headers: {
          'insurely-version': '2024-06-01',
        },
      }).then((response) => response.data),
    refetchOnWindowFocus: false,
    staleTime: FIFTEEN_MINUTES_IN_MILLI_SEC,
  });

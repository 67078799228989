import { Button, Icon, Toggle, Typography } from '@insurely/ui';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { PlateContainer } from '../../../WealthOverview/components/PlateContainer';

import styles from './settings.module.css';

interface SettingsProps {
  updateAccount: () => void;
  hideAccount: () => void;
  deleteAccount: () => void;
}

export const Settings = ({ updateAccount, hideAccount, deleteAccount }: SettingsProps) => {
  const { formatMessage } = useIntl();

  return (
    <PlateContainer className={classNames(styles.plate)}>
      <Typography component="h3" variant="ParagraphCaption">
        {formatMessage({
          id: 'overview.wealth-account.settings-headline-1',
          defaultMessage: 'Settings for overview',
        })}
      </Typography>
      <Toggle
        checked={false} // TODO: Change when implementing hideAccount
        id="overview-wealth-settings-toggle"
        onCheckedChange={hideAccount}
        className={styles.toggle}
        label={
          <div className={styles.toggleTextContainer}>
            <Typography
              component="label"
              variant="ParagraphBodySmall"
              htmlFor="overview-wealth-settings-toggle"
            >
              {formatMessage({
                id: 'overview.wealth-account.settings-toggle-headline',
                defaultMessage: 'Hide account',
              })}
            </Typography>
            <Typography component="p" variant="ParagraphTiny">
              {formatMessage({
                id: 'overview.wealth-account.settings-toggle-paragraph',
                defaultMessage:
                  'The value of the account is not added to the total amounts and is placed in the hidden part of the account list.',
              })}
            </Typography>
          </div>
        }
      />
      <Typography component="h3" variant="ParagraphCaption" className={styles.updateHeadline}>
        {formatMessage({
          id: 'overview.wealth-account.settings-headline-2',
          defaultMessage: 'Handle data',
        })}
      </Typography>
      <Button
        variant="ghost"
        icon={<Icon className={styles.icon} name="reload" size={24} />}
        iconPosition="left"
        className={classNames(styles.updateButton, styles.overwrite)}
        onClick={updateAccount}
      >
        <Typography component="span" variant="Label-2" className={styles.updateButtonText}>
          {formatMessage({
            id: 'overview.wealth-account.settings-update-button',
            defaultMessage: 'Update data',
          })}
        </Typography>
      </Button>
      <Button
        variant="ghost"
        icon={<Icon className={styles.icon} name="delete" size={24} />}
        iconPosition="left"
        className={classNames(styles.deleteButton, styles.overwrite)}
        onClick={deleteAccount}
      >
        <Typography component="span" variant="Label-2" className={styles.deleteButtonText}>
          {formatMessage({
            id: 'overview.wealth-account.settings-delete-button',
            defaultMessage: 'Remove account from overview',
          })}
        </Typography>
      </Button>
    </PlateContainer>
  );
};

import {
  CHECKOUT_PATH,
  COMPARE_PATH,
  CONTACT_PATH,
  DATA_AGGREGATION_PATH,
  INITIAL_NAVIGATION_PATH,
  OVERVIEW_PATH,
  PENSION_MOVE_PATH,
  QUESTIONNAIRE_PATH,
  RESULTS_PATH,
} from '@main/constants/paths';

import * as CHECKOUT_BASE_ROUTES from './checkout-base.routes';
import * as CHECKOUT_CAR_ROUTES from './checkout-car.routes';
import * as CHECKOUT_CONDO_ROUTES from './checkout-condo.routes';
import * as CHECKOUT_FRENCH_ROUTES from './checkout-fr.routes';
import * as CHECKOUT_GENERIC_ROUTES from './checkout-generic.routes';
import * as COMPARE_ROUTES from './compare.routes';
import * as DATA_AGGREGATION_ROUTES from './data-aggregation.routes';
import * as INITIAL_NAVIGATION_ROUTES from './initial-navigation.routes';
import * as OVERVIEW_ROUTES from './overview.routes';
import * as PENSION_MOVE_ROUTES from './pension-move.routes';
import * as QUESTIONNAIRE_ROUTES from './questionnaire.routes';
import * as RESULTS_ROUTES from './results.routes';

const createPathMap = (parentPath: string, routes: Record<string, string>) =>
  Object.entries(routes).reduce(
    (pathMap, [name, path]) => ({
      ...pathMap,
      [parentPath + path]: name,
    }),
    {},
  );

export const pathMap = {
  ...createPathMap(INITIAL_NAVIGATION_PATH, INITIAL_NAVIGATION_ROUTES),
  ...createPathMap(DATA_AGGREGATION_PATH, DATA_AGGREGATION_ROUTES),
  ...createPathMap(CHECKOUT_PATH + CHECKOUT_BASE_ROUTES.CHECKOUT_SE_CONDO, {
    ...CHECKOUT_CONDO_ROUTES,
    ...CHECKOUT_GENERIC_ROUTES,
  }),
  ...createPathMap(CHECKOUT_PATH + CHECKOUT_BASE_ROUTES.CHECKOUT_SE_CAR, {
    ...CHECKOUT_CAR_ROUTES,
    ...CHECKOUT_GENERIC_ROUTES,
  }),
  ...createPathMap(`${CHECKOUT_PATH}/`, CHECKOUT_FRENCH_ROUTES),
  ...createPathMap(COMPARE_PATH, {
    ...COMPARE_ROUTES,
  }),
  ...createPathMap(`${OVERVIEW_PATH}/`, OVERVIEW_ROUTES),
  ...createPathMap(`${PENSION_MOVE_PATH}/`, PENSION_MOVE_ROUTES),
  ...createPathMap(`${QUESTIONNAIRE_PATH}/`, QUESTIONNAIRE_ROUTES),
  [CONTACT_PATH]: 'CONTACT_DETAILS',
  ...createPathMap(RESULTS_PATH, RESULTS_ROUTES),
  // TODO: Update to 'RESULTS' when we know that customers don't listen to it
  [RESULTS_PATH]: 'DATA_AGGREGATION_RESULTS',
} as const;

import { faker } from '@faker-js/faker';
import { factory, primaryKey, nullable, manyOf } from '@mswjs/data';

export const mockDb = factory({
  collection: {
    // ...with these properties and value getters.
    id: primaryKey(faker.string.uuid),
    insuranceCompany: String,
    status: String,
    userInput: String,
    extraInformation: {
      ACCESS_TOKEN: nullable(String),
    },
    collectingItems: nullable(manyOf('collectingItem')),
  },
  collectingItem: {
    id: primaryKey(faker.string.uuid),
    status: String,
    displayName: nullable(String),
    insuranceSubType: nullable(String),
  },
});

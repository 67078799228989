import {
  InsuranceDetailsView,
  InsuranceOverview,
  WealthOverview,
  WealthAccount,
} from '@main/blocks/overview/views';
import {
  OVERVIEW_INSURANCE_DETAILS,
  OVERVIEW_INSURANCE_HOME,
  OVERVIEW_WEALTH_HOME,
  OVERVIEW_PATH,
  OVERVIEW_WEALTH_ACCOUNT,
} from '@main/constants';

import { Navigate } from '@main/services';
import { useUseCase } from '@main/shared-exports';

const Root = () => {
  const { useCase } = useUseCase();

  if (useCase.name.includes('wealth')) {
    return <Navigate to={`${OVERVIEW_PATH}/${OVERVIEW_WEALTH_HOME}`} replace />;
  }
  return <Navigate to={`${OVERVIEW_PATH}/${OVERVIEW_INSURANCE_HOME}`} replace />;
};

export const overviewRoutes = [
  { path: OVERVIEW_INSURANCE_HOME, Component: InsuranceOverview },
  { path: `${OVERVIEW_INSURANCE_DETAILS}/:externalId`, Component: InsuranceDetailsView },
  { path: OVERVIEW_WEALTH_HOME, Component: WealthOverview },
  { path: `${OVERVIEW_WEALTH_ACCOUNT}/:externalId`, Component: WealthAccount },
  { path: '', Component: Root },
];

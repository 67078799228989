export const localStorageAvailable = () => {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
};

const blocksKey = 'blocks';

export type StorageKeyValue = Record<string, unknown>;

export type StorageKey = keyof StorageKeyValue;
export type StorageValue<K extends StorageKey> = StorageKeyValue[K];

export type RemoveItemParams<K extends StorageKey> = {
  key: K;
  prefix?: string;
  useSessionStorage?: boolean;
};

export type GetItemParams<K> = {
  key: K;
  prefix?: string;
  useSessionStorage?: boolean;
};

export type SetItemParams<KeyValueMapping extends StorageKeyValue, K extends StorageKey> = {
  key: K;
  value: KeyValueMapping[K];
  prefix?: string;
  useSessionStorage?: boolean;
};

export const removeLocalItem = <K extends StorageKey>({
  key,
  prefix,
  useSessionStorage,
}: RemoveItemParams<K>): void => {
  const storage = useSessionStorage ? window.sessionStorage : window.localStorage;
  return storage.removeItem(`${blocksKey}-${prefix ? `${prefix}-` : ''}${String(key)}`);
};

export const getLocalItem = <
  KeyValueMapping extends StorageKeyValue,
  K extends keyof KeyValueMapping,
>({
  prefix,
  key,
  useSessionStorage,
}: GetItemParams<K>): KeyValueMapping[K] | null => {
  try {
    const storage = useSessionStorage ? window.sessionStorage : window.localStorage;
    const item = storage.getItem(`${blocksKey}-${prefix ? `${prefix}-` : ''}${String(key)}`);
    try {
      return item ? JSON.parse(item) : null;
    } catch (error) {
      removeLocalItem({ key: String(key), useSessionStorage });
      return null;
    }
  } catch (e) {
    console.error('Could not get local storage', e);
  }
  return null;
};

export const setLocalItem = <KeyValueMapping extends StorageKeyValue, K extends StorageKey>({
  key,
  value,
  prefix,
  useSessionStorage,
}: SetItemParams<KeyValueMapping, K>): void => {
  const storage = useSessionStorage ? window.sessionStorage : window.localStorage;
  return storage.setItem(
    `${blocksKey}-${prefix ? `${prefix}-` : ''}${String(key)}`,
    JSON.stringify(value),
  );
};

export enum LoginMethod {
  'DANISH_MITID' = 'DANISH_MITID',
  'DANISH_MITID_SAME_DEVICE' = 'DANISH_MITID_SAME_DEVICE',
  'ESTONIAN_MOBILE_ID' = 'ESTONIAN_MOBILE_ID',
  'ESTONIAN_MOBILE_ID_USERNAME' = 'ESTONIAN_MOBILE_ID_USERNAME',
  'ESTONIAN_SMART_ID' = 'ESTONIAN_SMART_ID',
  'ESTONIAN_SMART_ID_USERNAME' = 'ESTONIAN_SMART_ID_USERNAME',
  'LATVIAN_MOBILE_EPARAKSTS' = 'LATVIAN_MOBILE_EPARAKSTS',
  'LATVIAN_MOBILE_EPARAKSTS_USERNAME' = 'LATVIAN_MOBILE_EPARAKSTS_USERNAME',
  'LATVIAN_SMART_ID' = 'LATVIAN_SMART_ID',
  'LATVIAN_SMART_ID_USERNAME' = 'LATVIAN_SMART_ID_USERNAME',
  'LITHUANIAN_SMART_ID' = 'LITHUANIAN_SMART_ID',
  'LITHUANIAN_MOBILE_ID' = 'LITHUANIAN_MOBILE_ID',
  'NORWEGIAN_BANKID_ON_MOBILE' = 'NORWEGIAN_BANKID_ON_MOBILE',
  'NORWEGIAN_BANKID_ON_MOBILE_FULL_PERSONAL_NUMBER' = 'NORWEGIAN_BANKID_ON_MOBILE_FULL_PERSONAL_NUMBER',
  'PDF_UPLOAD' = 'PDF_UPLOAD',
  'SWEDISH_MOBILE_BANKID_ANY_DEVICE' = 'SWEDISH_MOBILE_BANKID_ANY_DEVICE',
  'SWEDISH_MOBILE_BANKID_ANY_DEVICE_TEST' = 'SWEDISH_MOBILE_BANKID_ANY_DEVICE_TEST',
  'SWEDISH_MOBILE_BANKID_OTHER_DEVICE_TEST' = 'SWEDISH_MOBILE_BANKID_OTHER_DEVICE_TEST',
  'SWEDISH_MOBILE_BANKID_SAME_DEVICE_TEST' = 'SWEDISH_MOBILE_BANKID_SAME_DEVICE_TEST',
  'SWEDISH_MOBILE_BANKID_OTHER_DEVICE' = 'SWEDISH_MOBILE_BANKID_OTHER_DEVICE',
  'SWEDISH_MOBILE_BANKID_OTHER_DEVICE_MOCK' = 'SWEDISH_MOBILE_BANKID_OTHER_DEVICE_MOCK',
  'SWEDISH_MOBILE_BANKID_SAME_DEVICE' = 'SWEDISH_MOBILE_BANKID_SAME_DEVICE',
  'SWEDISH_MOBILE_BANKID_SAME_DEVICE_CLIENT_SIDE_AUTHENTICATION' = 'SWEDISH_MOBILE_BANKID_SAME_DEVICE_CLIENT_SIDE_AUTHENTICATION',
  'SWEDISH_MOBILE_BANKID_SAME_DEVICE_CLIENT_SIDE_AUTHENTICATION_MOCK' = 'SWEDISH_MOBILE_BANKID_SAME_DEVICE_CLIENT_SIDE_AUTHENTICATION_MOCK',
  'SWEDISH_MOBILE_BANKID_SAME_OR_OTHER_DEVICE' = 'SWEDISH_MOBILE_BANKID_SAME_OR_OTHER_DEVICE',
  'SWEDISH_SECURITY_TOKEN' = 'SWEDISH_SECURITY_TOKEN',
  'USERNAME_AND_PASSWORD' = 'USERNAME_AND_PASSWORD',
  'EMAIL' = 'EMAIL',
}

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Plate, TextInput, Typography } from '@insurely/ui';

import { useForm } from 'react-hook-form';
import { useIntl, type IntlShape } from 'react-intl';

import { z } from 'zod';

import { BlocksBaseView } from '@main/components/BlocksBaseView/BlocksBaseView';
import { LanguageProvider } from '@main/i18n';
import { useNavigate } from '@main/services';
import { Language } from '@main/types';

import styles from './styles.module.css';

export const schema = (intl: IntlShape) =>
  z.object({
    code: z.string().regex(/^(\d{6}|4444|55555)$/, {
      // 4444/55555 are demo codes for test/prod
      message: intl.formatMessage({
        id: 'resume-code.code-input-invalid',
        defaultMessage: 'Invalid code. Please enter a 6-digit numerical code.',
      }),
    }),
  });

type FormData = z.infer<ReturnType<typeof schema>>;

function Content() {
  const intl = useIntl();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema(intl)),
  });

  const onSubmit = (data: FormData) => {
    navigate('/', { searchParams: { resumeCode: data.code } });
  };

  return (
    <BlocksBaseView showFooter isWebView>
      <div className={styles.container}>
        <Typography variant="Headline-5" component="h1">
          {intl.formatMessage({ id: 'resume-code.title', defaultMessage: '6 digit code' })}
        </Typography>
        <Typography variant="ParagraphBodySmall" component="p" className={styles.subTitle}>
          {intl.formatMessage({
            id: 'resume-code.sub-title',
            defaultMessage: 'Input your code to continue',
          })}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            {...register('code')}
            label={intl.formatMessage({
              id: 'resume-code.code-input-label',
              defaultMessage: 'Code',
            })}
            className={styles.codeTextInput}
            error={!!errors.code}
            helperText={errors.code?.message}
          />

          <Button type="submit" className={styles.submitButton} fullWidth>
            {intl.formatMessage({ id: 'resume-code.submit-button', defaultMessage: 'Continue' })}
          </Button>
        </form>
        <Plate variant="filled" className={styles.infoBox}>
          <Typography variant="Label-1" component="h2">
            {intl.formatMessage({
              id: 'resume-code.infobox-title',
              defaultMessage: "Don't have a code?",
            })}
          </Typography>
          <Typography variant="ParagraphBodySmall" component="h2" className={styles.infoBoxBody}>
            {intl.formatMessage({
              id: 'resume-code.infobox-body',
              defaultMessage:
                'On the website you came from, the 5-digit code is displayed. Go back and copy the code, then come back here to continue.',
            })}
          </Typography>
        </Plate>
      </div>
    </BlocksBaseView>
  );
}

export function ResumeCodeInputView() {
  const browserLanguage = window.navigator.language;
  const language = Object.values(Language).includes(browserLanguage as Language)
    ? (browserLanguage as Language)
    : Language.en;

  return (
    <LanguageProvider language={language}>
      <Content />
    </LanguageProvider>
  );
}

import { Currency } from './configurations';

interface AccountInformation {
  cashAccountNumber: string;
  cashValue: MonetaryAmount;
  interestRate: number;
}

export interface Fees {
  feeFixed?: MonetaryAmount;
  feeVariable?: number;
  feesCollected: boolean;
  feesPreviousYear: MonetaryAmount;
}

interface FinancialPeriod {
  endDate: string;
  startDate: string;
}

export interface Holder {
  name?: string;
  organization?: Organization;
  organizationSuggestions?: Array<Organization>;
}

interface Insurance {
  insuredPersonName?: string;
  survivorsProtection: boolean;
}

interface Movability {
  evaluatedRules: Array<MovabilityRule>;
  managementInstitutions?: Array<PensionManagementInstitution>;
  result: string;
}

interface MovabilityRule {
  result: string;
  rule: string;
}

export interface Organization {
  city: string;
  financialInformation: OrganizationFinancialInformation;
  name: string;
  number: string;
}

export enum Range {
  AGE_RANGE = 'AGE_RANGE',
  DATE_RANGE = 'DATE_RANGE',
}

interface PayoutPeriod {
  type: Range;
  endDate?: string;
  startDate?: string;
  endAge?: number;
  startAge?: number;
}

interface PayoutDetails {
  active: boolean;
  amount: MonetaryAmount;
  lifelongPayout: boolean;
  period: PayoutPeriod;
}

interface ProductFinancialInformation {
  currentValue: MonetaryAmount;
  performanceSinceStart?: number;
  totalDeposits?: MonetaryAmount;
}

export enum HoldingType {
  EQUITY = 'EQUITY',
  FUND = 'FUND',
}

interface Holding {
  acquisitionValue?: MonetaryAmount;
  feeVariable?: number;
  isin: string;
  name: string;
  quantity?: number;
  type: HoldingType;
  value: MonetaryAmount;
}

interface MonetaryAmount {
  amount: number;
  currency: Currency;
}

interface OrganizationFinancialInformation {
  employeeCount: number;
  financialPeriod: FinancialPeriod;
  netIncome: MonetaryAmount;
  shareCapital: MonetaryAmount;
  turnover: MonetaryAmount;
}

interface PensionManagementInstitution {
  name: string;
  url: string;
}

/**
 * Financial Product Type Objects
 */

export type FinancialProduct =
  | CustodyAccount
  | ISK
  | InvestmentUnknown
  | KF
  | PensionCollectiveOccupational
  | PensionIPS
  | PensionOccupational
  | PensionPrivate
  | PensionUnknown;

export type PensionFinancialProduct =
  | PensionCollectiveOccupational
  | PensionIPS
  | PensionOccupational
  | PensionPrivate
  | PensionUnknown;

export type InvestmentFinancialProduct = CustodyAccount | ISK | InvestmentUnknown;

export enum AvailableFinancialProductCompanies {
  SE_DEMO = 'se-demo',
  SE_FOLKSAM = 'se-folksam',
  SE_LANSFORSAKRINGAR = 'se-lansforsakringar',
  SE_AVANZA = 'se-avanza',
  SE_MOVESTIC = 'se-movestic',
  SE_SPP = 'se-spp',
  SE_NORDEA = 'se-nordea',
  SE_AMF = 'se-amf',
  SE_SEB = 'se-seb',
  SE_ALECTA = 'se-alecta',
  SE_NORDNET = 'se-nordnet',
  SE_HANDELSBANKEN = 'se-handelsbanken',
  SE_FUTUR = 'se-futur',
  SE_SWEDBANK = 'se-swedbank',
  SE_SKANDIA = 'se-skandia',
  SE_ICA_BANK = 'se-ica-bank',
  SE_DANSKE_BANK = 'se-danske-bank',
}

export enum FinancialProductType {
  KF = 'KF',
  ISK = 'ISK',
  CUSTODY_ACCOUNT = 'CUSTODY_ACCOUNT',
  UNKNOWN_INVESTMENT_TYPE = 'UNKNOWN_INVESTMENT_TYPE',
  IPS_PENSION = 'IPS_PENSION',
  PRIVATE_PENSION = 'PRIVATE_PENSION',
  OCCUPATIONAL_PENSION = 'OCCUPATIONAL_PENSION',
  COLLECTIVE_OCCUPATIONAL_PENSION = 'COLLECTIVE_OCCUPATIONAL_PENSION',
  UNKNOWN_PENSION_TYPE = 'UNKNOWN_PENSION_TYPE',
}

export enum Classification {
  ITP = 'ITP',
  FTP = 'FTP',
  KAP_KL = 'KAP-KL',
  SAF_LO = 'SAF-LO',
  PA16 = 'PA16',
  PA_KFS = 'PA-KFS',
  GTP = 'GTP',
  BTP = 'BTP',
  PPA07 = 'PPA07',
  PPA13 = 'PPA13',
  UNKNOWN_PENSION_CLASSIFICATION = 'UNKNOWN_PENSION_CLASSIFICATION',
}

export enum AccountState {
  DISPLAYED = 'DISPLAYED',
  HIDDEN = 'HIDDEN',
  REMOVED = 'REMOVED',
}

interface GenericAccount {
  company: AvailableFinancialProductCompanies;
  companyDisplayName: string;
  financialInformation: ProductFinancialInformation;
  holder: Holder;
  holdings: Array<Holding>;
  id: string;
  personalInformation: Record<string, string>;
  productName: string;
  productNumber: string;
}

interface GenericSavingsAccount extends GenericAccount {
  accountInformation: AccountInformation;
  monthlySavingsAmount: MonetaryAmount;
}

interface GenericPensionAccount extends GenericAccount {
  activePayment: boolean;
  activePayout: boolean;
  classification?: Classification;
  fees: Fees;
  insurance: Insurance;
  movability: Movability;
  otherInsuredPerson?: boolean;
  payoutDetails?: Array<PayoutDetails>;
  startDate: string;
  traditionallyManaged: boolean;
}

export interface CustodyAccount extends GenericSavingsAccount {
  type: FinancialProductType.CUSTODY_ACCOUNT;
}

export interface ISK extends GenericSavingsAccount {
  type: FinancialProductType.ISK;
}

export interface InvestmentUnknown extends GenericSavingsAccount {
  type: FinancialProductType.UNKNOWN_INVESTMENT_TYPE;
}

export interface KF extends GenericAccount {
  fees: Fees;
  insurance: Insurance;
  traditionallyManaged: boolean;
  type: FinancialProductType.KF;
}

export interface PensionCollectiveOccupational extends GenericPensionAccount {
  type: FinancialProductType.COLLECTIVE_OCCUPATIONAL_PENSION;
}

export interface PensionIPS extends GenericPensionAccount {
  type: FinancialProductType.IPS_PENSION;
}

export interface PensionOccupational extends GenericPensionAccount {
  type: FinancialProductType.OCCUPATIONAL_PENSION;
}

export interface PensionPrivate extends GenericPensionAccount {
  type: FinancialProductType.PRIVATE_PENSION;
}

export interface PensionUnknown extends GenericPensionAccount {
  type: FinancialProductType.UNKNOWN_PENSION_TYPE;
}

interface Assets {
  total: MonetaryAmount;
  savings: MonetaryAmount;
  pension: MonetaryAmount;
}

export type Account = FinancialProduct & {
  state: AccountState;
  isStale: boolean;
};

export type PensionAccount = PensionFinancialProduct & {
  state: AccountState;
  isStale: boolean;
};

export type InvestmentAccount = InvestmentFinancialProduct & {
  state: AccountState;
  isStale: boolean;
};

export type KfAccount = KF & {
  state: AccountState;
  isStale: boolean;
};

export interface WealthApiResponse {
  assets: Assets;
  hasStaleAccountData: boolean;
  latestUpdatedDate: Date;
  accounts: Array<Account>;
}

import {
  CondoInsuranceName,
  Country,
  FrenchCarInsuranceName,
  InsuranceSubType,
  InsuranceType,
} from '@main/types';
import { EquivalentPackageScopesResponse } from '@main/types/insurance/EquivalentPackageScope';
import { OwnershipStatus, ResidentialStatusFR } from '@main/types/insurance/france';

const primaryPackageScope: EquivalentPackageScopesResponse = {
  packageScopes: [
    {
      packageScope: {
        market: Country.fr,
        insuranceType: InsuranceType.houseContentInsurance,
        insuranceSubType: InsuranceSubType.condoInsurance,
        insuranceCompany: 'fr-cardif',
        packageName: CondoInsuranceName['mrh principale'],
      },
    },
  ],
};

const secondaryOwnerPackageScope: EquivalentPackageScopesResponse = {
  packageScopes: [
    {
      packageScope: {
        market: Country.fr,
        insuranceType: InsuranceType.houseContentInsurance,
        insuranceSubType: InsuranceSubType.condoInsurance,
        insuranceCompany: 'fr-cardif',
        packageName: CondoInsuranceName['mrh secondaire'],
      },
    },
  ],
};

const vehiclePackageScope: EquivalentPackageScopesResponse = {
  packageScopes: [
    {
      packageScope: {
        market: Country.fr,
        insuranceType: InsuranceType.vehicleInsurance,
        insuranceSubType: InsuranceSubType.carInsurance,
        insuranceCompany: 'fr-cardif',
        packageName: FrenchCarInsuranceName['formule tiers +'],
      },
    },
    {
      packageScope: {
        market: Country.fr,
        insuranceType: InsuranceType.vehicleInsurance,
        insuranceSubType: InsuranceSubType.carInsurance,
        insuranceCompany: 'fr-cardif',
        packageName: FrenchCarInsuranceName['formule tous risques'],
      },
    },
    {
      packageScope: {
        market: Country.fr,
        insuranceType: InsuranceType.vehicleInsurance,
        insuranceSubType: InsuranceSubType.carInsurance,
        insuranceCompany: 'fr-cardif',
        packageName: FrenchCarInsuranceName['formule tiers'],
      },
    },
  ],
};

const emptyEquivalentPackageScope: EquivalentPackageScopesResponse = {
  packageScopes: [],
};

const getCondoOwnerScope = (residentialStatus?: ResidentialStatusFR) => {
  switch (residentialStatus) {
    case ResidentialStatusFR.SECONDARY_RESIDENCE:
      return secondaryOwnerPackageScope;
    case ResidentialStatusFR.PRIMARY_RESIDENCE:
      return primaryPackageScope;
    default:
      return emptyEquivalentPackageScope;
  }
};

const getCondoTenantScope = (residentialStatus?: ResidentialStatusFR) => {
  if (residentialStatus === ResidentialStatusFR.PRIMARY_RESIDENCE) {
    return primaryPackageScope;
  }
  return emptyEquivalentPackageScope;
};

const getCondoPackageScope = (
  ownershipStatus?: OwnershipStatus,
  residentialStatus?: ResidentialStatusFR,
) => {
  if (ownershipStatus === OwnershipStatus.OWNER) {
    return getCondoOwnerScope(residentialStatus);
  }
  if (ownershipStatus === OwnershipStatus.TENANT) {
    return getCondoTenantScope(residentialStatus);
  }

  return emptyEquivalentPackageScope;
};

export const mapEquivalentPackageScopeFr = ({
  insuranceSubType,
  ownershipStatus,
  residentialStatus,
}: {
  insuranceSubType: InsuranceSubType;
  residentialStatus?: ResidentialStatusFR;
  ownershipStatus?: OwnershipStatus;
}) => {
  switch (insuranceSubType) {
    case InsuranceSubType.condoInsurance:
      return getCondoPackageScope(ownershipStatus, residentialStatus);
    case InsuranceSubType.carInsurance:
      return vehiclePackageScope;
    default:
      return emptyEquivalentPackageScope;
  }
};

import { match } from 'node-match-path';

import { sentryCaptureException } from '@main/services/sentry';

export enum PostMessageName {
  'WAITING_FOR_INITIALIZATION' = 'WAITING_FOR_INITIALIZATION',
  'INITIALIZE_APP' = 'INITIALIZE_APP',
  'CHANGE_LANGUAGE' = 'CHANGE_LANGUAGE',
  'AUTH_TOKEN_EXPIRING_SOON' = 'AUTH_TOKEN_EXPIRING_SOON',
  'UPDATE_AUTH_TOKEN' = 'UPDATE_AUTH_TOKEN',
  'VALID_AUTH_TOKEN' = 'VALID_AUTH_TOKEN',
  'INVALID_AUTH_TOKEN' = 'INVALID_AUTH_TOKEN',

  'APP_LOADED' = 'APP_LOADED',
  'APP_CLOSE' = 'APP_CLOSE',
  'APP_HEIGHT' = 'APP_HEIGHT',
  'APP_CENTER' = 'APP_CENTER',
  'PAGE_VIEW' = 'PAGE_VIEW',

  'SELECTED_COMPANY' = 'SELECTED_COMPANY',
  'SELECTED_AUTHENTICATION_METHOD' = 'SELECTED_AUTHENTICATION_METHOD',
  'INIT_SWEDISH_BANKID' = 'INIT_SWEDISH_BANKID',
  'OPEN_SWEDISH_BANKID' = 'OPEN_SWEDISH_BANKID',
  'INVALID_CREDENTIALS' = 'INVALID_CREDENTIALS',
  'RESET_HASH' = 'RESET_HASH',
  'RESULTS' = 'RESULTS',
  'COLLECTION_INITIATED' = 'COLLECTION_INITIATED',
  'COLLECTION_STATUS' = 'COLLECTION_STATUS',
  'COLLECTION_ID' = 'COLLECTION_ID',
  'SUCCESSFUL_PURCHASE' = 'SUCCESSFUL_PURCHASE',
  'COMPARE_ACCEPTED' = 'COMPARE_ACCEPTED',
  'COMPARE_CAR_QUOTE_ACCEPTED' = 'COMPARE_CAR_QUOTE_ACCEPTED',
  'REJECTED' = 'REJECTED',
  'ERROR' = 'ERROR',
  'REDIRECT' = 'REDIRECT',
  'SKIP_PRESSED' = 'SKIP_PRESSED',
  'SKIP_COMPARE_PRESSED' = 'SKIP_COMPARE_PRESSED',
  'GET_QUOTE' = 'GET_QUOTE',
  'QUOTE_ERROR' = 'QUOTE_ERROR',
  'CONTACT_DETAILS' = 'CONTACT_DETAILS',

  'PENSION_MOVE_REQUEST_PRESSED' = 'PENSION_MOVE_REQUEST_PRESSED',
  'PENSION_ADVISORY_MEETING_REQUESTED' = 'PENSION_ADVISORY_MEETING_REQUESTED',
  'PENSION_ADVISORY_BOOKING_REQUESTED' = 'PENSION_ADVISORY_BOOKING_REQUESTED',

  'SUPPLEMENTAL_INFORMATION' = 'SUPPLEMENTAL_INFORMATION',
}

export type PostMessageParams = {
  name: PostMessageName;
  value?: unknown;
  extraInformation?: Record<string, unknown>;
  targetOrigin: string;
};

export const SendInfoToParentWindow = ({
  name,
  value,
  extraInformation,
  targetOrigin,
}: PostMessageParams) => {
  window.parent.postMessage(
    {
      origin: 'insurely',
      name: PostMessageName[name],
      value,
      extraInformation,
    },
    targetOrigin,
  );
};

const INSURELY_ALLOWED_ORIGINS = [
  'https://*.insurely.com',
  'https://*.insurely.se',
  'https://insurely.com',
  'https://insurely.se',
];

export function isOriginAllowed(clientAllowedOrigins: string[], origin?: string) {
  if (!origin || origin?.length === 0) return false;
  const allowedOrigins = [...INSURELY_ALLOWED_ORIGINS, ...clientAllowedOrigins];
  return allowedOrigins.some((path) => match(path, origin).matches);
}

export const safeSendInfoToParentWindow = ({
  allowedOrigins,
  ...params
}: PostMessageParams & { allowedOrigins: string[] }) => {
  if (IS_DEVMODE) {
    SendInfoToParentWindow({ ...params, targetOrigin: '*' });
    return;
  }
  if (!isOriginAllowed(allowedOrigins, params.targetOrigin)) {
    // Stop issue from being reported when clients are integrating with blocks in local dev environments
    if (params.targetOrigin.includes('localhost')) return;

    sentryCaptureException(
      new Error(
        `Could not send postMessage event due to non-allowed target origin: ${params.targetOrigin}`,
      ),
    );
    return;
  }
  SendInfoToParentWindow({ ...params });
};

import axios from 'axios';
import { useEffect } from 'react';

import {
  ExtraErrorData,
  Sentry,
  isSentryEnabled,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from '@main/shared-exports';

// eslint-disable-next-line import/no-unresolved
import 'virtual:vite-plugin-sentry/sentry-config';

const { dist, release } = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG;

if (isSentryEnabled()) {
  Sentry.init({
    dsn: 'https://443ebde597bd4d57a5b980299791c5c0@o1213792.ingest.sentry.io/6760504',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new ExtraErrorData({ depth: 10 }),
    ],
    environment: ENVIRONMENT,
    tracesSampleRate: 0.2,
    normalizeDepth: 10,
    dist,
    release,
    beforeSend: (event, hint) => {
      if (
        axios.isAxiosError(hint.originalException) &&
        hint.originalException.response?.status &&
        hint.originalException.response.status === 503
      ) {
        return null;
      }
      return event;
    },
  });
}

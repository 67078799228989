export interface ErrorButtonWithOnClick {
  text?: string;
  href?: never;
  onClick?: () => void;
}

export interface ErrorButtonWithHref {
  text: string;
  href: string;
  onClick?: never;
}

interface StructuredErrorDetails {
  title: string;
  type: 'error' | 'warning' | 'timeout';
  message?: string | Array<string>;
  link?: ErrorButtonWithOnClick | ErrorButtonWithHref;
  button?: ErrorButtonWithOnClick | ErrorButtonWithHref;
}

export class StructuredError extends Error {
  public readonly details: StructuredErrorDetails;

  constructor(details: StructuredErrorDetails) {
    super(details.title);
    this.details = details;
    this.name = 'StructuredError';
  }
}

export function isStructuredError(
  error: string | Error | StructuredError | null | undefined,
): error is StructuredError {
  return !!(error as StructuredError)?.details?.title;
}

export function invariant(
  condition: boolean,
  message?: string,
  link?: ErrorButtonWithOnClick | ErrorButtonWithHref,
): asserts condition {
  if (condition) return;

  throw new StructuredError({
    title: 'Config error',
    type: 'warning',
    message,
    link,
  });
}

// This needs to be kept in sync with com.insurely.models.insurance

import {
  CoreCarInsurance,
  CoreGeneralHouseInsurance,
  CoreGeneralInsurance,
  CoreHomeInsurance,
  CoreVehicleInsurance,
} from '@main/types/insurance/core';

import {
  FrenchGeneralInsurance,
  FrenchHomeInsurance,
  FrenchVillaInsurance,
  FrenchCarInsurance,
  FrenchVehicleInsurance,
} from '@main/types/insurance/france';

import { SwedishCarInsurance, SwedishVehicleInsurance } from '@main/types/insurance/sweden';

import { EstonianCarInsurance, EstonianVehicleInsurance } from './estonia';
import { EstonianGeneralInsurance } from './estonia/EstonianGeneralInsurance';
import { InsuranceSubType, InsuranceType } from './InsuranceType';
import { LatvianGeneralInsurance } from './latvia/LatvianGeneralInsurance';

export type {
  AdditionalBuilding,
  CoreCarInsurance,
  CoreGeneralHouseInsurance,
  CoreGeneralInsurance,
  CoreHomeInsurance,
  CoreVehicleInsurance,
} from '@main/types/insurance/core';

export {
  AdditionalBuildingType,
  InsuranceLevel,
  PaymentMethod,
  VehicleType,
  PremiumFrequencyDA,
} from '@main/types/insurance/core';

export enum LossFreeYears {
  FIVE_YEARS = 5,
  UNDEFINED = 'UNDEFINED',
}

export enum ResidentialStatus {
  POLICY_HOLDER_ONLY = 'POLICY_HOLDER_ONLY',
  UNDEFINED = 'UNDEFINED',
}

export enum AnimalGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  CASTRATED_MALE = 'CASTRATED_MALE',
  CASTRATED_FEMALE = 'CASTRATED_FEMALE',
  UNKNOWN = 'UNKNOWN',
}

export enum FarmPropertyType {
  BARN = 'BARN',
  STABLE = 'STABLE',
  LOG_CABIN = 'LOG_CABIN',
  MACHINE_HALL = 'MACHINE_HALL',
  UNKNOWN = 'UNKNOWN',
}

export type GeneralInsurance =
  | CoreGeneralInsurance
  | FrenchGeneralInsurance
  | EstonianGeneralInsurance
  | LatvianGeneralInsurance;

export type AnimalInsurance = Omit<GeneralInsurance, 'insuranceType'> & {
  insuranceType: InsuranceType.animalInsurance;
  animalName?: string;
  animalBreed?: string;
  animalGender: AnimalGender;
  chipNumber?: string;
  dateOfBirth?: string;
  veterinaryCareAmount?: number;
  veterinaryCareAmountRemaining?: number;
  animalPurchasePrice?: number;
};

export type VehicleInsurance =
  | CoreVehicleInsurance
  | SwedishVehicleInsurance
  | FrenchVehicleInsurance
  | EstonianVehicleInsurance;

export type CarInsurance =
  | CoreCarInsurance
  | SwedishCarInsurance
  | FrenchCarInsurance
  | EstonianCarInsurance;

export type PersonInsurance = Omit<GeneralInsurance, 'insuranceType'> & {
  insuranceType: InsuranceType.personInsurance;
  insuredPersonName?: string;
  insuredAmount?: number;
};

export type AccidentInsurance = PersonInsurance & {
  insuranceSubType: InsuranceSubType.accidentInsurance | InsuranceSubType.childInsurance;
  insuredAmountBaseMedicalDisability?: number;
  insuredAmountBaseEconomicalDisability?: number;
  insuredAmountMaxMedicalDisability?: number;
  insuredAmountLife?: number;
};

export type PregnancyInsurance = PersonInsurance & {
  insuranceSubType: InsuranceSubType.pregnancyInsurance;
  insuredAmountBaseMedicalDisability?: number;
};

export type LifeInsurance = PersonInsurance & {
  insuranceSubType: InsuranceSubType.lifeInsurance;
  insuredAmountLife?: number;
};

export type ChildInsurance = AccidentInsurance;

export type HealthInsurance = PersonInsurance & {
  insuranceSubType: InsuranceSubType.healthInsurance;
};

export type IncomeInsurance = Omit<GeneralInsurance, 'insuranceType'> & {
  insuranceType: InsuranceType.incomeInsurance;
  // monthly payout amount
  insuredMonthlyAmount?: number;
  // For "sjukkapitalförsäkring", the selected insurance amount which a lump sum payout is based on
  insuredAmount?: number;
  // Number of working days before qualified for payouts
  qualifyingPeriodDays?: number;
  // number of days from unemployment/sickness until first payout
  numberOfDaysUntilFirstPayout?: number;
  // number of days from unemployment/sickness until last payout
  numberOfDaysUntilLastPayout?: number;
};

export type HomeInsurance = CoreHomeInsurance | FrenchHomeInsurance;

export type FarmProperty = {
  buildingType: FarmPropertyType;
};

export type GeneralHouseInsurance = CoreGeneralHouseInsurance | FrenchVillaInsurance;

export type VillaInsurance =
  | (GeneralHouseInsurance & {
      insuranceSubType: InsuranceSubType.villaInsurance;
    })
  | FrenchVillaInsurance;

export type CottageInsurance = GeneralHouseInsurance & {
  insuranceSubType: InsuranceSubType.cottageInsurance;
};

export type FarmInsurance = GeneralHouseInsurance & {
  insuranceSubType: InsuranceSubType.farmInsurance;
  insuranceObjectPropertyCode?: string;
  farmProperties: FarmProperty[];
};

export type Insurance =
  | GeneralInsurance
  | AnimalInsurance
  | VehicleInsurance
  | CarInsurance
  | PersonInsurance
  | AccidentInsurance
  | ChildInsurance
  | PregnancyInsurance
  | LifeInsurance
  | HomeInsurance
  | VillaInsurance
  | FarmInsurance
  | IncomeInsurance;

import { useCallback, useEffect, useRef } from 'react';
import {
  Navigate as OriginalNavigate,
  NavigateProps,
  NavigateOptions,
  useNavigate as useOriginalNavigate,
  useLocation,
} from 'react-router-dom';

import { getQueryString } from '@main/utils';

export * from 'react-router-dom';
interface ExtendedNavigateOptions extends NavigateOptions {
  searchParams?: Record<string, string>;
}

// This requires prevPath to be set by navigation, we should always use our wrappers below to ensure this is set correctly
export function usePreviousPath(): string | undefined {
  const { state } = useLocation();

  return state?.prevPath;
}

export function useNavigate() {
  const originalNavigate = useOriginalNavigate();
  const prevPath = usePreviousPath();

  const prevPathRef = useRef(prevPath);

  useEffect(() => {
    prevPathRef.current = prevPath;
  }, [prevPath]);

  return useCallback(
    (path: string | number, options?: ExtendedNavigateOptions) => {
      const isBackAction = typeof path === 'number';
      if (isBackAction) {
        return originalNavigate(path);
      }

      return originalNavigate(`${path}${getQueryString(options?.searchParams)}`, {
        ...options,
        state: {
          ...options?.state,
          prevPath: options?.replace ? prevPathRef.current : window.location.pathname,
        },
      });
    },
    [originalNavigate],
  );
}

export function Navigate({ to, ...rest }: NavigateProps) {
  const prevPath = usePreviousPath();
  return (
    <OriginalNavigate
      to={`${to}${window.location.search}`}
      state={{ prevPath: rest.replace ? prevPath : window.location.pathname }}
      {...rest}
    />
  );
}

import { Button } from '@insurely/ui';
import classNames from 'classnames';

import { useIntl } from 'react-intl';

import { ResultItemContent, ResultItemContentProps } from '@main/components/ResultItemContent';

import styles from './index.module.css';

export interface ResultCardItemProps extends ResultItemContentProps {
  navigateToDetails: () => void;
  navigateToCompare: () => void;
}

export const ResultCardItem = ({
  navigateToDetails,
  navigateToCompare,
  title,
  subTitle,
  amount,
  statusText,
  statusColor,
  insuranceCompany,
  insuranceCompanyDisplayName,
}: ResultCardItemProps) => {
  const intl = useIntl();

  return (
    <div className={styles.card}>
      <button
        type="button"
        className={styles.topContainer}
        onClick={navigateToDetails}
        data-testid="result-item"
        aria-label={`${title} ${subTitle}`}
      >
        <ResultItemContent
          title={title}
          subTitle={subTitle}
          amount={amount}
          statusText={statusText}
          statusColor={statusColor}
          insuranceCompany={insuranceCompany}
          insuranceCompanyDisplayName={insuranceCompanyDisplayName}
        />
      </button>
      <div className={classNames(styles.bottomContainer, styles.cardBottomContainer)}>
        {/* TODO: Change tertiary variant button to new link-button if we implement that later. Linear ticket: https://linear.app/insurely/issue/INS-1409/do-we-want-to-have-a-button-that-looks-like-our-link */}
        <Button variant="tertiary" size="medium" onClick={navigateToDetails} style={{ padding: 0 }}>
          {intl.formatMessage({ id: 'insurance.result.view.more' })}
        </Button>
        <Button variant="primary" size="medium" onClick={navigateToCompare}>
          {intl.formatMessage({ defaultMessage: 'Compare' })}
        </Button>
      </div>
    </div>
  );
};

import { useEffect, useRef } from 'react';
import { useInterval } from 'react-use';

import { useSharedDataConfig } from '../contexts';
import { sentrySetUser } from '../services';
import { tokenIsValid, PostMessageName, tokenIsExpiringSoon, extractIdFromToken } from '../utils';

import { usePostMessage } from './usePostMessage';

export function useAuthTokenExpirationCheck() {
  const hasSentTokenExpiringSoon = useRef(false);
  const { sessionId, userConfig } = useSharedDataConfig();
  const postMessage = usePostMessage();

  useInterval(
    () => {
      if (userConfig.authToken && !tokenIsValid(userConfig.authToken)) {
        postMessage({ name: PostMessageName.APP_CLOSE, value: 'Token expired' });
        return;
      }
      if (
        userConfig.authToken &&
        !hasSentTokenExpiringSoon.current &&
        tokenIsExpiringSoon(userConfig.authToken)
      ) {
        postMessage({ name: PostMessageName.AUTH_TOKEN_EXPIRING_SOON });
        hasSentTokenExpiringSoon.current = true;
      }
    },
    userConfig.authToken ? 10000 : null,
  );

  useEffect(() => {
    if (userConfig.authToken && !tokenIsValid(userConfig.authToken)) {
      postMessage({ name: PostMessageName.APP_CLOSE, value: 'Token expired' });
    }
    window.addEventListener('focus', () => {
      if (userConfig.authToken && !tokenIsValid(userConfig.authToken)) {
        postMessage({ name: PostMessageName.APP_CLOSE, value: 'Token expired' });
      }
    });
    sentrySetUser(extractIdFromToken(userConfig.authToken) ?? sessionId);
  }, [postMessage, sessionId, userConfig.authToken]);
}

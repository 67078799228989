import { useIntl } from 'react-intl';

import { Header as SharedHeader } from '@main/components/Header';
import { OVERVIEW_INSURANCE_HOME, useNavigate, useLocation } from '@main/shared-exports';

type HeaderProps = {
  title: string;
  ariaLabel?: string;
};

export const Header = ({ title, ariaLabel }: HeaderProps) => {
  const intl = useIntl();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const isHomePage = new RegExp(`/${OVERVIEW_INSURANCE_HOME}$`).test(pathname);

  return (
    <SharedHeader
      ariaLabel={ariaLabel}
      title={title}
      backButtonAriaLabel={intl.formatMessage({ defaultMessage: 'Go back to previous page' })}
      closeButtonAriaLabel={intl.formatMessage({ defaultMessage: 'Close application' })}
      showBackButton={!isHomePage}
      goBackOnClick={() => navigate(-1)}
    />
  );
};

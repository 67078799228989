import { addDays, format, subDays, subYears } from 'date-fns';

const randomDays = Math.ceil(Math.random() * 10);

export function getRenewalDate() {
  const renewalDate = addDays(new Date(), 5);
  return format(renewalDate, 'yyyy-MM-dd');
}

export function getEndDate(renewalDate?: string) {
  const endDate = subDays(renewalDate ? new Date(renewalDate) : new Date(), 1);
  return format(endDate, 'yyyy-MM-dd');
}

export function getStartDate(renewalDate?: string) {
  return format(subYears(renewalDate ? new Date(renewalDate) : new Date(), 1), 'yyyy-MM-dd');
}

export function getBirthDate() {
  const birthDate = subDays(new Date(), randomDays);
  return format(birthDate, 'yyyy-MM-dd');
}

import { Button, IconError, IconWarning, Typography } from '@insurely/ui';

import { BaseHeader } from '../Header';

import styles from './errorFallback.module.css';

interface UnhandledFallbackProps {
  resetError: () => void;
  title: string;
  headline: string;
  message: string;
  resetErrorButtonText: string;
  type?: 'Error' | 'Warning';
}

export function UnhandledFallback({
  resetError,
  title,
  headline,
  message,
  resetErrorButtonText,
  type = 'Error',
}: UnhandledFallbackProps) {
  return (
    <div>
      <BaseHeader
        title={title}
        backButtonAriaLabel="Go back to previous page"
        closeButtonAriaLabel="Close application"
      />
      {type === 'Error' ? (
        <IconError width="48px" className={styles.icon} />
      ) : (
        <IconWarning width="48px" className={styles.icon} />
      )}
      <Typography component="h2" variant="Headline-6" className={styles.headline}>
        {headline}
      </Typography>

      <div className={styles.content}>
        <Typography component="p" variant="ParagraphBody" className={styles.paragraph}>
          {message}
        </Typography>
      </div>
      <Button
        variant="primary"
        center
        onClick={resetError}
        style={{
          minWidth: '186px',
        }}
      >
        {resetErrorButtonText}
      </Button>
    </div>
  );
}

import { Button, Icon, Loader, Typography } from '@insurely/ui';
import { useIntl } from 'react-intl';

import { BaseView } from '@main/components/BaseView';
import { useUserInput } from '@main/contexts';
import {
  OVERVIEW_PATH,
  OVERVIEW_WEALTH_HOME,
  sentryCaptureException,
  useCompanies,
  useNavigate,
  useSearchParams,
  useSharedData,
  useUseCase,
} from '@main/shared-exports';

import { useWealthData } from '../../hooks';

import { isPensionAccount } from '../WealthOverview/utils';

import { HoldingInfo, GeneralInfo, PensionDetails, Settings } from './components';

import styles from './wealthAccount.module.css';

export const WealthAccount = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { nextBlock } = useUseCase();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') ?? undefined;
  const [
    {
      userConfig: { dataAggregation },
    },
  ] = useSharedData();
  const { setSelectedCompanies } = useUserInput();
  const { data: allCompanies } = useCompanies();

  // TODO: Replace userId with a valid id
  // Linear ticket: https://linear.app/insurely/issue/EXPD-154/implement-new-way-of-handling-useridjwt-in-frontend
  const { isInitialLoading, data } = useWealthData({ userId: '123456' });

  if (isInitialLoading) {
    return <Loader.Content />;
  }

  if (!data) {
    sentryCaptureException(new Error('Missing data on WealthAccount in Overview'));
    // TODO: Change to an ErrorPage instead
    // Linear ticket: https://linear.app/insurely/issue/EXPD-190/implement-error-pages-for-wealth-overview
    return <Loader.Content />;
  }

  const latestUpdatedDate = data?.latestUpdatedDate;
  const account = data.accounts.find((item) => item.id === id);

  if (!account) {
    sentryCaptureException(new Error('Missing account on WealthAccount in Overview'));
    // TODO: Change to an ErrorPage instead
    // Linear ticket: https://linear.app/insurely/issue/EXPD-190/implement-error-pages-for-wealth-overview
    return <Loader.Content />;
  }

  const { productName } = account;

  const navigateToOverview = () => navigate(`${OVERVIEW_PATH}/${OVERVIEW_WEALTH_HOME}`);

  const currentSelectedCompany = allCompanies?.find(
    (item) => item.insuranceCompany === account.company,
  );

  const updateAccount = () => {
    if (dataAggregation?.multiSelect && !!currentSelectedCompany) {
      setSelectedCompanies([currentSelectedCompany]);
    }
    nextBlock();
  };

  // TODO: Implement hide account
  // eslint-disable-next-line no-console
  const hideAccount = () => console.log('Hidden account');

  // TODO: Implement delete account
  // eslint-disable-next-line no-console
  const deleteAccount = () => console.log('Deleted account');

  return (
    <BaseView type="fullWidth" maxWidth="wide">
      <Button
        variant="ghost"
        icon={<Icon name="arrow-left" size={16} />}
        iconPosition="left"
        className={styles.button}
        onClick={navigateToOverview}
      >
        <Typography component="span" variant="ParagraphBodySmall" className={styles.buttonText}>
          {intl.formatMessage({
            id: 'overview.wealth-account.back-button',
            defaultMessage: 'Back',
          })}
        </Typography>
      </Button>
      <Typography component="h1" variant="Headline-5" className={styles.headline}>
        {productName}
      </Typography>
      <GeneralInfo account={account} latestUpdatedDate={latestUpdatedDate} />
      {account.holdings.length > 0 && (
        <>
          <Typography component="h2" variant="Headline-6" className={styles.subHeadline}>
            {intl.formatMessage({
              id: 'overview.wealth-account.sub-headline-1',
              defaultMessage: 'Holdings',
            })}
          </Typography>
          <HoldingInfo account={account} />
        </>
      )}
      {isPensionAccount(account) && (
        <>
          <Typography component="h2" variant="Headline-6" className={styles.subHeadline}>
            {intl.formatMessage({
              id: 'overview.wealth-account.sub-headline-2',
              defaultMessage: 'Details',
            })}
          </Typography>
          <PensionDetails account={account} />
        </>
      )}
      <Settings
        updateAccount={updateAccount}
        hideAccount={hideAccount}
        deleteAccount={deleteAccount}
      />
    </BaseView>
  );
};

import { intlFormatDistance, isToday } from 'date-fns';
import { IntlShape } from 'react-intl';

import {
  Account,
  FinancialProductType,
  InvestmentAccount,
  KfAccount,
  PensionAccount,
} from '@main/types';

export const formattedLatestUpdatedDate = ({
  latestUpdatedDate,
  intl,
  language,
  country,
}: {
  latestUpdatedDate: Date;
  intl: IntlShape;
  language: string;
  country: string;
}) => {
  const locale = `${language}-${country.toLocaleUpperCase()}`;

  const currentDate = new Date();
  const date = new Date(latestUpdatedDate);
  const distance = intlFormatDistance(date, currentDate, { locale });

  if (isToday(latestUpdatedDate)) {
    return intl.formatMessage({
      id: 'overview.wealth.assets-details.timeframe-today',
      defaultMessage: 'Updated today',
    });
  }

  return intl.formatMessage(
    {
      id: 'overview.wealth.assets-details.timeframe-distance',
      defaultMessage: 'Updated {distance}',
    },
    { distance },
  );
};

export function isPensionAccount(account: Account): account is PensionAccount {
  return (
    account.type === FinancialProductType.IPS_PENSION ||
    account.type === FinancialProductType.OCCUPATIONAL_PENSION ||
    account.type === FinancialProductType.PRIVATE_PENSION ||
    account.type === FinancialProductType.COLLECTIVE_OCCUPATIONAL_PENSION ||
    account.type === FinancialProductType.UNKNOWN_PENSION_TYPE
  );
}

export function isInvestmentAccount(account: Account): account is InvestmentAccount {
  return (
    account.type === FinancialProductType.ISK ||
    account.type === FinancialProductType.CUSTODY_ACCOUNT ||
    account.type === FinancialProductType.UNKNOWN_INVESTMENT_TYPE
  );
}

export function isKfAccount(account: Account): account is KfAccount {
  return account.type === FinancialProductType.KF;
}

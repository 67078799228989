/* eslint-disable max-lines */
import { InsuranceParameter } from '@main/types';

export const fakeNewSwedishHomeParameters = (): Array<InsuranceParameter> => [
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'responsibility',
    parameterDisplayName: 'Ansvarsskydd',
    parameterDescription:
      'Ansvarsskydd gäller om du blir krävd på ersättning från någon som anser att du orsakat denne en skada och kräver dig på skadestånd.',
    parameterGroup: 'Du och din familj',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'court',
    parameterDisplayName: 'Rättsskydd',
    parameterDescription:
      'Om du hamnar i en rättslig tvist med någon, kan din försäkring betala en stor del av kostnaderna för juridiskt ombud (advokat). Rättsskyddet ersätter ej vårdnadstvist direkt vid en separation. Skulle ytterligare vårdnadstvister uppkomma senare kan rättsskyddet gälla.',
    parameterGroup: 'Du och din familj',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'idTheft',
    parameterDisplayName: 'Skydd mot ID-stöld',
    parameterDescription:
      'Erklæres din bil totalskadet inden for det første år af bilens registrering, kan du være berettiget til en nyværdierstatning. Det vil sige en erstatning, der svarer til prisen for en fabriksny bil af samme fabrikat, type og årgang som den skaderamte bil.',
    parameterGroup: 'Du och din familj',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'assault',
    parameterDisplayName: 'Överfallsskydd',
    parameterDescription:
      'Överfallsskyddet kan ge dig ersättning om du blir utsatt för våld genom ett vålds- eller sexualbrott. De flesta försäkringsbolag ger även en engångsersättning vid bestående skada (medicinsk invaliditet). Ett fåtal försäkringsbolag ersätter även om du blir så skadad att du inte kan jobba (ekonomisk invaliditet)',
    parameterGroup: 'Du och din familj',
    parameterGroupOrder: 3,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'fire',
    parameterDisplayName: 'Brand, blixt & explosion',
    parameterDescription:
      'Om det börjar brinna i din bostad får du ersättning för brand-, sot- och rökskador på dina ägodelar. Täcker även skador genom explosion, samt skador orsakade genom blixtnedslag.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'burglaryDamage',
    parameterDisplayName: 'Stöld & skadegörelse',
    parameterDescription:
      'Försäkringen gäller för stöld av eller skadegörelse på lös egendom i bostad. Gäller för skada som begåtts av någon som olovligen tagit sig in.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'waterLeakage',
    parameterDisplayName: 'Vattenläcka & läckage',
    parameterDescription:
      'Ersättning vid skada t.ex. vattenskada orskadad av läckande vatten eller ånga, så får du ersättning för skadorna på dina ägodelar. Som vattenskada räknas till exempel om en vattenledning skulle brista, eller om kylen eller diskmaskinen läcker.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'flooding',
    parameterDisplayName: 'Översvämning',
    parameterDescription:
      'Ersättning vid skada på dina ägodelar vid t.ex. vattenskada som uppstått på grund av vatten som strömmat in i bostaden pga exempelvis skyfall, snösmältning, stigande sjö eller vattendrag.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'houseMachines',
    parameterDisplayName: 'Hushållsmaskiner, vitvaror m.m.',
    parameterDescription:
      'Försäkringen gäller för skada på hushållsmaskin och vitvaror, till exempel tvättmaskin och kylskåp. Rena skönhetsfel ersätts vanligtvis inte. Försäkringen ersätter ofta livsmedel eller tvätt som förstörts i samband med skadan.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'weather',
    parameterDisplayName: 'Oväder',
    parameterDescription:
      'Ersättning för skada på dina saker till följd av storm, vind, hagel eller snötryck.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'nature',
    parameterDisplayName: 'Andra naturskador',
    parameterDescription:
      'Ersättning för dina saker om skada skulle ske till följd av exempelvis jordras, jordskred, vulkanutbrott eller lavin.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'unusableHome',
    parameterDisplayName: 'Obrukbar bostad',
    parameterDescription:
      'Om den försäkrade bostaden blir helt eller delvis obrukbar vid skadehändelse som ersätts av försäkringen, kan ersättning ges för merkostnader för ex logi eller magasinerng samt för ev hyresbortfall om bostaden är uthyrd.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'away',
    parameterDisplayName: 'Stöld & skadegörelse på annan plats',
    parameterDescription:
      'Bortaskyddet ersätter egendom som förvaras i förråd eller bil vid stöld, brand och vattenskada. Ersätter även vid rån, väskryckning eller överfall. Ersättningen är betydligt lägre än för egendom som förvaras i hemmet.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'extendedAway',
    parameterDisplayName: 'Utökat skydd vid stöld & skadegörelse på annan plats',
    parameterDescription:
      'Ger större ersättning för lös egendom som du har på annan plats än i bostaden.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'transportTraffic',
    parameterDisplayName: 'Transport- och trafikskador',
    parameterDescription:
      'Ersätter skador på medförd egendom orsakad av transport eller trafikskada.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'travel',
    parameterDisplayName: 'Reseskydd',
    parameterDescription:
      'Reseskyddet gäller i regel de första 45 dagarna på en resa och ersätter kostnader vid akut sjukdom, olycksfall och tandskador. Skyddet täcker även kostnader för sjuktransport hem. Det förekommer undantag vid riskfylld aktivitet eller om du redan är sjuk samt vid långt gången graviditet. Ger även ersättning för förstörda semesterdagar på grund av sjukdom eller olycka. Kan även ge ersättning för person- och baggageförsening.',
    parameterGroup: 'På resan',
    parameterGroupOrder: 4,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'extendedTravel',
    parameterDisplayName: 'Utökat reseskydd',
    parameterDescription:
      'Ger extra skydd på resan samt ökad ersättning vid förstörda semesterdagar och förseningar. Om du till exempel måste avboka resan på grund av sjukdom, olycka eller vissa andra händelser som gör att du inte kan resa utgår ersättning.',
    parameterGroup: 'På resan',
    parameterGroupOrder: 4,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'accident',
    parameterDisplayName: 'Drulle eller allrisk',
    parameterDescription:
      'Allrisk är ett skydd för plötsliga och oförutsedda oturshändelser, som den vanliga hemförsäkringen inte täcker - till exempel om du skulle tappa din mobiltelefon.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'crisis',
    parameterDisplayName: 'Kris',
    parameterDescription:
      'Krisskyddet ersätter behandlingskostnader för kristerapi efter till exempel rån, överfall eller inbrott.',
    parameterGroup: 'Du och din familj',
    parameterGroupOrder: 3,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'reducedDeductible',
    parameterDisplayName: 'Reducerad självrisk',
    parameterDescription:
      'Detta tillägg ger dig minskad självrisk om något skulle inträffa som du kan begära ersättning för. Den minskade självrisken gäller bara för vissa händelser, vilket är olika för olika försäkringar.',
    parameterGroup: 'Övrigt',
    parameterGroupOrder: 5,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'brfInsurance',
    parameterDisplayName: 'Bostadsrättsförsäkring',
    parameterDescription:
      'Ger ersättning för skador på fast inredning och ytskikt i lägenheten så som t.ex. fasta garderober, vitvaror och inredning i badrum som exempelvis badkar, handfat, etc.',
    parameterGroup: 'Ditt hem',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'brfLayers',
    parameterDisplayName: 'Yt- och tätskikt',
    parameterDescription:
      'Kan ge dig ersättning för läckande tät- och ytskikt i bad- och duschrum. Ofta kräver försäkringen att branschregler följts och att eventuellt intyg finns.',
    parameterGroup: 'Ditt hem',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'extendedElectronic',
    parameterDisplayName: 'Utökad hemelektronikförsäkring',
    parameterDescription:
      'Du får lägre självrisk för skador på hemelektronik. Dessutom får du högre ersättning för stationär hemelektronik samt förbättrat åldersavdrag.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'bullying',
    parameterDisplayName: 'Mobbning',
    parameterDescription:
      'Ersätter kostnader för samtalsterapi, upp till 10 besök, samt en engångsersättning om ditt barn utsätts för kränkande behandling i skolan.',
    parameterGroup: 'Du och din familj',
    parameterGroupOrder: 3,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'smallBoat',
    parameterDisplayName: 'Småbåtsförsäkring',
    parameterDescription:
      'Ger ersättning för förlust eller skada på en liten båt som inte är längre än 6 meter med en motor på max 15 hästkrafter och/eller en segelyta på 10 kvadratmeter. Innehåller även ansvars- och rättsskydd för dig i rollen som brukare, ägare eller förare av den försäkrade båten.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'golf',
    parameterDisplayName: 'Golfförsäkring',
    parameterDescription:
      'Ger dig ersättning om din golfutrustning blir stulen eller förstörd samt en hole-in-one försäkring.',
    parameterGroup: 'Övrigt',
    parameterGroupOrder: 5,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'personalAccident',
    parameterDisplayName: 'Olycksfallsförsäkring',
    parameterDescription:
      'Ger viss ersättning för olycksfallsskador som inträffar under fritid eller arbetstid.',
    parameterGroup: 'Du och din familj',
    parameterGroupOrder: 3,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'rental',
    parameterDisplayName: 'Uthyrningsskydd',
    parameterDescription:
      'Hemförsäkring täcker vanligtvis inte stölder utförda av någon du släpper in frivilligt, men med uthyrningsskydd utgår ersättning vid stölder när du hyr ut din bostad.',
    parameterGroup: 'Övrigt',
    parameterGroupOrder: 5,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'lockChange',
    parameterDisplayName: 'Låsbyte',
    parameterDescription:
      'Om du skulle tappa bort dina nycklar och behöver betala ett låsbyte, kan din försäkring täcka detta. Åldersbegränsning kan förekomma.',
    parameterGroup: 'Övrigt',
    parameterGroupOrder: 5,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'oneYearWarranty',
    parameterDisplayName: 'Ett-års garanti',
    parameterDescription:
      'Om du har bytt från annat försäkringsbolag ersätter försäkringen en skadehändelse som normalt inte omfattas av försäkringen. Du behöver visa att din försäkring i den andra bolaget skulle ha ersatt den typen av skadehändelse.',
    parameterGroup: 'Övrigt',
    parameterGroupOrder: 5,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'underInsuredProtection',
    parameterDisplayName: 'Underförsäkringsgaranti',
    parameterDescription:
      'Om du under året skaffar mer saker hemma, så att värdet överstiger försäkringsbeloppet på din försäkring, blir du inte omedelbart underförsäkrad.',
    parameterGroup: 'Övrigt',
    parameterGroupOrder: 5,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'movingProtection',
    parameterDisplayName: 'Flyttskydd',
    parameterDescription:
      'När du flyttar så är dina saker försäkrade på både din gamla och nya adress under en övergångsperiod.',
    parameterGroup: 'Övrigt',
    parameterGroupOrder: 5,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'extendedTheft',
    parameterDisplayName: 'Utökat skydd vid stöld i hemmet',
    parameterDescription:
      'Blir du bestulen av en vårdgivare med tillåtelse att vara hemma hos dig kan du få ersättning för det som blivit stulet.',
    parameterGroup: 'Dina saker',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'specialItem',
    parameterDisplayName: 'Specialföremål eller värdesak',
    parameterDescription:
      'Tilläggsförsäkring för extra värdefulla föremål såsom smycken, konst eller en fin cykel.',
    parameterGroup: 'Övrigt',
    parameterGroupOrder: 5,
  },
];

export const fakeNewSwedishCarParameters = (): Array<InsuranceParameter> => [
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'traffic',
    parameterDisplayName: 'Trafik',
    parameterDescription:
      'Trafikförsäkringen lämnar ersättning för skador på andra personer samt egendom som du orsakat med bilen. Du som bilägare måste ha trafikförsäkring enligt lag. Trafikförsäkringen ersätter även skador på dig som förare, medpassagerare och personer utanför bilen. Med trafikförsäkringen får du däremot ingen ersättning för skador på ditt eget fordon eller egendom i fordonet.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'machine',
    parameterDisplayName: 'Maskinskada',
    parameterDescription:
      'Omfattar plötsliga fel som påverkar bilens funktion. Exempel kan vara fel på till exempel motor, elektronik, växellåda och kraftöverföring. Omfattningen är kopplad till bilens ålder och körsträcka.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'theft',
    parameterDisplayName: 'Stöld',
    parameterDescription:
      'Försäkringen täcker stöld eller tillgrepp av den försäkrade bilen och dess tillbehör. Du kan i vissa fall även få ersättning för skador på bilen som uppkommit i samband med stöld eller inbrottsförsök.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'fire',
    parameterDisplayName: 'Brand',
    parameterDescription:
      'Ersätter skador på din bil som orsakats av brand anlagd av tredje man, blixtnedslag, explosion eller brand som kommit lös.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'glass',
    parameterDisplayName: 'Glas',
    parameterDescription:
      'Kan ge ersättning för skador på bilens glasdelar, det vill säga vindruta, sidoruta, bakruta eller siktruta om de krossas eller spräcks. Du får ersättning för kostnaden att byta eller reparera ruta efter till exempel stenskott.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'rescue',
    parameterDisplayName: 'Räddning',
    parameterDescription:
      'Om du får ett driftstopp så får du hjälp med bogsering av bilen till närmaste verkstad, samt ersättning för hemresa eller vidare resa för personerna i bilen. Vissa undantag brukar finnas för orsaken till driftstoppet.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'legal',
    parameterDisplayName: 'Rättsskydd',
    parameterDescription:
      'Du kan få ersättning för kostnader som gäller tvister som rör dig som bilförare.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'interiorDamage',
    parameterDisplayName: 'Inredning',
    parameterDescription:
      'Kan ge dig ersättning som rör skador på bilens inredning, beroende på vad som har orsakat skadan. Vanligtvis ersätts inte skador som orsakats av djur.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'crisis',
    parameterDisplayName: 'Krishjälp',
    parameterDescription:
      'Täcker behandlingskostnad för krishjälp efter en trafikolycka, om du skulle drabbas av psykiska problem. Många försäkringsbolag erbjuder ett antal betalda terapitillfällen.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'vehicleDamage',
    parameterDisplayName: 'Vagnskada',
    parameterDescription:
      'Du får ersättning för skador på bilen som uppstått vid trafikolycka, skadegörelse, annan olycka eller annan yttre olyckshändelse. För bilar med gällande vagnskadegaranti ingår detta skydd i garantin. För mer information, se din bils garantibevis.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'lossOfKeys',
    parameterDisplayName: 'Nyckelförlust',
    parameterDescription:
      'Detta tillägg ersätter förlorad nyckel eller nyckelkort. Tillägget ersätter ny nyckel eller omkodning av nyckelkort upp till en viss kostnad.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'wrongFuel',
    parameterDisplayName: 'Feltankning',
    parameterDescription:
      'Om du tankat bilen med fel typ av bränsle eller fyllt annan behållare med fel medel (till exempel olja i tanken för spolarvätska), får du ersättning för nödvändiga kostnader för rengöring av tank, behållare, ledningar och byte av filter upp till en viss nivå. Denna ersättning är ofta kopplad till bilens ålder och miltal.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'fullValue',
    parameterDisplayName: 'Nyvärde',
    parameterDescription:
      'Du ersätts med ny bil om bilen är yngre än 1 år och har körts mindre än 2000 mil, du är första ägare av bilen och kostnaden för reparationen överstiger 50% av nypris.',
    parameterGroup: 'Grundskydd',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'rental',
    parameterDisplayName: 'Hyrbil',
    parameterDescription:
      'Hyrbilsförsäkring hjälper dig med en hyrbil om din bil blir obrukbar på grund av ersättningsbar skada.',
    parameterGroup: 'Tillägg',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'assistance',
    parameterDisplayName: 'Assistans',
    parameterDescription:
      'Om du får ett driftstopp får du, utan någon självrisk, hjälp med bärgning av bilen samt tillgång till hyrbil under en period. Assistans täcker även andra orsaker till driftstopp än räddning, till exempel om du får slut på drivmedel. Avser ofta ett begränsat antal användningstillfällen per år.',
    parameterGroup: 'Tillägg',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'extendedMachine',
    parameterDisplayName: 'Utökad maskinskada',
    parameterDescription:
      'En förlängning av års- och miltalsbegränsningen för maskinskadeskyddet som finns i din försäkring.',
    parameterGroup: 'Tillägg',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'deductibleDiscount',
    parameterDisplayName: 'Självriskrabatt',
    parameterDescription:
      'Kan eliminera eller ge rabatt på självrisken om något skulle inträffa och som du kan begära ersättning för i din försäkring. Med detta tillägg kan du därför få betala mindre i självrisk om du skulle behöva använda försäkringen eller få ersättning vid reparation. Kan även innehålla ett skydd som ger dig mycket mindre självrisk vid djurkollision.',
    parameterGroup: 'Tillägg',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'driverPassengerAccident',
    parameterDisplayName: 'Trafikolycksfall (förar- och passagerarolycksfall)',
    parameterDescription:
      'Kan ge ersättning om föraren eller passagerare skadas och får bestående men. Vissa försäkringar innehåller även ersättning vid sjukhusvistelse eller om någon skulle omkomma vid en trafikolycka.',
    parameterGroup: 'Tillägg',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'fullValueExtended',
    parameterDisplayName: 'Nyvärde utökat',
    parameterDescription:
      'Du ersätts med ny bil om bilen är yngre än 2 år och har körts mindre än 4000 mil samt att du är första ägare av bilen och kostnaden för reparation överstiger en viss nivå av nypris.',
    parameterGroup: 'Tillägg',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'pickupService',
    parameterDisplayName: 'Hämtservice',
    parameterDescription:
      'När bilen ska repareras efter ersättningsbar skada, kan bilen lämnas och hämtas på verkstad av ditt försäkringsbolag. Begränsat till vissa geografiska områden.',
    parameterGroup: 'Tillägg',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'privateCare',
    parameterDisplayName: 'Privatsjukvård',
    parameterDescription:
      'Detta tillägg ger dig privat sjukvård om du skulle vara med om en trafikolycka. Kan även täcka passagerare i bilen.',
    parameterGroup: 'Tillägg',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'deductibleDiscountAnimalCollision',
    parameterDisplayName: 'Självriskrabatt djurkollision',
    parameterDescription:
      'Om du skulle råka ut för en djurkollision så får du rabatt på din självrisk. Om du enbart har halvförsäkring så får du en extra ersättning för reparation efter djurkollision.',
    parameterGroup: 'Tillägg',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'deductibleDiscountVandalism',
    parameterDisplayName: 'Självriskrabatt skadegörelse',
    parameterDescription:
      'Om du skulle råka ut för skadegörelse så får du rabatt på din självrisk. Om du enbart har halvförsäkring så får du en extra ersättning för reparation efter skadegörelse.',
    parameterGroup: 'Tillägg',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'deductibleDiscountOther',
    parameterDisplayName: 'Övrig självriskrabatt',
    parameterDescription:
      'Du kan få ytterligare rabatt på självrisk för exempelvis räddning, brand, stöld, glas eller andra skador. Se ditt försäkringsbrev för exakta självrisker.',
    parameterGroup: 'Tillägg',
    parameterGroupOrder: 2,
  },
];

export const fakeNewSwedishAnimalParameters = (): Array<InsuranceParameter> => [
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'medication',
    parameterDisplayName: 'Medication',
    parameterDescription: 'Medication',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'petBoarding',
    parameterDisplayName: 'Pet boarding',
    parameterDescription: 'Pet boarding',
    parameterGroup: 'Add-on',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'rehab',
    parameterDisplayName: 'Rehab',
    parameterDescription: 'Rehab',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'veterinaryCare',
    parameterDisplayName: 'Veterinary care',
    parameterDescription: 'Veterinary care',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'behavioralDisorder',
    parameterDisplayName: 'Behavioral disorder',
    parameterDescription: 'Behavioral disorder',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'hiddenDefects',
    parameterDisplayName: 'Hidden defects',
    parameterDescription: 'Hidden defects',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'travelReturnHome',
    parameterDisplayName: 'Travel return home',
    parameterDescription: 'Travel return home',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'castration',
    parameterDisplayName: 'Castration',
    parameterDescription: 'Castration',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'lossOfWorkingAbility',
    parameterDisplayName: 'Loss of working ability',
    parameterDescription: 'Loss of working ability',
    parameterGroup: 'Add-on',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'cancerTreatment',
    parameterDisplayName: 'Cancer treatment',
    parameterDescription: 'Cancer treatment',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'euthanasiaExpenses',
    parameterDisplayName: 'Euthanasia expenses',
    parameterDescription: 'Euthanasia expenses',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'mri',
    parameterDisplayName: 'Mri',
    parameterDescription: 'Mri',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'travel',
    parameterDisplayName: 'Travel',
    parameterDescription: 'Travel',
    parameterGroup: 'Add-on',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'travelCoverEU',
    parameterDisplayName: 'Travel cover eu',
    parameterDescription: 'Travel cover eu',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'lifeInsurance',
    parameterDisplayName: 'Life insurance',
    parameterDescription: 'Life insurance',
    parameterGroup: 'Add-on',
    parameterGroupOrder: 2,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'lossOfBreedingAbility',
    parameterDisplayName: 'Loss of breeding ability',
    parameterDescription: 'Loss of breeding ability',
    parameterGroup: 'Add-on',
    parameterGroupOrder: 2,
  },
  {
    value: 'false',
    isAddonPurchased: false,
    parameterName: 'prescribedFood',
    parameterDisplayName: 'Prescribed food',
    parameterDescription: 'Prescribed food',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'complicationsPregnancy',
    parameterDisplayName: 'Complications pregnancy',
    parameterDescription: 'Complications pregnancy',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'dental',
    parameterDisplayName: 'Dental',
    parameterDescription: 'Dental',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
  {
    value: 'true',
    isAddonPurchased: false,
    parameterName: 'video',
    parameterDisplayName: 'Video',
    parameterDescription: 'Video',
    parameterGroup: 'Basic coverage',
    parameterGroupOrder: 1,
  },
];

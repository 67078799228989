import { Color, IconCheckMark, IconClose, IconMinus, ParagraphTiny } from '@insurely/ui';

import { ParameterValue } from '@main/types';

export const getIconFromParamValue = ({
  value,
  isAddOnPurchased,
  addOnPurchasedCopy,
  addOnAvailableCopy,
  trueAriaLabel,
  falseAriaLabel,
  unknownAriaLabel,
  strokeWidth = 3,
  width = '24px',
}: {
  value: ParameterValue;
  isAddOnPurchased: boolean;
  addOnPurchasedCopy: string;
  addOnAvailableCopy: string;
  trueAriaLabel: string;
  falseAriaLabel: string;
  unknownAriaLabel: string;
  strokeWidth?: number;
  width?: string;
}) => {
  switch (true) {
    case value === 'true' && !isAddOnPurchased:
      return (
        <IconCheckMark
          width={width}
          color={Color.green}
          strokeWidth={strokeWidth}
          aria-label={trueAriaLabel}
        />
      );
    case value === 'false' && !isAddOnPurchased:
      return (
        <IconClose
          width={width}
          color={Color['red-1']}
          strokeWidth={strokeWidth}
          aria-label={falseAriaLabel}
        />
      );
    case value === 'true' && isAddOnPurchased:
      return (
        <ParagraphTiny style={{ color: 'var(--colorSuccess, var(--green))' }}>
          {addOnPurchasedCopy}
        </ParagraphTiny>
      );
    case value === 'add-on' && !isAddOnPurchased:
      return <ParagraphTiny>{addOnAvailableCopy}</ParagraphTiny>;
    case value === 'possible':
    default:
      return (
        <IconMinus
          width={width}
          color={Color.midnight}
          strokeWidth={strokeWidth}
          aria-label={unknownAriaLabel}
        />
      );
  }
};

export const getNumberFromParamValue = (value: ParameterValue) => {
  switch (value) {
    case 'true':
      return 2;
    case 'possible':
    case 'add-on':
      return 0.5;
    case 'false':
    default:
      return 0;
  }
};

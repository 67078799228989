import { CollectionStatusSuccessfulResponse } from '@data-aggregation/views/CollectData/helpers';
import { CollectionStatus, LoginMethod } from '@main/types';

import { completedStatuses, failedStatuses } from '../../types';

const twoFactorLoginMethods = [
  LoginMethod.USERNAME_AND_PASSWORD,
  LoginMethod.EMAIL,
  LoginMethod.SWEDISH_SECURITY_TOKEN,
];

export function mapCollectionStatusToState(
  collectionInfo: CollectionStatusSuccessfulResponse,
  loginMethod: LoginMethod,
) {
  const { status, extraInformation } = collectionInfo;

  const twoFactorLoginWithRequiredInfo =
    status === CollectionStatus['TWO FACTOR PENDING'] &&
    extraInformation &&
    twoFactorLoginMethods.includes(loginMethod);

  const contactFormPending = status === CollectionStatus['CONTACT FORM PENDING'];

  if (
    contactFormPending ||
    twoFactorLoginWithRequiredInfo ||
    (status === CollectionStatus.LOGIN && !twoFactorLoginMethods.includes(loginMethod))
  ) {
    return 'REQUEST_USER_ACTION';
  }

  if (completedStatuses.includes(status)) return 'FETCH_RESULTS';
  if (failedStatuses.includes(status)) return 'FAIL';
  if (CollectionStatus.COLLECTING === status) return 'COLLECT';
  return null;
}

import { Typography } from '@insurely/ui';

import { useLocation } from '@main/shared-exports';

import styles from './notFoundScreen.module.css';

export default function NotFoundScreen() {
  const { pathname } = useLocation();
  return (
    <div>
      <Typography component="h1" variant="Headline-6" className={styles.title}>
        Page not found
      </Typography>
      <Typography component="p" variant="ParagraphBody" className={styles.path}>
        {pathname}
      </Typography>
    </div>
  );
}

import { HttpResponse, http, passthrough, HttpResponseResolver } from 'msw';

import { estonianInsuranceCompanies } from '@main/mocks/data/companyStatus/companyStatusEE';
import {
  CarQuotePayload,
  Country,
  CoveragePolicyData,
  InsurancePackageName,
  InsuranceSubType,
} from '@main/types';
import { BlocksClientConfig } from '@main/types/configurations/blocksConfig';
import { PackageScopeRequest } from '@main/types/insurance/EquivalentPackageScope';
import { LoginMethod } from '@main/types/loginMethods';
import { DeepPartial } from '@main/types/utils';

import { getDevToolsLocalItem } from '@main/utils/devTools';

import { configs } from './configs';
import { getData } from './data/collectData';
import { danishInsuranceCompanies } from './data/companyStatus/companyStatusDK';
import { frenchInsuranceCompanies } from './data/companyStatus/companyStatusFR';
import { pensionCompanies, swedishInsuranceCompanies } from './data/companyStatus/companyStatusSE';
import { getEquivalentPackageScope, getParameters } from './data/parameters';
import { getPriceEstimateSE } from './data/priceEstimate/priceEstimateSE';
import { mockDb } from './mockDb';

function getConfig(configName: string) {
  return configs[configName] ?? null;
}

const getDataHandler: HttpResponseResolver = ({ params }) => {
  const collectionId = params.id as string;
  const collection = mockDb.collection.findFirst({ where: { id: { equals: collectionId } } });
  return HttpResponse.json(getData(collection), { status: 200 });
};

function getFrontendOptions(
  configName: string,
): Partial<BlocksClientConfig['blocksConfig']> | undefined {
  switch (configName) {
    case 'fr-prefill-and-compare-condo': {
      return {
        frontendOptions: {
          redirectToHomePageUrl: 'https://www.insurely.com',
        },
      };
    }
    case 'fr-overview-and-compare': {
      return {
        frontendOptions: {
          resultItemWithButtons: true,
        },
      };
    }
    case 'fr-direct-assurance': {
      return {
        frontendOptions: {
          redirectToHomePageUrl: 'https://www.direct-assurance.fr/',
          skipPolicyDetailsView: true,
        },
      };
    }
    case 'fr-bnpp': {
      return {
        frontendOptions: {
          resultItemWithButtons: true,
          skipInsuranceOverviewPage: true,
          hideCompanySearch: true,
        },
      };
    }
    case 'fr-kozoo': {
      return {
        frontendOptions: {
          skipPolicyDetailsView: true,
        },
      };
    }
    default:
      return undefined;
  }
}

function unhandledRequestWarning(httpMethod: string, pathname: string) {
  return `[MSW] Warning: captured a request without a matching request handler:
  
  • ${httpMethod} ${pathname}
  
  ${
    PROXY_ENABLED
      ? `This request is proxied to ${PROXY_TARGET_URL}${pathname.replace('/api', '')}`
      : ''
  }
  
  If you still wish to intercept this unhandled request, please create a request handler for it.
  Read more: https://mswjs.io/docs/getting-started/mocks    
  `;
}

export const handlers = [
  http.get<never, never, Company[]>(`${API_URL}/insurance-company/status`, () => {
    const sharedData = getDevToolsLocalItem({ key: 'localSharedData' });

    if (sharedData?.clientConfig.market.country === Country.dk) {
      return HttpResponse.json(danishInsuranceCompanies, { status: 200 });
    }

    if (sharedData?.clientConfig.market.country === Country.fr) {
      return HttpResponse.json(frenchInsuranceCompanies, { status: 200 });
    }

    if (sharedData?.clientConfig.market.country === Country.ee) {
      return HttpResponse.json(estonianInsuranceCompanies, { status: 200 });
    }

    return HttpResponse.json(
      sharedData?.clientConfig.clientId.includes('pension')
        ? pensionCompanies
        : swedishInsuranceCompanies,
      { status: 200 },
    );
  }),
  http.get(`${API_URL}/insurance-collection/get-data/:id`, getDataHandler),
  http.get(`${API_URL}/pension-collection/get-data/:id`, getDataHandler),

  http.post<
    never,
    {
      insuranceSubType: InsuranceSubType;
      insuranceCompany: string;
      insuranceName: InsurancePackageName;
    }
  >(`${API_URL}/insurance-collection/get-parameters/`, async ({ request }) => {
    const { insuranceSubType, insuranceCompany, insuranceName } = await request.json();
    return HttpResponse.json(getParameters({ insuranceSubType, insuranceCompany, insuranceName }));
  }),

  http.post<
    never,
    {
      coveragePolicyData: CoveragePolicyData;
      packageScope: PackageScopeRequest;
    }
  >(`${API_URL}/coverage/equivalent-package-scopes`, async ({ request }) => {
    const { packageScope, coveragePolicyData } = await request.json();

    return HttpResponse.json(getEquivalentPackageScope({ coveragePolicyData, packageScope }));
  }),

  // Car ICA endpoint
  http.post<never, CarQuotePayload>(
    `${API_URL}/checkout/quote/price-estimate`,
    async ({ request }) => HttpResponse.json(getPriceEstimateSE(await request.json())),
  ),
  http.get<
    { configName: string },
    never,
    | {
        status: string;
        statusCode: 401;
        message: string;
        localizedMessage: string;
        errors: string[];
      }
    | DeepPartial<BlocksClientConfig>
  >(`${API_URL}/blocks/config/:configName`, ({ params }) => {
    const { configName } = params;
    const blocksConfig = getFrontendOptions(configName);
    const config = getConfig(configName);
    if (!config) {
      return HttpResponse.json(
        {
          status: 'UNAUTHORIZED',
          statusCode: 401,
          message: 'Not allowed',
          localizedMessage: 'Ej tillåtet',
          errors: [],
        },
        { status: 401 },
      );
    }
    return HttpResponse.json({ customerId: CUSTOMER_ID, config, blocksConfig }, { status: 200 });
  }),
  http.get(`${API_URL}/**/resumable-collections/:code`, () =>
    HttpResponse.json(
      {
        customerId: CUSTOMER_ID,
        configName: CONFIG_NAME,
        resumableCollections: [
          {
            company: 'se-swedbank',
            loginMethod: LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE_CLIENT_SIDE_AUTHENTICATION,
          },
        ],
      },
      { status: 200 },
    ),
  ),

  http.all(`${API_URL}/*`, ({ request }) => {
    const url = new URL(request.url);
    if (!PROXY_ENABLED) {
      const errorMessage = `Unhandled request: ${request.method} ${url.pathname}\n\n All requests should be mocked when proxy is disabled.`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
    console.warn(unhandledRequestWarning(request.method, url.pathname));
    return passthrough();
  }),
  http.all(`*`, () => passthrough()),
];

import classNames from 'classnames';

import { PropsWithChildren } from 'react';

import Footer from '../Footer';

import styles from './blocksBaseView.module.css';

export const BlocksBaseView = ({
  isWebView,
  showFooter,
  children,
}: PropsWithChildren<{
  isWebView?: boolean;
  showFooter?: boolean;
}>) => (
  <div
    className={classNames(styles.base, !showFooter && styles.noFooter)}
    style={{ minHeight: isWebView ? '100vh' : 700 }}
  >
    <div className={styles.content}>{children}</div>
    {showFooter ? <Footer /> : null}
  </div>
);

import { InsurelyUIProvider } from '@insurely/ui';

import { PropsWithChildren, Suspense } from 'react';
import { Helmet } from 'react-helmet';

import { BlocksBaseView } from '@main/components/BlocksBaseView/BlocksBaseView';

import { ErrorFallback } from '@main/components/ErrorFallback';
import Loading from '@main/components/Loading';
import { blocksPathMapping, useUseCase } from '@main/contexts';
import { useLoaderData, Outlet, Navigate, Await, useAsyncError } from '@main/services';
import { BlocksClientConfig, Language, UseCaseName } from '@main/types';
import { StructuredError } from '@main/utils';

import { AppLayout } from './layout';
import { AppLoader } from './loader';

function WithHelmet({
  shouldLoadIframeResizeScript = false,
  children,
}: PropsWithChildren<{ shouldLoadIframeResizeScript?: boolean }>) {
  return (
    <>
      {shouldLoadIframeResizeScript ? (
        <Helmet key="app-helmet">
          {/* Start fetching script in parallel with blocks config */}
          <script
            key="iframe-resizer-content-window-script"
            src={`${BLOCKS_URL}/assets/iframeResizer.contentWindow.js`}
            type="text/javascript"
          />
        </Helmet>
      ) : null}
      {/* This is used for up-check, do not remove! */}
      <div
        key="up-check-element"
        id="up-check"
        style={{ position: 'absolute', height: '2px', width: '2px' }}
      />
      {children}
    </>
  );
}

function ErrorElement({ language }: { language: Language }) {
  const error = useAsyncError() as Error | StructuredError;

  return (
    <ErrorFallback
      error={error}
      resetError={() => {
        window.location.reload();
      }}
      language={language}
    />
  );
}

export function AppInitialization() {
  const loaderData = useLoaderData() as AppLoader;

  const { customization, isWebView, language } = loaderData.moduleInput.config;

  return (
    <WithHelmet shouldLoadIframeResizeScript={!isWebView}>
      <InsurelyUIProvider
        theme={customization?.theme}
        fonts={customization?.fonts}
        deBrandedLogos={customization?.logosVariant === 'deBranded'}
      >
        <BlocksBaseView
          isWebView={!!loaderData.moduleInput.config.isWebView}
          showFooter={
            loaderData.blocksClientConfig?.config
              ? loaderData.blocksClientConfig.config.useCase !== UseCaseName['overview-and-compare']
              : false
          }
        >
          <Suspense fallback={<Loading />}>
            <Await
              resolve={loaderData.blocksClientConfig}
              errorElement={<ErrorElement language={language ?? Language.en} />}
            >
              {(blocksClientConfig: BlocksClientConfig) => (
                <AppLayout
                  loaderData={loaderData}
                  blocksClientConfig={blocksClientConfig}
                  apiVersion={loaderData.apiVersion}
                >
                  <Outlet />
                </AppLayout>
              )}
            </Await>
          </Suspense>
        </BlocksBaseView>
      </InsurelyUIProvider>
    </WithHelmet>
  );
}

export const RedirectToInitialModule = () => {
  const {
    useCase: { initialModule },
  } = useUseCase();

  return <Navigate to={`${blocksPathMapping[initialModule]}`} replace />;
};

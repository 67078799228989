import { useIntl } from 'react-intl';

import { BaseView } from '@main/components/BaseView';
import { Language, useSharedData } from '@main/shared-exports';

import { Header } from '@overview/components';

import summaryStyles from '../insuranceSummary.module.css';

import { HeadlineSkeleton, ChartSkeleton, ListSkeleton } from './components';

import styles from './skeletonLoaderOverview.module.css';

export const SkeletonLoaderOverview = () => {
  const intl = useIntl();
  const [
    {
      clientConfig: { language },
    },
  ] = useSharedData();

  return (
    <BaseView
      type="fullWidth"
      header={<Header title="" ariaLabel={intl.formatMessage({ defaultMessage: 'Loading' })} />}
    >
      <div data-testid="skeleton-loader" className={summaryStyles.outerDiv}>
        <div className={summaryStyles.container}>
          <HeadlineSkeleton
            style={{ marginBottom: language === Language.fr ? '90px' : 'var(--space-18)' }}
          />
          <ChartSkeleton className={styles.chart} />
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.listItem}>
          <ListSkeleton />
        </div>
        <div className={styles.listItem}>
          <ListSkeleton />
        </div>
      </div>
    </BaseView>
  );
};

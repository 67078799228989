/* eslint-disable max-lines */
import { CompanyAndLineOfBusiness, LoginMethod } from '@main/types';

export const swedishInsuranceCompanies: Company[] = [
  {
    insuranceCompany: 'se-agria',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Agria',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-bliwa',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Bliwa',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-demo',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Demo Company',
    loginMethods: [
      LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE,
      LoginMethod.USERNAME_AND_PASSWORD,
    ],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-dina',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Dina',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-euroaccident',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Euroaccident',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-folksam',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Folksam',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-gjensidige',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Gjensidige',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-hedvig',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Hedvig',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-ica',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'ICA',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-if',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'If',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-lansforsakringar',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Länsförsäkringar',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE, LoginMethod.PDF_UPLOAD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [CompanyAndLineOfBusiness.SE_LANSFORSAKRINGAR_HOME],
  },
  {
    insuranceCompany: 'se-lassie',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Lassie',
    loginMethods: [LoginMethod.EMAIL],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-manypets',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Many Pets',
    loginMethods: [LoginMethod.USERNAME_AND_PASSWORD],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-moderna',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Moderna',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-movestic',
    functional: true,
    status: 'OK',
    reason: 'THIRD_PARTY_ERROR',
    insuranceCompanyDisplayName: 'Movestic',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-paydrive',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Paydrive',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-skandia',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Skandia',
    loginMethods: [
      LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE,
      LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE,
    ],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-svedea',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Svedea',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-sveland',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Sveland',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-trekronor',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Trekronor',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE],
    nonFunctionalLoginMethods: [LoginMethod.SWEDISH_SECURITY_TOKEN],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-trygg-hansa',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Trygg-Hansa',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-volvia',
    functional: true,
    status: 'OK',
    reason: 'THIRD_PARTY_ERROR',
    insuranceCompanyDisplayName: 'Volvia',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
];

export const pensionCompanies: Company[] = [
  {
    insuranceCompany: 'se-alecta',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Alecta',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-amf',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'AMF',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-avanza',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Avanza',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-demo',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Demo Company',
    loginMethods: [
      LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE,
      LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE,
      LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE_MOCK,
      LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE_TEST,
    ],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-folksam',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Folksam',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-futur',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Futur',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-handelsbanken',
    functional: true,
    status: 'OK',
    reason: 'INSURELY_UPDATE',
    insuranceCompanyDisplayName: 'Handelsbanken',
    loginMethods: [
      LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE,
      LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE,
    ],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-lansforsakringar',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Länsförsäkringar',
    loginMethods: [
      LoginMethod.PDF_UPLOAD,
      LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE,
      LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE_CLIENT_SIDE_AUTHENTICATION,
    ],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-movestic',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Movestic / JustInCase',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-nordea',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Nordea',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-nordnet',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Nordnet',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-seb',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'SEB',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-skandia',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Skandia',
    loginMethods: [
      LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE,
      LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE,
    ],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-spp',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'SPP',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-svenska-fribrevsbolaget',
    functional: true,
    status: 'BETA',
    insuranceCompanyDisplayName: 'Svenska Fribrevsbolaget',
    loginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_ANY_DEVICE],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-trekronor',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Swedbank / Trekronor',
    loginMethods: [
      LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE,
      LoginMethod.SWEDISH_SECURITY_TOKEN,
    ],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'se-swedbank',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Swedbank',
    loginMethods: [
      LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE_CLIENT_SIDE_AUTHENTICATION,
      LoginMethod.SWEDISH_MOBILE_BANKID_OTHER_DEVICE,
    ],
    nonFunctionalLoginMethods: [LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
];

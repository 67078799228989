import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  QUESTIONNAIRE_FAILED_QUESTIONS,
  QUESTIONNAIRE_PATH,
  QUESTIONNAIRE_QUESTION,
} from '@main/constants';
import { useCollectionItems, useUseCase } from '@main/contexts';
import {
  sentryCaptureException,
  sharedApiClient,
  useLocation,
  useMutation,
  useNavigate,
  postHogCapture,
} from '@main/services';

type QuestionType = 'yes-no';

export interface Question {
  id: string;
  order: number;
  name: string;
  proxyName: string;
  description?: string;
  'question-type': QuestionType;
}

export type Answer = {
  label: string;
  value: string;
};

type Answers = Record<string, Answer>;

interface QuestionnaireContextType {
  questions?: Question[];
  answers: Answers;
  getQuestion: (questionId: string) => Question | undefined;
  answerQuestion: (answer: { questionId: string; answer: Answer }) => void;
  setQuestions: (questions: Question[]) => void;
  postQuestionnaireAnswers: () => void;
}

type QuestionnaireBlockContextType = Required<QuestionnaireContextType>;

const QuestionnaireContext = createContext<
  (QuestionnaireContextType | QuestionnaireBlockContextType) | undefined
>(undefined);

export function useQuestionnaire(): QuestionnaireContextType {
  const context = useContext(QuestionnaireContext);
  if (context === undefined) {
    throw new Error('useQuestionnaire must be used within a QuestionnaireProvider');
  }

  return context;
}

export function useQuestionnaireBlockData(): QuestionnaireBlockContextType {
  const context = useContext(QuestionnaireContext);
  if (context === undefined) {
    throw new Error('useQuestionnaire must be used within a QuestionnaireProvider');
  }

  if (context.questions === undefined) {
    throw new Error('Questions are required but do not exist in state');
  }

  return { ...context, questions: context.questions };
}

const sendQuestionnaireAnswers = async ({
  answers,
  collectionIds,
  onError,
  onSuccess,
  questions,
}: {
  answers: Answers;
  collectionIds: string[];
  onError?: () => void;
  onSuccess?: () => void;
  questions: Question[];
}) => {
  sharedApiClient({
    method: 'POST',
    url: '/questionnaire/answers',
    headers: { 'insurely-version': '2024-04-07' },
    data: {
      collectionIds,
      questionsAndAnswers: questions.map(({ id, name, order }) => ({
        question: name,
        answer: answers[id].label,
        order,
      })),
    },
  })
    .then((res) => {
      onSuccess?.();
      return res;
    })
    .catch((err) => {
      sentryCaptureException(new Error('Error when sending questionnaire answers', err));
      onError?.();
    });
};

export default function QuestionnaireProvider({ children }: PropsWithChildren) {
  const [questions, setQuestions] = useState<Question[] | undefined>();
  const [answers, setAnswers] = useState<Answers>({});
  const { collectionIds } = useCollectionItems();
  const navigate = useNavigate();
  const { nextBlock, useCase } = useUseCase();
  const location = useLocation();
  const { pathname } = location;

  const { mutateAsync: postAnswers } = useMutation(sendQuestionnaireAnswers);

  const postQuestionnaireAnswers = useCallback(
    (newAnswers: Answers = answers) => {
      const onError = () => {
        // This check is needed for the return to questionnaire button work properly i.e navigate(-1)
        if (pathname !== `${QUESTIONNAIRE_PATH}/${QUESTIONNAIRE_FAILED_QUESTIONS}`) {
          navigate(`${QUESTIONNAIRE_PATH}/${QUESTIONNAIRE_FAILED_QUESTIONS}`);
        }
      };
      if (questions) {
        return postAnswers({
          questions,
          answers: newAnswers,
          collectionIds,
          onSuccess: nextBlock,
          onError,
        });
      }
    },
    [answers, collectionIds, questions, navigate, postAnswers, nextBlock, pathname],
  );

  const getQuestion = useCallback(
    (questionId: string) => questions?.find((q) => q.id === questionId),
    [questions],
  );

  const answerQuestion = useCallback(
    async ({ questionId, answer }: { questionId: string; answer: Answer }) => {
      if (!questions) return;
      const answeredQuestionIndex = questions.findIndex((q) => q.id === questionId);
      const isLastQuestion = answeredQuestionIndex === questions.length - 1;

      setAnswers((prevAnswers) => {
        const newAnswers = { ...prevAnswers, [questionId]: answer };
        const nextBlockName = useCase.modules.questionnaire?.next;

        const formStatus = questions.reduce(
          (accumulator, currentValue) => ({
            ...accumulator,
            [currentValue.proxyName]: {
              hasDefaultValue: false,
              hasValue: !!newAnswers[currentValue.id]?.value,
              isDefaultValue: false,
              isValid: !!newAnswers[currentValue.id]?.value,
              value: newAnswers[currentValue.id]?.value,
            },
          }),
          {},
        );

        postHogCapture(`questionnaire:state`, {
          action: 'state',
          category: 'questionnaire',
          object: 'form',
          metadata: {
            formStatus,
            description: `User answered question number ${answeredQuestionIndex + 1} in form`,
            context: `question${answeredQuestionIndex + 1}`,
            target: isLastQuestion ? nextBlockName : `question${answeredQuestionIndex + 2}`,
          },
        });

        if (typeof answeredQuestionIndex === 'number' && isLastQuestion) {
          // All questions have been answered - post to backend
          // eslint-disable-next-line no-void
          void postQuestionnaireAnswers(newAnswers);
        }

        return newAnswers;
      });

      if (answeredQuestionIndex == null)
        throw new Error(`Question with id: ${questionId} cannot be found`);

      if (isLastQuestion) {
        return;
      }
      // Navigate to next question
      navigate(`${QUESTIONNAIRE_PATH}/${QUESTIONNAIRE_QUESTION}`, {
        searchParams: { questionId: questions[answeredQuestionIndex + 1].id },
      });
    },
    [questions, navigate, useCase.modules.questionnaire?.next, postQuestionnaireAnswers],
  );

  const value: QuestionnaireContextType = useMemo(
    () => ({
      answerQuestion,
      answers,
      getQuestion,
      questions,
      setQuestions,
      postQuestionnaireAnswers,
    }),
    [answerQuestion, answers, getQuestion, questions, setQuestions, postQuestionnaireAnswers],
  );

  return <QuestionnaireContext.Provider value={value}>{children}</QuestionnaireContext.Provider>;
}

import { TableTwo, Typography } from '@insurely/ui';

import classNames from 'classnames';

import { ReactNode } from 'react';

import styles from './table.module.css';

interface Column<Id extends string> {
  id: Id;
  title: ReactNode;
  canBeSorted?: boolean;
  customSorting?: (rowA: Row<Id>, rowB: Row<Id>) => number;
}

interface Row<Id extends string> {
  id: string;
  values: Record<Id, ReactNode>;
}

interface TableProps<Id extends string> {
  columns: Column<Id>[];
  rows: Row<Id>[];
  className?: string;
}

const renderCell = (cell: ReactNode) => {
  const isString = typeof cell === 'string';
  const isPositiveNumber = isString && cell.startsWith('+');
  const isNegativeNumber = isString && cell.startsWith('-');

  return (
    <Typography
      component="span"
      variant="Label-3"
      className={classNames(
        isPositiveNumber && styles.positiveNumber,
        isNegativeNumber && styles.negativeNumber,
      )}
    >
      {cell}
    </Typography>
  );
};

const renderHeader = (cell: ReactNode) => (
  <Typography component="span" variant="ParagraphTiny">
    {cell}
  </Typography>
);

export const Table = <Id extends string>({ columns, rows, className }: TableProps<Id>) => {
  const styledColumns = columns.map((item) => ({
    ...item,
    renderHeader,
    renderCell,
  }));

  return (
    <TableTwo
      className={classNames(styles.table, styles.overwrites, className)}
      columns={styledColumns}
      rows={rows}
      getRowProps={() => ({ className: styles.row })}
      getHeaderProps={() => ({ className: styles.tableHeader })}
    />
  );
};

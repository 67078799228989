import { IconArrowLeft, IconButton, IconClose, Typography } from '@insurely/ui';

import { useSharedData } from '@main/contexts';
import { usePostMessage } from '@main/hooks';
import { postHogCapture, usePreviousPath } from '@main/services';
import { PostMessageName } from '@main/utils';

import styles from './header.module.css';

export interface HeaderProps {
  title: string;
  backButtonAriaLabel: string;
  closeButtonAriaLabel: string;
  showBackButton: boolean;
  goBackOnClick: () => void;
  progressBar?: React.ReactNode;
  ariaLabel?: string;
}

type BaseHeaderProps = Omit<
  HeaderProps,
  'isFirstPageInBlock' | 'goBackOnClick' | 'showBackButton'
> & {
  closeOnClick?: () => void;
  goBackOnClick?: () => void;
  showBackButton?: boolean;
  showCloseButton?: boolean;
};

export const BaseHeader = ({
  title,
  backButtonAriaLabel,
  closeButtonAriaLabel,
  goBackOnClick,
  closeOnClick,
  showBackButton = false,
  showCloseButton = false,
  progressBar,
  ariaLabel,
}: BaseHeaderProps) => {
  const prevPath = usePreviousPath();
  const handleBackClick = () => {
    if (goBackOnClick) {
      postHogCapture('back-button-pressed', {
        fromPath: window.location.pathname,
        // May not accurately represent where button is navigating,
        // Best guess is previous path in history
        toPath: prevPath,
      });
      goBackOnClick();
    }
  };

  return (
    <header className={styles.header} aria-label={ariaLabel ?? title}>
      <div className={styles.topSection}>
        {showBackButton && goBackOnClick ? (
          <IconButton
            icon={<IconArrowLeft width="32px" />}
            onClick={handleBackClick}
            aria-label={backButtonAriaLabel}
          />
        ) : (
          <div />
        )}
        <Typography component="h1" variant="Headline-8" className={styles.title}>
          {title}
        </Typography>
        {showCloseButton && closeOnClick ? (
          <IconButton
            icon={<IconClose width="32px" />}
            onClick={closeOnClick}
            aria-label={closeButtonAriaLabel}
          />
        ) : (
          <div />
        )}
      </div>
      {progressBar}
    </header>
  );
};

export const Header = ({ ariaLabel, showBackButton, ...props }: HeaderProps) => {
  const [
    {
      userConfig: { showCloseButton, showBackButton: showBackButtonUserConfig },
    },
  ] = useSharedData();
  const postMessage = usePostMessage();
  const prevPath = usePreviousPath();
  const isFirstPage = !prevPath;
  const shouldShowBackButton = !isFirstPage && showBackButtonUserConfig ? showBackButton : false;

  return (
    <BaseHeader
      {...props}
      ariaLabel={ariaLabel}
      showBackButton={shouldShowBackButton}
      showCloseButton={showCloseButton}
      closeOnClick={() => postMessage({ name: PostMessageName.APP_CLOSE })}
    />
  );
};

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ReactNode, useEffect } from 'react';

import { useSharedData } from '@main/contexts';

import { sharedApiClient } from './sharedApiClient';

const queryClient = new QueryClient();
export function InsurelyQueryClientProvider({ children }: { children: ReactNode }) {
  const [
    {
      clientConfig: { clientId },
    },
  ] = useSharedData();

  useEffect(() => {
    sharedApiClient.defaults.headers.common['client-id'] = clientId;
  }, [clientId]);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

export * from '@tanstack/react-query';

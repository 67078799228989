import { createContext, useContext, useMemo, useState } from 'react';

import { Pension } from '@main/types';

export interface AdditionalPensionInformation {
  externalId: string;
  insuranceHolder?: {
    name: {
      prefilled: boolean; // true if value has been set by collected data else false
      value?: string;
    };
    orgNumber: {
      prefilled: boolean; // true if value has been set by collected data else false
      value?: string;
    };
  };
}

type NewPensionType = 'CURRENT_EMPLOYER' | 'PRIVATE';
export interface OccupationalPensionChoices {
  newInsuranceHolder: {
    type: NewPensionType; // Will always be 'PRIVATE' when pensionType == PRIVATE_PENSION
    name?: string;
    orgNumber?: string;
  };
  isTraditional: boolean;
}

export interface PrivatePensionChoices {
  isTraditional: boolean;
}

export interface NewPensionChoices {
  occupationalPension?: OccupationalPensionChoices;
  privatePension?: PrivatePensionChoices;
}

export interface TransferPensionContext {
  setAdditionalPensionInformation: React.Dispatch<
    React.SetStateAction<AdditionalPensionInformation[]>
  >;
  additionalPensionInformation: AdditionalPensionInformation[];

  newPensionChoices: NewPensionChoices;
  setNewPensionChoices: React.Dispatch<React.SetStateAction<NewPensionChoices>>;

  selectedTransferablePensions: Pension[];
  setSelectedTransferablePensions: React.Dispatch<React.SetStateAction<Pension[]>>;
}

const TransferPensionContext = createContext<TransferPensionContext | undefined>(undefined);

export function useTransferPensionContext(): TransferPensionContext {
  const context = useContext(TransferPensionContext);

  if (context === undefined) {
    throw new Error('useTransfer must be used within a TransferProvider');
  }

  return context;
}

export const TransferPensionProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedTransferablePensions, setSelectedTransferablePensions] = useState<Pension[]>([]);
  const [newPensionChoices, setNewPensionChoices] = useState<NewPensionChoices>({});

  const [additionalPensionInformation, setAdditionalPensionInformation] = useState<
    AdditionalPensionInformation[]
  >([]);

  const value: TransferPensionContext = useMemo(
    () => ({
      additionalPensionInformation,
      setAdditionalPensionInformation,
      newPensionChoices,
      setNewPensionChoices,
      selectedTransferablePensions,
      setSelectedTransferablePensions,
    }),
    [
      additionalPensionInformation,
      newPensionChoices,
      selectedTransferablePensions,
      setAdditionalPensionInformation,
      setSelectedTransferablePensions,
    ],
  );

  return (
    <TransferPensionContext.Provider value={value}>{children}</TransferPensionContext.Provider>
  );
};

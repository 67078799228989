export enum CompanyAvailabilityStatus {
  OK = 'OK',
  BETA = 'BETA',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
}

export enum ConsentRequirementsType {
  'TRADE_UNION_MEMBERSHIP' = 'TRADE_UNION_MEMBERSHIP',
}
export enum ConsentRequirementsMethod {
  'NONE' = 'NONE',
  'NOTICE' = 'NOTICE',
  'ACTIVE' = 'ACTIVE',
}

// Available CompanyAndLineOfBusiness from java backend
export enum CompanyAndLineOfBusiness {
  EE_ERGO = 'EE_ERGO',
  EE_IF = 'EE_IF',
  EE_SEESAM = 'EE_SEESAM',
  EE_SWEDBANK = 'EE_SWEDBANK',
  FR_ACHEEL_HOME = 'FR_ACHEEL_HOME',
  FR_ALLIANZ_HOME = 'FR_ALLIANZ_HOME',
  FR_ALLIANZ_VEHICLE = 'FR_ALLIANZ_VEHICLE',
  FR_ANIMAUXSANTE_HOME = 'FR_ANIMAUXSANTE_HOME',
  FR_ASSUR_OPOIL_ANIMAL = 'FR_ASSUR_OPOIL_ANIMAL',
  FR_AXA_HOME = 'FR_AXA_HOME',
  FR_AXA_VEHICLE = 'FR_AXA_VEHICLE',
  FR_AXA_GENERAL = 'FR_AXA_GENERAL',
  FR_BULLEBLEUE_HOME = 'FR_BULLEBLEUE_HOME',
  FR_CAISSE_DEPARGNE_HOME = 'FR_CAISSE_DEPARGNE_HOME',
  FR_CARDIF_HOME = 'FR_CARDIF_HOME',
  FR_CREDIT_AGRICOLE_HOME = 'FR_CREDIT_AGRICOLE_HOME',
  FR_CREDIT_AGRICOLE_VEHICLE = 'FR_CREDIT_AGRICOLE_VEHICLE',
  FR_CREDIT_MUTUEL_HOME = 'FR_CREDIT_MUTUEL_HOME',
  FR_CREDIT_MUTUEL_VEHICLE = 'FR_CREDIT_MUTUEL_VEHICLE',
  FR_DEMO_HOME = 'FR_DEMO_HOME',
  FR_DEMO_VEHICLE = 'FR_DEMO_VEHICLE',
  FR_DIRECT_ASSURANCE_HOME = 'FR_DIRECT_ASSURANCE_HOME',
  FR_DIRECT_ASSURANCE_VEHICLE = 'FR_DIRECT_ASSURANCE_VEHICLE',
  FR_ECA_HOME = 'FR_ECA_HOME',
  FR_GENERALI_HOME = 'FR_GENERALI_HOME',
  FR_GENERALI_VEHICLE = 'FR_GENERALI_VEHICLE',
  FR_GMF_HOME = 'FR_GMF_HOME',
  FR_GMF_VEHICLE = 'FR_GMF_VEHICLE',
  FR_GROUPAMA_HOME = 'FR_GROUPAMA_HOME',
  FR_GROUPAMA_VEHICLE = 'FR_GROUPAMA_VEHICLE',
  FR_LUKO_HOME = 'FR_LUKO_HOME',
  FR_MAAF_HOME = 'FR_MAAF_HOME',
  FR_MAAF_VEHICLE = 'FR_MAAF_VEHICLE',
  FR_MACIF_HOME = 'FR_MACIF_HOME',
  FR_MACIF_VEHICLE = 'FR_MACIF_VEHICLE',
  FR_MAIF_HOME = 'FR_MAIF_HOME',
  FR_MAIF_VEHICLE = 'FR_MAIF_VEHICLE',
  FR_MATMUT_HOME = 'FR_MATMUT_HOME',
  FR_MMA_HOME = 'FR_MMA_HOME',
  FR_MMA_VEHICLE = 'FR_MMA_VEHICLE',
  FR_SANTEVET_HOME = 'FR_SANTEVET_HOME',
  SE_LANSFORSAKRINGAR_HOME = 'SE_LANSFORSAKRINGAR_HOME',
  SE_PAYDRIVE_VEHICLE = 'SE_PAYDRIVE_VEHICLE',
  UK_AXA_HOME = 'UK_AXA_HOME',
  UK_AXA_VEHICLE = 'UK_AXA_VEHICLE',
  UK_HASTINGS_DIRECT_HOME = 'UK_HASTINGS_DIRECT_HOME',
  UK_HASTINGS_DIRECT_VEHICLE = 'UK_HASTINGS_DIRECT_VEHICLE',
  UK_POLICYEXPERT_HOME = 'UK_POLICYEXPERT_HOME',
  UK_POLICYEXPERT_VEHICLE = 'UK_POLICYEXPERT_VEHICLE',
  UK_POST_OFFICE_HOME = 'UK_POST_OFFICE_HOME',
  UK_THE_AA_HOME = 'UK_THE_AA_HOME',
  UK_THE_AA_VEHICLE = 'UK_THE_AA_VEHICLE',
  UK_URBAN_JUNGLE_HOME = 'UK_URBAN_JUNGLE_HOME',
  FR_AXA_PERSON = 'FR_AXA_PERSON',
}

export enum LoginConfigUsernameLabel {
  EMAIL = 'EMAIL',
  USERNAME = 'USERNAME',
}

export enum LoginConfigUsernameFieldPlaceholder {
  EMAIL = 'EMAIL',
  IDENTIFICATION_NUMBER_11 = 'IDENTIFICATION_NUMBER_11',
  IDENTIFICATION_CHARACTERS_8 = 'IDENTIFICATION_CHARACTERS_8',
  EMAIL_MOBILE_MUTUAL_MEMBER = 'EMAIL_MOBILE_MUTUAL_MEMBER',
  EMAIL_MOBILE_CLIENT = 'EMAIL_MOBILE_CLIENT',
  EMAIL_MUTUAL_OR_REGULAR_MEMBER = 'EMAIL_MUTUAL_OR_REGULAR_MEMBER',
  EMAIL_MOBILE_CONTRACT = 'EMAIL_MOBILE_CONTRACT',
  EMAIL_OR_MEMBER = 'EMAIL_OR_MEMBER',
}

export enum LoginConfigPassword {
  PASSWORD = 'PASSWORD',
  CONFIDENTIAL_CODE = 'CONFIDENTIAL_CODE',
  PERSONAL_CODE = 'PERSONAL_CODE',
}

/* eslint-disable max-lines */
import { enGB } from 'date-fns/locale';
import React, { Dispatch, useCallback, useEffect } from 'react';

import {
  CollectInfo,
  Country,
  DeepPartial,
  DevToolsContinuousUpdateContextState,
  FrHomeQuoteResponse,
  getLocale,
  Insurance,
  LinkedFrenchHomeForm,
} from '@main/types';

import { BlocksConfig, ClientConfig } from '@main/types/configurations/blocksConfig';
import { Language } from '@main/types/configurations/language';
import { UserConfig, ValidRegularModuleInput } from '@main/types/configurations/moduleInput';
import { FontType } from '@main/types/configurations/theme';
import { ResumableCollectionResponse } from '@main/types/resumable-collections';
import {
  invariant,
  isJwtToken,
  PostMessageName,
  safeSendInfoToParentWindow,
  setDevToolsLocalItem,
} from '@main/utils';
import { localStorageAvailable } from '@main/utils/localStorage';

import {
  CLEAR_FORM_DATA,
  CLEAR_SELECTED_POLICY,
  ReducerData,
  SET_ACCEPTED_OFFER_IN_COMPARE,
  SET_AUTH_TOKEN,
  SET_COLLECTION_INFO,
  SET_CONFIG,
  SET_DEVTOOLS_CONTINUOUS_UPDATE_DATA,
  SET_FORM_DATA,
  SET_LANGUAGE,
  SET_OFFER_DATA,
  SET_SELECTED_POLICY,
  SET_USER_INPUT_DATA,
  SharedDataState,
} from './types';
import { useUpdateModuleListener, validateAndSelectCheckoutProvider } from './utils';

const initialState = {
  userConfig: {
    language: Language.en,
    showCloseButton: false,
    showBackButton: true,
    customization: { fontType: 'main' as FontType },
    devToolsEnabled: false,
  },
  clientConfig: {
    locale: enGB,
  },
  frontendOptions: {},
  acceptedOfferInCompare: false,
} satisfies DeepPartial<SharedDataState>;

const SharedDataContext = React.createContext<
  [SharedDataState, React.Dispatch<ReducerData>] | undefined
>(undefined);

const sharedDataReducer = (state: SharedDataState, action: ReducerData): SharedDataState => {
  switch (action.type) {
    case SET_CONFIG: {
      return {
        ...state,
        userConfig: { ...state.userConfig, ...action.payload },
      };
    }
    case SET_COLLECTION_INFO: {
      return {
        ...state,
        collectInfo: { ...state.collectInfo, ...action.payload },
      };
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        clientConfig: {
          ...state.clientConfig,
          language: action.payload,
          locale: getLocale(state.clientConfig.market.country, action.payload),
        },
      };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        userConfig: {
          ...state.userConfig,
          authToken: action.payload,
        },
      };
    }
    case SET_SELECTED_POLICY: {
      return { ...state, selectedPolicy: action.payload };
    }
    case SET_USER_INPUT_DATA: {
      return { ...state, complementaryUserInput: action.payload };
    }
    case SET_OFFER_DATA: {
      return { ...state, offerData: action.payload };
    }
    case SET_ACCEPTED_OFFER_IN_COMPARE: {
      return { ...state, acceptedOfferInCompare: action.payload };
    }
    case CLEAR_SELECTED_POLICY: {
      return { ...state, selectedPolicy: undefined };
    }
    case SET_FORM_DATA: {
      return { ...state, formData: action.payload };
    }
    case SET_DEVTOOLS_CONTINUOUS_UPDATE_DATA: {
      return {
        ...state,
        userConfig: {
          ...state.userConfig,
          authToken: action.payload.general.advanced.authToken ?? state.userConfig.authToken,
          showCloseButton:
            action.payload.features.showCloseButton ?? state.userConfig.showCloseButton,
          showBackButton: action.payload.features.showBackButton ?? state.userConfig.showBackButton,
          compare: {
            skipCompareUrl:
              action.payload.features.skipCompareUrl ?? state.userConfig.compare?.skipCompareUrl,
            showCompanyLogo:
              action.payload.features.showCompanyLogo ?? state.userConfig.compare?.showCompanyLogo,
          },
          customization: {
            ...state.userConfig.customization,
            theme: action.payload.theming.theme ?? state.userConfig.customization.theme,
            fontType: action.payload.theming.fontType ?? state.userConfig.customization.fontType,
            logosVariant: action.payload.features.logosVariant,
          },
          devTranslations: action.payload.general.devTranslations,
        },
        clientConfig: {
          ...state.clientConfig,
          language: action.payload.general.language ?? state.clientConfig.language,
          locale: getLocale(
            state.clientConfig.market.country,
            action.payload.general.language ?? state.clientConfig.language,
          ),
        },
      };
    }
    case CLEAR_FORM_DATA: {
      return { ...state, formData: undefined };
    }
    default: {
      throw new Error(`Action type ${(action as { type: string }).type} is not supported`);
    }
  }
};

function createInitialState(
  moduleInput: ValidRegularModuleInput,
  clientConfig: ClientConfig,
  sessionId: string,
  blocksConfig?: BlocksConfig,
  resumableCollections?: ResumableCollectionResponse['resumableCollections'],
  apiVersion?: string,
): SharedDataState {
  const {
    config: {
      customerId,
      customization,
      dataAggregation,
      configName,
      language: userInputLanguage,
      authToken,
    },
  } = moduleInput;

  const { language } = clientConfig;
  invariant(
    !userInputLanguage || Object.values(Language).includes(userInputLanguage),
    `language must be one of ${Object.values(Language)}.`,
  );
  invariant(
    !authToken || isJwtToken(authToken),
    'authToken must be in a valid JWT token format (Bearer abc.123.xyz)',
  );

  const selectedCheckout = validateAndSelectCheckoutProvider(clientConfig, moduleInput);

  /** TODO: Remove  this logic when all clients have been moved to backend.
   *  https://linear.app/insurely/issue/EXPC-1112/use-new-configuration-data-from-blocksconfigconfigname-endpoint */

  const multiSelect =
    blocksConfig?.dataAggregation?.multiSelect ?? moduleInput.config.dataAggregation?.multiSelect;

  const currentCustomization = { ...customization, ...blocksConfig?.customization };

  const currentDataAggregation = { ...dataAggregation, ...blocksConfig?.dataAggregation };

  const currentCompare = { ...moduleInput.config.compare, ...blocksConfig?.compare };

  const showBackButton =
    blocksConfig?.showBackButton ??
    moduleInput.config.showBackButton ??
    initialState.userConfig.showBackButton;

  const showCloseButton =
    blocksConfig?.showCloseButton ??
    moduleInput.config.showCloseButton ??
    initialState.userConfig.showCloseButton;

  return {
    ...initialState,
    sessionId,
    apiVersion,
    frontendOptions: {
      ...blocksConfig?.frontendOptions,
    },
    clientConfig: {
      ...clientConfig,
      productConfigs: {
        ...clientConfig.productConfigs,
        checkout:
          clientConfig.productConfigs?.checkout && selectedCheckout
            ? { ...clientConfig.productConfigs.checkout, selectedCheckout }
            : undefined,
      },
      language: userInputLanguage ?? language,
      locale: getLocale(clientConfig.market.country, userInputLanguage ?? language),
    },
    userConfig: {
      ...initialState.userConfig,
      ...moduleInput.config,
      showBackButton,
      showCloseButton,
      authToken,
      customerId,
      configName,
      customization: {
        ...currentCustomization,
        logosVariant:
          !currentCustomization.logosVariant && clientConfig.market.country === Country.fr
            ? 'noLogos'
            : currentCustomization.logosVariant,
        fontType: currentCustomization.fontType ?? initialState.userConfig.customization.fontType,
      },
      dataAggregation: {
        ...currentDataAggregation,
        multiSelect: localStorageAvailable() && multiSelect,
      },
      compare: currentCompare,
    },
    prefill: {
      ...moduleInput.prefill,
    },
    resumableCollections,
  };
}

interface SharedDataContextProviderProps {
  apiVersion?: string;
  moduleInput: ValidRegularModuleInput;
  resumableCollections?: ResumableCollectionResponse['resumableCollections'];
  clientConfig: ClientConfig;
  blocksConfig?: BlocksConfig;
  sessionId: string;
  children: React.ReactNode;
}

export const SharedDataContextProvider: React.FC<SharedDataContextProviderProps> = ({
  apiVersion,
  moduleInput,
  resumableCollections,
  clientConfig,
  blocksConfig,
  sessionId,
  ...restProps
}) => {
  const [state, dispatch] = React.useReducer(
    sharedDataReducer,
    createInitialState(
      moduleInput,
      clientConfig,
      sessionId,
      blocksConfig,
      resumableCollections,
      apiVersion,
    ),
  );

  useEffect(() => {
    if (ENVIRONMENT === 'testrunner' || IS_MOCKED_API) {
      setDevToolsLocalItem({ key: 'localSharedData', value: state });
    }
  }, [state]);

  useEffect(() => {
    safeSendInfoToParentWindow({
      allowedOrigins: clientConfig.allowedOrigin,
      name: PostMessageName.APP_LOADED,
      targetOrigin: IS_DEVMODE ? '*' : moduleInput.config.parentOrigin,
      extraInformation: moduleInput.config.devToolsEnabled ? clientConfig : undefined,
    });
  }, [clientConfig, moduleInput.config.devToolsEnabled, moduleInput.config.parentOrigin]);

  useUpdateModuleListener(dispatch);

  const value = React.useMemo<[SharedDataState, Dispatch<ReducerData>]>(
    () => [state, dispatch],
    [state],
  );

  return <SharedDataContext.Provider value={value} {...restProps} />;
};

export const useSharedData = () => {
  const context = React.useContext(SharedDataContext);
  if (!context) {
    throw new Error('useSharedData must be used within a SharedDataContextProvider');
  }

  const [state, dispatch] = context;

  const setConfig = useCallback(
    (payload: UserConfig) => {
      dispatch({ type: SET_CONFIG, payload });
    },
    [dispatch],
  );

  const setCollectionInfo = useCallback(
    (payload: Partial<CollectInfo>) => {
      dispatch({ type: SET_COLLECTION_INFO, payload });
    },
    [dispatch],
  );

  const selectPolicy = useCallback(
    (policy: InsurancePolicy) => {
      if (state.formData && state.formData?.externalId !== policy.insurance.externalId) {
        dispatch({ type: CLEAR_FORM_DATA });
      }
      dispatch({ type: SET_SELECTED_POLICY, payload: policy });
    },
    [dispatch, state.formData],
  );

  const unselectPolicy = useCallback(() => {
    dispatch({ type: CLEAR_SELECTED_POLICY });
  }, [dispatch]);

  const setComplementaryUserInput = useCallback(
    (payload: Partial<Insurance>) => {
      dispatch({ type: SET_USER_INPUT_DATA, payload });
    },
    [dispatch],
  );

  const setOfferData = useCallback(
    (payload: FrHomeQuoteResponse) => {
      dispatch({ type: SET_OFFER_DATA, payload });
    },
    [dispatch],
  );

  const setFormData = useCallback(
    (payload: LinkedFrenchHomeForm) => {
      dispatch({ type: SET_FORM_DATA, payload });
    },
    [dispatch],
  );

  const setDevtoolsContinuousUpdateData = useCallback(
    (payload: DevToolsContinuousUpdateContextState) => {
      dispatch({ type: SET_DEVTOOLS_CONTINUOUS_UPDATE_DATA, payload });
    },
    [dispatch],
  );

  const clearFormData = useCallback(() => {
    dispatch({ type: CLEAR_FORM_DATA });
  }, [dispatch]);

  const clearCollectionId = useCallback(() => {
    safeSendInfoToParentWindow({
      name: PostMessageName.RESET_HASH,
      value: true,
      targetOrigin: state.userConfig.parentOrigin,
      allowedOrigins: state.clientConfig.allowedOrigin,
    });
    if (state.userConfig?.dataAggregation?.collectionId) {
      setConfig({
        ...state.userConfig,
        dataAggregation: {
          ...(state.userConfig?.dataAggregation ?? {}),
          collectionId: undefined,
        },
      });
    }
  }, [setConfig, state.clientConfig.allowedOrigin, state.userConfig]);

  const setAcceptedOfferInCompare = useCallback(
    (payload: boolean) => {
      dispatch({ type: SET_ACCEPTED_OFFER_IN_COMPARE, payload });
    },
    [dispatch],
  );

  return [
    state,
    {
      setConfig,
      setCollectionInfo,
      selectPolicy,
      setComplementaryUserInput,
      setOfferData,
      setFormData,
      unselectPolicy,
      clearFormData,
      clearCollectionId,
      setAcceptedOfferInCompare,
      setDevtoolsContinuousUpdateData,
    },
  ] as const;
};

export const useSharedDataConfig = () => useSharedData()[0];

export const useIsDevToolsEnabled = () => useSharedDataConfig().userConfig.devToolsEnabled;

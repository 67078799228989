import NotFoundScreen from '@main/components/NotFoundScreen/NotFoundScreen';
import { LanguageProvider } from '@main/i18n';
import { createBrowserRouter } from '@main/services';
import { Language } from '@main/types';

import { ResumeCodeInputView } from '@main/views/ResumeCodeInputView';

import { blockRoutes } from '../blocks/routes';

import { RouteError } from '../utils/RouteError';

import { AppInitialization, RedirectToInitialModule } from './initialization';
import { appLoader } from './loader';

export const rootRoutes = [
  {
    Component: AppInitialization,
    loader: appLoader,
    // Should only run one time
    shouldRevalidate: () => false,
    errorElement: <RouteError />,
    children: [...Object.values(blockRoutes), { path: '/', Component: RedirectToInitialModule }],
  },
  {
    path: '/resume-code-input',
    Component: () => (
      <LanguageProvider language={Language.en}>
        <ResumeCodeInputView />
      </LanguageProvider>
    ),
  },
  {
    path: '*',
    Component: NotFoundScreen,
  },
];

export const createRouter = () =>
  createBrowserRouter(rootRoutes, {
    basename: ROUTER_BASENAME,
  });

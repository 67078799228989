import posthog, { Properties } from 'posthog-js';

import { getDevToolsLocalItem } from '@main/utils';

export { posthog };

export interface PosthogRegisterParameters {
  customerId?: string;
  configName?: string;
  sessionId?: string;
  collectionId?: string;
  ageBracket?: string;
  gender?: string;
}

export const isPosthogEnabled = () => {
  if (ENVIRONMENT === 'testrunner') return false;
  if (/prod|test|staging/.test(ENVIRONMENT)) return true;
  return (
    getDevToolsLocalItem({ key: 'devtoolsRequiresReloadUpdate' })?.general.advanced
      .postHogEnabled ?? false
  );
};

export const getAgeBracket = (pin: string) => {
  const age = new Date().getFullYear() - parseInt(pin.substring(0, 4), 10);
  return `${Math.floor(age / 10)}0 - ${Math.floor(age / 10)}9`;
};

export const getGender = (pin: string) => {
  const birthNumber = pin.substring(8, 11);
  return Number(birthNumber) % 2 === 0 ? 'female' : 'male';
};

export const getFeatureFlag = <T = ReturnType<typeof posthog.getFeatureFlag>>(
  flagName: string,
): T | undefined => {
  if (!isPosthogEnabled()) return;
  return posthog.getFeatureFlag(flagName) as T | undefined;
};

const posthogDebug = (enabled: boolean, logMessage: string, ...logData: unknown[]) =>
  // eslint-disable-next-line no-console
  console.debug(`[posthog${enabled ? '' : ':disabled'}] ${logMessage}`, ...logData);

export const postHogGroup = ({
  groupCollectionName,
  groupName,
}: {
  groupCollectionName: string;
  groupName: string;
}) => {
  if (!isPosthogEnabled()) return;

  posthog.group(groupCollectionName, groupName, {
    name: groupName,
  });
};

export const postHogCapture: typeof posthog.capture = (eventName, data) => {
  const enabled = isPosthogEnabled();

  const options = {
    $current_url: `${window.location.origin}${window.location.pathname}`,
    referrer: window.location.ancestorOrigins?.[0] ?? document.referrer,
    queryParameters: window.location.search,
    ...data,
  };
  if (DEBUG_LOGGING) {
    posthogDebug(enabled, `capture ${eventName}`, options);
  }
  if (!enabled) return;

  posthog.capture(eventName, options);
};

export const postHogRegisterForSession: typeof posthog.register_for_session = (data) => {
  const enabled = isPosthogEnabled();
  if (DEBUG_LOGGING) {
    posthogDebug(enabled, 'register_for_session', data);
  }
  if (!enabled) return;

  posthog.register_for_session(data);
};

export const postHogUnregisterForSession: typeof posthog.unregister_for_session = (property) => {
  const enabled = isPosthogEnabled();
  if (DEBUG_LOGGING) {
    posthogDebug(enabled, 'register_for_session', { property });
  }
  if (!enabled) return;

  posthog.unregister_for_session(property);
};

export const postHogRegisterOnce: typeof posthog.register_once = (data: Properties) => {
  const enabled = isPosthogEnabled();
  if (DEBUG_LOGGING) {
    posthogDebug(enabled, 'register_once', { data });
  }
  if (!isPosthogEnabled()) return;

  posthog.register_once(data);
};

export const postHogRegisterAgeAndGender = (pin?: string) => {
  if (pin) {
    postHogRegisterOnce({ ageBracket: getAgeBracket(pin), gender: getGender(pin) });
  }
};

import { Typography, TypographyVariant } from '@insurely/ui';

import classNames from 'classnames';

import styles from './descriptionListItem.module.css';

export interface DescriptionListItemProps {
  term: string;
  description: string | number;
  termTextVariant?: TypographyVariant;
  descriptionTextVariant?: TypographyVariant;
  className?: string;
}

export const DescriptionListItem = ({
  term,
  description,
  termTextVariant = 'ParagraphTiny',
  descriptionTextVariant = 'Label-3',
  className,
}: DescriptionListItemProps) => (
  <dl className={classNames(styles.item, className)}>
    <Typography component="dt" variant={termTextVariant}>
      {term}
    </Typography>
    <Typography component="dd" variant={descriptionTextVariant}>
      {description}
    </Typography>
  </dl>
);

import { Insurance } from '@main/types';
import {
  isFrenchInsuranceModel,
  isEstonianInsuranceModel,
  isLatvianInsuranceModel,
} from '@main/utils';

export const getEndDate = (insurance: Insurance) =>
  (isFrenchInsuranceModel(insurance) ||
    isEstonianInsuranceModel(insurance) ||
    isLatvianInsuranceModel(insurance)) &&
  insurance.endDate
    ? insurance.endDate
    : insurance.renewalDate;

import { useEffect } from 'react';

import { sentryCaptureException } from '@main/services';
import { postHogCapture } from '@main/shared-exports';
import { Language } from '@main/types';
import { isStructuredError, StructuredError } from '@main/utils';

import copy from './copy.json';
import { HandledFallback } from './HandledFallback';
import { UnhandledFallback } from './UnhandledFallback';

interface ErrorFallbackProps {
  error: StructuredError | Error;
  resetError: () => void;
  pageTitle?: string;
  resetButtonText?: string;
  language: Language;
}

const UnhandledFallbackWrapper = ({
  error,
  language,
  resetError,
}: Omit<ErrorFallbackProps, 'pageTitle' | 'resetButtonText'>) => {
  const isOutdatedVersionError = error?.message === 'error loading dynamically imported module';

  useEffect(() => {
    if (error) {
      postHogCapture('view:unhandledError', {
        action: 'view',
        category: 'outsideOfBlocks',
        object: 'UnhandledFallbackWrapper',
        metadata: {
          description: error.message,
          type: 'error',
        },
      });
    }
    sentryCaptureException(error);
  }, [error]);

  return (
    <UnhandledFallback
      title={
        isOutdatedVersionError
          ? copy.outdatedVersionError.title[language]
          : copy.genericErrorTitle[language]
      }
      headline={
        isOutdatedVersionError
          ? copy.outdatedVersionError.headline[language]
          : copy.genericErrorHeadline[language]
      }
      message={
        isOutdatedVersionError
          ? copy.outdatedVersionError.message[language]
          : copy.genericErrorMessage[language]
      }
      resetErrorButtonText={
        isOutdatedVersionError
          ? copy.outdatedVersionError.refresh[language]
          : copy.resetErrorButton[language]
      }
      resetError={isOutdatedVersionError ? () => window.location.reload() : resetError}
      type={isOutdatedVersionError ? 'Warning' : 'Error'}
    />
  );
};

export function ErrorFallback(props: ErrorFallbackProps) {
  const { error, pageTitle, resetButtonText, language, ...rest } = props;
  if (!isStructuredError(error))
    return <UnhandledFallbackWrapper error={error} language={language} {...rest} />;
  return (
    <HandledFallback
      error={error}
      pageTitle={pageTitle ?? copy.genericErrorTitle[language]}
      resetButtonText={resetButtonText ?? copy.resetErrorButton[language]}
      {...rest}
    />
  );
}

import { CompanyLogo, Typography } from '@insurely/ui';

import { useIntl } from 'react-intl';

import { useSharedData } from '@main/shared-exports';
import { Account } from '@main/types';

import { formatCurrency, formatNumber } from '@main/utils';

import { formattedWealthType } from '../../../WealthOverview/components/AccountList/utils';
import { PlateContainer } from '../../../WealthOverview/components/PlateContainer';
import {
  formattedLatestUpdatedDate,
  isInvestmentAccount,
  isKfAccount,
  isPensionAccount,
} from '../../../WealthOverview/utils';

import {
  formattedInitialValue,
  formattedPerformanceSinceStart,
  formattedReturnValue,
  feeSectionExist,
} from '../../utils';
import { Table } from '../Table';

import { FeeSection } from './FeeSection';
import styles from './index.module.css';
import { InvestmentDescriptionList } from './InvestmentDescriptionList';
import { PensionDescriptionList } from './PensionDescriptionList';

interface GeneralInfoProp {
  account: Account;
  latestUpdatedDate: Date;
}

export const GeneralInfo = ({ account, latestUpdatedDate }: GeneralInfoProp) => {
  const intl = useIntl();

  const [
    {
      clientConfig: {
        language,
        market: { country },
      },
    },
  ] = useSharedData();

  const {
    company,
    companyDisplayName,
    financialInformation: { currentValue, totalDeposits, performanceSinceStart },
    type,
  } = account;

  const wealthType = formattedWealthType(type, intl);
  const headline = `${wealthType} · ${companyDisplayName}`;

  const date = formattedLatestUpdatedDate({
    latestUpdatedDate,
    intl,
    language,
    country,
  });

  const formattedCurrentValue = formatNumber(currentValue.amount, language);

  const currentValueWithCurrency = formatCurrency({
    language,
    market: {
      country,
      currency: currentValue.currency,
    },
    amount: currentValue.amount,
  });

  const historicalDataExist = totalDeposits?.amount || performanceSinceStart;

  const columns = [
    {
      id: 'value' as const,
      title: intl.formatMessage({
        id: 'overview.wealth-account.general-info.table-header-1',
        defaultMessage: 'Value',
      }),
    },
    {
      id: 'deposited' as const,
      title: intl.formatMessage({
        id: 'overview.wealth-account.general-info.table-header-2',
        defaultMessage: 'Deposited',
      }),
    },
    {
      id: 'performance' as const,
      title: intl.formatMessage({
        id: 'overview.wealth-account.general-info.table-header-3',
        defaultMessage: 'Performance',
      }),
    },
    {
      id: 'return' as const,
      title: intl.formatMessage({
        id: 'overview.wealth-account.general-info.table-header-4',
        defaultMessage: 'Return',
      }),
    },
  ];

  const financialInformationValues = {
    currentValue: currentValue.amount,
    initialValue: totalDeposits?.amount,
    performanceSinceStart,
    language,
  };

  const rows = [
    {
      values: {
        value: formattedCurrentValue,
        deposited: formattedInitialValue(financialInformationValues),
        performance: formattedPerformanceSinceStart(financialInformationValues),
        return: formattedReturnValue(financialInformationValues),
      },
      id: 'value-development',
    },
  ];

  return (
    <PlateContainer fullWidth>
      <div className={styles.companySection}>
        <CompanyLogo width={32} company={company} aria-hidden />
        <Typography component="p" variant="ParagraphBodySmall">
          {headline}
        </Typography>
      </div>
      <dl className={styles.infoSection}>
        <Typography component="dt" variant="ParagraphBodySmall" className={styles.term}>
          {intl.formatMessage({
            id: 'overview.wealth-account.general-info.total-value',
            defaultMessage: 'Total value',
          })}
        </Typography>
        <Typography component="dd" variant="Headline-6" className={styles.description}>
          {currentValueWithCurrency}
        </Typography>
        <Typography component="p" variant="ParagraphTiny">
          {date}
        </Typography>
      </dl>
      {historicalDataExist && (
        <>
          <Typography component="h3" variant="Label-2" className={styles.headline}>
            {intl.formatMessage({
              id: 'overview.wealth-account.general-info.table-headline',
              defaultMessage: 'Value development',
            })}
          </Typography>
          <Table columns={columns} rows={rows} />
        </>
      )}
      {(isPensionAccount(account) || isKfAccount(account)) && feeSectionExist(account.fees) ? (
        <FeeSection account={account} />
      ) : null}
      {isPensionAccount(account) && <PensionDescriptionList account={account} />}
      {isInvestmentAccount(account) && <InvestmentDescriptionList account={account} />}
    </PlateContainer>
  );
};

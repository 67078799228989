import { EstonianCarDeductible } from '@main/types/insurance/estonia';

export const fakeNewEstonianCarDeductibles: Array<EstonianCarDeductible> = [
  {
    deductibleName: 'General deductible',
    deductibleId: 'deductibleGeneral',
    deductibleAmount: 300,
  },
  {
    deductibleName: 'Glass deductible',
    deductibleId: 'deductibleGlass',
    deductibleAmount: 0,
  },
  {
    deductibleName: 'Theft deductible',
    deductibleId: 'deductibleTheft',
    deductibleAmount: 100,
    deductibleFreeTextFormula:
      'If, at the time of the loss event, the individual policyholder has, in addition to this contract, annual contracts of at least two different types of insurance in ERGO (traffic insurance (except trailer), home insurance, accident insurance, legal expenses insurance or repeat travel insurance), the deductible is reduced by 100 euros ( but. not lower than 0 euros) than stated in the contract.',
  },
];

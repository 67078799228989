import { useEffect } from 'react';

import { ClientConfig } from '@main/types';
import { ValidModuleInput } from '@main/types/configurations/moduleInput';
import { PostMessageName, invariant, isJwtToken, useErrorHandler } from '@main/utils';
import { isLanguage } from '@main/utils/typeAssertions/language';

import { ReducerData, SET_AUTH_TOKEN, SET_LANGUAGE } from './types';

export const validateAndSelectCheckoutProvider = (
  clientConfig: ClientConfig,
  moduleInput: ValidModuleInput,
) => {
  if (!clientConfig.productConfigs?.checkout) return;
  const selectedCheckout =
    clientConfig.productConfigs.checkout.availableCheckouts.find(
      (availableCheckout) => !/.*-demo/.test(availableCheckout.insuranceCompany),
    ) ?? clientConfig.productConfigs.checkout.availableCheckouts[0];
  const checkoutConfig = {
    ...selectedCheckout,
    insuranceDocuments: clientConfig.productConfigs.checkout.insuranceDocuments,
    subType: selectedCheckout?.insuranceSubType?.replace('Insurance', ''),
  };
  if (moduleInput.config.checkout?.insuranceProvider) {
    const userSelectedCheckout =
      clientConfig.productConfigs.checkout.availableCheckouts?.find(
        (availableCheckout) =>
          availableCheckout.insuranceCompany === moduleInput.config.checkout?.insuranceProvider,
      ) ?? clientConfig.productConfigs.checkout.availableCheckouts[0];
    invariant(
      !!userSelectedCheckout,
      `It seems like ${moduleInput.config.checkout?.insuranceProvider} is not a valid insuranceProvider for this customer`,
    );
    return {
      ...userSelectedCheckout,
      subType: userSelectedCheckout.insuranceSubType.replace('Insurance', ''),
      insuranceDocuments: clientConfig.productConfigs.checkout.insuranceDocuments,
    };
  }
  return checkoutConfig;
};

export const useUpdateModuleListener = (dispatch: React.Dispatch<ReducerData>) => {
  const errorHandler = useErrorHandler();
  useEffect(() => {
    function updateModuleInputListener(event: MessageEvent) {
      const { data } = event;
      switch (data.name) {
        case PostMessageName.CHANGE_LANGUAGE: {
          const possibleLanguage = data.value;
          if (!isLanguage(possibleLanguage)) return;
          dispatch({ type: SET_LANGUAGE, payload: possibleLanguage });
          break;
        }
        case PostMessageName.UPDATE_AUTH_TOKEN: {
          const possibleToken = data.value;
          if (!isJwtToken(possibleToken)) {
            errorHandler('JWT token is in invalid format');
            postMessage({ name: PostMessageName.INVALID_AUTH_TOKEN });
          } else {
            postMessage({ name: PostMessageName.VALID_AUTH_TOKEN });
          }
          dispatch({ type: SET_AUTH_TOKEN, payload: possibleToken });
          break;
        }
        default:
      }
    }

    window.addEventListener('message', updateModuleInputListener);
    return () => {
      window.removeEventListener('message', updateModuleInputListener);
    };
  }, [dispatch, errorHandler]);
};

import {
  da as daLocale,
  enGB as enGBLocale,
  et as etLocale,
  fr as frLocale,
  lv as lvLocale,
  nb as nbLocale,
  sv as svLocale,
} from 'date-fns/locale';

import { Country } from '@main/types/configurations/market';

export enum Language {
  da = 'da',
  en = 'en',
  et = 'et',
  fr = 'fr',
  lv = 'lv',
  no = 'no',
  sv = 'sv',
}

interface MarketLanguage {
  language: Language;
  name: string;
  locale: Locale;
  default?: boolean;
}

type MarketLanguages = Partial<Record<Language, MarketLanguage>>;

type LanguageProp = Partial<Record<Country, MarketLanguages>>;

export const languageProp: LanguageProp = {
  [Country.dk]: {
    [Language.da]: {
      language: Language.da,
      name: 'Danish',
      locale: daLocale,
      default: true,
    },
    [Language.en]: {
      language: Language.en,
      name: 'English',
      locale: enGBLocale,
    },
  },
  [Country.ee]: {
    [Language.et]: {
      language: Language.et,
      name: 'Estonian',
      locale: etLocale,
      default: true,
    },
    [Language.en]: {
      language: Language.en,
      name: 'English',
      locale: enGBLocale,
    },
  },
  [Country.lv]: {
    [Language.lv]: {
      language: Language.lv,
      name: 'Latvian',
      locale: lvLocale,
      default: true,
    },
    [Language.en]: {
      language: Language.en,
      name: 'English',
      locale: enGBLocale,
    },
  },
  [Country.no]: {
    [Language.no]: {
      language: Language.no,
      name: 'Norwegian',
      locale: nbLocale,
      default: true,
    },
    [Language.en]: {
      language: Language.en,
      name: 'English',
      locale: enGBLocale,
    },
  },
  [Country.se]: {
    [Language.sv]: {
      language: Language.sv,
      name: 'Swedish',
      locale: svLocale,
      default: true,
    },
    [Language.en]: {
      language: Language.en,
      name: 'English',
      locale: enGBLocale,
    },
  },
  [Country.fr]: {
    [Language.fr]: {
      language: Language.fr,
      name: 'French',
      locale: frLocale,
      default: true,
    },
    [Language.en]: {
      language: Language.en,
      name: 'French',
      locale: enGBLocale,
    },
  },
} as const;

export const getLocale = (country: Country, language: Language) => {
  const locale = languageProp[country]?.[language]?.locale as Locale | undefined;
  return locale ?? enGBLocale;
};

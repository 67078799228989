import { InsuranceParameter } from '@main/types';
import { FrenchCarInsurance, FrenchHomeInsurance } from '@main/types/insurance/france';

import { FrenchAccidentInsurance } from '@main/types/insurance/france/FrenchPersonalInsurance';

import {
  fakeNewFrenchCarInsurance,
  fakeNewFrenchHomeInsurance,
  fakeNewFrenchAccidentInsurance,
} from './insurance';
import { fakeNewFrenchCarParameters, fakeNewFrenchHomeParameters } from './parameters';

export const fakeNewFrenchHomeCollection = (
  overrides?:
    | Array<{
        insurance?: Partial<FrenchHomeInsurance>;
        parameters?: Array<InsuranceParameter>;
      } | null>
    | number,
): Array<InsurancePolicy> => {
  const defaultData = () => ({
    insurance: fakeNewFrenchHomeInsurance.one(),
    parameters: fakeNewFrenchHomeParameters(),
    addons: [],
    personalInformation: { PERSONAL_NUMBER: '199301010101' },
  });

  return typeof overrides === 'number'
    ? Array(overrides)
        .fill(0)
        .map((_) => defaultData())
    : (overrides?.map((o) => {
        const collectionData = defaultData();
        return {
          ...collectionData,
          insurance: {
            ...collectionData.insurance,
            ...o?.insurance,
          },
          parameters: o?.parameters ?? collectionData.parameters,
        };
      }) ?? [defaultData()]);
};

export const fakeNewFrenchCarCollection = (
  overrides?: Array<{ insurance?: Partial<FrenchCarInsurance> }> | number,
): Array<InsurancePolicy> => {
  const defaultData = () => ({
    insurance: fakeNewFrenchCarInsurance.one(),
    parameters: fakeNewFrenchCarParameters(),
    addons: [],
    personalInformation: { PERSONAL_NUMBER: '199301010101' },
  });
  return typeof overrides === 'number'
    ? Array(overrides)
        .fill(0)
        .map((_) => defaultData())
    : (overrides?.map((o) => {
        const collectionData = defaultData();
        return {
          ...collectionData,
          insurance: {
            ...collectionData.insurance,
            ...o.insurance,
          },
        };
      }) ?? [defaultData()]);
};

export const fakeNewFrenchAccidentCollection = (
  overrides?: Array<{ insurance?: Partial<FrenchAccidentInsurance> }> | number,
): Array<InsurancePolicy> => {
  const defaultData = () => ({
    insurance: fakeNewFrenchAccidentInsurance.one(),
    parameters: [],
    addons: [],
    personalInformation: { PERSONAL_NUMBER: '199301010101' },
  });
  return typeof overrides === 'number'
    ? Array(overrides)
        .fill(0)
        .map((_) => defaultData())
    : (overrides?.map((o) => {
        const collectionData = defaultData();
        return {
          ...collectionData,
          insurance: {
            ...collectionData.insurance,
            ...o.insurance,
          },
        };
      }) ?? [defaultData()]);
};

import { createRoot } from 'react-dom/client';

import '@main/services/sentry/start';
import './index.css';
import Root from './App';
import { createRouter } from './routes/app/routes';

const container = document.getElementById('root');
if (!container) {
  throw new Error('No root container found');
}

if (ENVIRONMENT === 'development' && IS_MOCKED_API === 'true') {
  const { worker } = await import('./mocks/browser');
  await worker.start({ onUnhandledRequest: 'warn' });
}

const root = createRoot(container);

root.render(<Root router={createRouter()} />);

import classNames from 'classnames';

import styles from './skeleton.module.css';

export const HeadlineSkeleton = ({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) => (
  <div aria-hidden className={classNames(styles.background, styles.headlineMask)} style={style}>
    <svg
      width="179"
      height="41"
      viewBox="0 0 179 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <clipPath id="clipPathHeadline">
        <rect width="179" height="16" fill="white" rx="4" ry="4" transform="translate(0 0.5)" />
        <rect width="129" height="16" fill="white" rx="4" ry="4" transform="translate(25 24.5)" />
      </clipPath>
    </svg>
  </div>
);

import {
  INITIAL_NAVIGATION_ROUTE,
  DATA_AGGREGATION_ROUTE,
  COMPARE_ROUTE,
  CHECKOUT_ROUTE,
  OVERVIEW_ROUTE,
  QUESTIONNAIRE_ROUTE,
  RESULTS_ROUTE,
  CONTACT_ROUTE,
  END_STATE_ROUTE,
  PENSION_MOVE_ROUTE,
} from '@main/constants';
import { BlocksModule } from '@main/types';

import { lazyWithPreloadAndRetry } from '@main/utils';

import { overviewRoutes } from '../overview/routes';

export const blockRoutes = {
  [BlocksModule.InitialNavigation]: {
    path: `${INITIAL_NAVIGATION_ROUTE}/*`,
    Component: lazyWithPreloadAndRetry(() => import('@main/blocks/initial-navigation')),
  },
  [BlocksModule.DataAggregation]: {
    path: `${DATA_AGGREGATION_ROUTE}/*`,
    Component: lazyWithPreloadAndRetry(() => import('@main/blocks/data-aggregation')),
  },
  [BlocksModule.Compare]: {
    path: `${COMPARE_ROUTE}/*`,
    Component: lazyWithPreloadAndRetry(() => import('@main/blocks/compare')),
  },
  [BlocksModule.Checkout]: {
    path: `${CHECKOUT_ROUTE}/*`,
    Component: lazyWithPreloadAndRetry(() => import('@main/blocks/checkout')),
  },
  [BlocksModule.Overview]: {
    path: `${OVERVIEW_ROUTE}`,
    Component: lazyWithPreloadAndRetry(() => import('@main/blocks/overview')),
    children: overviewRoutes,
  },
  [BlocksModule.Questionnaire]: {
    path: `${QUESTIONNAIRE_ROUTE}/*`,
    Component: lazyWithPreloadAndRetry(() => import('@main/blocks/questionnaire')),
  },
  [BlocksModule.Results]: {
    path: `${RESULTS_ROUTE}/*`,
    Component: lazyWithPreloadAndRetry(() => import('@main/blocks/results')),
  },
  [BlocksModule.Contact]: {
    path: `${CONTACT_ROUTE}/*`,
    Component: lazyWithPreloadAndRetry(() => import('@main/blocks/contact')),
  },
  [BlocksModule.EndState]: {
    path: `${END_STATE_ROUTE}/*`,
    /**
     * EndState block currently only has customized implementations, which is why it's imported from __customer-implementations__
     * */
    Component: lazyWithPreloadAndRetry(
      () => import('@main/__customer-implementations__/blocks/end-state'),
    ),
  },
  [BlocksModule.PensionMove]: {
    path: `${PENSION_MOVE_ROUTE}/*`,
    /**
     * PensionMove block currently only has customized implementations, which is why it's imported from __customer-implementations__
     * */
    Component: lazyWithPreloadAndRetry(
      () => import('@main/__customer-implementations__/blocks/pension-move'),
    ),
  },
};

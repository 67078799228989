import { fakeNewSwedishPension } from '@main/mocks/fake-utils/collections';
import { Pension, PensionType, MovabilityResult, PensionClassification } from '@main/types';

export const pension: Pension[] = [
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.COLLECTIVE_OCCUPATIONAL_PENSION,
      insuranceHolderName: 'Moa Strömberg Forsberg',
      insuranceName: 'Individuell Tjänstepension',
      insuranceNumber: '11aa11aa11aa11aa11aa',
      externalId: '11aa11aa',
      startDate: '2018-01-01',
      currentValue: 20867.79,
      totalPaid: 8000.0,
      activePayment: true,
      activePayout: false,
      feeFixed: 240.0,
      feeVariable: 0.0065,
      feesCollected: true,
      feesPreviousYear: 324.45,
      hasSurvivorsProtection: true,
      movability: {
        result: MovabilityResult.NOT_MOVABLE,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYMENT',
            result: MovabilityResult.NOT_MOVABLE,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.MOVABLE,
          },
        ],
        managementInstitutions: [
          {
            name: 'Avtalat',
            url: 'https://www.avtalat.se/logga-in/',
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234568',
          name: 'Framtidsfond',
          shareCount: 998.23,
          value: 10000.76,
          valueInPercent: 0.479,
          feeVariable: 0.01,
          currencyCode: 'SEK',
        },
        {
          isin: 'SE0001234561',
          name: 'Globalfond',
          shareCount: 14.22,
          value: 6544.16,
          valueInPercent: 0.314,
          feeVariable: 0.005,
          currencyCode: 'SEK',
        },
        {
          isin: 'SE0001234563',
          name: 'Sverigefond',
          shareCount: 99.42,
          value: 4322.87,
          valueInPercent: 0.207,
          feeVariable: 0.012,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.OCCUPATIONAL_PENSION,
      insuranceName: 'Tjänstepensionsförsäkring',
      insuranceNumber: '22bb22bb22bb22bb22bb',
      externalId: '22bb22bb',
      currentValue: 15000.45,
      totalPaid: 12000.0,
      startDate: '2019-01-01',
      activePayment: true,
      activePayout: false,
      hasSurvivorsProtection: true,
      insuranceHolderName: 'Testcompany 1 AB',
      insuranceHolderOrgNumber: undefined,
      movability: {
        result: MovabilityResult.MOVABLE,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.MOVABLE,
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234512',
          name: 'Nordenfond',
          shareCount: 99.23,
          value: 7400.23,
          valueInPercent: 49.3,
          currencyCode: 'SEK',
        },
        {
          isin: 'SE0001234561',
          name: 'Asienfond',
          shareCount: 34.45,
          value: 7600.22,
          valueInPercent: 50.7,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.OCCUPATIONAL_PENSION,
      insuranceName: 'Traditionell tjänstepension',
      insuranceNumber: '33cc33cc33cc33cc33cc',
      externalId: '33cc33cc',
      currentValue: 15000.45,
      totalPaid: 12000.0,
      startDate: '2019-01-01',
      activePayment: true,
      activePayout: false,
      hasSurvivorsProtection: true,
      insuranceHolderName: 'Testcompany 2 AB',
      insuranceHolderOrgNumber: undefined,
      traditionallyManaged: true,
      movability: {
        result: MovabilityResult.MOVABLE,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.MOVABLE,
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234512',
          name: 'Nordenfond',
          shareCount: 99.23,
          value: 7400.23,
          valueInPercent: 49.3,
          currencyCode: 'SEK',
        },
        {
          isin: 'SE0001234561',
          name: 'Asienfond',
          shareCount: 34.45,
          value: 7600.22,
          valueInPercent: 50.7,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.OCCUPATIONAL_PENSION,
      insuranceName: 'Tjänstepension ITP 1',
      insuranceNumber: '44dd44dd44dd44dd44dd',
      externalId: '44dd44dd',
      currentValue: 12777.79,
      totalPaid: 8000.0,
      startDate: '2017-01-01',
      activePayment: false,
      activePayout: false,
      feeFixed: 65.0,
      feeVariable: 0.0,
      feesCollected: false,
      feesPreviousYear: 124.75,
      hasSurvivorsProtection: true,
      insuranceHolderName: undefined,
      insuranceHolderOrgNumber: undefined,
      movability: {
        result: MovabilityResult.MOVABLE,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.MOVABLE,
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234111',
          name: 'Asienfond',
          shareCount: 99.23,
          value: 10000.76,
          valueInPercent: 0.783,
          feeVariable: 0.014,
          currencyCode: 'SEK',
        },
        {
          isin: 'SE0001234561',
          name: 'Globalfond',
          shareCount: 14.22,
          value: 2777.03,
          valueInPercent: 0.217,
          feeVariable: 0.005,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.PRIVATE_PENSION,
      insuranceHolderName: 'Moa Strömberg Forsberg',
      insuranceName: 'Pensionssparande',
      insuranceNumber: '55ee55ee55ee55ee55ee',
      externalId: '55ee55ee',
      currentValue: 10000.76,
      totalPaid: 8000.0,
      startDate: '2017-01-01',
      activePayment: false,
      activePayout: false,
      feeFixed: 0.0,
      feeVariable: 0.0,
      feesCollected: false,
      feesPreviousYear: 0.0,
      hasSurvivorsProtection: false,
      movability: {
        result: MovabilityResult.UNKNOWN,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.UNKNOWN,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.MOVABLE,
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234111',
          name: 'Asienfond',
          shareCount: 99.23,
          value: 10000.76,
          valueInPercent: 1.0,
          feeVariable: 0.014,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.IPS_PENSION,
      insuranceHolderName: 'Moa Strömberg Forsberg',
      insuranceName: 'IPS Pension',
      insuranceNumber: '66ff66ff66ff66ff66ff',
      externalId: '66ff66ff',
      currentValue: 10000.76,
      totalPaid: 8000.0,
      startDate: '2012-01-01',
      activePayment: false,
      activePayout: false,
      feeFixed: 0.0,
      feeVariable: 0.0,
      feesCollected: false,
      feesPreviousYear: 0.0,
      hasSurvivorsProtection: false,
      movability: {
        result: MovabilityResult.NOT_MOVABLE,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.NOT_MOVABLE,
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234111',
          name: 'Asienfond',
          shareCount: 99.23,
          value: 10000.76,
          valueInPercent: 1.0,
          feeVariable: 0.014,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
  fakeNewSwedishPension.one({
    overrides: {
      pensionType: PensionType.COLLECTIVE_OCCUPATIONAL_PENSION,
      insuranceName: 'Kollektivavtalad pension ITP 1',
      insuranceNumber: '77gg77gg77gg77gg77gg',
      externalId: '77gg77gg',
      currentValue: 230000.79,
      totalPaid: 8000.0,
      startDate: '2011-01-01',
      activePayment: false,
      activePayout: false,
      feeFixed: 65.0,
      feeVariable: 0.0,
      feesCollected: false,
      feesPreviousYear: 124.75,
      hasSurvivorsProtection: true,
      insuranceHolderName: undefined,
      insuranceHolderOrgNumber: undefined,
      pensionClassification: PensionClassification.ITP,
      movability: {
        result: MovabilityResult.MOVABLE,
        evaluatedRules: [
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'ACTIVE_PAYOUT',
            result: MovabilityResult.MOVABLE,
          },
          {
            rule: 'OLD_TRAD_PENSION',
            result: MovabilityResult.MOVABLE,
          },
        ],
        managementInstitutions: [
          {
            name: 'Avtalat',
            url: 'https://www.avtalat.se/logga-in/',
          },
        ],
      },
      holding: [
        {
          isin: 'SE0001234111',
          name: 'Asienfond',
          shareCount: 99.23,
          value: 10000.76,
          valueInPercent: 0.783,
          feeVariable: 0.014,
          currencyCode: 'SEK',
        },
        {
          isin: 'SE0001234561',
          name: 'Globalfond',
          shareCount: 14.22,
          value: 2777.03,
          valueInPercent: 0.217,
          feeVariable: 0.005,
          currencyCode: 'SEK',
        },
      ],
    },
  }),
];

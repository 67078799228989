import { faker } from '@faker-js/faker';
import { build, perBuild } from '@jackfranklin/test-data-bot';

import { Country, Currency, InsuranceLevel, InsuranceSubType, InsuranceType } from '@main/types';
import {
  PremiumFrequencyLiteral,
  TaxationType,
} from '@main/types/insurance/core/CoreGeneralInsurance';
import {
  Amenity,
  Floor,
  FrenchCarInsurance,
  FrenchHomeInsurance,
  FrenchVehicleCoverage,
  Gender,
  OwnershipStatus,
  ParkingLocationType,
  ResidentialStatusFR,
  VehicleOwnershipStatus,
  VehicleUsage,
} from '@main/types/insurance/france';

import { FrenchAccidentInsurance } from '@main/types/insurance/france/FrenchPersonalInsurance';

import { getEndDate, getRenewalDate, getStartDate } from '../common/utils';

import { frenchFaker } from './frenchFaker';

export const fakeNewFrenchHomeInsurance = build<FrenchHomeInsurance>({
  fields: {
    insuranceObjectStreetAddress: perBuild(() => frenchFaker.location.streetAddress()),
    insuranceObjectPostalCode: perBuild(() => frenchFaker.location.zipCode()),
    insuranceObjectCity: perBuild(() => frenchFaker.location.city()),
    livingArea: perBuild(() => frenchFaker.number.int(150)),
    numberOfResidents: 4,
    insuredMovablesAmount: 12000,
    externalId: perBuild(() => frenchFaker.string.uuid()),
    insuranceName: 'Assurance habitation locataire',
    insuranceCompany: 'fr-demo',
    insuranceType: InsuranceType.houseContentInsurance,
    insuranceSubType: InsuranceSubType.condoInsurance,
    insuranceNumber: perBuild(() => frenchFaker.string.uuid()),
    premiumAmountYearRounded: perBuild(() => frenchFaker.number.int({ min: 200, max: 500 })),
    discountAmount: 0,
    discountPercentage: '0',
    renewalDate: perBuild(getRenewalDate),
    endDate: perBuild(() => getEndDate(getRenewalDate())),
    startDate: perBuild(() => getStartDate(getRenewalDate())),
    coming: false,
    premiumFrequency: 12,
    paymentMethod: 'GENERIC_INVOICE',
    employerPaid: false,
    otherInsuranceHolder: false,
    premium: {
      amount: {
        minorUnitAmount: perBuild(() => frenchFaker.number.float({ min: 25000, max: 50000 })),
        currencyCode: Currency.EUR,
      },
      frequency: PremiumFrequencyLiteral.YEARLY_PREMIUM_FREQUENCY,
      taxation: TaxationType.INCLUSIVE_TAXATION,
    },
    policyHolder: {
      name: {
        fullName: 'Cornélie Boivin',
        firstName: 'Cornélie',
        lastName: 'Boivin',
      },
    },
    insuranceHolderName: 'Cornélie Boivin',
    insuranceHolderStreetAddress: "34 Rue d'Enghien",
    insuranceHolderPostalCode: '80495',
    insuranceHolderCity: 'Paris',
    insurancePreviouslyRefused: false,
    insuredValuablesAmount: 25000,
    professionalUsage: false,
    floor: Floor.TOP_FLOOR,
    numberOfRooms: 4,
    additionalAmenities: [Amenity.FIREPLACE],
    ownershipStatus: OwnershipStatus.OWNER,
    outbuildingsTotalArea: {
      lowerLimit: 51,
      upperLimit: 100,
    },
    residentialStatus: ResidentialStatusFR.PRIMARY_RESIDENCE,
    numberOfAdults: 2,
    numberOfChildren: 2,
    insuranceHolderOccupation: 'Agricultrice',
    insuranceHolderEmail: 'cornelie.boivin@insurely.com',
    insuranceHolderPhoneNumber: '+33 93 233 8246',
    insuranceHolderGender: Gender.FEMALE,
    insuranceHolderDateOfBirth: '1999-12-31',
    '@market': Country.fr as const,
  },
});

export const fakeNewFrenchCarInsurance = build<FrenchCarInsurance>({
  fields: {
    externalId: perBuild(() => frenchFaker.string.uuid()),
    insuranceName: 'Tous Risques Confort',
    insuranceCompany: 'fr-demo',
    insuranceType: InsuranceType.vehicleInsurance,
    insuranceSubType: InsuranceSubType.carInsurance,
    insuranceNumber: perBuild(() => frenchFaker.string.uuid()),
    premiumAmountYearRounded: perBuild(() => frenchFaker.number.int({ min: 200, max: 500 })),
    premium: {
      amount: {
        minorUnitAmount: perBuild(() => frenchFaker.number.float({ min: 25000, max: 50000 })),
        currencyCode: Currency.EUR,
      },
      frequency: PremiumFrequencyLiteral.YEARLY_PREMIUM_FREQUENCY,
      taxation: TaxationType.INCLUSIVE_TAXATION,
    },
    discountAmount: 0,
    discountPercentage: '0',
    renewalDate: perBuild(getRenewalDate),
    endDate: perBuild(() => getEndDate(getRenewalDate())),
    startDate: perBuild(() => getStartDate(getRenewalDate())),
    coming: false,
    premiumFrequency: 12,
    paymentMethod: 'GENERIC_INVOICE',
    employerPaid: false,
    otherInsuranceHolder: false,
    insuranceHolderName: 'Cornélie Boivin',
    insuranceHolderStreetAddress: "34 Rue d'Enghien",
    insuranceHolderPostalCode: '80495',
    insuranceHolderCity: 'Paris',
    registrationNo: perBuild(() =>
      `${frenchFaker.string.alpha(2)}-${frenchFaker.string.numeric(3)}-${frenchFaker.string.alpha(
        2,
      )}`.toUpperCase(),
    ),
    brand: perBuild(() => frenchFaker.vehicle.manufacturer()),
    model: perBuild(() => frenchFaker.vehicle.model()),
    maxMileage: 1500,
    youngDriver: false,
    youngDriverAge: 18,
    usedForWork: false,
    coverage: FrenchVehicleCoverage.UNDEFINED,
    insuranceLevel: InsuranceLevel.BASE,
    insuranceHolderOccupation: 'Agricultrice',
    insuranceHolderEmail: 'cornelie.boivin@insurely.com',
    insuranceHolderPhoneNumber: '+33 93 233 8246',
    insuranceHolderGender: Gender.FEMALE,
    insuranceHolderDateOfBirth: '1999-12-31',
    vehicleOwnership: {
      status: VehicleOwnershipStatus.OWNED,
    },
    parking: {
      parkingLocationType: frenchFaker.helpers.enumValue(ParkingLocationType),
      parkingAddress: {
        street: "34 Rue d'Enghien",
        postalCode: '80495',
        city: 'Paris',
      },
    },
    primaryDriver: {
      name: 'Cornélie Boivin',
      birthDate: '1999-12-31',
      licenseIssuingDate: '2001-07-16',
    },
    secondaryDrivers: [
      {
        name: 'Simon Pierre',
        birthDate: '1985-04-16',
        licenseIssuingDate: '1998-06-06',
      },
    ],
    usagePurposes: [VehicleUsage.PRIVATE],
    '@market': Country.fr,
  },
});

export const fakeNewFrenchAccidentInsurance = build<FrenchAccidentInsurance>({
  fields: {
    version: '2',
    insuranceNumber: perBuild(() => faker.string.uuid()),
    insuranceHolderPostalCode: '75007',
    insuranceHolderDateOfBirth: '1999-09-08',
    insuranceHolderEmail: 'demo@insurely.com',
    insuranceHolderGender: Gender.FEMALE,
    insuranceHolderName: 'Cornélie Boivin',
    insuranceHolderStreetAddress: '8 AV. GUSTAVE EIFFEL',
    insuranceHolderPhoneNumber: '0123456789',
    insuranceHolderCity: 'PARIS',
    policyHolder: {
      name: {
        fullName: 'Cornélie Boivin',
        firstName: 'Cornélie',
        lastName: 'Boivin',
      },
    },
    insuranceName: 'Contrat tranquille famille',
    insuranceType: InsuranceType.personInsurance,
    insuranceSubType: InsuranceSubType.accidentInsurance,
    premiumAmountYearRounded: perBuild(() => faker.number.int({ min: 200, max: 500 })),
    premium: {
      amount: {
        minorUnitAmount: perBuild(() => faker.number.float({ min: 25000, max: 50000 })),
        currencyCode: Currency.EUR,
      },
      frequency: PremiumFrequencyLiteral.YEARLY_PREMIUM_FREQUENCY,
      taxation: TaxationType.INCLUSIVE_TAXATION,
    },
    premiumFrequency: 12,
    paymentMethod: 'UNDEFINED',
    addOns: [],
    deductibles: [],
    endDate: perBuild(() => getEndDate(getRenewalDate())),
    startDate: perBuild(() => getStartDate(getRenewalDate())),
    '@market': Country.fr,
    insuranceCompany: 'fr-demo',
    externalId: perBuild(() => faker.string.uuid()),
  },
});

import {
  fakeNewFrenchCarCollection,
  fakeNewFrenchHomeCollection,
} from '@main/mocks/fake-utils/collections';

export const frenchHomeInsurances = fakeNewFrenchHomeCollection();
export const frenchPartialHomeInsurances = fakeNewFrenchHomeCollection();
export const frenchCarInsurances = fakeNewFrenchCarCollection();

// Used for displaying insurances on load for overview
export const frenchPreviousCollections: Record<string, InsurancePolicy[]> = {
  'fr-completed-1': frenchHomeInsurances,
  'fr-completed-partial-1': frenchPartialHomeInsurances,
  'fr-completed-empty-1': [],
  'fr-completed-2': frenchHomeInsurances,
};

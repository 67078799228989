import { Buffer } from 'buffer';

const FIVE_MINUTES = 5 * 60 * 1000;
export type JwtToken = string;

export function isJwtToken(possibleToken: unknown): possibleToken is JwtToken {
  if (typeof possibleToken !== 'string') return false;
  if (!possibleToken.startsWith('Bearer ')) return false;

  return possibleToken.split('.').length === 3;
}

export const extractClaimsFromToken = (jwtToken: JwtToken) => {
  const claimsPart = jwtToken.split('.')[1];
  if (!claimsPart) return undefined;
  try {
    const parsedClaims = JSON.parse(Buffer.from(claimsPart, 'base64').toString());

    if (typeof parsedClaims !== 'object') return undefined;

    return parsedClaims as Record<string, unknown>;
  } catch {
    return undefined;
  }
};

export const extractExpirationFromToken = (jwtToken: JwtToken) => {
  const parsedClaims = extractClaimsFromToken(jwtToken);

  if (!parsedClaims || !('exp' in parsedClaims) || typeof parsedClaims.exp !== 'number')
    return undefined;

  return Number(parsedClaims.exp * 1000); // exp is in seconds while date is in milliseconds
};

export const extractIdFromToken = (jwtToken: unknown) => {
  if (!isJwtToken(jwtToken)) return undefined;
  const parsedClaims = extractClaimsFromToken(jwtToken);

  if (!parsedClaims || !('sub' in parsedClaims) || typeof parsedClaims.sub !== 'string')
    return undefined;

  return String(parsedClaims.sub);
};

export function tokenIsValid(jwtToken: unknown) {
  if (!isJwtToken(jwtToken)) return false;
  const jwtExpiration = extractExpirationFromToken(jwtToken);
  if (!jwtExpiration) return false;

  return Date.now() <= Number(jwtExpiration);
}

export function tokenIsExpiringSoon(jwtToken: unknown) {
  if (!isJwtToken(jwtToken)) return true;
  const jwtExpiration = extractExpirationFromToken(jwtToken);
  if (!jwtExpiration) return true;

  return Date.now() + FIVE_MINUTES >= Number(jwtExpiration);
}

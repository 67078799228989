import { WealthGetDataModel } from '@insurely/common-api-client';
import { createContext, useCallback, useContext, useMemo, useState, ReactNode } from 'react';

import { CollectionStatus } from '@main/types';
import { isPension } from '@main/utils';

import { getDistinctPolicies, getDistinctWealthData } from './utils';

type CompanyKey = string;

export type CompanyPolicies = Record<
  CompanyKey,
  { collectionId?: string; policies?: Policy[]; status: CollectionStatus }
>;

export type CompanyWealthData = Record<
  CompanyKey,
  { collectionId?: string; collectionItems?: WealthGetDataModel[]; status: CollectionStatus }
>;

type CompanyStatus = { company: string; status: CollectionStatus };

type CollectionItemsContextType = {
  externalIdToCollectionId: Record<string, string>;
  companyPolicies: CompanyPolicies;
  numberOfPolicies: number;
  collectionIds: string[];
  companyStatuses: CompanyStatus[];
  companyWealthData: CompanyWealthData;
  addPolicies: (params: {
    collectionId?: string;
    companyKey: CompanyKey;
    policies?: Policy[];
    status: CollectionStatus;
  }) => void;
  addWealthData: (params: {
    collectionId?: string;
    companyKey: CompanyKey;
    collectionItems?: WealthGetDataModel[];
    status: CollectionStatus;
  }) => void;
};

const CollectionItemsContext = createContext<CollectionItemsContextType | undefined>(undefined);

type CollectionItemsContextProviderProps = {
  children: ReactNode;
};

export function useCollectionItems() {
  const context = useContext(CollectionItemsContext);
  if (context === undefined) {
    throw new Error('useCollectionItems must be used within a CollectionItemsContextProvider');
  }
  return context;
}

export function useFlatPolicies() {
  const { companyPolicies } = useCollectionItems();
  return useMemo(
    () =>
      Object.values(companyPolicies).reduce<Policy[]>(
        (acc, { policies }) => (policies ? [...acc, ...policies] : acc),
        [],
      ),
    [companyPolicies],
  );
}

export function useFlatWealthData() {
  const { companyWealthData } = useCollectionItems();
  return useMemo(
    () =>
      Object.values(companyWealthData).reduce<WealthGetDataModel[]>(
        (acc, { collectionItems }) => (collectionItems ? [...acc, ...collectionItems] : acc),
        [],
      ),
    [companyWealthData],
  );
}

export function CollectionItemsContextProvider({ children }: CollectionItemsContextProviderProps) {
  const [companyPolicies, setPolicies] = useState<CompanyPolicies>({});
  const [companyWealthData, setCompanyWealthData] = useState<CompanyWealthData>({});

  const addWealthData = useCallback(
    ({
      collectionId,
      companyKey,
      collectionItems: newCollectionItems,
      status,
    }: {
      collectionId?: string;
      companyKey: CompanyKey;
      collectionItems?: WealthGetDataModel[];
      status: CollectionStatus;
    }) => {
      setCompanyWealthData((previousData) => ({
        ...previousData,
        [companyKey]: {
          collectionId,
          collectionItems: newCollectionItems
            ? getDistinctWealthData(
                previousData[companyKey]?.collectionItems ?? [],
                newCollectionItems,
              )
            : undefined,
          status,
        },
      }));
    },
    [],
  );

  const addPolicies = useCallback(
    ({
      collectionId,
      companyKey,
      policies: newPolicies,
      status,
    }: {
      collectionId?: string;
      companyKey: CompanyKey;
      policies?: Policy[];
      status: CollectionStatus;
    }) => {
      setPolicies((previousPolicies) => ({
        ...previousPolicies,
        [companyKey]: {
          collectionId,
          policies: newPolicies
            ? getDistinctPolicies(previousPolicies[companyKey]?.policies ?? [], newPolicies)
            : undefined,
          status,
        },
      }));
    },
    [setPolicies],
  );

  const externalIdToCollectionId = Object.values(companyPolicies).reduce(
    (prev, { policies, collectionId }) => {
      if (!policies || !collectionId) return prev;

      const companyMapping = policies.reduce(
        (companyMappingPrev, policy) => ({
          ...companyMappingPrev,
          [isPension(policy) ? policy.externalId : policy.insurance.externalId]: collectionId,
        }),
        {},
      );

      return { ...prev, ...companyMapping };
    },
    {},
  );

  const collectionIds = Object.values(companyPolicies).reduce<string[]>(
    (prev, { collectionId }) => (collectionId ? [...prev, collectionId] : prev),
    [],
  );

  const numberOfPolicies = Object.values(companyPolicies).reduce((prev, { policies }) => {
    if (!policies) return prev;
    return prev + policies.length;
  }, 0);

  const companyStatuses = Object.entries(companyPolicies).map<CompanyStatus>(
    ([companyKey, { status }]) => ({
      company: companyKey,
      status,
    }),
  );

  const value = useMemo<CollectionItemsContextType>(
    () => ({
      externalIdToCollectionId,
      companyPolicies,
      numberOfPolicies,
      collectionIds,
      companyStatuses,
      addPolicies,
      addWealthData,
      companyWealthData,
    }),
    [
      externalIdToCollectionId,
      companyPolicies,
      numberOfPolicies,
      collectionIds,
      companyStatuses,
      addPolicies,
      addWealthData,
      companyWealthData,
    ],
  );

  return (
    <CollectionItemsContext.Provider value={value}>{children}</CollectionItemsContext.Provider>
  );
}

import { useEffect } from 'react';

import { useSharedData } from '../contexts';
import { useNavigate } from '../services';

import { DevToolsEventName, setDevToolsLocalItem, SharedDevToolsEvent } from '../utils';

export function useDevTools() {
  const [
    {
      userConfig: { parentOrigin },
    },
    { setDevtoolsContinuousUpdateData },
  ] = useSharedData();
  const navigate = useNavigate();
  useEffect(() => {
    function devToolsEventListener(event: MessageEvent) {
      if (event.origin !== parentOrigin) return;
      const { name, value } = event.data as SharedDevToolsEvent;
      if (!DevToolsEventName[name]) return;
      switch (name) {
        case DevToolsEventName.UPDATE_REQUESTS: {
          setDevToolsLocalItem({ key: 'requests', value: value ?? [] });
          break;
        }
        case DevToolsEventName.SET_CONTINUOUS_UPDATE_DATA: {
          setDevToolsLocalItem({ key: 'devtoolsContinuousUpdate', value });
          setDevtoolsContinuousUpdateData(value);
          break;
        }
        case DevToolsEventName.SET_REQUIRES_RELOAD_DATA: {
          setDevToolsLocalItem({ key: 'devtoolsRequiresReloadUpdate', value: value ?? false });
          break;
        }
        case DevToolsEventName.NAVIGATE: {
          navigate(value);
          break;
        }
        default:
          throw new Error(`Unhandled dev-tools event: ${name}`);
      }
    }

    window.addEventListener('message', devToolsEventListener);

    return () => window.removeEventListener('message', devToolsEventListener);
  }, [navigate, parentOrigin, setDevtoolsContinuousUpdateData]);
}

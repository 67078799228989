import { CollectionStatus } from '@main/types';

import {
  FailedCollectionStatus,
  userInputFailedStatuses,
  verificationFailedStatuses,
} from './types';

export function mapFailedStatus(status?: CollectionStatus) {
  if (!status) return FailedCollectionStatus.COLLECTION_FAILED_GENERAL_ERROR;
  if (status === CollectionStatus['FAILED PDF PARSE'])
    return FailedCollectionStatus.COLLECTION_FAILED_PDF_PARSE_FAILED;
  if (status === CollectionStatus['FAILED PDF USER INPUT'])
    return FailedCollectionStatus.COLLECTION_FAILED_PDF_USER_INPUT;
  if (status === CollectionStatus['INCORRECT CREDENTIALS'])
    return FailedCollectionStatus.COLLECTION_FAILED_INCORRECT_CREDENTIALS;
  if (verificationFailedStatuses.includes(status))
    return FailedCollectionStatus.COLLECTION_FAILED_NO_VERIFICATION;
  if (userInputFailedStatuses.includes(status))
    return FailedCollectionStatus.COLLECTION_FAILED_USER_INPUT_REQUIRED;
  return FailedCollectionStatus.COLLECTION_FAILED_GENERAL_ERROR;
}

import { DescriptionList } from '@insurely/ui';

import { Country, Language, Market } from '@main/types';

import { isHomeInsuranceFR, isPension, isCarInsuranceFR, isFrenchInsurance } from '@main/utils';
import { formatInsurancePrice, getInsuranceYearlyPrice } from '@main/utils/getInsurancePrice';
import { formatLocalDate } from '@main/utils/locale';

import { getEndDate } from '@main/utils/parsers/policyParsers';

import { checkInvalidValueAndReturnString } from './helpers';

import styles from './information.module.css';

export interface InformationCopy {
  unknown: string;
  notAvailable: string;
  monthlyCost: string;
  annualCost: string;
  validUntil: string;
  insuranceName: string;
  insuranceHolder: string;
  homeOwnership?: string;
  homeOwnershipDetails?: string;
  residentialStatus?: string;
  residentialStatusDetails?: string;
  insuranceCompany: string;
  policyNumber: string;
  paymentType: string;
  noAddons: string;
  addons: string;
  paymentMethod: string;
}

interface InformationViewProps {
  companyName: string;
  policy?: Policy;
  language: Language;
  market: Market;
  copy: InformationCopy;
}

export const Information = ({
  companyName,
  policy,
  language,
  market,
  copy,
}: InformationViewProps) => {
  const endDate = policy?.insurance && getEndDate(policy?.insurance);
  const items: {
    insuranceInfo?: string | number | string[];
    section: string;
    className?: string;
  }[] = [
    {
      insuranceInfo: companyName,
      section: copy.insuranceCompany,
    },
    {
      insuranceInfo: policy?.insurance?.insuranceHolderName ?? copy.notAvailable,
      section: copy.insuranceHolder,
      className: 'ph-no-capture',
    },
    {
      insuranceInfo:
        policy && getInsuranceYearlyPrice(policy)
          ? formatInsurancePrice({ policy, language, market, perMonth: true })
          : copy.notAvailable,
      section: copy.monthlyCost,
    },
    {
      insuranceInfo:
        policy && getInsuranceYearlyPrice(policy)
          ? formatInsurancePrice({ policy, language, market })
          : copy.notAvailable,
      section: copy.annualCost,
    },
    {
      insuranceInfo: endDate
        ? (formatLocalDate(endDate, market.country) ?? copy.notAvailable)
        : copy.notAvailable,
      section: copy.validUntil,
    },
    ...(isHomeInsuranceFR(policy?.insurance) && policy?.insurance?.ownershipStatus
      ? [
          {
            insuranceInfo: copy?.homeOwnershipDetails ?? copy.notAvailable,
            section: copy?.homeOwnership ?? copy.notAvailable,
            className: 'ph-no-capture',
          },
        ]
      : []),
    ...(isHomeInsuranceFR(policy?.insurance) && policy?.insurance?.residentialStatus
      ? [
          {
            insuranceInfo: copy?.residentialStatusDetails ?? copy.notAvailable,
            section: copy?.residentialStatus ?? copy.notAvailable,
            className: 'ph-no-capture',
          },
        ]
      : []),
    {
      insuranceInfo:
        policy?.insurance?.insuranceName !== 'unknownInsurance'
          ? (policy?.insurance?.insuranceName ?? copy.notAvailable)
          : copy.notAvailable,
      section: copy.insuranceName,
      className: 'ph-no-capture',
    },
    {
      insuranceInfo: policy?.insurance?.insuranceNumber ?? copy.notAvailable,
      section: copy.policyNumber,
      className: 'ph-no-capture',
    },
    ...(market.country !== Country.fr
      ? [
          {
            insuranceInfo:
              policy && !isPension(policy)
                ? checkInvalidValueAndReturnString({
                    value: policy.insurance.paymentMethod,
                    unknownCopy: copy.notAvailable,
                    displayValue: copy.paymentMethod,
                  })
                : copy.notAvailable,
            section: copy.paymentType,
          },
        ]
      : []),
    ...(!isFrenchInsurance(policy?.insurance) ||
    isCarInsuranceFR(policy?.insurance) ||
    isHomeInsuranceFR(policy?.insurance)
      ? [
          {
            insuranceInfo:
              policy && !isPension(policy) && policy.addons?.length
                ? policy.addons.map((addon) => addon.addOnName).join(', ')
                : copy.noAddons,
            section: copy.addons,
          },
        ]
      : []),
  ];

  return (
    <div className={styles.detailsContainer}>
      {items.map(({ section, insuranceInfo, className }) => (
        <div key={section} className={styles.detailsList}>
          <DescriptionList
            rows={[
              {
                label: section,
                value: insuranceInfo
                  ? checkInvalidValueAndReturnString({
                      value: insuranceInfo?.toString(),
                      unknownCopy: copy.notAvailable,
                    })
                  : copy.notAvailable,
              },
            ]}
            valueClassName={className}
          />
        </div>
      ))}
    </div>
  );
};

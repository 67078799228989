import { Country } from '@main/types';
import {
  AnimalInsurance,
  HomeInsurance,
  Insurance,
  InsuranceSubType,
  InsuranceType,
  LifeInsurance,
  Pension,
} from '@main/types/insurance';
import { EstonianGeneralInsurance } from '@main/types/insurance/estonia';
import {
  EstonianCarInsurance,
  EstonianVehicleCoverage,
} from '@main/types/insurance/estonia/EstonianCarInsurance';
import {
  FrenchCarInsurance,
  FrenchGeneralInsurance,
  FrenchHomeInsurance,
  FrenchVillaInsurance,
} from '@main/types/insurance/france';
import { LatvianGeneralInsurance } from '@main/types/insurance/latvia';

import { SwedishCarInsurance } from '@main/types/insurance/sweden';

export function isPension(policy: Policy): policy is Pension {
  return policy.insurance === undefined;
}

export function isInsurancePolicy(policy: Policy): policy is InsurancePolicy {
  return policy.insurance !== undefined;
}

export function insurancePolicyOfType<T extends Insurance>(
  isInsuranceOfType: (insurance?: Insurance) => insurance is T,
) {
  return (policy: Policy): policy is InsurancePolicyOfType<T> =>
    isInsurancePolicy(policy as Policy) && isInsuranceOfType(policy.insurance);
}

export function isAnimalInsurance(insurance?: Insurance): insurance is AnimalInsurance {
  return insurance?.insuranceType === InsuranceType.animalInsurance;
}

export function isPetInsurance(insurance?: Insurance): insurance is AnimalInsurance {
  return (
    insurance?.insuranceSubType === InsuranceSubType.dogInsurance ||
    insurance?.insuranceSubType === InsuranceSubType.catInsurance
  );
}

export function isLifeInsurance(insurance?: Insurance): insurance is LifeInsurance {
  return insurance?.insuranceSubType === InsuranceSubType.lifeInsurance;
}

export function isCarInsuranceEE(insurance?: Insurance): insurance is EstonianCarInsurance {
  return (
    insurance?.insuranceSubType === InsuranceSubType.carInsurance &&
    (insurance['@market'] === Country.ee ||
      // TODO: remove workaround once scraper bug has been fixed:
      // https://linear.app/insurely/issue/EXPC-547/update-iscarinsuranceee-when-scraper-bug-for-market-is-fixed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      insurance['@market'] === 'core')
  );
}

export const isCarCoverageMtplEE = (
  coverage: EstonianVehicleCoverage,
): coverage is EstonianVehicleCoverage => coverage === EstonianVehicleCoverage.MTPL;

export function isCarInsuranceSE(insurance?: Insurance): insurance is SwedishCarInsurance {
  return (
    !!insurance &&
    insurance?.insuranceSubType === InsuranceSubType.carInsurance &&
    insurance['@market'] === Country.se
  );
}

export function isCondoInsuranceSE(insurance?: Insurance): insurance is HomeInsurance {
  return (
    insurance?.insuranceSubType === InsuranceSubType.condoInsurance &&
    insurance['@market'] === Country.se
  );
}

export function isCarInsuranceFR(insurance?: Insurance): insurance is FrenchCarInsurance {
  return (
    !!insurance &&
    insurance?.insuranceSubType === InsuranceSubType.carInsurance &&
    insurance['@market'] === Country.fr
  );
}

export function isCondoInsuranceFR(insurance?: Insurance): insurance is FrenchHomeInsurance {
  return (
    !!insurance?.insuranceSubType &&
    InsuranceSubType.condoInsurance === insurance.insuranceSubType &&
    insurance?.['@market'] === Country.fr
  );
}

export function isVillaInsuranceFR(insurance?: Insurance): insurance is FrenchVillaInsurance {
  return (
    !!insurance?.insuranceSubType &&
    InsuranceSubType.villaInsurance === insurance.insuranceSubType &&
    insurance?.['@market'] === Country.fr
  );
}

export function isHomeInsuranceFR(
  insurance?: Insurance,
): insurance is FrenchHomeInsurance | FrenchVillaInsurance {
  return isCondoInsuranceFR(insurance) || isVillaInsuranceFR(insurance);
}

export function isFrenchInsuranceModel(insurance?: Insurance): insurance is FrenchGeneralInsurance {
  return insurance?.['@market'] === Country.fr;
}

export function isEstonianInsuranceModel(
  insurance?: Insurance,
): insurance is EstonianGeneralInsurance {
  return (
    insurance?.['@market'] === Country.ee ||
    insurance?.insuranceCompany.split('-')?.[0] === Country.ee
  );
}

export function isLatvianInsuranceModel(
  insurance?: Insurance,
): insurance is LatvianGeneralInsurance {
  return (
    insurance?.['@market'] === Country.lv ||
    insurance?.insuranceCompany.split('-')?.[0] === Country.lv
  );
}

export function isFrenchInsurance(insurance?: Insurance): boolean {
  return (
    !!insurance &&
    !!insurance.insuranceCompany &&
    insurance.insuranceCompany.split('-')?.[0] === Country.fr
  );
}

export function isHomeInsuranceUK(insurance?: Insurance) {
  return (
    insurance?.insuranceType === InsuranceType.houseContentInsurance &&
    insurance.insuranceCompany.split('-')?.[0] === Country.uk
  );
}

import { ComponentType } from 'react';

import { IntlConfig, IntlProvider } from 'react-intl';

import { fetchLatestMessages } from '@main/i18n/fetchLatestMessages';
import { sentryCaptureException } from '@main/services';
import { Language } from '@main/types';
import { lazyWithPreloadAndRetry } from '@main/utils';

type Messages = NonNullable<IntlConfig['messages']>;

type MessageProviderProps = React.PropsWithChildren<Omit<IntlConfig, 'messages'>>;
export type MessageProvider = ComponentType<MessageProviderProps>;

export type MessageLoader = () => Promise<{ default: Messages }>;

export const extendMessages =
  (languageLoader: MessageLoader, extensionsLoader: MessageLoader) => async () => {
    const [language, extensions] = await Promise.all([languageLoader(), extensionsLoader()]);
    return {
      default: { ...language.default, ...extensions.default } as Messages,
    };
  };

const messageProviderHoc = async ({
  messageLoader,
  baseLanguage,
  extensionName,
}: {
  messageLoader: MessageLoader;
  baseLanguage: Language;
  extensionName?: string;
}) => {
  const name = extensionName ?? baseLanguage;

  if (DEBUG_LOGGING) {
    // eslint-disable-next-line no-console
    console.debug(`[i18n] loading messages ${name}`);
  }

  try {
    const { default: fallbackMessages } = await messageLoader();
    const latestMessages = await fetchLatestMessages({
      baseLanguage,
      extensionName,
    });

    const messages = {
      ...fallbackMessages,
      ...(latestMessages ?? {}),
    } as Messages;

    if (DEBUG_LOGGING) {
      // eslint-disable-next-line no-console
      console.debug(`[i18n] loaded messages ${name}`);
    }

    const messageProvider: MessageProvider = ({ children, ...props }) => (
      <IntlProvider messages={messages} {...props}>
        {children}
      </IntlProvider>
    );
    messageProvider.displayName = `MessageProvider [${name}]`;

    return { default: messageProvider };
  } catch (e) {
    if (DEBUG_LOGGING) {
      console.error(`[i18n] failed loading messages ${name}`, e);
    }
    sentryCaptureException(e);
    throw e;
  }
};

export type LazyMessageProvider = ReturnType<typeof messageProvider>;

export const messageProvider = ({
  messageLoader,
  baseLanguage,
  extensionName,
}: {
  messageLoader: MessageLoader;
  baseLanguage: Language;
  extensionName?: string;
}) =>
  lazyWithPreloadAndRetry(() => messageProviderHoc({ messageLoader, baseLanguage, extensionName }));

import { Spawner } from 'xstate';

import { singleCollectionMachine } from '../../singleCollectorMachine';
import {
  CompanyCollector,
  MultiCollectionMachineActorRef,
  MultiCollectionMachineContext,
  StartCollectionEvent,
} from '../types';

import { getPollingStatus } from './collectorStatuses';

export const startCompanyCollection = ({
  context,
  spawn,
  self,
  data,
}: {
  context: MultiCollectionMachineContext;
  spawn: Spawner<never>;
  self: MultiCollectionMachineActorRef;
  data: StartCollectionEvent;
}): CompanyCollector[] =>
  context.collectors.map((c) => {
    if (c.status === 'notStarted') {
      const baseInput = {
        apiClient: context.apiClient,
        insuranceApiClient: context.insuranceApiClient,
        wealthApiClient: context.wealthApiClient,
        productType: context.productType,
      };
      const input =
        context.resumeCode && c.companyDetails.loginMethod
          ? {
              ...baseInput,
              resumeCode: context.resumeCode,
              index: c.companyDetails.resumableCollectionIndex,
              loginMethod: c.companyDetails.loginMethod,
            }
          : {
              ...baseInput,
              payload: { ...data, insuranceCompany: c.companyDetails.insuranceCompany },
            };

      const actor = spawn(singleCollectionMachine, {
        input,
      });

      const updateStatusListener = actor.subscribe((snapshot) => {
        self.send({
          type: 'UPDATE_COLLECTOR_STATUS',
          data: {
            insuranceCompany: c.companyDetails.insuranceCompany,
            status: getPollingStatus(snapshot.value),
          },
        });
        if (snapshot.status === 'done') {
          updateStatusListener.unsubscribe();
        }
      });

      return {
        ...c,
        status: getPollingStatus(actor.getSnapshot().value),
        actor,
      };
    }
    return c;
  });

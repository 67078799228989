import classNames from 'classnames';

import React from 'react';

import { useSharedData } from '@main/contexts';
import { useDirect } from '@main/hooks/useDirect';

import styles from './baseView.module.css';

interface BaseViewProps extends Omit<React.HTMLProps<HTMLDivElement>, 'width' | 'title'> {
  children: React.ReactNode | boolean;
  header?: React.ReactNode;
  className?: string;
  expand?: boolean;
  maxWidth?: 'narrow' | 'wide';
  type?: 'padded' | 'fullWidth';
}

export const BaseView = ({
  className,
  children,
  header,
  expand,
  maxWidth = 'narrow',
  type = 'padded',
}: BaseViewProps) => {
  const [
    {
      clientConfig: { language },
    },
  ] = useSharedData();
  const { isDirect } = useDirect();

  return (
    <div lang={language} className={expand ? styles.expandContent : undefined}>
      {isDirect ? null : header}
      <main className={styles[type]}>
        <div className={classNames(className, styles.page, styles[maxWidth])}>{children}</div>
      </main>
    </div>
  );
};

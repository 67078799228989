import { v4 as uuid } from 'uuid';

import { getUseCase } from '@main/contexts/UseCaseContext/useCases';
import { defer, LoaderFunction, redirect, sentrySetContext } from '@main/services';
import { BlocksClientConfig, UseCaseName } from '@main/types';

import {
  isValidModuleInput,
  isValidRegularModuleInput,
  isValidResumeCodeModuleInput,
  isValidShowResumeInputModuleInput,
  ModuleInput,
  ValidModuleInput,
  ValidRegularModuleInput,
} from '@main/types/configurations/moduleInput';

import { ResumableCollectionResponse } from '@main/types/resumable-collections';
import { PostMessageName, SendInfoToParentWindow, StructuredError } from '@main/utils';

import { blockRoutes } from '../blocks/routes';

async function waitForModuleInput() {
  let isInitialized = false;
  return new Promise<ValidModuleInput>((resolve, reject) => {
    function initializeEventListener(event: MessageEvent) {
      if (isInitialized) {
        window.removeEventListener('message', initializeEventListener);
        return;
      }

      const { data } = event;
      if (data.name !== PostMessageName.INITIALIZE_APP) return;

      isInitialized = true;
      window.removeEventListener('message', initializeEventListener);

      const userModuleInput = data.value as ModuleInput;

      if (!isValidModuleInput(userModuleInput)) {
        reject(
          new StructuredError({
            title: 'Config error',
            message: 'customerId and configName is required',
            type: 'warning',
          }),
        );
        return;
      }

      sentrySetContext('User config input', {
        customerId: userModuleInput.config.customerId,
        configName: userModuleInput.config.configName,
        resumeCode: userModuleInput.config.resumeCode ? 'xxxxx' : undefined,
      });
      resolve(userModuleInput);
    }

    window.addEventListener('message', initializeEventListener);

    const initializationInterval = window.setInterval(() => {
      if (isInitialized) {
        clearInterval(initializationInterval);
        return;
      }
      SendInfoToParentWindow({
        name: PostMessageName.WAITING_FOR_INITIALIZATION,
        // How to handle targetOrigin before we have received parentOrigin
        targetOrigin: '*',
      });
    }, 100);
  });
}

function preloadBlocks(useCase?: UseCaseName) {
  const { modules } = getUseCase(useCase);

  Object.keys(modules).forEach((moduleName) => {
    blockRoutes[moduleName]?.Component?.preload?.().catch((e) =>
      console.error(`Error preloading module: ${e}`),
    );
  });
}

async function fetchResumableCollections({
  resumeCode,
  sessionId,
}: {
  resumeCode: string;
  sessionId: string;
}) {
  return fetch(`${API_URL}/wealth/collection/resumable-collections/${resumeCode}`, {
    method: 'GET',
    headers: {
      'request-id': uuid(),
      'insurely-session-id': sessionId,
      'insurely-version': '2024-06-01',
    },
  }).then(async (response) => {
    if (response.ok) {
      const resumambleCollections = (await response.json()) as ResumableCollectionResponse;

      return resumambleCollections;
    }
    // create error object and reject if not a 2xx response code
    if (response.status === 401)
      throw new StructuredError({
        title: 'Config error',
        message: `Resume code is invalid`,
        type: 'warning',
      });

    throw new Error(
      `/wealth/collection/resumable-collections/xxxxx responded with ${response.status}`,
    );
  });
}

type FetchBlocksConfigParams = {
  customerId: string;
  configName: string;
  sessionId: string;
};

async function fetchBlocksConfig({ configName, customerId, sessionId }: FetchBlocksConfigParams) {
  return fetch(`${API_URL}/blocks/config/${configName}`, {
    method: 'GET',
    headers: {
      'customer-id': customerId,
      'config-name': configName,
      'request-id': uuid(),
      'insurely-session-id': sessionId,
    },
  }).then(async (response) => {
    if (response.ok) {
      const config = (await response.json()) as BlocksClientConfig;
      preloadBlocks(config.config.useCase);

      const apiVersion = response.headers.get('insurely-version');

      return { apiVersion, config };
    }
    // create error object and reject if not a 2xx response code
    if (response.status === 401)
      throw new StructuredError({
        title: 'Config error',
        message: `It seems like ${configName} is not a valid configName for this customer`,
        type: 'warning',
      });

    throw new Error(`/blocks/config responded with ${response.status}`);
  });
}

export type AppLoader =
  | {
      moduleInput: ValidRegularModuleInput;
      sessionId: string;
      resumableCollections?: ResumableCollectionResponse['resumableCollections'];
      blocksClientConfig: BlocksClientConfig;
      apiVersion?: string;
    }
  | {
      moduleInput: ValidRegularModuleInput;
      sessionId: string;
      resumableCollections?: ResumableCollectionResponse['resumableCollections'];
      blocksClientConfig?: never;
      apiVersion?: never;
    };

export const appLoader: LoaderFunction = async ({ request }) => {
  let moduleInput = await waitForModuleInput();
  const sessionId = moduleInput.config.sessionId || uuid();

  let resumableCollectionResponse: ResumableCollectionResponse | undefined;

  const url = new URL(request.url);
  const resumeCode = url.searchParams.get('resumeCode');

  if (resumeCode) {
    moduleInput.config.resumeCode = resumeCode;
  }

  if (isValidResumeCodeModuleInput(moduleInput)) {
    resumableCollectionResponse = await fetchResumableCollections({
      resumeCode: moduleInput.config.resumeCode,
      sessionId,
    });

    moduleInput = {
      ...moduleInput,
      config: {
        ...moduleInput.config,
        customerId: resumableCollectionResponse.customerId,
        configName: resumableCollectionResponse.configName,
      },
      prefill: {
        ...moduleInput.prefill,
        dataAggregation: moduleInput.config.dataAggregation?.multiSelect
          ? {
              companies: resumableCollectionResponse.resumableCollections.map((c) => c.company),
            }
          : {
              company: resumableCollectionResponse.resumableCollections[0]?.company,
            },
      },
    };
  } else if (isValidShowResumeInputModuleInput(moduleInput)) {
    return redirect('/resume-code-input');
  }

  if (!isValidRegularModuleInput(moduleInput)) {
    throw new StructuredError({
      title: 'Config error',
      message: 'Could not initialise module',
      type: 'warning',
    });
  }

  const blocksClientConfigResponse = fetchBlocksConfig({
    customerId: moduleInput.config.customerId,
    configName: moduleInput.config.configName,
    sessionId,
  });

  return defer({
    moduleInput,
    sessionId,
    resumableCollections: resumableCollectionResponse?.resumableCollections ?? undefined,
    blocksClientConfig: (await blocksClientConfigResponse).config,
    apiVersion: (await blocksClientConfigResponse).apiVersion,
  });
};

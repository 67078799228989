import { Button, Icon, Loader, Typography } from '@insurely/ui';

import { useIntl } from 'react-intl';

import { BaseView } from '@main/components/BaseView';

import { useUserInput } from '@main/contexts/UserInputContext';
import {
  AccountState,
  formatNumber,
  useCompanies,
  useSharedData,
  useUseCase,
} from '@main/shared-exports';

import { useWealthData } from '../../hooks';

import { AssetsDetails, HiddenAccounts, UpdateDataSection } from './components';
import { AccountList } from './components/AccountList/AccountList';
import { formattedLatestUpdatedDate } from './utils';

import styles from './wealthOverview.module.css';

export const WealthOverview = () => {
  const intl = useIntl();
  const { nextBlock } = useUseCase();
  const { setSelectedCompanies } = useUserInput();
  const [
    {
      clientConfig: {
        language,
        market: { country },
      },
      userConfig: { dataAggregation },
    },
  ] = useSharedData();
  const { data: allCompanies } = useCompanies();

  // TODO: Replace userId with a valid id
  // Linear ticket: https://linear.app/insurely/issue/EXPD-154/implement-new-way-of-handling-useridjwt-in-frontend
  const { isInitialLoading, data } = useWealthData({ userId: '123456' });

  if (isInitialLoading || !data) {
    return <Loader.Content />;
  }

  const { latestUpdatedDate, assets, hasStaleAccountData, accounts } = data;

  const displayedAccounts = accounts.filter((account) => account.state === AccountState.DISPLAYED);
  const hiddenAccounts = accounts.filter((account) => account.state === AccountState.HIDDEN);

  const formatAssetsNumber = (amount: number) => formatNumber(amount, language, 2, 2);
  const sumOfTotalAssets = formatAssetsNumber(assets.total.amount);
  const sumOfSavings = formatAssetsNumber(assets.savings.amount);
  const sumOfPensions = formatAssetsNumber(assets.pension.amount);

  const displayedCompanies = displayedAccounts
    .map((account) =>
      allCompanies?.filter((company) => company.insuranceCompany === account.company),
    )
    .flat();

  const uniqueDisplayedCompanies = Array.from(
    displayedCompanies
      .reduce((accumulator, currentValue) => {
        if (currentValue) accumulator.set(currentValue.insuranceCompany, currentValue);
        return accumulator;
      }, new Map())
      .values(),
  );

  const updateCurrentAccountsData = () => {
    if (dataAggregation?.multiSelect) {
      setSelectedCompanies(uniqueDisplayedCompanies);
    }
    nextBlock();
  };

  const navigateToDataAggregation = () => {
    setSelectedCompanies([]);
    nextBlock();
  };

  return (
    <BaseView maxWidth="wide">
      <Typography component="h1" variant="Headline-5" className={styles.headline}>
        {intl.formatMessage({ id: 'overview.wealth.headline-1', defaultMessage: 'Overview' })}
      </Typography>
      <AssetsDetails
        sumOfTotalAssets={sumOfTotalAssets}
        sumOfSavings={sumOfSavings}
        sumOfPensions={sumOfPensions}
        latestUpdatedDate={formattedLatestUpdatedDate({
          latestUpdatedDate,
          intl,
          language,
          country,
        })}
      />
      {hasStaleAccountData && <UpdateDataSection onClick={updateCurrentAccountsData} />}
      <Typography component="h2" variant="Headline-6" className={styles.sectionHeadline}>
        {intl.formatMessage({
          id: 'overview.wealth.headline-2',
          defaultMessage: 'Accounts',
        })}
      </Typography>
      <AccountList accounts={displayedAccounts} />
      {hiddenAccounts.length !== 0 && <HiddenAccounts accounts={hiddenAccounts} />}
      <Button
        variant="ghost"
        fullWidth
        icon={<Icon className={styles.icon} name="plus" size={24} />}
        iconPosition="left"
        className={styles.addAccountButton}
        onClick={navigateToDataAggregation}
      >
        {intl.formatMessage({
          id: 'overview.wealth.button-text',
          defaultMessage: 'Add an account',
        })}
      </Button>
    </BaseView>
  );
};

import { Language, Market } from '@main/types';
import { formatCurrency } from '@main/utils/format';

export const getInsuranceYearlyPrice = (policy?: Policy) => {
  if (policy?.insurance?.premium?.amount.minorUnitAmount) {
    return Number.parseFloat((policy.insurance.premium.amount.minorUnitAmount / 100).toFixed(2));
  }

  return policy?.insurance?.premiumAmountYearRounded;
};

interface FormatInsurancePriceProps {
  policy?: Policy;
  market: Market;
  language: Language;
  /** Set to true if it should be per month, default is per year */
  perMonth?: boolean;
}

export const formatInsurancePrice = ({
  policy,
  language,
  market,
  perMonth,
}: FormatInsurancePriceProps) => {
  const monthDivision = perMonth ? 12 : 1;
  const insurancePrice = getInsuranceYearlyPrice(policy);
  if (insurancePrice) {
    return formatCurrency({
      amount: insurancePrice / monthDivision,
      language,
      market,
    });
  }

  return undefined;
};

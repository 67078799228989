import { build, perBuild } from '@jackfranklin/test-data-bot';

import {
  AccidentInsurance,
  AnimalGender,
  AnimalInsurance,
  CarInsurance,
  Country,
  HomeInsurance,
  InsuranceLevel,
  InsuranceSubType,
  InsuranceType,
  LifeInsurance,
} from '@main/types';
import { SwedishVehicleCoverage } from '@main/types/insurance/sweden';

import { swedishFaker } from './swedishFaker';

export const fakeNewSwedishHomeInsurance = build<HomeInsurance>({
  fields: {
    insuranceObjectStreetAddress: perBuild(() => swedishFaker.location.streetAddress()),
    insuranceObjectPostalCode: perBuild(() => swedishFaker.location.zipCode()),
    insuranceObjectCity: perBuild(() => swedishFaker.location.city()),
    securityDoorGridDiscount: true,
    connectedAlarmDiscount: false,
    livingArea: 100,
    numberOfResidents: 1,
    insuredMovablesAmount: 1000000,
    externalId: perBuild(() => swedishFaker.string.uuid()),
    insuranceName: 'Hemförsäkring',
    insuranceCompany: 'se-demo',
    insuranceType: InsuranceType.houseContentInsurance,
    insuranceSubType: InsuranceSubType.condoInsurance,
    insuranceNumber: perBuild(() => swedishFaker.string.uuid()),
    premiumAmountYearRounded: 2700,
    discountAmount: 0,
    discountPercentage: '0%',
    renewalDate: '2022-06-09',
    startDate: '2022-03-09',
    coming: false,
    premiumFrequency: 12,
    paymentMethod: 'AUTOGIRO',
    employerPaid: false,
    otherInsuranceHolder: false,
    insuranceHolderName: perBuild(() => swedishFaker.person.fullName()),
    insuranceHolderStreetAddress: perBuild(() => swedishFaker.location.streetAddress()),
    insuranceHolderPostalCode: perBuild(() => swedishFaker.location.zipCode()),
    insuranceHolderCity: perBuild(() => swedishFaker.location.city()),
    '@market': Country.se,
  },
});

export const fakeNewSwedishCarInsurance = build<CarInsurance>({
  fields: {
    maxMileage: 20000,
    coverage: SwedishVehicleCoverage.FULL_WITH_CHASSI,
    insuranceLevel: swedishFaker.helpers.enumValue(InsuranceLevel),
    registrationNo: perBuild(() =>
      `${swedishFaker.string.alpha(3)}${swedishFaker.string.numeric(
        2,
      )}${swedishFaker.string.alphanumeric(1)}`.toUpperCase(),
    ),
    brand: perBuild(() => swedishFaker.vehicle.manufacturer()),
    model: perBuild(() => swedishFaker.vehicle.model()),
    externalId: perBuild(() => swedishFaker.string.uuid()),
    insuranceName: 'Helförsäkring',
    insuranceCompany: 'se-demo',
    insuranceType: InsuranceType.vehicleInsurance,
    insuranceSubType: InsuranceSubType.carInsurance,
    insuranceNumber: perBuild(() => swedishFaker.string.uuid()),
    premiumAmountYearRounded: 14184,
    discountAmount: 0,
    discountPercentage: '0%',
    renewalDate: '2022-11-26',
    startDate: '2022-08-26',
    coming: false,
    premiumFrequency: 12,
    paymentMethod: 'ELECTRONIC_INVOICE',
    employerPaid: false,
    otherInsuranceHolder: false,
    insuranceHolderName: perBuild(() => swedishFaker.person.fullName()),
    insuranceHolderStreetAddress: perBuild(() => swedishFaker.location.streetAddress()),
    insuranceHolderPostalCode: perBuild(() => swedishFaker.location.zipCode()),
    insuranceHolderCity: perBuild(() => swedishFaker.location.city()),
    '@market': Country.se,
  },
});

export const fakeNewSwedishLifeInsurance = build<LifeInsurance>({
  fields: {
    insuredAmountLife: 2_000_000,
    insuredPersonName: 'Förnamn Efternamn',
    externalId: perBuild(() => swedishFaker.string.uuid()),
    insuranceName: 'Livförsäkring',
    insuranceCompany: 'se-demo',
    insuranceType: InsuranceType.personInsurance,
    insuranceSubType: InsuranceSubType.lifeInsurance,
    insuranceNumber: perBuild(() => swedishFaker.string.uuid()),
    premiumAmountYearRounded: 947,
    renewalDate: '2022-11-26',
    startDate: '2022-08-26',
    coming: false,
    premiumFrequency: 4,
    paymentMethod: 'GENERIC_INVOICE',
    employerPaid: false,
    otherInsuranceHolder: false,
    insuranceHolderName: perBuild(() => swedishFaker.person.fullName()),
    insuranceHolderStreetAddress: perBuild(() => swedishFaker.location.streetAddress()),
    insuranceHolderPostalCode: perBuild(() => swedishFaker.location.zipCode()),
    insuranceHolderCity: perBuild(() => swedishFaker.location.city()),
    '@market': Country.se,
  },
  postBuild: (insurance) => ({
    ...insurance,
    insuredPersonName: insurance.insuranceHolderName,
  }),
});

export const fakeNewSwedishAnimalInsurance = build<AnimalInsurance>({
  fields: {
    animalName: perBuild(() => swedishFaker.person.firstName()),
    animalBreed: perBuild(() => swedishFaker.animal.dog()),
    animalGender: swedishFaker.helpers.enumValue(AnimalGender),
    chipNumber: '1234567899',
    dateOfBirth: '2018-03-01',
    veterinaryCareAmount: 60000,
    animalPurchasePrice: 12000,
    externalId: '5b19f193-5016-47c2-af7d-8cf460ad2af4',
    insuranceName: 'Hundförsäkring',
    insuranceCompany: 'se-demo',
    insuranceType: InsuranceType.animalInsurance,
    insuranceSubType: InsuranceSubType.dogInsurance,
    insuranceNumber: perBuild(() => swedishFaker.string.uuid()),
    premiumAmountYearRounded: 6936,
    discountAmount: 0,
    discountPercentage: '0',
    renewalDate: '2022-10-24',
    startDate: '2022-09-24',
    coming: false,
    premiumFrequency: 12,
    paymentMethod: 'PAPER_INVOICE',
    employerPaid: false,
    otherInsuranceHolder: false,
    insuranceHolderName: perBuild(() => swedishFaker.person.fullName()),
    insuranceHolderStreetAddress: perBuild(() => swedishFaker.location.streetAddress()),
    insuranceHolderPostalCode: perBuild(() => swedishFaker.location.zipCode()),
    insuranceHolderCity: perBuild(() => swedishFaker.location.city()),
    '@market': Country.se,
  },
});

export const fakeNewSwedishAccidentInsurance = build<AccidentInsurance>({
  fields: {
    externalId: perBuild(() => swedishFaker.string.uuid()),
    insuranceName: 'Olycksfallsförsäkring',
    insuranceCompany: 'se-demo',
    insuranceType: InsuranceType.personInsurance,
    insuranceSubType: InsuranceSubType.accidentInsurance,
    insuranceNumber: perBuild(() => swedishFaker.string.uuid()),
    insuredPersonName: perBuild(() => swedishFaker.person.fullName()),
    premiumAmountYearRounded: 6936,
    discountAmount: 0,
    discountPercentage: '0',
    renewalDate: '2022-10-24',
    startDate: '2022-09-24',
    coming: false,
    premiumFrequency: 12,
    paymentMethod: 'PAPER_INVOICE',
    employerPaid: false,
    otherInsuranceHolder: false,
    insuranceHolderName: perBuild(() => swedishFaker.person.fullName()),
    insuranceHolderStreetAddress: perBuild(() => swedishFaker.location.streetAddress()),
    insuranceHolderPostalCode: perBuild(() => swedishFaker.location.zipCode()),
    insuranceHolderCity: perBuild(() => swedishFaker.location.city()),
    '@market': Country.se,
  },
});

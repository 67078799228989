import { BlocksModule } from '@main/types/blocksModule';

export function getBlocksModule(blocksModule: string): BlocksModule {
  switch (true) {
    case /data-aggregation/.test(blocksModule):
      return BlocksModule.DataAggregation;
    case /initial-navigation/.test(blocksModule):
      return BlocksModule.InitialNavigation;
    case /end-state/.test(blocksModule):
      return BlocksModule.EndState;
    case /pension-move/.test(blocksModule):
      return BlocksModule.PensionMove;
    default:
      return blocksModule as BlocksModule;
  }
}

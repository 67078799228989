import { useCallback } from 'react';

import { useSharedData } from '@main/contexts';
import { PostMessageParams, safeSendInfoToParentWindow } from '@main/utils';

export function usePostMessage() {
  const [
    {
      clientConfig: { allowedOrigin },
      userConfig: { parentOrigin },
    },
  ] = useSharedData();

  const postMessage = useCallback(
    (params: Omit<PostMessageParams, 'targetOrigin'>) => {
      safeSendInfoToParentWindow({
        allowedOrigins: allowedOrigin,
        targetOrigin: parentOrigin,
        ...params,
      });
    },
    [allowedOrigin, parentOrigin],
  );

  return postMessage;
}

export type PostMessageFunction = ReturnType<typeof usePostMessage>;

import { Button, Typography } from '@insurely/ui';
import { useIntl } from 'react-intl';

import { PlateContainer } from '../PlateContainer';

import styles from './updateDataSection.module.css';

export const UpdateDataSection = ({ onClick }: { onClick: () => void }) => {
  const { formatMessage } = useIntl();

  return (
    <PlateContainer variant="filled">
      <Typography component="h2" variant="Label-2">
        {formatMessage({
          id: 'overview.wealth.update-data-section.headline',
          defaultMessage: 'Welcome back!',
        })}
      </Typography>
      <Typography component="p" variant="ParagraphCaption" className={styles.paragraph}>
        {formatMessage({
          id: 'overview.wealth.update-data-section.paragraph',
          defaultMessage: 'Update your data to see your progress and correct values.',
        })}
      </Typography>
      <Button size="large" variant="ghost" onClick={onClick} className={styles.button}>
        {formatMessage({
          id: 'overview.wealth.update-data-section.button-text',
          defaultMessage: 'Update',
        })}
      </Button>
    </PlateContainer>
  );
};

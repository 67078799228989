import { faker } from '@faker-js/faker';
import { build, perBuild } from '@jackfranklin/test-data-bot';

import {
  AnimalGender,
  AnimalInsurance,
  Country,
  Currency,
  InsuranceSubType,
  InsuranceType,
} from '@main/types';

import {
  PremiumFrequencyLiteral,
  TaxationType,
} from '@main/types/insurance/core/CoreGeneralInsurance';

import { getBirthDate, getRenewalDate, getStartDate } from './utils';

export const fakeNewAnimalInsurance = build<AnimalInsurance>({
  fields: {
    externalId: perBuild(() => faker.string.uuid()),
    insuranceName: 'Tous Risques Confort',
    insuranceCompany: 'fr-demo',
    insuranceType: InsuranceType.animalInsurance,
    insuranceSubType: InsuranceSubType.dogInsurance,
    insuranceNumber: perBuild(() => faker.string.uuid()),
    premiumAmountYearRounded: perBuild(() => faker.number.int({ min: 200, max: 500 })),
    premium: {
      amount: {
        minorUnitAmount: perBuild(() => faker.number.float({ min: 25000, max: 50000 })),
        currencyCode: Currency.EUR,
      },
      frequency: PremiumFrequencyLiteral.YEARLY_PREMIUM_FREQUENCY,
      taxation: TaxationType.INCLUSIVE_TAXATION,
    },
    discountAmount: 0,
    discountPercentage: '0',
    renewalDate: perBuild(getRenewalDate),
    startDate: perBuild(() => getStartDate(getRenewalDate())),
    coming: false,
    premiumFrequency: 12,
    paymentMethod: 'GENERIC_INVOICE',
    employerPaid: false,
    otherInsuranceHolder: false,
    insuranceHolderName: 'Cornélie Boivin',
    policyHolder: {
      name: {
        fullName: 'Cornélie Boivin',
        firstName: 'Cornélie',
        lastName: 'Boivin',
      },
    },
    insuranceHolderStreetAddress: "34 Rue d'Enghien",
    insuranceHolderPostalCode: '80495',
    insuranceHolderCity: 'Paris',
    insuranceHolderEmail: 'cornelie.boivin@insurely.com',
    insuranceHolderPhoneNumber: '+33 93 233 8246',

    animalGender: faker.helpers.enumValue(AnimalGender),
    animalBreed: 'Bug',
    animalName: 'Zarek',
    animalPurchasePrice: 2000,
    dateOfBirth: perBuild(getBirthDate),
    '@market': Country.se,
  },
});

import { useEffect } from 'react';

import { DATA_AGGREGATION_COLLECT_DATA, pathMap } from '../constants';

import { postHogCapture, useLocation } from '../services';
import { PostMessageName } from '../utils/postMessage';

import { usePostMessage } from './usePostMessage';

const getRouteName = (path: string) => {
  const cleanPath = path.replace(
    /\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/,
    '',
  );

  return pathMap[cleanPath as keyof typeof pathMap];
};

export default function useHistoryListener() {
  const location = useLocation();
  const { pathname } = location;

  const postMessage = usePostMessage();

  if (IS_DEVMODE) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // eslint-disable-next-line no-console
      console.debug(`[App] location ${location.pathname}`, location);
    }, [location]);
  }

  useEffect(() => {
    // useCollectData.tsx handles $pageview tracking when collecting data
    if (!pathname.includes(DATA_AGGREGATION_COLLECT_DATA)) {
      postHogCapture('$pageview', {
        // We overwrite the current_url for this tracking since we've seen that window.location.pathname can differ from location.pathname from useLocation
        $current_url: `${window.location.origin}${pathname}`,
      });
    }
    const routeName = getRouteName(pathname);
    if (routeName) {
      postMessage({ name: PostMessageName.PAGE_VIEW, value: routeName });
    }
  }, [pathname, postMessage]);
}

import { Typography } from '@insurely/ui';
import { useIntl } from 'react-intl';

import { useSharedData } from '@main/contexts';
import { Account } from '@main/types';

import { formatNumber } from '@main/utils';

import { PlateContainer } from '../../../WealthOverview/components/PlateContainer';
import {
  formattedInitialValue,
  formattedPerformanceSinceStart,
  formattedReturnValue,
} from '../../utils';
import { DescriptionListItem } from '../DescriptionListItem';

import { Table } from '../Table';

import styles from './holdingInfo.module.css';

interface HoldingInfoProp {
  account: Account;
}

export const HoldingInfo = ({ account }: HoldingInfoProp) => {
  const { holdings } = account;
  const { formatMessage } = useIntl();
  const [
    {
      clientConfig: { language },
    },
  ] = useSharedData();

  const columns = [
    {
      id: 'value' as const,
      title: formatMessage({
        id: 'overview.wealth-account.holding-info.table-header-1',
        defaultMessage: 'Value',
      }),
    },
    {
      id: 'acquisition' as const,
      title: formatMessage({
        id: 'overview.wealth-account.holding-info.table-header-2',
        defaultMessage: 'Acquisition',
      }),
    },
    {
      id: 'performance' as const,
      title: formatMessage({
        id: 'overview.wealth-account.holding-info.table-header-3',
        defaultMessage: 'Performance',
      }),
    },
    {
      id: 'return' as const,
      title: formatMessage({
        id: 'overview.wealth-account.holding-info.table-header-4',
        defaultMessage: 'Return',
      }),
    },
  ];

  return (
    <PlateContainer fullWidth className={styles.plate}>
      {holdings.map((item) => {
        const historicalDataExist = item.acquisitionValue?.amount;

        const formattedCurrentValue = formatNumber(item.value.amount, language);

        const financialInformationValues = {
          currentValue: item.value.amount,
          initialValue: item.acquisitionValue?.amount,
          language,
        };

        const rows = [
          {
            values: {
              value: formattedCurrentValue,
              acquisition: formattedInitialValue(financialInformationValues),
              performance: formattedPerformanceSinceStart(financialInformationValues),
              return: formattedReturnValue(financialInformationValues),
            },
            id: 'value-development',
          },
        ];

        if (historicalDataExist) {
          return (
            <div key={item.isin} className={styles.tableContainer}>
              <Typography component="h3" variant="Label-2">
                {item.name}
              </Typography>
              <Table columns={columns} rows={rows} className={styles.table} />
            </div>
          );
        }
        return (
          <DescriptionListItem
            key={item.isin}
            term={item.name}
            description={formattedCurrentValue}
            termTextVariant="Label-2"
            descriptionTextVariant="ParagraphCaption"
            className={styles.listItem}
          />
        );
      })}
    </PlateContainer>
  );
};

import { COMPARE_NOT_POSSIBLE } from '@main/constants';
import { PossibleToCompare, FailedToCompareReason, UseCaseName } from '@main/types';
import { isCarInsuranceSE, isCondoInsuranceSE } from '@main/utils/typeAssertions';

import { isPossibleToCompareCarIcaSE } from './carCompareAssertions';
import { isPossibleToCompareCondoIcaSE } from './condoCompareAssertions';

type IsPossibleToCompare = ({
  policy,
  useCaseName,
}: {
  policy: InsurancePolicy;
  useCaseName: UseCaseName;
}) => PossibleToCompare;

export const isPossibleToCompareSE: IsPossibleToCompare = ({ policy }) => {
  const { insurance, personalInformation } = policy;
  if (isCarInsuranceSE(insurance)) {
    return isPossibleToCompareCarIcaSE({
      insurance,
      personalInformation,
    });
  }

  if (isCondoInsuranceSE(insurance)) {
    return isPossibleToCompareCondoIcaSE({
      insurance,
      personalInformation,
    });
  }

  return {
    isComparisonPossible: false,
    failedReason: FailedToCompareReason.SubTypeNotSupported,
    failedNavigatePath: COMPARE_NOT_POSSIBLE,
  };
};

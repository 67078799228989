import { BlocksModule, UseCaseName } from '@main/types';

const DATA_AGGREGATION: UseCase = {
  name: 'data-aggregation',
  initialModule: BlocksModule.DataAggregation,
  modules: {
    [BlocksModule.DataAggregation]: {
      next: BlocksModule.Results,
      skip: BlocksModule.Results,
    },
    [BlocksModule.Results]: {},
  },
};

const DATA_AGGREGATION_WITHOUT_RESULTS: UseCase = {
  name: 'data-aggregation-without-results',
  initialModule: BlocksModule.DataAggregation,
  modules: {
    [BlocksModule.DataAggregation]: {},
  },
};

const COLLECT_AND_CONTACT: UseCase = {
  name: 'collect-and-contact',
  initialModule: BlocksModule.DataAggregation,
  modules: {
    [BlocksModule.DataAggregation]: { next: BlocksModule.Contact, skip: BlocksModule.Contact },
    [BlocksModule.Contact]: { next: BlocksModule.Results },
    [BlocksModule.Results]: {},
  },
};

const CHECKOUT: UseCase = {
  name: 'checkout',
  initialModule: BlocksModule.Checkout,
  modules: { [BlocksModule.Checkout]: {} },
};

const COMPARE_AND_SWITCH: UseCase = {
  name: 'compare-and-switch',
  initialModule: BlocksModule.InitialNavigation,
  modules: {
    [BlocksModule.InitialNavigation]: {
      next: BlocksModule.DataAggregation,
      skip: BlocksModule.Checkout,
    },
    [BlocksModule.DataAggregation]: {
      next: BlocksModule.Results,
      skip: BlocksModule.Checkout,
    },
    [BlocksModule.Results]: {
      next: BlocksModule.Compare,
      skip: BlocksModule.Checkout,
    },
    [BlocksModule.Compare]: {
      next: BlocksModule.Checkout,
    },
    [BlocksModule.Checkout]: {},
  },
};

const PREFILL_AND_COMPARE: UseCase = {
  name: 'prefill-and-compare',
  initialModule: BlocksModule.InitialNavigation,
  modules: {
    [BlocksModule.InitialNavigation]: {
      next: BlocksModule.DataAggregation,
      skip: BlocksModule.Checkout,
    },
    [BlocksModule.EndState]: {
      next: BlocksModule.DataAggregation,
    },
    [BlocksModule.DataAggregation]: {
      next: BlocksModule.Results,
      skip: BlocksModule.Checkout,
    },
    [BlocksModule.Results]: {
      next: BlocksModule.Checkout,
      skip: BlocksModule.Checkout,
    },
    [BlocksModule.Checkout]: {
      next: BlocksModule.Compare,
    },
    [BlocksModule.Compare]: {
      skip: BlocksModule.Compare,
    },
  },
};

const OVERVIEW: UseCase = {
  name: 'overview',
  initialModule: BlocksModule.DataAggregation,
  modules: {
    [BlocksModule.DataAggregation]: { next: BlocksModule.Overview, skip: BlocksModule.Overview },
    [BlocksModule.Overview]: { next: BlocksModule.DataAggregation },
  },
};

const OVERVIEW_AND_COMPARE: UseCase = {
  name: 'overview-and-compare',
  initialModule: BlocksModule.Overview,
  modules: {
    [BlocksModule.Overview]: { next: BlocksModule.DataAggregation, skip: BlocksModule.Compare },
    [BlocksModule.DataAggregation]: { next: BlocksModule.Overview, skip: BlocksModule.Compare },
    [BlocksModule.Compare]: {},
  },
};

const WEALTH_OVERVIEW: UseCase = {
  name: 'wealth-overview',
  initialModule: BlocksModule.Overview,
  modules: {
    [BlocksModule.Overview]: {
      next: BlocksModule.DataAggregation,
      skip: BlocksModule.DataAggregation,
    },
    [BlocksModule.DataAggregation]: { next: BlocksModule.Overview },
  },
};

const PREFILL_OVERVIEW_AND_COMPARE: UseCase = {
  name: 'prefill-overview-and-compare',
  initialModule: BlocksModule.DataAggregation,
  modules: {
    [BlocksModule.DataAggregation]: { next: BlocksModule.Overview, skip: BlocksModule.Overview },
    [BlocksModule.Overview]: {
      next: BlocksModule.DataAggregation,
      skip: BlocksModule.Questionnaire,
    },
    [BlocksModule.Questionnaire]: { next: BlocksModule.Compare, skip: BlocksModule.Compare },
    [BlocksModule.Compare]: { skip: BlocksModule.Compare },
  },
};

const COLLECT_AND_QUESTION: UseCase = {
  name: 'collect-and-question',
  initialModule: BlocksModule.DataAggregation,
  modules: {
    [BlocksModule.DataAggregation]: {
      next: BlocksModule.Questionnaire,
      skip: BlocksModule.Questionnaire,
    },
    [BlocksModule.Questionnaire]: { next: BlocksModule.Results },
    [BlocksModule.Results]: { next: BlocksModule.Contact },
    [BlocksModule.Contact]: { next: BlocksModule.EndState },
    [BlocksModule.EndState]: {},
  },
};

const COLLECT_QUESTION_AND_COMPARE: UseCase = {
  name: 'collect-question-and-compare',
  initialModule: BlocksModule.InitialNavigation,
  modules: {
    [BlocksModule.InitialNavigation]: {
      next: BlocksModule.DataAggregation,
      skip: BlocksModule.Questionnaire,
    },
    [BlocksModule.EndState]: {
      next: BlocksModule.DataAggregation,
    },
    [BlocksModule.DataAggregation]: {
      next: BlocksModule.Results,
      skip: BlocksModule.Questionnaire,
    },
    [BlocksModule.Results]: { next: BlocksModule.Questionnaire },
    [BlocksModule.Questionnaire]: { next: BlocksModule.Compare },
    [BlocksModule.Compare]: {},
  },
};

const PENSION_MOVE: UseCase = {
  name: 'pension-move',
  initialModule: BlocksModule.DataAggregation,
  modules: {
    [BlocksModule.DataAggregation]: { next: BlocksModule.Contact, skip: BlocksModule.Contact },
    [BlocksModule.Contact]: { next: BlocksModule.PensionMove },
    [BlocksModule.PensionMove]: {},
  },
};

export const useCaseMapping: Record<UseCaseName, UseCase> = {
  'data-aggregation': DATA_AGGREGATION,
  'data-aggregation-without-results': DATA_AGGREGATION_WITHOUT_RESULTS,
  checkout: CHECKOUT,
  'collect-and-contact': COLLECT_AND_CONTACT,
  'compare-and-switch': COMPARE_AND_SWITCH,
  'prefill-and-compare': PREFILL_AND_COMPARE,
  overview: OVERVIEW,
  'overview-and-compare': OVERVIEW_AND_COMPARE,
  'wealth-overview': WEALTH_OVERVIEW,
  'collect-and-question': COLLECT_AND_QUESTION,
  'collect-question-and-compare': COLLECT_QUESTION_AND_COMPARE,
  'pension-move': PENSION_MOVE,
  'prefill-overview-and-compare': PREFILL_OVERVIEW_AND_COMPARE,
};

const useCases = Object.keys(useCaseMapping);

// default to data-aggregation use-case

export const getUseCase = (useCaseName?: UseCaseName, hideResultsView?: boolean) => {
  if ((!useCaseName || useCaseName === UseCaseName['data-aggregation']) && hideResultsView) {
    return useCaseMapping['data-aggregation-without-results'];
  }

  return useCaseName && useCases.includes(useCaseName)
    ? useCaseMapping[useCaseName]
    : useCaseMapping['data-aggregation'];
};

export const isCompareModuleAvailable = (useCase?: UseCaseName) =>
  useCase && Object.keys(useCaseMapping[useCase].modules).includes(BlocksModule.Compare);

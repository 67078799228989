/* eslint-disable max-lines */
import { LoginMethod } from '@main/types';

export const estonianInsuranceCompanies: Company[] = [
  {
    insuranceCompany: 'ee-bta',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'BTA',
    loginMethods: [LoginMethod.ESTONIAN_MOBILE_ID, LoginMethod.ESTONIAN_SMART_ID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'ee-demo',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Demo Company',
    loginMethods: [LoginMethod.ESTONIAN_MOBILE_ID, LoginMethod.ESTONIAN_SMART_ID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'ee-ergo',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Ergo',
    loginMethods: [LoginMethod.ESTONIAN_MOBILE_ID, LoginMethod.ESTONIAN_SMART_ID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'ee-gjensidige',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Gjensidige',
    loginMethods: [LoginMethod.ESTONIAN_MOBILE_ID, LoginMethod.ESTONIAN_SMART_ID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'ee-if',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'If',
    loginMethods: [LoginMethod.ESTONIAN_MOBILE_ID, LoginMethod.ESTONIAN_SMART_ID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'ee-lhv',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'LHV',
    loginMethods: [LoginMethod.ESTONIAN_SMART_ID_USERNAME, LoginMethod.ESTONIAN_MOBILE_ID_USERNAME],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'ee-pzu',
    functional: false,
    status: 'OK',
    reason: 'THIRD_PARTY_ERROR',
    insuranceCompanyDisplayName: 'PZU',
    loginMethods: [LoginMethod.ESTONIAN_MOBILE_ID, LoginMethod.ESTONIAN_SMART_ID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'ee-salva',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Salva',
    loginMethods: [LoginMethod.ESTONIAN_MOBILE_ID, LoginMethod.ESTONIAN_SMART_ID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'ee-seesam',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Seesam',
    loginMethods: [LoginMethod.ESTONIAN_MOBILE_ID, LoginMethod.ESTONIAN_SMART_ID],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
  {
    insuranceCompany: 'ee-swedbank',
    functional: true,
    status: 'OK',
    insuranceCompanyDisplayName: 'Swedbank',
    loginMethods: [LoginMethod.ESTONIAN_SMART_ID_USERNAME, LoginMethod.ESTONIAN_MOBILE_ID_USERNAME],
    nonFunctionalLoginMethods: [],
    additionalLoginInputs: [],
    companyAndLineOfBusinesses: [],
  },
];

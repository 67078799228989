import { ComponentProps, ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from '../ErrorFallback/ErrorFallback';

export function ErrorBoundary({
  children,
  ...rest
}: Omit<ComponentProps<typeof ErrorFallback>, 'error' | 'resetError'> & { children: ReactNode }) {
  return (
    <ReactErrorBoundary
      fallbackRender={({ resetErrorBoundary, error: e }) => (
        <ErrorFallback error={e} resetError={resetErrorBoundary} {...rest} />
      )}
    >
      {children}
    </ReactErrorBoundary>
  );
}

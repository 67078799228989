import { COMPARE_NOT_POSSIBLE } from '@main/constants';
import { FailedToCompareReason, PossibleToCompare } from '@main/types';
import { isHomeInsuranceUK } from '@main/utils/typeAssertions';

export const isPossibleToCompareUK = ({
  policy,
}: {
  policy: InsurancePolicy;
}): PossibleToCompare => {
  const { insurance } = policy;

  if (isHomeInsuranceUK(insurance)) return { isComparisonPossible: true };
  return {
    isComparisonPossible: false,
    failedReason: FailedToCompareReason.SubTypeNotSupported,
    failedNavigatePath: COMPARE_NOT_POSSIBLE,
  };
};

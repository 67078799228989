export const INITIAL_NAVIGATION_ROUTE = 'initial-navigation';
export const INITIAL_NAVIGATION_PATH = `/${INITIAL_NAVIGATION_ROUTE}`;
export const DATA_AGGREGATION_ROUTE = 'data-aggregation';
export const DATA_AGGREGATION_PATH = `/${DATA_AGGREGATION_ROUTE}`;
export const CHECKOUT_ROUTE = 'checkout';
export const CHECKOUT_PATH = `/${CHECKOUT_ROUTE}`;
export const COMPARE_ROUTE = 'compare';
export const COMPARE_PATH = `/${COMPARE_ROUTE}`;
export const OVERVIEW_ROUTE = 'overview';
export const OVERVIEW_PATH = `/${OVERVIEW_ROUTE}`;
export const QUESTIONNAIRE_ROUTE = 'questionnaire';
export const QUESTIONNAIRE_PATH = `/${QUESTIONNAIRE_ROUTE}`;
export const RESULTS_ROUTE = 'results';
export const RESULTS_PATH = `/${RESULTS_ROUTE}`;
export const CONTACT_ROUTE = 'contact';
export const CONTACT_PATH = `/${CONTACT_ROUTE}`;
export const END_STATE_ROUTE = 'end-state';
export const END_STATE_PATH = `/${END_STATE_ROUTE}`;
export const PENSION_MOVE_ROUTE = 'pension-move';
export const PENSION_MOVE_PATH = `/${PENSION_MOVE_ROUTE}`;
